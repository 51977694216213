/** 依頼区分 */
export const requestCategorys = {
  /** 一般 */
  General: "1",
  /** 発注書返送処理 */
  Catalog: "2",
  /** 医用広告データ */
  medical_ad_data: "3",
  /** 院内パネル */
  hospital_panel_data: "4",
  /** 特殊印刷詳細 */
  special_printing_detail: "5",
};
