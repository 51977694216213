<template>
  <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel">
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="message"
        ><p class="text-h6 text--primary">{{ message }}</p></v-card-text
      >
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click.native="cancel">{{
          options.cancelmessage
        }}</v-btn>
        <v-btn
          v-if="isCancelOnly"
          color="blue darken-1"
          text
          @click.native="agree"
          >{{ options.agreemessage }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: "indigo darken-2",
      width: 400,
      agreemessage: "はい",
      cancelmessage: "いいえ",
      cancelOnly: false,
    },
  }),
  computed: {
    isCancelOnly() {
      return !this.options.cancelOnly;
    },
  },
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
