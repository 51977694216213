export default {
  data() {
    return {
      costBurdenDepartmentHeaders: [
        {
          text: "依頼所属部門",
          value: "department_name",
          sortable: false,
          type: "string",
        },
        {
          text: "所属部門コード",
          value: "department_code",
          sortable: false,
          type: "string",
        },
        {
          text: "率",
          value: "rate",
          sortable: false,
          type: "number",
          custom: { suffix: "%" },
        },
        { text: "操作", value: "actions", sortable: false, type: "action" },
      ],
      caseDetailHeaders: [
        {
          text: "依頼項目名",
          value: "request_content",
          sortable: false,
          type: "text",
        },
        {
          text: "原価",
          value: "cost",
          sortable: false,
          type: "text",
          custom: { suffix: "%", type: "number" },
        },
        {
          text: "売価",
          value: "selling_price",
          sortable: false,
          type: "text",
          custom: { suffix: "%", type: "number" },
        },
        { text: "操作", value: "actions", sortable: false, type: "action" },
      ],
      obicHeaders: [
        {
          text: "ステータス",
          value: "status",
          sortable: false,
          type: "text",
        },
        {
          text: "受注番号",
          value: "obic_order_no",
          sortable: false,
          type: "text",
        },
        {
          text: "受注区分",
          value: "order_category_disp",
          sortable: false,
          type: "text",
        },
        {
          text: "商品名",
          value: "product_name_1",
          sortable: false,
          type: "text",
        },
        {
          text: "売上総計",
          value: "total_ex_tax_detail_sell_price",
          sortable: false,
          type: "number",
        },
        {
          text: "仕入先名",
          value: "supplier_name",
          sortable: false,
          type: "text",
        },
        {
          text: "仕入総計",
          value: "total_ex_tax_detail_buy_amount",
          sortable: false,
          type: "number",
        },
        {
          text: "発注書発行日",
          value: "purchase_order_issue_date",
          sortable: false,
          type: "text",
        },
        {
          text: "完了フラグ",
          value: "finish_flag_disp",
          sortable: false,
          type: "text",
        },
        {
          text: "完了日",
          value: "finish_date",
          sortable: false,
          type: "text",
        },
        { text: "操作", value: "actions", sortable: false, type: "action" },
      ],
      obicCostBurdenDepartmentHeaders: [
        {
          text: "依頼部門",
          value: "cost_burden_customer_name",
          sortable: false,
          type: "string",
        },
        {
          text: "部門コード",
          value: "cost_burden_customer_code",
          sortable: false,
          type: "string",
        },
        {
          text: "金額",
          value: "total_burden_amount",
          sortable: false,
          type: "number",
        },
        {
          text: "率",
          value: "average_input_burden_rate",
          sortable: false,
          type: "number",
          custom: { suffix: "%" },
        },
      ],
      quotationDetailHeaders: [
        {
          text: "No",
          value: "detail_no",
          sortable: false,
        },
        {
          text: "項目",
          value: "item",
          sortable: false,
        },
        {
          text: "内容",
          value: "content",
          sortable: false,
        },
        {
          text: "数量",
          value: "quantity",
          sortable: false,
          align: "end",
        },
        {
          text: "",
          value: "unit",
          sortable: false,
        },
        {
          text: "単価",
          value: "unit_cost",
          sortable: false,
        },
        {
          text: "金額",
          value: "amount",
          sortable: false,
          align: "end",
        },
        { text: "操作", value: "actions", sortable: false },
      ],
      quotationDetailHeadersReport: [
        {
          text: "No",
          value: "detail_no",
          sortable: false,
        },
        {
          text: "項目",
          value: "item",
          sortable: false,
        },
        {
          text: "内容",
          value: "content",
          sortable: false,
        },
        {
          text: "数量",
          value: "quantity",
          sortable: false,
          align: "end",
        },
        {
          text: "",
          value: "unit",
          sortable: false,
        },
        {
          text: "単価",
          value: "unit_cost",
          sortable: false,
        },
        {
          text: "金額",
          value: "amount",
          sortable: false,
        },
      ],
      orderReceiptHeaders: [
        {
          text: "No",
          value: "detail_no",
          sortable: false,
        },
        {
          text: "項目",
          value: "item",
          sortable: false,
        },
        {
          text: "内容",
          value: "content",
          sortable: false,
        },
        {
          text: "数量",
          value: "quantity",
          sortable: false,
          align: "end",
        },
        {
          text: "",
          value: "unit",
          sortable: false,
        },
        {
          text: "単価",
          value: "unit_cost",
          sortable: false,
        },
        {
          text: "金額",
          value: "amount",
          sortable: false,
        },
      ],
      deliveryCertCostBurdenDepHeaders: [
        {
          text: "No",
          value: "delivery_cert_detail_no",
          sortable: false,
        },
        {
          text: "部門",
          value: "department.code",
          sortable: false,
        },
        {
          text: "部門名",
          value: "department.name",
          sortable: false,
        },
        {
          text: "負担金額",
          value: "amount",
          sortable: false,
        },
        {
          text: "率",
          value: "rate",
          sortable: false,
        },
      ],
    };
  },
};
