import axios from "../axios";

import store from "../../store";
import router from "../../router";
import i18n from "../i18n";
import { Notify } from "vuetify-message-snackbar";

let lock = false; // refresh lock
const originRequestFactories = []; // buffer

/**
 * wait token refresh until finished then redo/continue prior requests
 * @param config prior request config
 * @returns {Promise<unknown>}
 */
export default (config) => {
  // when not refreshing
  if (!lock) {
    lock = true;
    store
      .dispatch("refreshToken")
      .then((newToken) => {
        // replace token with new access token then retry request
        originRequestFactories.forEach((callback) => callback(newToken));
      })
      .catch(() => {
        originRequestFactories.forEach((callback) => callback());
        Notify.error(i18n.t("messages.auth.authCheckError"));
        router.push({ name: "login" });
      })
      .finally(() => {
        // reset
        lock = false;
        originRequestFactories.splice(0);
      });
  }
  // when refreshing
  return new Promise((resolve, reject) => {
    // add pending request factories to buffer
    originRequestFactories.push((newToken) => {
      if (newToken) {
        config.headers.authorization = `Bearer ${newToken}`;
        resolve(axios(config));
      } else {
        reject();
      }
    });
  });
};
