<template>
  <v-container class="lighten-5" id="main_container">
    <v-form ref="inputForm" lazy-validation>
      <!--承認フロー-->
      <v-card class="mx-auto mt-5" width="60rem">
        <WorkflowAction
          :id="displayWorkflowId"
          v-on:updated="changeWorkflowHistoryKey()"
        ></WorkflowAction>
      </v-card>

      <!--承認履歴-->
      <WorkflowHistory
        :refreshKey="workflowHistoryKey"
        :workflow_id="workflowId"
      ></WorkflowHistory>
    </v-form>
  </v-container>
</template>
<script>
import groupPermissionMixin from "../../mixins/groupPermissionMixin";
import updateMixin from "../../mixins/updateMixin";
import relationMixin from "../../mixins/relationMixin";
import fieldPermissionMixin from "../../mixins/fieldPermissionMixin";
import { validationMixin } from "vuelidate";
import headersMixin from "../../mixins/headersMixin";
import { mapGetters } from "vuex";

export default {
  resource: "specificApprovalRoutes",

  mixins: [
    groupPermissionMixin,
    fieldPermissionMixin,
    updateMixin,
    validationMixin,
    headersMixin,
    relationMixin,
  ],
  data() {
    return {
      formData: {
        quotation_id: 0,
        division: "",
      },
      specificApprovalRouteData: {},
      dialog: false,
      workflowHistoryKey: 0,
    };
  },
  methods: {
    async fetchData(id) {
      this.loading = true;
      try {
        const [specificApprovalRouteData] = await Promise.all([
          this.$api.getOne(this.$options.resource, id),
        ]);
        this.specificApprovalRouteData = specificApprovalRouteData;
        this.$utils.update(this.formData, specificApprovalRouteData);
      } finally {
        this.loading = false;
      }
    },
    /**
     * 承認履歴再描画用のキー変更処理
     */
    changeWorkflowHistoryKey() {
      this.workflowHistoryKey++;
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    // 明細の入力ダイアログ用Validation
    workflowId() {
      return [
        this.specificApprovalRouteData?.adcom_workflow,
        this.specificApprovalRouteData?.client_workflow,
      ].filter((id) => id);
    },
    displayWorkflowId() {
      return this.hasPerm(`adcomStaff`, `adcomManager`)
        ? this.specificApprovalRouteData?.adcom_workflow || null
        : this.specificApprovalRouteData?.client_workflow || null;
    },
  },
};
</script>
