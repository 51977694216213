import {
  VDataTable,
  VDivider,
  VDialog,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
  VSpacer,
  VToolbar,
  VToolbarTitle,
  VForm,
} from "vuetify/lib";

export default {
  name: "DataTable",
  components: {
    VDataTable,
    VDivider,
    VDialog,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VSpacer,
    VToolbar,
    VToolbarTitle,
    VForm,
  },
  props: {
    resource: {
      type: String,
      required: true,
    },
    parentRelationName: {
      type: String,
      required: true,
    },
    parentId: {
      type: Number,
      required: false,
      default: null,
    },
    headers: {
      type: Array,
      required: true,
    },
    defaultItem: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
    fileFields: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
    itemValidations: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
    showDeleteConfirm: {
      type: Boolean,
      required: false,
      default: true,
    },
    showReferenceButton: {
      type: [Boolean, Function],
      required: false,
      default: false,
    },
    showAddButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    showEditButton: {
      type: [Boolean, Function],
      required: false,
      default: false,
    },
    showDeleteButton: {
      type: [Boolean, Function],
      required: false,
      default: false,
    },
    showParticularRowDeleteButton: {
      type: [Boolean, Function],
      default: false,
      required: false
    },
    dialogFormMaxWidth: { type: String, required: false, default: "500px" },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      dialogDelete: false,
      dialogReference: false,
      editedIndex: -1,
      items: [],
      editedItem: { id: null, ...this.defaultItem },
    };
  },
  validations() {
    return {
      editedItem: this.itemValidations,
    };
  },
  computed: {
    dialogTitle() {
      return this.editedIndex === -1
        ? this.$t("actions.insert")
        : this.$t("actions.update");
    },
  },
  watch: {
    parentId: {
      async handler(newId) {
        await this.load(newId);
      },
      immediate: true,
    },
    defaultItem: {
      handler(newItem) {
        this.editedItem = { id: null, ...newItem };
      },
    },
  },
  methods: {
    updateEditedItem(item) {
      const itemData = { ...item };
      this.fileFields.forEach((field) => {
        itemData[field] = {
          link: itemData[field] || null,
          file: null,
          deleted: false,
        };
      });
      this.$utils.update(this.editedItem, itemData);
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.updateEditedItem(item);
      this.$emit("edit", item);
      this.dialog = true;
    },

    referenceItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.updateEditedItem(item);
      this.dialogReference = true;
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.updateEditedItem(item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.loading = true;
      try {
        await this.$api.delete(this.resource, this.editedItem.id);
        this.items.splice(this.editedIndex, 1);
        this.$emit("delete", this.editedIndex);
      } finally {
        this.loading = false;
      }
      this.closeDelete();
    },

    open(e) {
      e.stopPropagation();
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.dialogReference = false;
      this.$nextTick(() => {
        this.$utils.update(this.editedItem, this.defaultItem);
        this.editedItem.id = null;
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.$utils.update(this.editedItem, this.defaultItem);
        this.editedItem.id = null;
        this.editedIndex = -1;
      });
    },

    // @vuese
    // データ取得(全件)
    async load(parent_id) {
      this.loading = true;
      this.$v.$touch();
      try {
        if (!Number.isInteger(parent_id)) {
          this.items = [];
        } else {
          this.items = await this.$api.getMany(this.resource, {
            [this.parentRelationName]: parent_id,
          });
        }
      } finally {
        this.loading = false;
      }
    },

    // @vuese
    // 登録・更新
    async save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          if (this.editedIndex > -1) {
            const { id, ...updateItem } = this.editedItem;
            let fileFormData;
            if (this.fileFields.length > 0) {
              fileFormData = new FormData();
              this.fileFields.forEach((field) => {
                const { file, deleted } = updateItem[field];
                if (!file && deleted) {
                  updateItem[field] = null;
                } else if (file) {
                  fileFormData.append(field, file);
                  delete updateItem[field];
                } else {
                  delete updateItem[field];
                }
              });
            }
            let item;
            item = await this.$api.update(this.resource, id, updateItem);
            if (fileFormData && Array.from(fileFormData.keys()).length > 0) {
              fileFormData.append("version", item.version);
              item = await this.$api.update(
                this.resource,
                id,
                fileFormData,
                true
              );
            }
            this.$emit("update", item);
          } else {
            const createItem = { ...this.editedItem };
            let fileFormData;
            if (this.fileFields.length > 0) {
              fileFormData = new FormData();
              this.fileFields.forEach((field) => {
                const { file } = createItem[field];
                if (file) {
                  fileFormData.append(field, file);
                }
                delete createItem[field];
              });
            }
            let item;
            item = await this.$api.create(this.resource, {
              ...createItem,
              [this.parentRelationName]: this.parentId,
            });
            if (fileFormData && Array.from(fileFormData.keys()).length > 0) {
              fileFormData.append("version", item.version);
              item = await this.$api.update(
                this.resource,
                item.id,
                fileFormData,
                true
              );
            }
            this.$emit("create", item);
          }
          this.close();
        } finally {
          this.$refs.editForm?.reset();
          await this.load(this.parentId);
          this.loading = false;
        }
      }
    },
  },
  render() {
    const customizedSlots = {};

    this.headers.forEach(({ type, value, custom }) => {
      switch (type) {
        case "link":
          customizedSlots[`item.${value}`] = ({ item }) => {
            const redirect = () => {
              this.$router.push({
                name: `${custom.name}`,
              });
            };
            return (
              <v-btn text color="primary" vOn: click={redirect}>
                {item.text}
              </v-btn>
            );
          };
          break;
        default:
          return;
      }
    });

    if (
      this.showAddButton ||
      this.showEditButton ||
      this.showDeleteButton ||
      this.showReferenceButton
    ) {
      customizedSlots["top"] = () => {
        return (
          <v-toolbar flat>
            <v-toolbar-title>
              {this.$t(`resources.${this.resource}.name`)}
            </v-toolbar-title>
            {/* <v-divider class="mx-4" inset vertical></v-divider> */}
            <v-spacer></v-spacer>
            {this.showAddButton && (
              <v-btn
                color="primary"
                dark
                class="mb-2"
                disabled={this.loading}
                loading={this.loading}
                vOn: click={this.open}
              >
                {this.$t("actions.add")}
              </v-btn>
            )}
            <v-dialog vModel={this.dialog} max-width={this.dialogFormMaxWidth}>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{this.dialogTitle}</span>
                </v-card-title>

                <v-card-text>
                  <v-form ref="editForm">
                    {this.$scopedSlots.dialogForm({
                      item: this.editedItem,
                      validator: this.$v,
                    })}
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text vOn: click={this.close}>
                    {this.$t("actions.cancel")}
                  </v-btn>
                  <v-btn color="blue darken-1" text vOn: click={this.save}>
                    {this.$t("actions.save")}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              vModel={this.dialogDelete}
              max-width={this.dialogFormMaxWidth}
            >
              <v-card>
                <v-card-title class="text-h5">
                  {this.$t("messages.actions.confirmDelete")}
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    vOn: click={this.closeDelete}
                  >
                    {this.$t("actions.cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    disabled={this.loading}
                    loading={this.loading}
                    vOn: click={this.deleteItemConfirm}
                  >
                    {this.$t("actions.confirm")}
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              vModel={this.dialogReference}
              max-width={this.dialogFormMaxWidth}
            >
              <v-card>
                <v-card-title>{this.$t("actions.reference")}</v-card-title>

                <v-card-text>
                  {this.$scopedSlots.dialogForm({
                    item: this.editedItem,
                    validator: this.$v,
                  })}
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text vOn: click={this.close}>
                    {this.$t("actions.close")}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        );
      };
    }

    if (
      this.showEditButton ||
      this.showDeleteButton ||
      this.showReferenceButton
    ) {
      if (this.headers?.some(({ value }) => value === "actions")) {
        customizedSlots["item.actions"] = ({ item }) => {
          const onDeleteClick = () => this.deleteItem(item);
          const onUpdateClick = () => this.editItem(item);
          const onReferenceClick = () => this.referenceItem(item);
          const showEdit =
            this.showEditButton instanceof Function
              ? this.showEditButton(item)
              : this.showEditButton;
          const showDelete =
            this.showDeleteButton instanceof Function
              ? this.showDeleteButton(item)
              : this.showDeleteButton;
          const showReferance =
            this.showReferenceButton instanceof Function
              ? this.showReferenceButton(item)
              : this.showReferenceButton;
          const showParticularRowDeleteIcon = this.showParticularRowDeleteButton instanceof Function ? this.showParticularRowDeleteButton(item) : this.showParticularRowDeleteButton
          return (
            <span>
              {showEdit ? (
                <v-icon small class="mr-2" vOn: click={onUpdateClick}>
                  mdi-pencil
                </v-icon>
              ) : null}
              {showDelete && !showParticularRowDeleteIcon ? (
                <v-icon small vOn: click={onDeleteClick}>
                  mdi-delete
                </v-icon>
              ) : null}
              {showReferance ? (
                <v-icon small class="mr-2" vOn: click={onReferenceClick}>
                  mdi-monitor
                </v-icon>
              ) : null}
            </span>
          );
        };
      }
    }

    return (
      <v-data-table
        headers={this.headers}
        items={this.items}
        {...{
          attrs: { ...this.$attrs },
          scopedSlots: { ...this.$scopedSlots, ...customizedSlots },
        }}
        disable-pagination
        hide-default-footer
      ></v-data-table>
    );
  },
};
