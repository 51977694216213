import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { VSnackbar, VBtn, VIcon } from "vuetify/lib";

import i18n from "./i18n";
import message from "vuetify-message-snackbar";

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
});

const vuetify = new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
});

Vue.use(message, { vuetifyInstance: vuetify });

export default vuetify;
