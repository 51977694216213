<template>
  <v-text-field
    v-bind="$attrs"
    :value="value"
    @input="onInput"
    :label="label"
    type="date"
    persistent-hint
    :clearable="clearable"
    max="9999-12-31"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  methods: {
    onInput(val) {
      this.$emit("input", val);
    },
  },
};
</script>
