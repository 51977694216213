export const authPayload = {
  login: "auth/login/",
  refreshToken: "auth/token/refresh/",
  verifyToken: "auth/token/verify/",
  user: "auth/user/",
  signup: "/auth/registration/",
  passwordReset: "/auth/password/reset/",
  passwordResetConfirm: "/auth/password/reset/confirm/",
};

export const identities = {
  adcomManager: ["is_adcom", "is_manager"],
  adcomStaff: ["is_adcom", "is_staff"],
  adcomProcurer: ["is_adcom", "is_procurer"],
  adcomProvision: ["is_adcom", "is_provision"],
  adcomDuty: ["is_adcom", "is_duty"],
  clientManager: ["is_client", "is_manager"],
  clientStaff: ["is_client", "is_staff"],
  adcom: ["is_adcom"],
  client: ["is_client"],
};
