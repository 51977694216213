var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"lighten-5 overflow-hidden mx-auto",attrs:{"id":"main_container"}},[_c('form-wrapper',{attrs:{"validator":_vm.$v.formData}},[_c('v-form',{ref:"inputForm",attrs:{"lazy-validation":""}},[_c('h2',[_vm._v(_vm._s(_vm.$t("resources.cases.create_name")))]),_c('v-card',{staticClass:"mx-auto mt-5",attrs:{"max-width":"100%"}},[_c('v-system-bar',{staticClass:"pa-5",attrs:{"color":"indigo darken-2","dark":""}},[_vm._v(" "+_vm._s(_vm.$t("resources.cases.client_Info"))+" ")]),_c('v-container',{staticClass:"pa-5",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',{staticClass:"d-flex"},[_c('v-col',{attrs:{"cols":"12","lg":"2","md":"4"}},[_c('v-text-field',{staticClass:"text-field-border-radius",attrs:{"label":_vm.$t('resources.cases.fields.quotation_request_date'),"readonly":"","rounded":""},model:{value:(_vm.formData.quotation_request_date),callback:function ($$v) {_vm.$set(_vm.formData, "quotation_request_date", $$v)},expression:"formData.quotation_request_date"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"2","md":"4"}},[_c('form-group',{attrs:{"validator":_vm.$v.formData.client_user,"attribute":"resources.cases.fields.client_user"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('ReferenceAutocomplete',_vm._b({attrs:{"resource":"users","value":"id","text":"all_name","label":_vm.$t('resources.cases.fields.client_user'),"outlined":!_vm.isClient,"readonly":_vm.isClient,"rounded":_vm.isClient},on:{"input:item":_vm.clientUserSelected},model:{value:(_vm.formData.client_user),callback:function ($$v) {_vm.$set(_vm.formData, "client_user", $$v)},expression:"formData.client_user"}},'ReferenceAutocomplete',attrs,false))]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"2","md":"4"}},[_c('v-text-field',{staticClass:"text-field-border-radius",attrs:{"value":_vm.clientUser.department.name,"label":_vm.$t('resources.cases.fields.client_department'),"readonly":"","rounded":""}})],1),_c('v-col',{attrs:{"cols":"12","lg":"2","md":"4"}},[_c('v-text-field',{staticClass:"text-field-border-radius",attrs:{"value":_vm.clientUser.tel,"label":_vm.$t('resources.cases.fields.client_tel'),"readonly":"","rounded":""}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"4"}},[_c('v-text-field',{staticClass:"text-field-border-radius",attrs:{"value":_vm.clientUser.email,"label":_vm.$t('resources.cases.fields.client_mail'),"readonly":"","rounded":""}})],1)],1)],1)],1)],1)],1),_c('v-card',{staticClass:"mx-auto mt-5",attrs:{"max-width":"100%"}},[_c('v-system-bar',{staticClass:"pa-5",attrs:{"color":"indigo darken-2","dark":""}},[_vm._v(" "+_vm._s(_vm.$t("resources.cases.order_Info"))+" ")]),_c('v-container',{staticClass:"pa-5",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('form-group',{attrs:{"validator":_vm.$v.formData.request_title,"attribute":"resources.cases.fields.request_title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"maxlength":"140","outlined":"","label":_vm.$t('resources.cases.fields.request_title')},model:{value:(_vm.formData.request_title),callback:function ($$v) {_vm.$set(_vm.formData, "request_title", $$v)},expression:"formData.request_title"}},'v-text-field',attrs,false))]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"3","md":"4"}},[_c('form-group',{attrs:{"validator":_vm.$v.formData.preferred_due_date,"attribute":"resources.cases.fields.preferred_due_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('DatePicker',_vm._b({attrs:{"label":_vm.$t('resources.cases.fields.preferred_due_date'),"outlined":""},model:{value:(_vm.formData.preferred_due_date),callback:function ($$v) {_vm.$set(_vm.formData, "preferred_due_date", $$v)},expression:"formData.preferred_due_date"}},'DatePicker',attrs,false))]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-row',[_c('v-col',[_c('form-group',{attrs:{"validator":_vm.$v.formData.draft_file_0},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('FileInput',_vm._b({ref:"draftFileInput",attrs:{"outlined":"","accept":"*","label":_vm.$t('resources.cases.fields.draft_file'),"messages":_vm.$t('resources.cases.messages.draft_file')},model:{value:(_vm.formData.draft_file[0]),callback:function ($$v) {_vm.$set(_vm.formData.draft_file, 0, $$v)},expression:"formData.draft_file[0]"}},'FileInput',attrs,false))]}}])})],1)],1)],1)],1),_c('v-row',{staticClass:"mb-1 d-flex justify-end"},[_c('v-btn',{staticClass:"text-right",attrs:{"color":"primary","depressed":""},on:{"click":function($event){_vm.showMultipleFileUpload = !_vm.showMultipleFileUpload}}},[(!_vm.showMultipleFileUpload)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("resources.cases.buttonOpenClose.showFileButton"))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("resources.cases.buttonOpenClose.hideFileButton"))+" ")])])],1),(_vm.showMultipleFileUpload)?_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-row',[_c('v-col',[_c('form-group',{attrs:{"validator":_vm.$v.formData.draft_file_1},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('FileInput',_vm._b({ref:"draftFileInput",attrs:{"outlined":"","accept":"*","label":_vm.$t('resources.cases.fields.draft_file')},model:{value:(_vm.formData.draft_file[1]),callback:function ($$v) {_vm.$set(_vm.formData.draft_file, 1, $$v)},expression:"formData.draft_file[1]"}},'FileInput',attrs,false))]}}],null,false,1422948877)})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-row',[_c('v-col',[_c('form-group',{attrs:{"validator":_vm.$v.formData.draft_file_2},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('FileInput',_vm._b({ref:"draftFileInput",attrs:{"outlined":"","accept":"*","label":_vm.$t('resources.cases.fields.draft_file')},model:{value:(_vm.formData.draft_file[2]),callback:function ($$v) {_vm.$set(_vm.formData.draft_file, 2, $$v)},expression:"formData.draft_file[2]"}},'FileInput',attrs,false))]}}],null,false,186136270)})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-row',[_c('v-col',[_c('form-group',{attrs:{"validator":_vm.$v.formData.draft_file_3},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('FileInput',_vm._b({ref:"draftFileInput",attrs:{"outlined":"","accept":"*","label":_vm.$t('resources.cases.fields.draft_file')},model:{value:(_vm.formData.draft_file[3]),callback:function ($$v) {_vm.$set(_vm.formData.draft_file, 3, $$v)},expression:"formData.draft_file[3]"}},'FileInput',attrs,false))]}}],null,false,1678086159)})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-row',[_c('v-col',[_c('form-group',{attrs:{"validator":_vm.$v.formData.draft_file_4},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('FileInput',_vm._b({ref:"draftFileInput",attrs:{"outlined":"","accept":"*","label":_vm.$t('resources.cases.fields.draft_file')},model:{value:(_vm.formData.draft_file[4]),callback:function ($$v) {_vm.$set(_vm.formData.draft_file, 4, $$v)},expression:"formData.draft_file[4]"}},'FileInput',attrs,false))]}}],null,false,2567937992)})],1)],1)],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-row',[_c('v-col',[_c('FileLinkInput',{ref:"draftFileLinkInput",attrs:{"outlined":"","label":_vm.$t('resources.cases.fields.draft_file_link')},model:{value:(_vm.formData.draft_file_link),callback:function ($$v) {_vm.$set(_vm.formData, "draft_file_link", $$v)},expression:"formData.draft_file_link"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.formData.request_category),callback:function ($$v) {_vm.$set(_vm.formData, "request_category", $$v)},expression:"formData.request_category"}},[_c('v-radio',{attrs:{"label":_vm.$t('resources.cases.fields.general'),"value":"1"},on:{"click":function($event){_vm.general = false}}}),(_vm.isNormal)?_c('v-radio',{attrs:{"label":_vm.$t('resources.cases.fields.catalog'),"value":"2"},on:{"click":function($event){_vm.general = true}}}):_vm._e(),(_vm.isNormal)?_c('v-radio',{attrs:{"label":_vm.$t('resources.cases.fields.medical_ad_data'),"value":"3"}}):_vm._e(),(_vm.isNormal)?_c('v-radio',{attrs:{"label":_vm.$t('resources.cases.fields.hospital_panel_data'),"value":"4"}}):_vm._e(),(_vm.isNormal)?_c('v-radio',{attrs:{"label":_vm.$t(
                          'resources.cases.fields.special_printing_detail_header'
                        ),"value":"5"}}):_vm._e()],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.request_category == '2'),expression:"formData.request_category == '2'"}]},[_c('div',{staticClass:"d-flex justify-space-between flex-wrap"},[_c('div',[_c('form-group',{attrs:{"validator":_vm.$v.formData.c_no,"attribute":"resources.cases.fields.c_no"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"outlined":"","maxlength":"15","label":_vm.$t('resources.cases.fields.c_no')},model:{value:(_vm.formData.c_no),callback:function ($$v) {_vm.$set(_vm.formData, "c_no", $$v)},expression:"formData.c_no"}},'v-text-field',attrs,false))]}}])})],1),_c('div',[_c('ReferenceSelect',{attrs:{"resource":"editions","label":_vm.$t('resources.cases.fields.edition'),"outlined":""},model:{value:(_vm.formData.edition),callback:function ($$v) {_vm.$set(_vm.formData, "edition", $$v)},expression:"formData.edition"}})],1),_c('div',[_c('form-group',{attrs:{"validator":_vm.$v.formData.c_revision_no,"attribute":"resources.cases.fields.c_revision_no"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"outlined":"","maxlength":"2","label":_vm.$t('resources.cases.fields.c_revision_no')},model:{value:(_vm.formData.c_revision_no),callback:function ($$v) {_vm.$set(_vm.formData, "c_revision_no", $$v)},expression:"formData.c_revision_no"}},'v-text-field',attrs,false))]}}])})],1),_c('div',[_c('form-group',{attrs:{"validator":_vm.$v.formData.language_class,"attribute":"resources.cases.fields.language_class"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('ReferenceSelect',_vm._b({attrs:{"resource":"languageClasses","label":_vm.$t('resources.cases.fields.language_class'),"outlined":""},on:{"input:item":_vm.sizeLanguageToggle},model:{value:(_vm.formData.language_class),callback:function ($$v) {_vm.$set(_vm.formData, "language_class", $$v)},expression:"formData.language_class"}},'ReferenceSelect',attrs,false))]}}])})],1),_c('div',[_c('form-group',{attrs:{"validator":_vm.$v.formData.page_count,"attribute":"resources.cases.fields.page_count"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"outlined":"","maxlength":"3","label":_vm.$t('resources.cases.fields.page_count')},model:{value:(_vm.formData.page_count),callback:function ($$v) {_vm.$set(_vm.formData, "page_count", $$v)},expression:"formData.page_count"}},'v-text-field',attrs,false))]}}])})],1),_c('div',[_c('form-group',{attrs:{"validator":_vm.$v.formData.delivery_form,"attribute":"resources.cases.fields.delivery_form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('ReferenceSelect',_vm._b({attrs:{"resource":"deliveryForms","label":_vm.$t('resources.cases.fields.delivery_form'),"outlined":""},model:{value:(_vm.formData.delivery_form),callback:function ($$v) {_vm.$set(_vm.formData, "delivery_form", $$v)},expression:"formData.delivery_form"}},'ReferenceSelect',attrs,false))]}}])})],1),_c('div',[_c('form-group',{attrs:{"validator":_vm.$v.formData.size,"attribute":"resources.cases.fields.size"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"outlined":"","maxlength":"15","label":_vm.$t('resources.cases.fields.size')},model:{value:(_vm.formData.size),callback:function ($$v) {_vm.$set(_vm.formData, "size", $$v)},expression:"formData.size"}},'v-text-field',attrs,false))]}}])})],1),_c('div',[_c('form-group',{attrs:{"validator":_vm.$v.formData.old_c_no,"attribute":"resources.cases.fields.old_c_no"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"outlined":"","maxlength":"15","label":_vm.$t('resources.cases.fields.old_c_no')},model:{value:(_vm.formData.old_c_no),callback:function ($$v) {_vm.$set(_vm.formData, "old_c_no", $$v)},expression:"formData.old_c_no"}},'v-text-field',attrs,false))]}}])})],1),_c('div',[_c('form-group',{attrs:{"validator":_vm.$v.formData.old_c_revision_no,"attribute":"resources.cases.fields.old_c_revision_no"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"outlined":"","maxlength":"2","label":_vm.$t('resources.cases.fields.old_c_revision_no')},model:{value:(_vm.formData.old_c_revision_no),callback:function ($$v) {_vm.$set(_vm.formData, "old_c_revision_no", $$v)},expression:"formData.old_c_revision_no"}},'v-text-field',attrs,false))]}}])})],1),_c('div',[_c('form-group',{attrs:{"validator":_vm.$v.formData.old_processing,"attribute":"resources.cases.fields.old_processing"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('ReferenceSelect',_vm._b({attrs:{"resource":"processings","label":_vm.$t('resources.cases.fields.old_processing'),"outlined":""},model:{value:(_vm.formData.old_processing),callback:function ($$v) {_vm.$set(_vm.formData, "old_processing", $$v)},expression:"formData.old_processing"}},'ReferenceSelect',attrs,false))]}}])})],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.request_category == '3'),expression:"formData.request_category == '3'"}]},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-alert',{attrs:{"dense":"","border":"left","color":"orange darken-2","dark":""}},[_vm._v(_vm._s(_vm.$t( "resources.cases.fields.medical_ad_data_header_message" )))])],1)],1),_c('v-row',{staticClass:"d-flex"},[_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('form-group',{staticClass:"mr-5",attrs:{"validator":_vm.$v.formData.media_name,"attribute":"resources.cases.fields.media_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"maxlength":"100","label":_vm.$t('resources.cases.fields.media_name'),"outlined":""},model:{value:(_vm.formData.media_name),callback:function ($$v) {_vm.$set(_vm.formData, "media_name", $$v)},expression:"formData.media_name"}},'v-text-field',attrs,false))]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('form-group',{attrs:{"validator":_vm.$v.formData.ad_format,"attribute":"resources.cases.fields.ad_format"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('ReferenceSelect',_vm._b({attrs:{"resource":"adFormats","label":_vm.$t('resources.cases.fields.ad_format'),"outlined":""},on:{"input:item":_vm.adFormatSelected},model:{value:(_vm.formData.ad_format),callback:function ($$v) {_vm.$set(_vm.formData, "ad_format", $$v)},expression:"formData.ad_format"}},'ReferenceSelect',attrs,false))]}}])})],1)],1),_c('v-row',{staticClass:"d-flex"},[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('form-group',{attrs:{"validator":_vm.$v.formData.is_ad_specs,"attribute":"resources.cases.fields.is_ad_specs"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-checkbox',_vm._b({attrs:{"label":_vm.$t('resources.cases.fields.is_ad_specs'),"outlined":""},model:{value:(_vm.formData.is_ad_specs),callback:function ($$v) {_vm.$set(_vm.formData, "is_ad_specs", $$v)},expression:"formData.is_ad_specs"}},'v-checkbox',attrs,false))]}}])})],1),(_vm.formData.is_ad_specs)?_c('v-col',{attrs:{"cols":"12","lg":"9"}},[_c('form-group',{attrs:{"validator":_vm.$v.formData.ad_sepecs_file},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('FileInput',_vm._b({ref:"adSpecsFileInput",attrs:{"outlined":"","accept":"*","disableUpload":!_vm.formData.is_ad_specs,"label":_vm.$t('resources.cases.fields.ad_sepecs_file'),"messages":_vm.$t('resources.cases.messages.draft_file')},model:{value:(_vm.formData.ad_sepecs_file),callback:function ($$v) {_vm.$set(_vm.formData, "ad_sepecs_file", $$v)},expression:"formData.ad_sepecs_file"}},'FileInput',attrs,false))]}}],null,false,1836966062)})],1):_vm._e()],1),_c('v-row',{staticClass:"d-flex"},[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('form-group',{attrs:{"validator":_vm.$v.formData.plate_size,"attribute":"resources.cases.fields.plate_size"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('ReferenceSelect',_vm._b({attrs:{"resource":"plateSizes","label":_vm.$t('resources.cases.fields.plate_size'),"outlined":""},on:{"input:item":_vm.plateSizeSelected},model:{value:(_vm.formData.plate_size),callback:function ($$v) {_vm.$set(_vm.formData, "plate_size", $$v)},expression:"formData.plate_size"}},'ReferenceSelect',attrs,false))]}}])})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","lg":"4"}},[(_vm.isPlateSizeInputOther)?_c('form-group',{staticClass:"mr-5",attrs:{"validator":_vm.$v.formData.plate_height,"attribute":"resources.cases.fields.plate_height"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"maxlength":"5","label":_vm.$t('resources.cases.fields.plate_height'),"placeholder":_vm.$t('resources.cases.fields.plate_height'),"outlined":""},model:{value:(_vm.formData.plate_height),callback:function ($$v) {_vm.$set(_vm.formData, "plate_height", $$v)},expression:"formData.plate_height"}},'v-text-field',attrs,false))]}}],null,false,266081886)}):_vm._e(),(_vm.isPlateSizeInputOther)?_c('form-group',{attrs:{"validator":_vm.$v.formData.plate_width,"attribute":"resources.cases.fields.plate_width"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"maxlength":"5","label":_vm.$t('resources.cases.fields.plate_width'),"placeholder":_vm.$t('resources.cases.fields.plate_width'),"outlined":""},model:{value:(_vm.formData.plate_width),callback:function ($$v) {_vm.$set(_vm.formData, "plate_width", $$v)},expression:"formData.plate_width"}},'v-text-field',attrs,false))]}}],null,false,349878599)}):_vm._e()],1)],1),_c('v-row',{staticClass:"d-flex"},[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('form-group',{attrs:{"validator":_vm.$v.formData.color,"attribute":"resources.cases.fields.color"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('ReferenceSelect',_vm._b({attrs:{"resource":"colors","label":_vm.$t('resources.cases.fields.color'),"outlined":""},on:{"input:item":_vm.colorSelected},model:{value:(_vm.formData.color),callback:function ($$v) {_vm.$set(_vm.formData, "color", $$v)},expression:"formData.color"}},'ReferenceSelect',attrs,false))]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[(_vm.isColorInputOther)?_c('form-group',{staticClass:"mr-5",attrs:{"validator":_vm.$v.formData.other_color,"attribute":"resources.cases.fields.other_color"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"maxlength":"20","label":_vm.$t('resources.cases.fields.other_color'),"placeholder":_vm.$t('resources.cases.fields.other_color'),"outlined":""},model:{value:(_vm.formData.other_color),callback:function ($$v) {_vm.$set(_vm.formData, "other_color", $$v)},expression:"formData.other_color"}},'v-text-field',attrs,false))]}}],null,false,133412387)}):_vm._e()],1)],1),_c('v-row',{staticClass:"d-flex"},[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('form-group',{attrs:{"validator":_vm.$v.formData.ad_no,"attribute":"resources.cases.fields.ad_no"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"maxlength":"20","label":_vm.$t('resources.cases.fields.ad_no'),"outlined":""},model:{value:(_vm.formData.ad_no),callback:function ($$v) {_vm.$set(_vm.formData, "ad_no", $$v)},expression:"formData.ad_no"}},'v-text-field',attrs,false))]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"9"}},[_c('form-group',{attrs:{"validator":_vm.$v.formData.ad_name,"attribute":"resources.cases.fields.ad_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"maxlength":"50","label":_vm.$t('resources.cases.fields.ad_name'),"outlined":""},model:{value:(_vm.formData.ad_name),callback:function ($$v) {_vm.$set(_vm.formData, "ad_name", $$v)},expression:"formData.ad_name"}},'v-text-field',attrs,false))]}}])})],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.request_category == '4'),expression:"formData.request_category == '4'"}]},[_c('v-row',{staticClass:"d-flex"},[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('form-group',{attrs:{"validator":_vm.$v.formData.desired_panel_no,"attribute":"resources.cases.fields.desired_panel_no"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"maxlength":"10","label":_vm.$t('resources.cases.fields.desired_panel_no'),"outlined":""},model:{value:(_vm.formData.desired_panel_no),callback:function ($$v) {_vm.$set(_vm.formData, "desired_panel_no", $$v)},expression:"formData.desired_panel_no"}},'v-text-field',attrs,false))]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"9"}},[_c('form-group',{attrs:{"validator":_vm.$v.formData.hospital_name,"attribute":"resources.cases.fields.hospital_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"maxlength":"50","label":_vm.$t('resources.cases.fields.hospital_name'),"outlined":""},model:{value:(_vm.formData.hospital_name),callback:function ($$v) {_vm.$set(_vm.formData, "hospital_name", $$v)},expression:"formData.hospital_name"}},'v-text-field',attrs,false))]}}])})],1)],1),_c('v-row',{staticClass:"d-flex"},[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('form-group',{attrs:{"validator":_vm.$v.formData.panel_size,"attribute":"resources.cases.fields.panel_size"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('ReferenceSelect',_vm._b({attrs:{"resource":"panelSizes","label":_vm.$t('resources.cases.fields.panel_size'),"outlined":""},on:{"input:item":_vm.panelSelected},model:{value:(_vm.formData.panel_size),callback:function ($$v) {_vm.$set(_vm.formData, "panel_size", $$v)},expression:"formData.panel_size"}},'ReferenceSelect',attrs,false))]}}])})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","lg":"5"}},[(_vm.isPanelSizeInputOther)?_c('form-group',{staticClass:"mr-5",attrs:{"validator":_vm.$v.formData.panel_height,"attribute":"resources.cases.fields.panel_height"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({staticClass:"ml-3",attrs:{"maxlength":"5","label":_vm.$t('resources.cases.fields.panel_height'),"placeholder":_vm.$t('resources.cases.fields.panel_height'),"outlined":""},model:{value:(_vm.formData.panel_height),callback:function ($$v) {_vm.$set(_vm.formData, "panel_height", $$v)},expression:"formData.panel_height"}},'v-text-field',attrs,false))]}}],null,false,3003676475)}):_vm._e(),(_vm.isPanelSizeInputOther)?_c('form-group',{staticClass:"mr-5",attrs:{"validator":_vm.$v.formData.panel_width,"attribute":"resources.cases.fields.panel_width"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"maxlength":"5","label":_vm.$t('resources.cases.fields.panel_width'),"placeholder":_vm.$t('resources.cases.fields.panel_width'),"outlined":""},model:{value:(_vm.formData.panel_width),callback:function ($$v) {_vm.$set(_vm.formData, "panel_width", $$v)},expression:"formData.panel_width"}},'v-text-field',attrs,false))]}}],null,false,3580393501)}):_vm._e()],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.request_category == '5'),expression:"formData.request_category == '5'"}]},[_c('form-group',{attrs:{"validator":_vm.$v.formData.special_printing_detail,"attribute":"resources.cases.fields.special_printing_detail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-textarea',_vm._b({attrs:{"outlined":"","name":"input-7-1","label":_vm.$t('resources.cases.fields.special_printing_detail')},model:{value:(_vm.formData.special_printing_detail),callback:function ($$v) {_vm.$set(_vm.formData, "special_printing_detail", $$v)},expression:"formData.special_printing_detail"}},'v-textarea',attrs,false))]}}])})],1),_c('v-row',{staticClass:"d-flex"},[_c('v-col',{attrs:{"cols":"12","lg":"2"}},[_c('form-group',{attrs:{"validator":_vm.$v.formData.request_count,"attribute":"resources.cases.fields.request_count"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"maxlength":"6","outlined":"","label":_vm.$t('resources.cases.fields.request_count'),"messages":_vm.$t('resources.cases.messages.request_count')},on:{"input":function($event){return _vm.requestCountStorageAmountDifferemce()}},model:{value:(_vm.formData.request_count),callback:function ($$v) {_vm.$set(_vm.formData, "request_count", $$v)},expression:"formData.request_count"}},'v-text-field',attrs,false))]}}])})],1)],1),_c('v-row',{staticClass:"d-flex"},[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('form-group',{attrs:{"validator":_vm.$v.formData.delivery_destination,"attribute":"resources.cases.fields.delivery_destination"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('ReferenceSelect',_vm._b({attrs:{"resource":"deliveryDestinations","label":_vm.$t('resources.cases.fields.delivery_destination'),"outlined":""},on:{"input:item":_vm.deliveryDestinationSelected},model:{value:(_vm.formData.delivery_destination),callback:function ($$v) {_vm.$set(_vm.formData, "delivery_destination", $$v)},expression:"formData.delivery_destination"}},'ReferenceSelect',attrs,false))]}}])})],1),(_vm.isDeliveryDestinationManualInput)?_c('v-col',{attrs:{"cols":"12","lg":"9"}},[_c('form-group',{attrs:{"validator":_vm.$v.formData.delivery_destination_manual,"attribute":"resources.cases.fields.direct_delivery"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var attrs = ref.attrs;
return [(_vm.isDeliveryDestinationManualInput)?_c('v-text-field',_vm._b({attrs:{"maxlength":"100","placeholder":_vm.$t(
                            'resources.cases.fields.delivery_destination_manual'
                          ),"outlined":_vm.isDeliveryDestinationManualInput,"readonly":!_vm.isDeliveryDestinationManualInput,"rounded":!_vm.isDeliveryDestinationManualInput},model:{value:(_vm.formData.delivery_destination_manual),callback:function ($$v) {_vm.$set(_vm.formData, "delivery_destination_manual", $$v)},expression:"formData.delivery_destination_manual"}},'v-text-field',attrs,false)):_vm._e()]}}],null,false,2105137836)})],1):_vm._e(),(_vm.isStorageNumberInput)?_c('v-col',{attrs:{"cols":"12","lg":"2"}},[_c('form-group',{attrs:{"validator":_vm.$v.formData.storage_number,"attribute":"resources.cases.fields.storage_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var attrs = ref.attrs;
return [(_vm.isStorageNumberInput)?_c('v-text-field',_vm._b({attrs:{"maxlength":"6","label":_vm.$t('resources.cases.fields.storage_number'),"placeholder":_vm.$t('resources.cases.fields.storage_number'),"outlined":_vm.isStorageNumberInput,"readonly":!_vm.isStorageNumberInput,"rounded":!_vm.isStorageNumberInput},on:{"input":function($event){return _vm.requestCountStorageAmountDifferemce()}},model:{value:(_vm.formData.storage_number),callback:function ($$v) {_vm.$set(_vm.formData, "storage_number", $$v)},expression:"formData.storage_number"}},'v-text-field',attrs,false)):_vm._e()]}}],null,false,3066948486)})],1):_vm._e(),(_vm.isStorageNumberInput)?_c('v-col',{attrs:{"cols":"12","lg":"2"}},[_c('form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"maxlength":"6","label":_vm.$t(
                            'resources.cases.fields.catalog_storage_quantity'
                          ),"placeholder":_vm.$t(
                            'resources.cases.fields.catalog_storage_quantity'
                          ),"outlined":"","readonly":""},on:{"input":function($event){return _vm.requestCountStorageAmountDifferemce()}},model:{value:(_vm.formData.catalog_storage_quantity),callback:function ($$v) {_vm.$set(_vm.formData, "catalog_storage_quantity", $$v)},expression:"formData.catalog_storage_quantity"}},'v-text-field',attrs,false))]}}],null,false,1844925528)})],1):_vm._e()],1),_c('v-row',{staticClass:"d-flex mb-6"},[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('form-group',{attrs:{"validator":_vm.$v.formData.request_remarks,"attribute":"remarks"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var attrs = ref.attrs;
return [_c('v-textarea',_vm._b({attrs:{"outlined":"","name":"input-7-1","label":_vm.$t('remarks')},model:{value:(_vm.formData.request_remarks),callback:function ($$v) {_vm.$set(_vm.formData, "request_remarks", $$v)},expression:"formData.request_remarks"}},'v-textarea',attrs,false))]}}])})],1)],1)],1)],1)],1)],1),_c('v-card',{staticClass:"mx-auto mt-5",attrs:{"max-width":"100%"}},[_c('v-system-bar',{staticClass:"pa-5",attrs:{"color":"indigo darken-2","dark":""}},[_vm._v(" "+_vm._s(_vm.$t("resources.cases.invoice_Info"))+" ")]),_c('v-container',{staticClass:"pa-5",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',[_c('v-col',[_c('form-group',{attrs:{"validator":_vm.$v.formData.expense_account,"attribute":"resources.cases.fields.expense_account"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var attrs = ref.attrs;
return [_c('ReferenceAutocomplete',_vm._b({attrs:{"resource":"expenseAccounts","value":"id","text":"all_name","outlined":"","label":_vm.$t('resources.cases.fields.expense_account')},on:{"input:item":_vm.expenseAccountSelelcted},model:{value:(_vm.formData.expense_account),callback:function ($$v) {_vm.$set(_vm.formData, "expense_account", $$v)},expression:"formData.expense_account"}},'ReferenceAutocomplete',attrs,false))]}}])})],1)],1),_c('v-row',[_c('v-col',[(_vm.isNormal)?_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.formData.billing_category),callback:function ($$v) {_vm.$set(_vm.formData, "billing_category", $$v)},expression:"formData.billing_category"}},[(this.client_department_code.length < 5)?_c('v-radio',{attrs:{"label":_vm.$t('resources.cases.fields.billing_category_1'),"value":"1"}}):_vm._e(),(this.client_department_code.length < 5)?_c('v-radio',{attrs:{"label":_vm.$t('resources.cases.fields.billing_category_2'),"value":"2"}}):_vm._e(),_c('v-radio',{attrs:{"label":_vm.$t('resources.cases.fields.billing_category_3'),"value":"3"}})],1):_vm._e()],1)],1),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.billing_category === '3'),expression:"formData.billing_category === '3'"}],attrs:{"flat":""}},[_c('v-row',{staticClass:"d-flex justify-space-between mb-6"},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('div',[_c('form-group',{attrs:{"validator":_vm.$v.formData.billing_user,"attribute":"resources.cases.fields.billing_user"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"maxlength":"100","label":_vm.$t('resources.cases.fields.billing_user'),"outlined":""},model:{value:(_vm.formData.billing_user),callback:function ($$v) {_vm.$set(_vm.formData, "billing_user", $$v)},expression:"formData.billing_user"}},'v-text-field',attrs,false))]}}])})],1)]),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('div',[_c('form-group',{attrs:{"validator":_vm.$v.formData.billing_department,"attribute":"resources.cases.fields.billing_department"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"maxlength":"100","label":_vm.$t('resources.cases.fields.billing_department'),"outlined":""},model:{value:(_vm.formData.billing_department),callback:function ($$v) {_vm.$set(_vm.formData, "billing_department", $$v)},expression:"formData.billing_department"}},'v-text-field',attrs,false))]}}])})],1)]),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('form-group',{attrs:{"validator":_vm.$v.formData.billing_address,"attribute":"resources.cases.fields.billing_address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"outlined":"","maxlength":"100","label":_vm.$t('resources.cases.fields.billing_address')},model:{value:(_vm.formData.billing_address),callback:function ($$v) {_vm.$set(_vm.formData, "billing_address", $$v)},expression:"formData.billing_address"}},'v-text-field',attrs,false))]}}])})],1)],1)],1)],1)],1)],1)],1),(_vm.isNormal)?_c('v-alert',{staticClass:"mx-auto mt-5 mb-15",attrs:{"dense":"","border":"left","color":"orange darken-2","dark":""}},[_vm._v(_vm._s(_vm.$t("resources.cases.messages.input_request")))]):_vm._e(),_c('v-bottom-navigation',{attrs:{"fixed":"","horizontal":"","scroll-target":"#main_container"}},[_c('v-btn',{attrs:{"disabled":_vm.loading,"text":""},on:{"click":function($event){return _vm.save()}}},[_c('span',[_vm._v(_vm._s(_vm.$t("save")))]),_c('v-icon',[_vm._v("mdi-content-save-outline")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }