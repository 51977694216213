<template>
  <v-autocomplete
    v-bind="$attrs"
    :items="items"
    :item-text="text"
    item-value="id"
    :value="value"
    @input="onInput"
  ></v-autocomplete>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    resource: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: false,
    },
    params: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    text: {
      type: [String, Function],
      required: false,
      default: "name",
    },
    value: {
      type: [Number, Array],
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      items: [],
    };
  },
  async created() {
    try {
      this.loading = true;
      await this.getItems();
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async getItems() {
      let params = new URLSearchParams();
      Object.entries(this.params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          params = { [key]: value };
        } else {
          params.append(key, value);
        }
      });
      if (this.action) {
        this.items = await this.$api.dispatch(
          "GET",
          this.resource,
          this.action,
          null,
          params
        );
      } else {
        this.items = await this.$api.getMany(this.resource, params);
      }
    },
    onInput(id) {
      this.$emit("input", id);
      this.$emit(
        "input:item",
        this.items.find((i) => i?.id === id)
      );
    },
  },
};
</script>

<style></style>
