import { get } from "lodash";
import Vue from "vue";
import App from "./App.vue";

import store from "./store";
import router from "./router";

import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import "./plugins/vuelidate";
import "./plugins/dayjs";

import "./components/particals/common";
import api from "./api";
import utils from "./utils";
import downloadUrl from "./plugins/download";

Vue.config.productionTip = false;

Vue.prototype.$api = api;
Vue.prototype.$utils = utils;
Vue.prototype.$download = downloadUrl;

Vue.prototype.$get = function (path, defaultValue) {
  return get(this, path) || defaultValue;
};

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
