import axios from "../plugins/axios";
import { Notify } from "vuetify-message-snackbar";

const countHeader = "content-range";

const httpClient = (method, url, dataOrParams, extraParams) => {
  const keyName = ["PUT", "POST", "PATCH"].includes(method) ? "data" : "params";
  return axios({ method, url, [keyName]: dataOrParams, ...extraParams }).catch(
    (err) => {
      if (err?.message) {
        Notify.error(err.message);
      }
    }
  );
};

const api = () => ({
  getList: async (resource, params) => {
    // console.log(params)
    const { page, perpage } = params;
    const reqHeaders = {};
    if (page && perpage) {
      const rangeStart = (page - 1) * perpage;
      const rangeEnd = page * perpage - 1;

      // Chrome doesn't return `content-range` header if no `Range` is provided in the request.
      reqHeaders["range"] = `${resource}=${rangeStart}-${rangeEnd}`;
    }

    const url = `${resource}/`;
    const { headers, data } = await httpClient("GET", url, params, {
      headers: reqHeaders,
    });
    if (!(countHeader in headers)) {
      return {
        data,
        total: data.length,
      };
    } else {
      return {
        data,
        total: parseInt(headers[countHeader]?.split("/").pop() || "0"),
      };
    }
  },
  getCsv: async (resource, params) => {
    const url = `${resource}/`;
    const reqHeaders = {
      Accept: "text/csv",
    };
    const { data, headers } = await httpClient("GET", url, params, {
      headers: reqHeaders,
      responseType: "blob",
    });
    const mineType = headers["content-type"];
    const dispostion = headers["content-disposition"];
    let filename = dispostion
      .split(";")[1]
      .split("=")[1]
      .replace('"', "")
      .replace('"', "");
    filename = decodeURI(filename);
    const blob = new Blob([data], { type: mineType });
    return {
      data: blob,
      filename,
    };
  },
  getMany: async (resource, params) => {
    if (params) {
      if (params instanceof URLSearchParams) {
        const { data } = await httpClient("GET", `${resource}/`, params);
        return data;
      } else {
        const { page, perpage, ...restParams } = params;
        const { data } = await httpClient("GET", `${resource}/`, restParams);
        return data;
      }
    } else {
      const { data } = await httpClient("GET", `${resource}/`);
      return data;
    }
  },
  getOne: async (resource, id, params) => {
    if (id === null || id === undefined) {
      return null;
    }
    const { data } = await httpClient("GET", `${resource}/${id}/`, params);
    return data;
  },
  getPDF: async (resource, id, params) => {
    if (id === null || id === undefined) {
      return null;
    }
    const reqHeaders = {
      Accept: "application/pdf",
    };
    const { data, headers } = await httpClient(
      "GET",
      `${resource}/${id}/`,
      params,
      {
        headers: reqHeaders,
        responseType: "blob",
      }
    );
    const mineType = headers["content-type"];
    const dispostion = headers["content-disposition"];
    let filename = dispostion
      .split(";")[1]
      .split("=")[1]
      .replace('"', "")
      .replace('"', "");
    filename = decodeURI(filename);
    const blob = new Blob([data], { type: mineType });
    return {
      data: blob,
      filename,
    };
  },
  update: async (resource, id, reqData, hasFile = false) => {
    const extraParams = {};
    if (hasFile) {
      Object.assign(extraParams, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    }
    const { data } = await httpClient(
      "PATCH",
      `${resource}/${id}/`,
      reqData,
      extraParams
    );
    return data;
  },
  create: async (resource, reqData, hasFile = false) => {
    if (!reqData) {
      throw new Error("create data required.");
    }
    const extraParams = {};
    if (hasFile) {
      Object.assign(extraParams, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    }
    const { data } = await httpClient(
      "POST",
      `${resource}/`,
      reqData,
      extraParams
    );
    return data;
  },
  delete: async (resource, id) => {
    const { data } = await httpClient("DELETE", `${resource}/${id}/`);
    return data;
  },
  // request: async (method, url, dataOrParams, headers) => {
  //   const res = await httpClient(method, url, dataOrParams, headers);
  //   return res;
  // },
  permission: async (resource) => {
    const { data } = await httpClient("GET", `${resource}/permission/`);
    return data;
  },
  dispatch: async (method, resource, action, id, reqData) => {
    const url = id ? `${resource}/${id}/${action}/` : `${resource}/${action}/`;
    const { data } = await httpClient(method, url, reqData);
    return data;
  },
  httpClient,
});

export default api();
