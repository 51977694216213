<template>
  <v-container class="lighten-5 overflow-hidden mx-auto" id="main_container">
    <form-wrapper :validator="$v.formData">
      <v-form ref="inputForm" lazy-validation>
        <h2>{{ $t("resources.cases.create_name") }}</h2>
        <!-- client info card -->
        <v-card class="mx-auto mt-5" max-width="100%">
          <v-system-bar color="indigo darken-2" dark class="pa-5">
            {{ $t("resources.cases.client_Info") }}
          </v-system-bar>
          <v-container fluid class="pa-5">
            <v-row no-gutters>
              <v-col>
                <v-row class="d-flex">
                  <v-col cols="12" lg="2" md="4">
                    <v-text-field
                      class="text-field-border-radius"
                      v-model="formData.quotation_request_date"
                      :label="
                        $t('resources.cases.fields.quotation_request_date')
                      "
                      readonly
                      rounded
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="2" md="4">
                    <form-group
                      :validator="$v.formData.client_user"
                      attribute="resources.cases.fields.client_user"
                    >
                      <template v-slot="{ attrs }">
                        <ReferenceAutocomplete
                          v-bind="attrs"
                          resource="users"
                          v-model="formData.client_user"
                          value="id"
                          text="all_name"
                          :label="$t('resources.cases.fields.client_user')"
                          @input:item="clientUserSelected"
                          :outlined="!isClient"
                          :readonly="isClient"
                          :rounded="isClient"
                        >
                        </ReferenceAutocomplete>
                      </template>
                    </form-group>
                  </v-col>
                  <v-col cols="12" lg="2" md="4">
                    <v-text-field
                      class="text-field-border-radius"
                      :value="clientUser.department.name"
                      :label="$t('resources.cases.fields.client_department')"
                      readonly
                      rounded
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="2" md="4">
                    <v-text-field
                      class="text-field-border-radius"
                      :value="clientUser.tel"
                      :label="$t('resources.cases.fields.client_tel')"
                      readonly
                      rounded
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="4" md="4">
                    <v-text-field
                      class="text-field-border-radius"
                      :value="clientUser.email"
                      :label="$t('resources.cases.fields.client_mail')"
                      readonly
                      rounded
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <!-- order info card -->
        <v-card class="mx-auto mt-5" max-width="100%">
          <v-system-bar color="indigo darken-2" dark class="pa-5">
            {{ $t("resources.cases.order_Info") }}
          </v-system-bar>
          <v-container fluid class="pa-5">
            <v-row no-gutters>
              <v-col>
                <!-- 案件番号は表示しない
                <v-row>
                  <v-col>
                    <v-text-field
                      class="text-field-border-radius"
                      :label="$t('resources.cases.fields.case_no')"
                      v-model="formData.case_no"
                      readonly
                      rounded
                    ></v-text-field>
                  </v-col>
                </v-row>
                -->
                <v-row>
                  <v-col cols="12" lg="12">
                    <form-group
                      :validator="$v.formData.request_title"
                      attribute="resources.cases.fields.request_title"
                    >
                      <template v-slot="{ attrs }">
                        <v-text-field
                          v-bind="attrs"
                          v-model="formData.request_title"
                          maxlength="140"
                          outlined
                          :label="$t('resources.cases.fields.request_title')"
                        ></v-text-field>
                      </template>
                    </form-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="3" md="4">
                    <form-group
                      :validator="$v.formData.preferred_due_date"
                      attribute="resources.cases.fields.preferred_due_date"
                    >
                      <template v-slot="{ attrs }">
                        <DatePicker
                          v-bind="attrs"
                          :label="
                            $t('resources.cases.fields.preferred_due_date')
                          "
                          v-model="formData.preferred_due_date"
                          outlined
                        />
                      </template>
                    </form-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="12">
                    <v-row>
                      <v-col>
                        <form-group :validator="$v.formData.draft_file_0">
                          <template v-slot="{ attrs }">
                            <FileInput
                              ref="draftFileInput"
                              v-bind="attrs"
                              outlined
                              v-model="formData.draft_file[0]"
                              accept="*"
                              :label="$t('resources.cases.fields.draft_file')"
                              :messages="
                                $t('resources.cases.messages.draft_file')
                              "
                            >
                            </FileInput>
                          </template>
                        </form-group>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="mb-1 d-flex justify-end">
                  <v-btn
                    class="text-right"
                    color="primary"
                    depressed
                    @click="showMultipleFileUpload = !showMultipleFileUpload"
                  >
                    <div v-if="!showMultipleFileUpload">
                      {{ $t("resources.cases.buttonOpenClose.showFileButton") }}
                    </div>
                    <div v-else>
                      {{ $t("resources.cases.buttonOpenClose.hideFileButton") }}
                    </div>
                  </v-btn>
                </v-row>
                <v-container v-if="showMultipleFileUpload">
                  <v-row>
                    <v-col cols="12" lg="12">
                      <v-row>
                        <v-col>
                          <form-group :validator="$v.formData.draft_file_1">
                            <template v-slot="{ attrs }">
                              <FileInput
                                ref="draftFileInput"
                                v-bind="attrs"
                                outlined
                                v-model="formData.draft_file[1]"
                                accept="*"
                                :label="$t('resources.cases.fields.draft_file')"
                              >
                              </FileInput>
                            </template>
                          </form-group>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="12">
                      <v-row>
                        <v-col>
                          <form-group :validator="$v.formData.draft_file_2">
                            <template v-slot="{ attrs }">
                              <FileInput
                                ref="draftFileInput"
                                v-bind="attrs"
                                outlined
                                v-model="formData.draft_file[2]"
                                accept="*"
                                :label="$t('resources.cases.fields.draft_file')"
                              >
                              </FileInput>
                            </template>
                          </form-group>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="12">
                      <v-row>
                        <v-col>
                          <form-group :validator="$v.formData.draft_file_3">
                            <template v-slot="{ attrs }">
                              <FileInput
                                ref="draftFileInput"
                                v-bind="attrs"
                                outlined
                                v-model="formData.draft_file[3]"
                                accept="*"
                                :label="$t('resources.cases.fields.draft_file')"
                              >
                              </FileInput>
                            </template>
                          </form-group>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="12">
                      <v-row>
                        <v-col>
                          <form-group :validator="$v.formData.draft_file_4">
                            <template v-slot="{ attrs }">
                              <FileInput
                                ref="draftFileInput"
                                v-bind="attrs"
                                outlined
                                v-model="formData.draft_file[4]"
                                accept="*"
                                :label="$t('resources.cases.fields.draft_file')"
                              >
                              </FileInput>
                            </template>
                          </form-group>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
                <v-row>
                  <v-col cols="12" lg="12">
                    <v-row>
                      <v-col>
                        <FileLinkInput
                          ref="draftFileLinkInput"
                          outlined
                          v-model="formData.draft_file_link"
                          :label="$t('resources.cases.fields.draft_file_link')"
                        >
                        </FileLinkInput>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-radio-group row v-model="formData.request_category">
                      <v-radio
                        :label="$t('resources.cases.fields.general')"
                        value="1"
                        @click="general = false"
                      ></v-radio>
                      <v-radio
                        v-if="isNormal"
                        :label="$t('resources.cases.fields.catalog')"
                        value="2"
                        @click="general = true"
                      ></v-radio>
                      <v-radio
                        v-if="isNormal"
                        :label="$t('resources.cases.fields.medical_ad_data')"
                        value="3"
                      ></v-radio>
                      <v-radio
                        v-if="isNormal"
                        :label="
                          $t('resources.cases.fields.hospital_panel_data')
                        "
                        value="4"
                      ></v-radio>
                      <v-radio
                        v-if="isNormal"
                        :label="
                          $t(
                            'resources.cases.fields.special_printing_detail_header'
                          )
                        "
                        value="5"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <div v-show="formData.request_category == '2'">
                  <div class="d-flex justify-space-between flex-wrap">
                    <div>
                      <form-group
                        :validator="$v.formData.c_no"
                        attribute="resources.cases.fields.c_no"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            outlined
                            v-model="formData.c_no"
                            maxlength="15"
                            :label="$t('resources.cases.fields.c_no')"
                          ></v-text-field>
                        </template>
                      </form-group>
                    </div>
                    <div>
                      <ReferenceSelect
                        resource="editions"
                        v-model="formData.edition"
                        :label="$t('resources.cases.fields.edition')"
                        outlined
                      >
                      </ReferenceSelect>
                    </div>
                    <div>
                      <form-group
                        :validator="$v.formData.c_revision_no"
                        attribute="resources.cases.fields.c_revision_no"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            outlined
                            v-model="formData.c_revision_no"
                            maxlength="2"
                            :label="$t('resources.cases.fields.c_revision_no')"
                          ></v-text-field>
                        </template>
                      </form-group>
                    </div>
                    <div>
                      <form-group
                        :validator="$v.formData.language_class"
                        attribute="resources.cases.fields.language_class"
                      >
                        <template v-slot="{ attrs }">
                          <ReferenceSelect
                            v-bind="attrs"
                            resource="languageClasses"
                            v-model="formData.language_class"
                            @input:item="sizeLanguageToggle"
                            :label="$t('resources.cases.fields.language_class')"
                            outlined
                          >
                          </ReferenceSelect>
                        </template>
                      </form-group>
                    </div>

                    <div>
                      <form-group
                        :validator="$v.formData.page_count"
                        attribute="resources.cases.fields.page_count"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            outlined
                            v-model="formData.page_count"
                            maxlength="3"
                            :label="$t('resources.cases.fields.page_count')"
                          ></v-text-field>
                        </template>
                      </form-group>
                    </div>
                    <div>
                      <form-group
                        :validator="$v.formData.delivery_form"
                        attribute="resources.cases.fields.delivery_form"
                      >
                        <template v-slot="{ attrs }">
                          <ReferenceSelect
                            v-bind="attrs"
                            resource="deliveryForms"
                            v-model="formData.delivery_form"
                            :label="$t('resources.cases.fields.delivery_form')"
                            outlined
                          >
                          </ReferenceSelect>
                        </template>
                      </form-group>
                    </div>
                    <div>
                      <form-group
                        :validator="$v.formData.size"
                        attribute="resources.cases.fields.size"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            outlined
                            v-model="formData.size"
                            maxlength="15"
                            :label="$t('resources.cases.fields.size')"
                          ></v-text-field>
                        </template>
                      </form-group>
                    </div>
                    <div>
                      <form-group
                        :validator="$v.formData.old_c_no"
                        attribute="resources.cases.fields.old_c_no"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            outlined
                            v-model="formData.old_c_no"
                            maxlength="15"
                            :label="$t('resources.cases.fields.old_c_no')"
                          ></v-text-field>
                        </template>
                      </form-group>
                    </div>
                    <div>
                      <form-group
                        :validator="$v.formData.old_c_revision_no"
                        attribute="resources.cases.fields.old_c_revision_no"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            outlined
                            v-model="formData.old_c_revision_no"
                            maxlength="2"
                            :label="
                              $t('resources.cases.fields.old_c_revision_no')
                            "
                          ></v-text-field>
                        </template>
                      </form-group>
                    </div>
                    <div>
                      <form-group
                        :validator="$v.formData.old_processing"
                        attribute="resources.cases.fields.old_processing"
                      >
                        <template v-slot="{ attrs }">
                          <ReferenceSelect
                            v-bind="attrs"
                            resource="processings"
                            v-model="formData.old_processing"
                            :label="$t('resources.cases.fields.old_processing')"
                            outlined
                          >
                          </ReferenceSelect>
                        </template>
                      </form-group>
                    </div>
                  </div>
                </div>
                <div v-show="formData.request_category == '3'">
                  <v-row>
                    <v-col cols="12" lg="4">
                      <v-alert
                        dense
                        border="left"
                        color="orange darken-2"
                        dark
                        >{{
                          $t(
                            "resources.cases.fields.medical_ad_data_header_message"
                          )
                        }}</v-alert
                      >
                    </v-col>
                  </v-row>
                  <v-row class="d-flex">
                    <v-col cols="12" lg="8">
                      <form-group
                        :validator="$v.formData.media_name"
                        attribute="resources.cases.fields.media_name"
                        class="mr-5"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.media_name"
                            maxlength="100"
                            :label="$t('resources.cases.fields.media_name')"
                            outlined
                          ></v-text-field>
                        </template>
                      </form-group>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <form-group
                        :validator="$v.formData.ad_format"
                        attribute="resources.cases.fields.ad_format"
                      >
                        <template v-slot="{ attrs }">
                          <ReferenceSelect
                            v-bind="attrs"
                            resource="adFormats"
                            v-model="formData.ad_format"
                            :label="$t('resources.cases.fields.ad_format')"
                            @input:item="adFormatSelected"
                            outlined
                          >
                          </ReferenceSelect>
                        </template>
                      </form-group>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex">
                    <v-col cols="12" lg="3">
                      <form-group
                        :validator="$v.formData.is_ad_specs"
                        attribute="resources.cases.fields.is_ad_specs"
                      >
                        <template v-slot="{ attrs }">
                          <v-checkbox
                            v-bind="attrs"
                            v-model="formData.is_ad_specs"
                            :label="$t('resources.cases.fields.is_ad_specs')"
                            outlined
                          >
                          </v-checkbox>
                        </template>
                      </form-group>
                    </v-col>
                    <v-col cols="12" lg="9" v-if="formData.is_ad_specs">
                      <form-group :validator="$v.formData.ad_sepecs_file">
                        <template v-slot="{ attrs }">
                          <FileInput
                            ref="adSpecsFileInput"
                            outlined
                            v-bind="attrs"
                            v-model="formData.ad_sepecs_file"
                            accept="*"
                            :disableUpload="!formData.is_ad_specs"
                            :label="$t('resources.cases.fields.ad_sepecs_file')"
                            :messages="
                              $t('resources.cases.messages.draft_file')
                            "
                          >
                          </FileInput>
                        </template>
                      </form-group>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex">
                    <v-col cols="12" lg="4">
                      <form-group
                        :validator="$v.formData.plate_size"
                        attribute="resources.cases.fields.plate_size"
                      >
                        <template v-slot="{ attrs }">
                          <ReferenceSelect
                            v-bind="attrs"
                            v-model="formData.plate_size"
                            resource="plateSizes"
                            :label="$t('resources.cases.fields.plate_size')"
                            @input:item="plateSizeSelected"
                            outlined
                          >
                          </ReferenceSelect>
                        </template>
                      </form-group>
                    </v-col>
                    <v-col cols="12" lg="4" class="d-flex">
                      <form-group
                        v-if="isPlateSizeInputOther"
                        class="mr-5"
                        :validator="$v.formData.plate_height"
                        attribute="resources.cases.fields.plate_height"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.plate_height"
                            maxlength="5"
                            :label="$t('resources.cases.fields.plate_height')"
                            :placeholder="
                              $t('resources.cases.fields.plate_height')
                            "
                            outlined
                          >
                          </v-text-field>
                        </template>
                      </form-group>
                      <form-group
                        v-if="isPlateSizeInputOther"
                        :validator="$v.formData.plate_width"
                        attribute="resources.cases.fields.plate_width"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.plate_width"
                            maxlength="5"
                            :label="$t('resources.cases.fields.plate_width')"
                            :placeholder="
                              $t('resources.cases.fields.plate_width')
                            "
                            outlined
                          >
                          </v-text-field>
                        </template>
                      </form-group>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex">
                    <v-col cols="12" lg="4">
                      <form-group
                        :validator="$v.formData.color"
                        attribute="resources.cases.fields.color"
                      >
                        <template v-slot="{ attrs }">
                          <ReferenceSelect
                            v-bind="attrs"
                            v-model="formData.color"
                            resource="colors"
                            :label="$t('resources.cases.fields.color')"
                            @input:item="colorSelected"
                            outlined
                          >
                          </ReferenceSelect>
                        </template>
                      </form-group>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <form-group
                        v-if="isColorInputOther"
                        class="mr-5"
                        :validator="$v.formData.other_color"
                        attribute="resources.cases.fields.other_color"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.other_color"
                            maxlength="20"
                            :label="$t('resources.cases.fields.other_color')"
                            :placeholder="
                              $t('resources.cases.fields.other_color')
                            "
                            outlined
                          >
                          </v-text-field>
                        </template>
                      </form-group>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex">
                    <v-col cols="12" lg="3">
                      <form-group
                        :validator="$v.formData.ad_no"
                        attribute="resources.cases.fields.ad_no"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.ad_no"
                            maxlength="20"
                            :label="$t('resources.cases.fields.ad_no')"
                            outlined
                          ></v-text-field>
                        </template>
                      </form-group>
                    </v-col>
                    <v-col cols="12" lg="9">
                      <form-group
                        :validator="$v.formData.ad_name"
                        attribute="resources.cases.fields.ad_name"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.ad_name"
                            maxlength="50"
                            :label="$t('resources.cases.fields.ad_name')"
                            outlined
                          ></v-text-field>
                        </template>
                      </form-group>
                    </v-col>
                  </v-row>
                </div>
                <div v-show="formData.request_category == '4'">
                  <v-row class="d-flex">
                    <v-col cols="12" lg="3">
                      <form-group
                        :validator="$v.formData.desired_panel_no"
                        attribute="resources.cases.fields.desired_panel_no"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.desired_panel_no"
                            maxlength="10"
                            :label="
                              $t('resources.cases.fields.desired_panel_no')
                            "
                            outlined
                          ></v-text-field>
                        </template>
                      </form-group>
                    </v-col>
                    <v-col cols="12" lg="9">
                      <form-group
                        :validator="$v.formData.hospital_name"
                        attribute="resources.cases.fields.hospital_name"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.hospital_name"
                            maxlength="50"
                            :label="$t('resources.cases.fields.hospital_name')"
                            outlined
                          ></v-text-field>
                        </template>
                      </form-group>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex">
                    <v-col cols="12" lg="3">
                      <form-group
                        :validator="$v.formData.panel_size"
                        attribute="resources.cases.fields.panel_size"
                      >
                        <template v-slot="{ attrs }">
                          <ReferenceSelect
                            v-bind="attrs"
                            v-model="formData.panel_size"
                            resource="panelSizes"
                            :label="$t('resources.cases.fields.panel_size')"
                            @input:item="panelSelected"
                            outlined
                          >
                          </ReferenceSelect>
                        </template>
                      </form-group>
                    </v-col>
                    <v-col cols="12" lg="5" class="d-flex">
                      <form-group
                        class="mr-5"
                        v-if="isPanelSizeInputOther"
                        :validator="$v.formData.panel_height"
                        attribute="resources.cases.fields.panel_height"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            class="ml-3"
                            v-bind="attrs"
                            v-model="formData.panel_height"
                            maxlength="5"
                            :label="$t('resources.cases.fields.panel_height')"
                            :placeholder="
                              $t('resources.cases.fields.panel_height')
                            "
                            outlined
                          >
                          </v-text-field>
                        </template>
                      </form-group>

                      <form-group
                        v-if="isPanelSizeInputOther"
                        class="mr-5"
                        :validator="$v.formData.panel_width"
                        attribute="resources.cases.fields.panel_width"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.panel_width"
                            maxlength="5"
                            :label="$t('resources.cases.fields.panel_width')"
                            :placeholder="
                              $t('resources.cases.fields.panel_width')
                            "
                            outlined
                          >
                          </v-text-field>
                        </template>
                      </form-group>
                    </v-col>
                  </v-row>
                </div>
                <div v-show="formData.request_category == '5'">
                  <form-group
                    :validator="$v.formData.special_printing_detail"
                    attribute="resources.cases.fields.special_printing_detail"
                  >
                    <template v-slot="{ attrs }">
                      <v-textarea
                        v-bind="attrs"
                        outlined
                        v-model="formData.special_printing_detail"
                        name="input-7-1"
                        :label="
                          $t('resources.cases.fields.special_printing_detail')
                        "
                      ></v-textarea>
                    </template>
                  </form-group>
                </div>
                <v-row class="d-flex">
                  <v-col cols="12" lg="2">
                    <form-group
                      :validator="$v.formData.request_count"
                      attribute="resources.cases.fields.request_count"
                    >
                      <template v-slot="{ attrs }">
                        <v-text-field
                          v-bind="attrs"
                          v-model="formData.request_count"
                          maxlength="6"
                          @input="requestCountStorageAmountDifferemce()"
                          outlined
                          :label="$t('resources.cases.fields.request_count')"
                          :messages="
                            $t('resources.cases.messages.request_count')
                          "
                        ></v-text-field>
                      </template>
                    </form-group>
                  </v-col>
                </v-row>
                <v-row class="d-flex">
                  <v-col cols="12" lg="3">
                    <form-group
                      :validator="$v.formData.delivery_destination"
                      attribute="resources.cases.fields.delivery_destination"
                    >
                      <template v-slot="{ attrs }">
                        <ReferenceSelect
                          v-bind="attrs"
                          resource="deliveryDestinations"
                          v-model="formData.delivery_destination"
                          :label="
                            $t('resources.cases.fields.delivery_destination')
                          "
                          outlined
                          @input:item="deliveryDestinationSelected"
                        >
                        </ReferenceSelect>
                      </template>
                    </form-group>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="9"
                    v-if="isDeliveryDestinationManualInput"
                  >
                    <form-group
                      :validator="$v.formData.delivery_destination_manual"
                      attribute="resources.cases.fields.direct_delivery"
                    >
                      <template v-slot="{ attrs }">
                        <v-text-field
                          v-if="isDeliveryDestinationManualInput"
                          v-bind="attrs"
                          v-model="formData.delivery_destination_manual"
                          maxlength="100"
                          :placeholder="
                            $t(
                              'resources.cases.fields.delivery_destination_manual'
                            )
                          "
                          :outlined="isDeliveryDestinationManualInput"
                          :readonly="!isDeliveryDestinationManualInput"
                          :rounded="!isDeliveryDestinationManualInput"
                        ></v-text-field>
                      </template>
                    </form-group>
                  </v-col>
                  <v-col cols="12" lg="2" v-if="isStorageNumberInput">
                    <form-group
                      :validator="$v.formData.storage_number"
                      attribute="resources.cases.fields.storage_number"
                    >
                      <template v-slot="{ attrs }">
                        <v-text-field
                          v-if="isStorageNumberInput"
                          v-bind="attrs"
                          v-model="formData.storage_number"
                          maxlength="6"
                          @input="requestCountStorageAmountDifferemce()"
                          :label="$t('resources.cases.fields.storage_number')"
                          :placeholder="
                            $t('resources.cases.fields.storage_number')
                          "
                          :outlined="isStorageNumberInput"
                          :readonly="!isStorageNumberInput"
                          :rounded="!isStorageNumberInput"
                        ></v-text-field>
                      </template>
                    </form-group>
                  </v-col>
                  <v-col cols="12" lg="2" v-if="isStorageNumberInput">
                    <form-group>
                      <template v-slot="{ attrs }">
                        <v-text-field
                          v-bind="attrs"
                          v-model="formData.catalog_storage_quantity"
                          maxlength="6"
                          @input="requestCountStorageAmountDifferemce()"
                          :label="
                            $t(
                              'resources.cases.fields.catalog_storage_quantity'
                            )
                          "
                          :placeholder="
                            $t(
                              'resources.cases.fields.catalog_storage_quantity'
                            )
                          "
                          outlined
                          readonly
                        ></v-text-field>
                      </template>
                    </form-group>
                  </v-col>
                </v-row>

                <v-row class="d-flex mb-6">
                  <v-col cols="12" lg="12">
                    <form-group
                      :validator="$v.formData.request_remarks"
                      attribute="remarks"
                    >
                      <template v-slot="{ attrs }">
                        <v-textarea
                          v-bind="attrs"
                          outlined
                          v-model="formData.request_remarks"
                          name="input-7-1"
                          :label="$t('remarks')"
                        ></v-textarea>
                      </template>
                    </form-group>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <!-- invoice info card -->

        <v-card class="mx-auto mt-5" max-width="100%">
          <v-system-bar color="indigo darken-2" dark class="pa-5">
            {{ $t("resources.cases.invoice_Info") }}
          </v-system-bar>
          <v-container fluid class="pa-5">
            <v-row no-gutters>
              <v-col>
                <v-row>
                  <v-col>
                    <form-group
                      :validator="$v.formData.expense_account"
                      attribute="resources.cases.fields.expense_account"
                    >
                      <template v-slot="{ attrs }">
                        <ReferenceAutocomplete
                          v-bind="attrs"
                          resource="expenseAccounts"
                          v-model="formData.expense_account"
                          value="id"
                          text="all_name"
                          outlined
                          :label="$t('resources.cases.fields.expense_account')"
                          @input:item="expenseAccountSelelcted"
                        ></ReferenceAutocomplete>
                      </template>
                    </form-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-radio-group
                      v-if="isNormal"
                      row
                      v-model="formData.billing_category"
                    >
                      <v-radio
                        v-if="this.client_department_code.length < 5"
                        :label="$t('resources.cases.fields.billing_category_1')"
                        value="1"
                      ></v-radio>
                      <v-radio
                        v-if="this.client_department_code.length < 5"
                        :label="$t('resources.cases.fields.billing_category_2')"
                        value="2"
                      ></v-radio>
                      <v-radio
                        :label="$t('resources.cases.fields.billing_category_3')"
                        value="3"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-card flat v-show="formData.billing_category === '3'">
                  <v-row class="d-flex justify-space-between mb-6">
                    <v-col cols="12" lg="6">
                      <div>
                        <form-group
                          :validator="$v.formData.billing_user"
                          attribute="resources.cases.fields.billing_user"
                        >
                          <template v-slot="{ attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-model="formData.billing_user"
                              maxlength="100"
                              :label="$t('resources.cases.fields.billing_user')"
                              outlined
                            >
                            </v-text-field>
                          </template>
                        </form-group>
                      </div>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <div>
                        <form-group
                          :validator="$v.formData.billing_department"
                          attribute="resources.cases.fields.billing_department"
                        >
                          <template v-slot="{ attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-model="formData.billing_department"
                              maxlength="100"
                              :label="
                                $t('resources.cases.fields.billing_department')
                              "
                              outlined
                            >
                            </v-text-field>
                          </template>
                        </form-group>
                      </div>
                    </v-col>
                    <v-col cols="12" lg="12">
                      <form-group
                        :validator="$v.formData.billing_address"
                        attribute="resources.cases.fields.billing_address"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            outlined
                            v-model="formData.billing_address"
                            maxlength="100"
                            :label="
                              $t('resources.cases.fields.billing_address')
                            "
                          ></v-text-field>
                        </template>
                      </form-group>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-alert
          v-if="isNormal"
          class="mx-auto mt-5 mb-15"
          dense
          border="left"
          color="orange darken-2"
          dark
          >{{ $t("resources.cases.messages.input_request") }}</v-alert
        >

        <v-bottom-navigation fixed horizontal scroll-target="#main_container">
          <v-btn @click="save()" :disabled="loading" text>
            <span>{{ $t("save") }}</span>
            <v-icon>mdi-content-save-outline</v-icon>
          </v-btn>
        </v-bottom-navigation>
      </v-form>
    </form-wrapper>
  </v-container>
</template>
<script>
import createMixin from "../../mixins/createMixin";
import groupPermissionMixin from "../../mixins/groupPermissionMixin";
import fieldPermissionMixin from "../../mixins/fieldPermissionMixin";
import headersMixin from "../../mixins/headersMixin";
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  numeric,
  between,
  minValue,
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { statuses } from "../../../configs/statusConfig";
import { caseCategorys } from "../../../configs/caseCategoryConfig";
import { checkMaxFileSize } from "../../../utils";
/**
 * @group 受注管理
 * 案件登録画面<br>
 * 機能仕様書：4.2, 4.3, 5.15, 5.17, 6.2
 */
export default {
  resource: "cases",
  mixins: [
    fieldPermissionMixin,
    createMixin,
    validationMixin,
    headersMixin,
    groupPermissionMixin,
  ],
  validations() {
    const formData = {
      delivery_destination: {},
      delivery_destination_manual: { maxLength: maxLength(100) },
      storage_number: {
        numeric,
        betweenValue: between(1, 999999),
      },
      request_title: { maxLength: maxLength(140) },
      expense_account: { maxLength: maxLength(20) },
      client_user: { requiredSelect: required },
      request_count: {
        numeric,
        betweenValue: between(0, 999999),
      },
      c_no: {},
      ad_no: { numeric, maxLength: maxLength(20) },
      desired_panel_no: { numeric, maxLength: maxLength(10) },
      ad_name: { maxLength: maxLength(50) },
      hospital_name: { maxLength: maxLength(50) },
      media_name: { maxLength: maxLength(100) },
      plate_size: {},
      plate_height: { numeric, betweenValue: between(1, 999999) },
      plate_width: { numeric, betweenValue: between(1, 999999) },
      panel_height: { numeric, betweenValue: between(1, 999999) },
      panel_width: { numeric, betweenValue: between(1, 999999) },
      ad_format: {},
      draft_file_link: {},
      draft_file_0: {},
      draft_file_1: {},
      draft_file_2: {},
      draft_file_3: {},
      draft_file_4: {},
      color: {},
      other_color: { maxLength: maxLength(20) },
      is_ad_specs: {},
      panel_size: {},
      ad_sepecs_file: {},
      c_revision_no: {},
      old_c_revision_no: {},
      size: { maxLength: maxLength(20) },
      page_count: {
        numeric,
        maxLength: maxLength(5),
        betweenValue: between(1, 999),
      },
      language_class: {},
      delivery_form: {},
      old_c_no: { maxLength: maxLength(15) },
      old_processing: {},
      request_remarks: {},
      special_printing_detail: {},
      billing_user: {},
      billing_department: {},
      billing_address: { maxLength: maxLength(100) },
    };
    formData.draft_file_0 = {
      maxFileSize: () => checkMaxFileSize(this.formData.draft_file[0]),
    };

    formData.draft_file_1 = {
      maxFileSize: () => checkMaxFileSize(this.formData.draft_file[1]),
    };

    formData.draft_file_2 = {
      maxFileSize: () => checkMaxFileSize(this.formData.draft_file[2]),
    };
    formData.draft_file_3 = {
      maxFileSize: () => checkMaxFileSize(this.formData.draft_file[3]),
    };

    formData.draft_file_4 = {
      maxFileSize: () => checkMaxFileSize(this.formData.draft_file[4]),
    };
    if (this.formData.request_category === "5") {
      formData.special_printing_detail = {
        required,
      };
    } else {
      formData.special_printing_detail = {};
    }
    if (this.selectedDeliveryDestination?.description_flag) {
      formData.delivery_destination_manual = {
        maxLength: maxLength(100),
        required,
      };
    } else {
      formData.delivery_destination_manual = {
        maxLength: maxLength(100),
      };
    }
    if (this.selectedDeliveryDestination?.code === "3") {
      formData.storage_number = {
        numeric,
        betweenValue: between(1, 999999),
        required,
      };
    } else {
      formData.storage_number = {
        numeric,
        betweenValue: between(1, 999999),
      };
    }
    if (this.formData.request_count && this.formData.storage_number) {
      formData.storage_number = {
        numeric,
        betweenValue: between(1, 999999),
        differenceCheck: function () {
          return minValue(0)(
            this.formData.request_count - this.formData.storage_number
          );
        },
      };
    }
    if (this.formData.is_ad_specs) {
      formData.ad_sepecs_file = {
        required,
        maxFileSize: () => checkMaxFileSize(this.formData.ad_sepecs_file),
      };
    }
    if (this.selectedPanelSize?.description_flag) {
      formData.panel_height = {
        required,
        maxLength: maxLength(5),
      };
      formData.panel_width = {
        required,
        maxLength: maxLength(5),
      };
    } else {
      formData.panel_height = {
        maxLength: maxLength(5),
      };
      formData.panel_width = {
        maxLength: maxLength(5),
      };
    }
    if (this.selectedColor?.description_flag) {
      formData.other_color = {
        required,
        maxLength: maxLength(20),
      };
    } else {
      formData.other_color = {
        maxLength: maxLength(20),
      };
    }
    if (this.selectedPlateSize?.description_flag) {
      formData.plate_height = {
        required,
        maxLength: maxLength(5),
      };
      formData.plate_width = {
        required,
        maxLength: maxLength(5),
      };
    } else {
      formData.plate_height = {
        maxLength: maxLength(5),
      };
      formData.plate_width = {
        maxLength: maxLength(5),
      };
    }
    return { formData };
  },
  data() {
    return {
      /**
       * @vuese
       * 画面項目の動作制御
       */
      loading: false,
      /**
       * @vuese
       * 原稿ファイルボタンの動作制御
       */
      showMultipleFileUpload: false,
      /**
       * @vuese
       * 案件に関連する情報の保持
       * および画面項目の制御
       */
      formData: {
        case_no: "",
        quotation_request_date: this.$dateStr(),
        preferred_due_date: this.$dateStr(),
        delivery_destination: null,
        delivery_destination_manual: "",
        storage_number: null,
        catalog_storage_quantity: null,
        client_user: null,
        contact_user: null,
        language_class: null,
        request_title: "",
        request_category: "1",
        request_count: null,
        c_no: "",
        c_revision_no: "",
        old_c_revision_no: "",
        edition: null,
        size: "",
        page_count: null,
        delivery_form: null,
        part_number: null,
        old_c_no: "",
        old_processing: null,
        request_remarks: "",
        special_printing_detail: "",
        expense_account: null,
        billing_category: "",
        billing_user: null,
        billing_department: null,
        billing_address: "",
        quotation: null,
        quotation_date: null,
        quotation_amount: null,
        quotation_remarks: "",
        total_cost: null,
        gross_margin: null,
        gross_margin_ratio: null,
        status: null,
        case_category: "",
        client_department: null,
        contact_department: null,
        media_name: "",
        is_ad_specs: false,
        plate_size: null,
        plate_height: null,
        plate_width: null,
        panel_height: null,
        panel_width: null,
        other_size: false,
        ad_format: null,
        color: null,
        other_color: "",
        ad_no: "",
        ad_name: "",
        desired_panel_no: "",
        panel_size: null,
        hospital_name: "",
        // extra fields
        draft_file_link: {
          file: null,
          link: null,
          deleted: false,
        },
        ad_sepecs_file: {
          file: null,
          link: null,
          deleted: false,
        },
        draft_file: [
          {
            file: null,
            link: null,
            deleted: false,
          },
          {
            file: null,
            link: null,
            deleted: false,
          },
          {
            file: null,
            link: null,
            deleted: false,
          },
          {
            file: null,
            link: null,
            deleted: false,
          },
          {
            file: null,
            link: null,
            deleted: false,
          },
        ],
      },
      clientUser: {
        department: {
          name: null,
        },
        tel: null,
        email: null,
        name: null,
      },
      /**
       * @vuese
       * 一般とカタログ（C番号管理）の関連項目表示切り替え制御区分
       */
      general: false,
      /**
       * @vuese
       * 納品先切り替え時の請求先入力項目の制御配列
       */
      selectedDeliveryDestination: { description_flag: true },
      /**
       * @vuese
       * 原稿形態を保持
       */
      selectedAdFormat: {},
      /**
       * @vuese
       * 版型を保持
       */
      selectedPlateSize: { description_flag: false },
      /**
       * @vuese
       * 原稿色を保持
       */
      selectedColor: { description_flag: false },
      /**
       * @vuese
       * パネルサイズを保持
       */
      selectedPanelSize: { description_flag: false },
      /**
       * @vuese
       * 版マスタの保持リスト
       */
      editions: {},
      /**
       * @vuese
       * 原稿色マスタの保持リスト
       */
      colors: {},
      /**
       * @vuese
       * 原稿形態マスタの保持リスト
       */
      adFormats: {},
      /**
       * @vuese
       * パネルサイズマスタの保持リスト
       */
      panelSizes: {},
      /**
       * @vuese
       * 版型・サイズマスタの保持リスト
       */
      plateSizes: {},
      /**
       * @vuese
       * 納品形態マスタの保持リスト
       */
      deliveryForms: {},
      /**
       * @vuese
       * 処理マスタの保持リスト
       */
      processings: {},
      /**
       * @vuese
       * 費目マスタの保持リスト
       */
      expenseAccounts: {},
      /**
       * @vuese
       * 納品先マスタの保持リスト
       */
      deliveryDestinations: {},
      /**
       * @vuese
       * 部門マスタの保持リスト
       */
      departments: {},
      /**
       * @vuese
       * クライアント部門コードの保持
       */
      client_department_code: "",
    };
  },
  computed: {
    /**
     * @vuese
     * ログインユーザ情報を取得
     */
    ...mapGetters(["currentUser"]),
    /**
     * @vuese
     * クライアントのユーザかどうか
     */
    isClient() {
      return this.hasPerm("clientManager", "clientStaff");
    },
    /**
     * @vuese
     * 直送先の手入力項目制御
     * @type String
     */
    isDeliveryDestinationManualInput() {
      return this.selectedDeliveryDestination.description_flag;
    },
    /**
     * @vuese
     * 保管部数の手入力項目制御
     * @type String
     */
    isStorageNumberInput() {
      return this.selectedDeliveryDestination.code === "3";
    },
    /**
     * @vuese
     * パネルサイズ入力の手動入力項目管理
     * @type String
     */
    isPanelSizeInputOther() {
      return this.selectedPanelSize.description_flag;
    },
    /**
     * @vuese
     * プレートサイズ入力の手動入力項目管理
     * @type String
     */
    isPlateSizeInputOther() {
      return this.selectedPlateSize.description_flag;
    },
    /**
     * @vuese
     * カラー入力の手動入力項目制御
     * @type String
     */
    isColorInputOther() {
      return this.selectedColor.description_flag;
    },
    /**
     * @vuese
     * 通常案件か否かの判定
     */
    isNormal() {
      return this.formData.case_category === caseCategorys.Normal;
    },
    /**
     * @vuese
     * 稟議書案件か否かの判定
     */
    isApproval() {
      return this.formData.case_category === caseCategorys.Approval;
    },
    /**
     * @vuese
     * 赤黒案件か否かの判定
     */
    isReversalAndCorrecting() {
      return (
        this.formData.case_category === caseCategorys.reversalAndCorrecting
      );
    },
  },
  // インスタンスの初期化後処理
  async created() {
    this.$v.formData.$touch();
    // パラメータから取得したカテゴリを案件区分を設定
    this.formData.case_category = this.$route.params.category;
    // プルダウンの内容取得
    [
      this.editions,
      this.deliveryForms,
      this.processings,
      this.expenseAccounts,
      this.deliveryDestinations,
    ] = await Promise.all([
      this.$api.getMany("editions"),
      this.$api.getMany("deliveryForms"),
      this.$api.getMany("processings"),
      this.$api.getMany("expenseAccounts"),
      this.$api.getMany("deliveryDestinations"),
      this.$api.getMany("colors"),
      this.$api.getMany("adFormats"),
      this.$api.getMany("panelSizes"),
      this.$api.getMany("plateSizes"),
    ]);
    //ログインユーザの初期情報セット（問題ないか確認）
    this.clientUser.department.name = this.currentUser.department.name;
    this.clientUser.tel = this.currentUser.tel;
    this.clientUser.email = this.currentUser.email;
    this.clientUser.name =
      this.currentUser.last_name + "　" + this.currentUser.first_name;
    this.department = this.currentUser.department;
    this.client_department_code = this.department.code;
    this.formData.client_user = this.currentUser.id;
    this.formData.client_department = this.department.id;
    this.formData.billing_category =
      this.client_department_code.length < 5 ? "1" : "3";
    //当画面遷移による制御（新規10、代理10、稟議51、赤黒52）
    switch (this.formData.case_category) {
      case caseCategorys.Normal:
        this.formData.status = statuses.PendingRequests;
        break;
      case caseCategorys.Approval:
        this.formData.status = statuses.Inquiry;
        // 稟議案件は費用処理方法は不要
        this.formData.billing_category = null;
        break;
      case caseCategorys.reversalAndCorrecting:
        this.formData.status = statuses.ReversalAndCorrectingEntry;
        // 赤黒案件は費用処理方法は不要
        this.formData.billing_category = null;
        break;
    }
    if (this.deliveryDestinations && this.isNormal) {
      this.formData.delivery_destination = this.deliveryDestinations[0].id;
    }
    this.selectedDeliveryDestination.description_flag = this.isNormal
      ? true
      : false;
  },
  methods: {
    /**
     * @vuese
     * 納品先選択時メソッド
     * @arg item - 納品先選択時情報
     */
    deliveryDestinationSelected(item) {
      // 納品先選択時処理
      this.selectedDeliveryDestination = item;
      if (!this.selectedDeliveryDestination.description_flag) {
        this.formData.delivery_destination_manual = null;
      } else if (this.selectedDeliveryDestination.code !== 3) {
        this.formData.storage_number = null;
      }
    },
    /**
         *@vuese
         ※版型・サイズ選択時メソッド
         * @arg item - 版型・サイズ選択時情報
         */
    plateSizeSelected(item) {
      this.selectedPlateSize = item;
      if (!this.selectedPlateSize.description_flag) {
        this.formData.plate_height = null;
        this.formData.plate_width = null;
      }
    },
    /**
     * *@vuese
     * * 言言語種別選択時の規格サイズ変更
     * * */
    sizeLanguageToggle(item) {
      if (item.id === 1 || item.id === 2) {
        this.formData.size = item.size;
      }
    },
    /**
      *@vuese
      ※原稿形態選択時メソッド
      * @arg item - 原稿形態選択時情報
      */
    adFormatSelected(item) {
      this.selectedAdFormat = item;
    },
    /**
     *@vuese
      ※原稿色選択時メソッド
      * @arg item - 原稿色選択時情報
      */
    colorSelected(item) {
      this.selectedColor = item;
      if (!this.selectedColor.description_flag) {
        this.formData.other_color = null;
      }
    },
    /**
     *@vuese
      ※パネルサイズ選択時メソッド
      * @arg item - パネルサイズ選択時情報
      */
    panelSelected(item) {
      this.selectedPanelSize = item;
      if (!this.selectedPanelSize.description_flag) {
        this.formData.panel_height = null;
        this.formData.panel_width = null;
      }
    },
    /**
     * @vuese
     * 費目選択時メソッド
     * @arg item - 費目選択時情報
     */
    expenseAccountSelelcted(item) {
      this.expense_account = item;
    },
    /**
     * @vuese
     * 依頼ユーザ選択時メソッド
     * @arg item - 依頼ユーザ選択時選択時情報
     */
    clientUserSelected(item) {
      this.clientUser = item;
      this.formData.client_user = item.id;
      this.department = item.department;
      this.formData.client_department = this.department.id;
      this.client_department_code = this.department.code;
      if (this.isNormal) {
        this.formData.billing_category =
          this.client_department_code.length < 5 ? "1" : "3";
      }
    },
    /**
     * @vuese
     * ToDO: 依頼数とカタログ倉庫部数の差
     */
    requestCountStorageAmountDifferemce() {
      if (this.formData.request_count && this.formData.storage_number) {
        if (
          !isNaN(this.formData.request_count) &&
          !isNaN(this.formData.storage_number)
        ) {
          this.formData.catalog_storage_quantity =
            this.formData.request_count - this.formData.storage_number;
        }
      } else {
        this.formData.catalog_storage_quantity = null;
      }
    },
    uploadDraftFile(fileObject, case_id) {
      const { file } = fileObject;
      const draftFileData = new FormData();
      draftFileData.append("case", case_id);
      draftFileData.append("draft_file", file);
      return this.$api.create("draftFiles", draftFileData, true);
    },
    async save() {
      this.$v.formData.$touch();
      if (!this.$v.formData.$invalid) {
        this.loading = true;
        let case_id = null;
        const { draft_file, ad_sepecs_file, draft_file_link, ...caseData } =
          this.formData;
        try {
          const { id } = await this.$api.create(
            this.$options.resource,
            caseData
          );
          case_id = id;
        } catch {
          this.loading = false;
          return;
        }
        // for multiple createAPI call
        if (this.formData.draft_file.length !== 0) {
          for (let i = 0; i < this.formData.draft_file.length; i++) {
            if (this.formData.draft_file[i].file !== null) {
              try {
                await this.uploadDraftFile(
                  this.formData.draft_file[i],
                  case_id
                );
              } catch {
                this.loading = false;
                return;
              }
            }
          }
        }
        const file_1 = ad_sepecs_file.file;
        if (file_1) {
          try {
            let { file } = ad_sepecs_file;
            const adSpecsFileData = new FormData();
            adSpecsFileData.append("case", case_id);
            adSpecsFileData.append("ad_sepecs_file", file);
            await this.$api.create("adSpecsFiles", adSpecsFileData, true);
          } catch {
            this.loading = false;
            return;
          } finally {
            this.$refs.adSpecsFileInput?.clear();
            this.loading = false;
          }
        }
        const file_2 = draft_file_link.file;
        if (file_2) {
          try {
            let { file } = draft_file_link;
            const draftFileLinkData = new FormData();
            draftFileLinkData.append("case", case_id);
            draftFileLinkData.append("draft_file_link", file);
            await this.$api.create("draftFileLinks", draftFileLinkData, true);
          } catch {
            this.loading = false;
            return;
          } finally {
            this.$refs.draftFileLinkInput?.clear();
            this.loading = false;
          }
        }
        this.$message.success(this.$t("messages.success.create"));
        this.loading = false;
        this.gotoEdit(case_id);
      }
    },
  },
};
</script>
