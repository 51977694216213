<template>
  <div class="d-flex align-baseline">
    <v-file-input
      :key="fileInputKey"
      v-bind="$attrs"
      @change="onInput"
      :disabled="disableUpload"
    ></v-file-input>
    <v-chip
      class="ml-4"
      color="primary"
      outlined
      @click="onDownload"
      v-if="showDownload"
    >
      <v-icon left>mdi-file-download</v-icon>
      {{ fileName }}
    </v-chip>
    <v-chip
      class="ml-4"
      color="red"
      outlined
      @click="onDelete"
      v-if="showDelete"
    >
      <v-icon>mdi-delete</v-icon>
    </v-chip>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        file: null,
        link: null,
        deleted: false,
      }),
    },
    disableUpload: {
      type: Boolean,
      required: false,
      default: false,
    },
    visivleDelete: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      fileInputKey: 0,
    };
  },
  computed: {
    fileName() {
      return decodeURI(this.value?.link?.split("/").pop() || "");
    },
    showDownload() {
      return this.value?.link && !this.value?.deleted;
    },
    showDelete() {
      return this.visivleDelete && this.value?.link && !this.value?.deleted;
    },
  },
  methods: {
    onInput(file) {
      this.$emit("input", { ...this.value, file });
    },
    onDelete() {
      this.$emit("input", { ...this.value, deleted: true, file: null });
    },
    onDownload() {
      this.$download(this.value?.link);
    },
    clear() {
      this.fileInputKey++;
    },
  },
};
</script>
