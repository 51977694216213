export default {
  methods: {
    async join(data, fields) {
      const tasks = fields.map(({ name, resource }) => {
        if (data[name]) {
          return this.$api.getOne(resource, data[name]).then((res) => {
            this.$set(data, name, res);
          });
        } else {
          return Promise.resolve();
        }
      });
      await Promise.all(tasks);
    },
  },
};
