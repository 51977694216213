import Vue from "vue";
import Vuelidate from "vuelidate";
import VuelidateErrorExtractor, { templates } from "vuelidate-error-extractor";
import i18nAttributes from "../../configs/formFieldConfig";

Vue.use(Vuelidate);
Vue.use(VuelidateErrorExtractor, {
  i18n: "messages.validations",
  i18nAttributes,
});
Vue.component("formWrapper", templates.FormWrapper);
