<template>
  <!--承認フロー-->
  <v-skeleton-loader type="card" v-if="!workflow_id"></v-skeleton-loader>
  <v-card v-else class="mx-auto mt-5 mb-5" width="60rem" :loading="loading">
    <v-system-bar color="orange darken-2" dark class="pa-5">
      {{ $t("resources.orderReceipts.approvalLog") }}
    </v-system-bar>
    <v-container fluid class="pa-5">
      <v-row align="center">
        <v-col>
          <v-data-table
            :headers="headers"
            :items="formData.workflow_details"
            hide-default-footer
          >
            <template v-slot:[`item.fullname`]="{ item }">
              {{ item.proxy_fullname || item.user_fullname || "" }}
            </template>
            <!--明細のコメント-->
            <template v-slot:[`item.comment`]="props">
              <v-edit-dialog :return-value.sync="props.item.comment">
                {{
                  props.item.comment && props.item.comment.length > 20
                    ? props.item.comment.slice(0, 20) + "..."
                    : props.item.comment
                }}
                <template v-slot:input>
                  <v-textarea
                    v-model="props.item.comment"
                    readonly
                  ></v-textarea>
                </template>
              </v-edit-dialog>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import groupPermissionMixin from "../../mixins/groupPermissionMixin";
export default {
  /**ワークフローID*/
  props: {
    refreshKey: {
      type: Number,
      required: true,
      default: 0,
    },
    workflow_id: {
      type: [],
      required: true,
      default: [0],
    },
  },
  mixins: [groupPermissionMixin],
  data() {
    return {
      loading: true,
      formData: {
        workflow_details: [],
      },
      users: [],
      headers: [
        {
          text: "承認者",
          value: "fullname",
          sortable: false,
        },
        {
          text: "ステータス",
          value: "status_name",
          sortable: false,
        },
        {
          text: "コメント",
          value: "comment",
          sortable: false,
        },
        {
          text: "更新日時",
          value: "processing_date_format",
          sortable: false,
        },
      ],
    };
  },
  watch: {
    refreshKey: async function (newVal) {
      if (newVal) {
        await this.fetchInitData();
      }
    },
    workflow_id: async function (newVal) {
      if (newVal) {
        await this.fetchInitData();
      }
    },
  },
  computed: {
    url() {
      let queryString = "";
      if (this.workflow_id) {
        this.workflow_id.forEach((id) => {
          if (queryString.length > 0) {
            queryString += "&";
          }
          queryString += `workflow=${id}`;
        });
      }
      return `workflowDetails/?${queryString}`;
    },
  },
  methods: {
    /**
     * @vuese
     * 表示データの取得
     */
    async fetchInitData() {
      try {
        const { data: workflowDetailData } = await this.$api.httpClient(
          "GET",
          this.url
        );
        // 表示用に加工
        workflowDetailData.forEach((data) => {
          if (data.processing_date) {
            data.processing_date_format = this.$date(
              data.processing_date
            ).format("YYYY/MM/DD HH:mm");
          } else {
            data.processing_date_format = "";
          }
        });
        this.formData.workflow_details = workflowDetailData;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
