<template>
  <v-app id="login">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-tabs
              class="elevation-12 rounded-t"
              v-model="tab"
              background-color="primary"
              show-arrows
              icons-and-text
              dark
              grow
            >
              <v-tabs-slider color="primary"></v-tabs-slider>
              <v-tab v-for="i in tabs" :key="i.name">
                <v-icon large>{{ i.icon }}</v-icon>
                <div class="caption py-1">{{ i.text }}</div>
              </v-tab>
              <v-tab-item>
                <form-wrapper :validator="$v.loginForm">
                  <v-form lazy-validation @submit.prevent="login">
                    <v-card>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <form-group name="username">
                              <template v-slot="{ attrs }">
                                <v-text-field
                                  v-bind="attrs"
                                  prepend-icon="mdi-mail"
                                  :label="$t('resources.users.fields.pid')"
                                  v-model="loginForm.username"
                                  type="text"
                                  outlined
                                  maxlength="6"
                                  dense
                                ></v-text-field>
                              </template>
                            </form-group>
                          </v-col>
                          <v-col cols="12">
                            <form-group name="password">
                              <template v-slot="{ attrs }">
                                <v-text-field
                                  v-bind="attrs"
                                  id="password"
                                  prepend-icon="mdi-lock"
                                  :label="$t('resources.users.fields.password')"
                                  v-model="loginForm.password"
                                  :append-icon="
                                    showPassword ? 'mdi-eye' : 'mdi-eye-off'
                                  "
                                  :type="showPassword ? 'text' : 'password'"
                                  counter
                                  @click:append="showPassword = !showPassword"
                                  outlined
                                  dense
                                ></v-text-field>
                              </template>
                            </form-group>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <a @click="gotoPasswordReset">
                              {{ $t("auth.passwordReset.entry") }}
                            </a>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          type="sumbit"
                          :disabled="loading"
                          >{{ $t("auth.login") }}</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </form-wrapper>
              </v-tab-item>
              <v-tab-item>
                <form-wrapper :validator="$v.signupForm">
                  <v-form
                    ref="signupForm"
                    lazy-validation
                    @submit.prevent="signup"
                  >
                    <v-card>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <form-group
                              :validator="$v.signupForm.username"
                              attribute="resources.users.fields.pid"
                            >
                              <template v-slot="{ attrs }">
                                <v-text-field
                                  v-bind="attrs"
                                  v-model="signupForm.username"
                                  :label="$t('resources.users.fields.pid')"
                                  maxLength="6"
                                  outlined
                                  @change="resetServerError('username')"
                                ></v-text-field>
                              </template>
                            </form-group>
                          </v-col>
                          <v-col cols="6">
                            <form-group
                              :validator="$v.signupForm.last_name"
                              attribute="resources.users.fields.last_name"
                            >
                              <template v-slot="{ attrs }">
                                <v-text-field
                                  v-bind="attrs"
                                  v-model="signupForm.last_name"
                                  :label="
                                    $t('resources.users.fields.last_name')
                                  "
                                  maxLength="15"
                                  outlined
                                ></v-text-field>
                              </template>
                            </form-group>
                          </v-col>
                          <v-col cols="6">
                            <form-group
                              :validator="$v.signupForm.first_name"
                              attribute="resources.users.fields.first_name"
                            >
                              <template v-slot="{ attrs }">
                                <v-text-field
                                  v-bind="attrs"
                                  v-model="signupForm.first_name"
                                  :label="
                                    $t('resources.users.fields.first_name')
                                  "
                                  maxLength="15"
                                  outlined
                                ></v-text-field>
                              </template>
                            </form-group>
                          </v-col>
                          <v-col cols="12">
                            <form-group
                              :validator="$v.signupForm.email"
                              attribute="resources.users.fields.email"
                            >
                              <template v-slot="{ attrs }">
                                <v-text-field
                                  v-bind="attrs"
                                  v-model="signupForm.email"
                                  :label="$t('resources.users.fields.email')"
                                  maxlength="254"
                                  outlined
                                  @change="resetServerError('email')"
                                ></v-text-field>
                              </template>
                            </form-group>
                          </v-col>
                          <v-col cols="12">
                            <form-group
                              :validator="$v.signupForm.department"
                              attribute="resources.users.fields.department"
                            >
                              <template v-slot="{ attrs }">
                                <v-autocomplete
                                  v-bind="attrs"
                                  item-value="id"
                                  item-text="all_name"
                                  :items="departments"
                                  v-model="signupForm.department"
                                  clearable
                                  :label="
                                    $t('resources.users.fields.department')
                                  "
                                  outlined
                                ></v-autocomplete>
                              </template>
                            </form-group>
                          </v-col>
                          <v-col cols="12">
                            <form-group
                              :validator="$v.signupForm.tel"
                              attribute="resources.users.fields.tel"
                            >
                              <template v-slot="{ attrs }">
                                <v-text-field
                                  v-bind="attrs"
                                  v-model="signupForm.tel"
                                  :label="$t('resources.users.fields.tel')"
                                  maxlength="21"
                                  outlined
                                ></v-text-field>
                              </template>
                            </form-group>
                          </v-col>
                          <v-col cols="12">
                            <form-group
                              :validator="$v.signupForm.password1"
                              attribute="resources.users.fields.password"
                            >
                              <template v-slot="{ attrs }">
                                <v-text-field
                                  v-bind="attrs"
                                  v-model="signupForm.password1"
                                  :append-icon="
                                    showPassword ? 'mdi-eye' : 'mdi-eye-off'
                                  "
                                  :type="showPassword ? 'text' : 'password'"
                                  :label="$t('resources.users.fields.password')"
                                  counter
                                  @click:append="showPassword = !showPassword"
                                  maxlength="128"
                                  minlength="6"
                                  outlined
                                ></v-text-field>
                              </template>
                            </form-group>
                          </v-col>
                          <v-col cols="12">
                            <form-group
                              :validator="$v.signupForm.password2"
                              attribute="resources.users.fields.verify"
                            >
                              <template v-slot="{ attrs }">
                                <v-text-field
                                  v-bind="attrs"
                                  block
                                  v-model="signupForm.password2"
                                  :append-icon="
                                    showPassword ? 'mdi-eye' : 'mdi-eye-off'
                                  "
                                  :type="showPassword ? 'text' : 'password'"
                                  :label="$t('resources.users.fields.verify')"
                                  counter
                                  @click:append="showPassword = !showPassword"
                                  maxlength="128"
                                  outlined
                                ></v-text-field>
                              </template>
                            </form-group>
                          </v-col>
                          <v-col cols="12">
                            <form-group
                              :validator="$v.signupForm.workflow_user1"
                              attribute="resources.users.fields.workflow_user1"
                            >
                              <template v-slot="{ attrs }">
                                <v-autocomplete
                                  v-bind="attrs"
                                  v-model="signupForm.workflow_user1"
                                  :items="managerUsers"
                                  item-value="id"
                                  item-text="all_name"
                                  :label="
                                    $t('resources.users.fields.workflow_user1')
                                  "
                                  outlined
                                ></v-autocomplete>
                              </template>
                            </form-group>
                          </v-col>
                          <v-col cols="12">
                            <form-group
                              :validator="$v.signupForm.workflow_user2"
                              attribute="resources.users.fields.workflow_user2"
                            >
                              <template v-slot="{ attrs }">
                                <v-autocomplete
                                  v-bind="attrs"
                                  v-model="signupForm.workflow_user2"
                                  :items="managerUsers"
                                  item-value="id"
                                  item-text="all_name"
                                  :label="
                                    $t('resources.users.fields.workflow_user2')
                                  "
                                  outlined
                                ></v-autocomplete>
                              </template>
                            </form-group>
                          </v-col>
                          <v-col cols="12">
                            <form-group
                              :validator="$v.signupForm.proxy_user"
                              attribute="resources.users.fields.proxy_user"
                            >
                              <template v-slot="{ attrs }">
                                <v-autocomplete
                                  v-bind="attrs"
                                  v-model="signupForm.proxy_user"
                                  :items="users"
                                  item-value="id"
                                  item-text="all_name"
                                  :label="
                                    $t('resources.users.fields.proxy_user')
                                  "
                                  outlined
                                ></v-autocomplete>
                              </template>
                            </form-group>
                          </v-col>
                          <v-col cols="6">
                            <form-group
                              :validator="$v.signupForm.role_normal"
                              attribute="resources.users.fields.role_normal"
                            >
                              <template v-slot="{ attrs }">
                                <v-checkbox
                                  v-bind="attrs"
                                  v-model="signupForm.role_normal"
                                  :label="
                                    $t('resources.users.fields.role_normal')
                                  "
                                  color="primary"
                                ></v-checkbox>
                              </template>
                            </form-group>
                          </v-col>
                          <v-col cols="6">
                            <form-group
                              :validator="$v.signupForm.role_approver"
                              attribute="resources.users.fields.role_approver"
                            >
                              <template v-slot="{ attrs }">
                                <v-checkbox
                                  v-bind="attrs"
                                  v-model="signupForm.role_approver"
                                  :label="
                                    $t('resources.users.fields.role_approver')
                                  "
                                  color="primary"
                                ></v-checkbox>
                              </template>
                            </form-group>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          type="submit"
                          :disabled="loading"
                          >{{ $t("auth.signup") }}</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </form-wrapper>
              </v-tab-item>
            </v-tabs>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <Confirm ref="confirm"></Confirm>
  </v-app>
</template>

<script>
import Confirm from "../particals/Confirm.vue";
import axios from "../../plugins/axios";
import { validationMixin } from "vuelidate";
import {
  required,
  sameAs,
  email,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { any, alreadyExist } from "../../validators";

const ajax = axios.create();

/**
 * @group 認証
 * ログイン画面<br>
 * 機能仕様書：3.1, 3.3, 6.2
 */
export default {
  name: "Login",
  mixins: [validationMixin],
  components: { Confirm },
  validations() {
    const loginForm = {
      username: {
        required,
        numeric,
        justLength: minLength(6),
      },
      password: { required },
    };
    const signupForm = {
      username: {
        required,
        numeric,
        justLength: minLength(6),
        alreadyExist: alreadyExist("username"),
      },
      last_name: { required },
      first_name: { required },
      email: {
        required,
        email,
        alreadyExist: alreadyExist("email"),
      },
      department: { required, numeric },
      tel: { required, maxLength: maxLength(21) },
      password1: { required, minLength: minLength(6) },
      password2: { required, sameAsPassword: sameAs("password1") },
      proxy_user: { numeric },
      role_normal: {
        roleRequired: any(["role_approver"]),
      },
      role_approver: {
        roleRequired: any(["role_normal"]),
      },
    };
    return { loginForm, signupForm };
  },
  data() {
    return {
      loading: false,
      loginForm: {
        username: "",
        password: "",
      },
      loginFormValid: true,
      signupForm: {
        username: "",
        email: "",
        password1: "",
        password2: "",
        last_name: "",
        first_name: "",
        department: null,
        tel: "",
        proxy_user: null,
        role_normal: false,
        role_approver: false,
        workflow_user1: null,
        workflow_user2: null,
      },
      departments: [],
      managerUsers: [],
      users: [],
      signupFormValid: true,
      tab: 0,
      tabs: [
        { name: "login", text: this.$t("auth.login"), icon: "mdi-account" },
        {
          name: "signup",
          text: this.$t("auth.signup"),
          icon: "mdi-account-outline",
        },
      ],
      showPassword: false,
      serverErrors: {},
    };
  },
  /**
   * @vuese
   * 画面生成時処理
   */
  async created() {
    try {
      const user_params = new URLSearchParams();
      user_params.append("identities", "is_manager");

      const [{ data: departments }, { data: managerUsers }, { data: users }] =
        await Promise.all([
          ajax.get("public/departments/"),
          ajax.get("public/users/", { params: user_params }),
          ajax.get("public/users/"),
        ]);
      this.departments = departments;
      this.managerUsers = managerUsers;
      this.users = users;
    } catch (err) {
      if (err?.response) {
        const { status } = err.response;
        this.$message.error(this.$utils.translateHttpCode(status));
      }
    }
  },
  methods: {
    async login() {
      this.$v.loginForm.$touch();
      if (!this.$v.loginForm.$invalid) {
        try {
          this.loading = true;
          await this.$store.dispatch("login", this.loginForm);
          const { redirect } = this.$route.params;
          if (redirect) {
            this.$router.push(redirect, null, () => {});
          } else {
            this.$router.push("/", null, () => {});
          }
        } catch (err) {
          if (err?.response) {
            const { status } = err.response;
            if (status === 400) {
              this.$message.error(this.$t("messages.auth.loginError"));
            } else {
              this.$message.error(this.$utils.translateHttpCode(status));
            }
            this.loginForm.password = "";
          }
        } finally {
          this.loading = false;
        }
      }
    },
    async signup() {
      this.$v.signupForm.$touch();
      if (!this.$v.signupForm.$invalid) {
        if (
          await this.$refs.confirm.open(
            "actions.confirm",
            "messages.actions.confirmSignup"
          )
        ) {
          try {
            await this.$store.dispatch("signup", this.signupForm);
            this.$message.success(this.$t("messages.success.signup"));
            this.tab = 0;
            this.resetSignupForm();
          } catch (err) {
            this.$message.error(this.$t("messages.auth.signupError"));
            if (err.response?.data) {
              this.serverErrors = err.response.data;
            }
          }
        }
      }
    },
    resetSignupForm() {
      this.$v.signupForm.$reset();
      this.$refs.signupForm.reset();
      Object.assign(this.signupForm, {
        role_normal: false,
        role_approver: false,
      });
      this.serverErrors = {};
    },
    gotoPasswordReset() {
      this.$router.push({ name: "passwordReset" });
    },
    resetServerError(field) {
      this.$delete(this.serverErrors, field);
    },
  },
};
</script>

<style></style>
