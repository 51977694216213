import { CRUD_OPERATION } from "../../configs/crudConfig";
import {
  isEqual,
  isObject,
  mapValues,
  isEmpty,
  pickBy,
  has,
  isNil,
  some,
} from "lodash";
import { saveAs } from "file-saver";

function stringifyFilters(filters, meta) {
  return pickBy(
    mapValues(filters, (value, key) => {
      if (
        value === undefined ||
        value === null ||
        Number.isNaN(value) ||
        (isObject(value) && isEmpty(value)) ||
        !has(meta, key)
      )
        return undefined;
      return meta[key].serialize(value);
    }),
    (v) => !isEmpty(v)
  );
}

function parseFilters(query, meta) {
  return pickBy(
    mapValues(query, (value, key) => {
      if (
        value === undefined ||
        value === null ||
        Number.isNaN(value) ||
        (isObject(value) && isEmpty(value)) ||
        !has(meta, key)
      )
        return undefined;
      return meta[key].parse(value);
    }),
    (v) => !isNil(v)
  );
}

export default {
  watch: {
    filters: {
      handler(val) {
        // get stringfied filters from query string
        const { ordering, ...filters } = this.$route.query;
        const { filterTypes } = this.$route.meta;
        const santilizedFilters = stringifyFilters(val, filterTypes);
        if (!isEqual(santilizedFilters, filters)) {
          this.$router.replace({
            query: {
              ordering,
              ...santilizedFilters,
            },
          });
        }
      },
      deep: true,
    },
    ordering: {
      handler(val) {
        const { ordering, ...filters } = this.$route.query;
        if (!isEqual(val, ordering)) {
          this.$router.replace({
            query: {
              ordering: val,
              ...filters,
            },
          });
        }
      },
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const { ordering, ...filters } = to.query;
      const { filterTypes } = to.meta;
      const santilizedFilters = parseFilters(filters, filterTypes);
      vm.filters = { ...vm.filters, ...santilizedFilters };
      vm.ordering = ordering;
      if (some(vm.filters, Boolean)) {
        vm.search();
      }
    });
  },
  data() {
    return {
      filters: {
        search: "",
      },
      ordering: null,
      total: 0,
      data: [],
      loading: false,
    };
  },
  methods: {
    clear() {
      this.filters = this.$options.data.call(this).filters;
    },
    async search() {
      this.loading = true;
      try {
        const { filterTypes } = this.$route.meta;
        const santilizedFilters = stringifyFilters(this.filters, filterTypes);
        const { total, data } = await this.$api.getList(
          this.$options.resource,
          {
            ...santilizedFilters,
            ordering: this.ordering,
          }
        );
        this.total = total;
        this.data = data;
      } finally {
        this.loading = false;
      }
    },
    async downloadCSV() {
      this.loading = true;
      const filters = this.$route.query;
      try {
        const { data, filename } = await this.$api.getCsv(
          this.$options.resource,
          filters
        );
        saveAs(data, filename);
      } finally {
        this.loading = false;
      }
    },
    create(category) {
      console.log("create method! category", category);
      this.$router.push({
        name: `${this.$options.resource}${CRUD_OPERATION.CREATE}`,
        params: { category: category },
      });
    },
    edit({ id }) {
      this.$router.push({
        name: `${this.$options.resource}${CRUD_OPERATION.EDIT}`,
        params: { id },
      });
    },
    show({ id }) {
      this.$router.push({
        name: `${this.$options.resource}${CRUD_OPERATION.SHOW}`,
        params: { id },
      });
    },
    toLocaleString(number) {
      return number?.toLocaleString() || "";
    },
  },
};
