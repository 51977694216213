<template>
  <v-container class="lighten-5" id="main_container">
    <v-card class="mx-auto mt-5" width="60rem">
      <v-system-bar color="indigo darken-2" dark class="pa-5">
        {{ $t("resources.orderReceipts.header_title") }}
      </v-system-bar>
      <v-container fluid class="pa-5">
        <v-card class="d-flex justify-center text-h4 mb-6" flat tile>
          {{ $t("resources.orderReceipts.name") }}
        </v-card>

        <v-row class="mx-10">
          <v-card class="d-flex flex-column mr-auto" flat tile>
            <v-card class="text-h5" flat tile>
              {{ $get("orderReceiptData.case.client_department.name", "") }}
              御中
            </v-card>
            <v-card class="pt-2" flat tile>
              {{ $get("orderReceiptData.case.client_user.full_name", "") }}
              様
            </v-card>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.orderReceipts.fields.request_title") }}：
              {{ $get("orderReceiptData.case.request_title", "") }}
            </v-card>
            <v-card class="pt-4 text-h5" flat tile>
              {{
                isApproval
                  ? $t("resources.orderReceipts.fields.budget_amount")
                  : $t("resources.orderReceipts.fields.quotation_amount")
              }}： {{ quotationPriceFormatted }} 円（税別）
            </v-card>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.orderReceipts.fields.delivery_destination") }}：
              {{ deliveryDestinationValue }}
            </v-card>
            <v-card class="pt-2" flat tile v-if="isStorageNumberInput">
              {{ $t("resources.cases.fields.storage_number") }}：
              {{ storageNumberFormatted }}
            </v-card>
            <v-card class="pt-2" flat tile v-if="isStorageNumberInput">
              {{ $t("resources.cases.fields.catalog_storage_quantity") }}：
              {{ catalogStorageQuantityFormatted }}
            </v-card>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.orderReceipts.fields.preferred_due_date") }}：
              {{ $get("orderReceiptData.case.preferred_due_date", "") }}
            </v-card>
          </v-card>
          <v-card class="d-flex flex-column ml-auto" flat tile>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.orderReceipts.fields.order_receipt_no") }}：
              {{ orderReceiptNoFormatted }}
            </v-card>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.orderReceipts.fields.case_no") }}：
              {{ $get("orderReceiptData.case.case_no", "") }}
            </v-card>
            <v-card v-if="isApproval" class="pt-2" flat tile>
              {{ $t("resources.orderReceipts.fields.approval_no") }}：
              {{ $get("orderReceiptData.case.approval_no", "") }}
            </v-card>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.orderReceipts.fields.quotation_date") }}：
              {{ $get("orderReceiptData.quotation.quotation_date", "") }}
            </v-card>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.orderReceipts.fields.expense_account") }}：
              {{ $get("orderReceiptData.case.expense_account.all_name", "") }}
            </v-card>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.orderReceipts.fields.request_count") }}：
              {{ requestCountFormatted }}
            </v-card>
          </v-card>
        </v-row>

        <v-row v-if="isShowRequestCategory" class="mx-10">
          <v-card class="d-flex flex-column mr-auto" flat tile>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.orderReceipts.fields.c_no") }}：
              {{ $get("orderReceiptData.case.c_no", "") }}
            </v-card>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.orderReceipts.fields.size") }}：
              {{ $get("orderReceiptData.case.size", "") }}
            </v-card>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.orderReceipts.fields.language_class") }}：
              {{ $get("orderReceiptData.case.language_class.name", "") }}
            </v-card>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.orderReceipts.fields.old_c_revision_no") }}：
              {{ $get("orderReceiptData.case.old_c_revision_no", "") }}
            </v-card>
          </v-card>

          <v-card class="d-flex flex-column text-xs-center" flat tile>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.orderReceipts.fields.c_revision_no") }}：
              {{ $get("orderReceiptData.case.c_revision_no", "") }}
            </v-card>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.orderReceipts.fields.page_count") }}：
              {{ $get("orderReceiptData.case.page_count", "") }}
            </v-card>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.orderReceipts.fields.old_c_no") }}：
              {{ $get("orderReceiptData.case.old_c_no", "") }}
            </v-card>
          </v-card>

          <v-card class="d-flex flex-column ml-auto" flat tile>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.orderReceipts.fields.edition") }}：
              {{ $get("orderReceiptData.case.edition.name", "") }}
            </v-card>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.orderReceipts.fields.delivery_form") }}：
              {{ $get("orderReceiptData.case.delivery_form.name", "") }}
            </v-card>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.orderReceipts.fields.old_processing") }}：
              {{ $get("orderReceiptData.case.old_processing.name", "") }}
            </v-card>
          </v-card>
        </v-row>
        <v-row v-if="isShowRequestCategoryAdData" class="mx-10">
          <v-card class="d-flex flex-column mr-auto" flat tile>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.cases.fields.media_name") }}：
              {{ $get("orderReceiptData.case.media_name", "") }}
            </v-card>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.cases.fields.ad_name") }}：
              {{ $get("orderReceiptData.case.ad_name", "") }}
            </v-card>
          </v-card>
          <v-card class="d-flex flex-column mr-auto" flat tile>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.cases.fields.plate_size") }}：
              {{ $get("orderReceiptData.case.plate_size.name", "") }}
            </v-card>
            <v-card
              class="pt-2"
              flat
              tile
              v-if="
                orderReceiptData.quotation.plate_height ||
                orderReceiptData.case.plate_height
              "
            >
              {{ $t("resources.cases.fields.plate_height") }}：
              {{ plateHeightFormatted }}
            </v-card>
            <v-card
              class="pt-2"
              flat
              tile
              v-if="
                orderReceiptData.quotation.plate_width ||
                orderReceiptData.case.plate_width
              "
            >
              {{ $t("resources.cases.fields.plate_width") }}：
              {{ plateWidthFormatted }}
            </v-card>
          </v-card>
          <v-card class="d-flex flex-column ml-auto" flat tile>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.cases.fields.ad_format") }}：
              {{ $get("orderReceiptData.case.ad_format.name", "") }}
            </v-card>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.cases.fields.color") }}：
              {{ $get("orderReceiptData.case.color.name", "") }}
            </v-card>
            <v-card
              class="pt-2"
              flat
              tile
              v-if="orderReceiptData.case.other_color"
            >
              {{ $t("resources.cases.fields.other_color") }}：
              {{ $get("orderReceiptData.case.other_color", "") }}
            </v-card>
          </v-card>
        </v-row>
        <v-row v-if="isShowRequestCategoryPanelData" class="mx-10">
          <v-card class="d-flex flex-column mr-auto" flat tile>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.cases.fields.desired_panel_no") }}：
              {{ $get("orderReceiptData.case.desired_panel_no", "") }}
            </v-card>
          </v-card>
          <v-card class="d-flex flex-column mr-auto" flat tile>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.cases.fields.hospital_name") }}：
              {{ $get("orderReceiptData.case.hospital_name", "") }}
            </v-card>
          </v-card>
          <v-card class="d-flex flex-column mr-auto" flat tile>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.cases.fields.panel_size") }}：
              {{ $get("orderReceiptData.case.panel_size.name", "") }}
            </v-card>
            <v-card
              class="pt-2"
              flat
              tile
              v-if="
                orderReceiptData.quotation.panel_height ||
                orderReceiptData.case.panel_height
              "
            >
              {{ $t("resources.cases.fields.panel_height") }}：
              {{ panelHeightFormatted }}
            </v-card>
            <v-card
              class="pt-2"
              flat
              tile
              v-if="
                orderReceiptData.quotation.panel_width ||
                orderReceiptData.case.panel_width
              "
            >
              {{ $t("resources.cases.fields.panel_width") }}：
              {{ panelWidthFormatted }}
            </v-card>
          </v-card>
        </v-row>
        <v-row v-if="isShowRequestCategorySpecialPrintingDetails" class="mx-10">
          <v-card class="mr-auto" flat tile>
            <v-card class="pt-2" flat tile>
              {{ $t("resources.cases.fields.special_printing_detail") }}：
            </v-card>
          </v-card>
          <v-textarea
            v-model="formData.special_printing_detail"
            class="pt-0"
            :readonly="true"
            :rounded="true"
          >
          </v-textarea>
        </v-row>

        <v-row justify="start">
          <v-col cols="12">
            <DataTable
              v-if="!isApproval"
              resource="quotationDetails"
              parentRelationName="quotation"
              :parentId="this.quotationId"
              :headers="orderReceiptHeaders"
              :showAddButton="false"
              :showEditButton="false"
              :showDeleteButton="false"
            >
              <template v-slot:[`item.amount`]="{ item }">
                {{ toLocaleString(item.amount) }}
              </template>
              <template v-slot:[`item.unit_cost`]="{ item }">
                {{ toLocaleString(item.unit_cost) }}
              </template>
              <template v-slot:[`item.quantity`]="{ item }">
                {{ toLocaleString(item.quantity) }}
              </template>
            </DataTable>
          </v-col>
        </v-row>
        <v-card class="d-flex justify-end" flat tile>
          <v-card class="pt-2 mr-10" flat tile>
            <v-text-field
              v-if="!isApproval"
              :value="totalAmountFormatted"
              flat
              reverse
              prefix="円（税別）"
              :suffix="$t(`resources.orderReceipts.fields.sub_total`)"
              readonly
            ></v-text-field>
          </v-card>
        </v-card>
        <v-textarea
          v-model="formData.remarks"
          class="mt-6"
          :label="$t('resources.orderReceipts.fields.remarks')"
          :outlined="propsStandardItem"
          :readonly="!propsStandardItem"
          :rounded="!propsStandardItem"
        ></v-textarea>
      </v-container>
    </v-card>

    <!--承認フロー-->
    <v-card v-show="isShowAdcomUser" class="mx-auto mt-5" width="60rem">
      <WorkflowAction
        v-show="IsWorkflow"
        :id="displayWorkflowId"
        v-on:updated="changeWorkflow"
      ></WorkflowAction>
    </v-card>

    <!--承認履歴-->
    <WorkflowHistory
      :refreshKey="workflowHistoryKey"
      :workflow_id="workflowId"
    ></WorkflowHistory>

    <v-bottom-navigation fixed horizontal scroll-target="#main_container">
      <v-btn @click="moveTop()" :disabled="loading" text>
        <span>{{ $t("moveTop") }}</span>
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-btn @click="moveCase()" :disabled="loading" text>
        <span>{{ $t("moveCase") }}</span>
        <v-icon>mdi-clipboard-text</v-icon>
      </v-btn>
      <v-btn v-if="isShowSave" @click="save()" :disabled="loading" text>
        <span>{{ $t("save") }}</span>
        <v-icon>mdi-content-save-outline</v-icon>
      </v-btn>
      <v-btn v-if="isShowPrint" @click="print()" :disabled="loading" text>
        <span>{{ $t("resources.orderReceipts.actions.print") }}</span>
        <v-icon>mdi-file-download-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-container>
</template>
<script>
import groupPermissionMixin from "../../mixins/groupPermissionMixin";
import updateMixin from "../../mixins/updateMixin";
import relationMixin from "../../mixins/relationMixin";
import fieldPermissionMixin from "../../mixins/fieldPermissionMixin";
import headersMixin from "../../mixins/headersMixin";
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import { statuses } from "../../../configs/statusConfig";
import { requestCategorys } from "../../../configs/requestCategoryConfig";
import { workfrowStatuses } from "../../../configs/workfrowStatusConfig";
import { caseCategorys } from "../../../configs/caseCategoryConfig";
import { saveAs } from "file-saver";

/**
 * @vuese
 * @group 帳票
 * 注文請書画面<br>
 * 機能仕様書：4.6, 5.8, 5.13, 5.16, 5.18, 6.1, 6.2, 6.4
 */
export default {
  name: "OrderReceipts.Report",
  resource: "orderReceipts",
  mixins: [
    groupPermissionMixin,
    fieldPermissionMixin,
    updateMixin,
    headersMixin,
    validationMixin,
    relationMixin,
  ],
  validations() {
    const formData = {
      remarks: {},
    };
    return {
      formData,
    };
  },
  data() {
    return {
      formData: {
        order_receipt_no: "",
        order_date: "",
        remarks: "",
        special_printing_detail: "",
      },
      totalAmount: null,
      orderReceiptData: {},
      quotationData: {},
      workflowHistoryKey: 0,
      orderReceiptWorkflowStatus: workfrowStatuses.NoWorkfrow,
    };
  },
  methods: {
    /**
     * @vuese
     * データ取得処理
     * @arg id - 請書情報ID
     */
    async fetchData(id) {
      this.loading = true;
      try {
        const [orderReceiptData] = await Promise.all([
          this.$api.getOne(this.$options.resource, id),
          this.getOrderReceiptWorkflowStatus(),
        ]);
        this.orderReceiptData = orderReceiptData;
        this.$utils.update(this.formData, orderReceiptData);
        // fetch case
        await this.join(this.orderReceiptData, [
          { name: "case", resource: "cases" },
          { name: "quotation", resource: "quotations" },
        ]);
        if (orderReceiptData?.order_receipt_no) {
          console.log("case.idを更新前に退避");
          //case.idを更新前に退避
          const case_id = this.orderReceiptData.case.id;
          // 見積書に保持している情報で表示するために、案件情報の内容を変更する
          this.$utils.update(
            this.orderReceiptData.case,
            this.orderReceiptData.quotation
          );
          //case.idを更新後に戻す
          this.orderReceiptData.case.id = case_id;
        }
        // 特殊印刷詳細の値の割り当て
        this.formData.special_printing_detail =
          this.orderReceiptData.case.special_printing_detail;

        await this.join(this.orderReceiptData.case, [
          { name: "client_user", resource: "users" },
          { name: "client_department", resource: "departments" },
          { name: "delivery_destination", resource: "deliveryDestinations" },
          { name: "expense_account", resource: "expenseAccounts" },
          { name: "language_class", resource: "languageClasses" },
          { name: "delivery_form", resource: "deliveryForms" },
          { name: "old_processing", resource: "processings" },
          { name: "edition", resource: "editions" },
          { name: "contact_user", resource: "users" },
          { name: "quotation_id", resource: "quotations" },
          { name: "contact_user", resource: "users" },
          { name: "color", resource: "colors" },
          { name: "ad_format", resource: "adFormats" },
          { name: "panel_size", resource: "panelSizes" },
          { name: "plate_size", resource: "plateSizes" },
        ]);
        if (!this.isApproval) {
          await this.getQuotationAmount();
        }
      } finally {
        this.loading = false;
      }
    },
    /**
     * @vuese
     * 見積額の取得
     */
    async getQuotationAmount() {
      const { total_amount } = await this.$api.dispatch(
        "GET",
        "quotations",
        "total_amount",
        this.quotationId
      );
      this.totalAmount = total_amount;
    },
    /**
     * @vuese
     * ワークフロー更新時の処理
     */
    async changeWorkflow(args) {
      this.workflowHistoryKey++;
      await Promise.all([
        this.getOrderReceiptWorkflowStatus(),
        this.orderReceiptRelord(),
      ]);
      if (args.action === "applicate") {
        // 申請の場合は備考が変更されている可能性があるので、請書情報を更新する
        const orderReceiptData = await this.$api.getOne(
          this.$options.resource,
          this.id
        );
        const remarks = this.formData.remarks;
        orderReceiptData.remarks = remarks;
        this.$utils.update(this.formData, orderReceiptData);
        this.save("messages.success.workflows.applicate");
      } else {
        // 申請以外の場合はメッセージのみ表示
        this.$message.success(
          this.$t("messages.success.workflows." + args.action)
        );
      }
    },
    /**
     * @vuese
     * 検索一覧移動メソッド
     */
    async moveTop() {
      if (this.hasPerm("adcom")) {
        await this.$router.push({
          name: "caseList",
        });
      } else {
        await this.$router.push({
          name: "quotationList",
        });
      }
    },
    /**
     * @vuese
     * 案件詳細移動メソッド
     */
    async moveCase() {
      await this.$router.push({
        name: "casesEdit",
        params: { id: this.orderReceiptData.case.id },
      });
    },
    /**
     * @vuese
     * PDF出力
     */
    async print() {
      const { data, filename } = await this.$api.getPDF(
        this.$options.resource,
        this.id
      );
      saveAs(data, filename);
    },
    /**
     * @vuese
     * 注文請書書情報更新メソッド
     */
    async orderReceiptRelord() {
      const [orderReceiptData] = await Promise.all([
        this.$api.getOne(this.$options.resource, this.id),
      ]);
      this.orderReceiptData.order_receipt_no =
        orderReceiptData.order_receipt_no;
      this.orderReceiptData.order_receipt_no_seq =
        orderReceiptData.order_receipt_no_seq;
    },
    /**
     * @vuese
     * 見積書のワークフロー状況取得メソッド
     */
    async getOrderReceiptWorkflowStatus() {
      const { status } = await this.$api.dispatch(
        "GET",
        this.$options.resource,
        "get_workflow_status",
        this.id
      );
      this.orderReceiptWorkflowStatus = status;
    },
    toLocaleString(number) {
      return number?.toLocaleString() || "";
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    quotationPriceFormatted() {
      if (this.orderReceiptData?.order_receipt_no) {
        return this.orderReceiptData?.quotation?.amount?.toLocaleString();
      } else {
        return this.orderReceiptData?.case?.total_selling_price?.toLocaleString();
      }
    },
    requestCountFormatted() {
      if (this.orderReceiptData?.order_receipt_no) {
        return this.orderReceiptData?.quotation?.request_count?.toLocaleString();
      } else {
        return this.orderReceiptData?.case?.request_count?.toLocaleString();
      }
    },
    storageNumberFormatted() {
      if (this.orderReceiptData?.order_receipt_no) {
        return this.orderReceiptData?.quotation?.storage_number?.toLocaleString();
      } else {
        return this.orderReceiptData?.case?.storage_number?.toLocaleString();
      }
    },
    catalogStorageQuantityFormatted() {
      if (this.orderReceiptData?.order_receipt_no) {
        return (
          this.orderReceiptData?.quotation?.request_count -
          this.orderReceiptData?.quotation?.storage_number
        )?.toLocaleString();
      } else {
        return (
          this.orderReceiptData?.case?.request_count -
          this.orderReceiptData?.case?.storage_number
        )?.toLocaleString();
      }
    },
    plateHeightFormatted() {
      if (this.orderReceiptData?.order_receipt_no) {
        return this.orderReceiptData?.quotation?.plate_height?.toLocaleString();
      } else {
        return this.orderReceiptData?.case?.plate_height?.toLocaleString();
      }
    },
    plateWidthFormatted() {
      if (this.orderReceiptData?.order_receipt_no) {
        return this.orderReceiptData?.quotation?.plate_width?.toLocaleString();
      } else {
        return this.orderReceiptData?.case?.plate_width?.toLocaleString();
      }
    },
    panelHeightFormatted() {
      if (this.orderReceiptData?.order_receipt_no) {
        return this.orderReceiptData?.quotation?.panel_height?.toLocaleString();
      } else {
        return this.orderReceiptData?.case?.panel_height?.toLocaleString();
      }
    },
    panelWidthFormatted() {
      if (this.orderReceiptData?.order_receipt_no) {
        return this.orderReceiptData?.quotation?.panel_width?.toLocaleString();
      } else {
        return this.orderReceiptData?.case?.panel_width?.toLocaleString();
      }
    },
    orderReceiptNoFormatted() {
      if (this.orderReceiptData?.order_receipt_no) {
        return (
          this.orderReceiptData?.order_receipt_no +
          "-" +
          ("0000" + this.orderReceiptData?.order_receipt_no_seq).slice(-4)
        );
      } else {
        return "";
      }
    },
    totalAmountFormatted() {
      return this.totalAmount?.toLocaleString() || "";
    },
    deliveryDestinationValue() {
      if (this.orderReceiptData?.case?.delivery_destination_manual) {
        return this.orderReceiptData?.case?.delivery_destination_manual;
      } else {
        return this.orderReceiptData?.case?.delivery_destination?.name || "";
      }
    },
    isStorageNumberInput() {
      return this.orderReceiptData?.case?.delivery_destination?.code === "3";
    },
    workflowId() {
      return [
        this.orderReceiptData?.adcom_workflow,
        this.orderReceiptData?.client_workflow,
      ].filter((id) => id);
    },
    displayWorkflowId() {
      return this.hasPerm(`adcomStaff`, `adcomManager`)
        ? this.orderReceiptData?.adcom_workflow || null
        : this.orderReceiptData?.client_workflow || null;
    },
    /**
     * @vuese
     * カタログ情報の表示非表示設定
     */
    isShowRequestCategory() {
      return (
        this.orderReceiptData?.case?.request_category ==
        requestCategorys.Catalog
      );
    },
    /**
     * @vuese
     * 医用広告データ情報の表示非表示設定
     */
    isShowRequestCategoryAdData() {
      return (
        this.orderReceiptData?.case?.request_category ===
        requestCategorys.medical_ad_data
      );
    },
    /**
     * @vuese
     * 院内パネル情報の表示非表示設定
     */
    isShowRequestCategoryPanelData() {
      return (
        this.orderReceiptData?.case?.request_category ===
        requestCategorys.hospital_panel_data
      );
    },
    /**
     * @vuese
     * 特殊印刷詳細情報の表示非表示設定
     */
    isShowRequestCategorySpecialPrintingDetails() {
      return (
        this.orderReceiptData?.case?.request_category ===
        requestCategorys.special_printing_detail
      );
    },
    /**
     * @vuese
     * 保存ボタンの表示非表示設定
     */
    isShowSave() {
      return (
        this.hasPerm("adcom") &&
        (!this.orderReceiptWorkflowStatus ||
          this.orderReceiptWorkflowStatus === workfrowStatuses.NoWorkfrow)
      );
    },
    /**
     * @vuese
     * アドコム権限の表示非表示設定
     */
    isShowAdcomUser() {
      return this.hasPerm("adcom");
    },
    /**
     * @vuese
     * クライアント権限の表示非表示設定
     */
    isShowClientUser() {
      return this.hasPerm("client");
    },
    /**
     * @vuese
     * 案件情報ID
     */
    caseId() {
      return this.orderReceiptData?.case?.id;
    },
    /**
     * @vuese
     * 見積書ID
     */
    quotationId() {
      return this.orderReceiptData?.quotation?.id;
    },
    /**
     * @vuese
     * 標準項目入力項目制御
     */
    propsStandardItem() {
      return this.isInput ? this.hasPerm("adcomStaff") : false;
    },
    /**
     * @vuese
     * 印刷ボタンの表示非表示設定
     */
    isShowPrint() {
      return this.orderReceiptWorkflowStatus === workfrowStatuses.Approval;
    },
    /**
     * @vuese
     * ワークフロー申請および削除時入力制御
     */
    isInput() {
      return (
        this.orderReceiptWorkflowStatus == workfrowStatuses.NoWorkfrow &&
        (this.orderReceiptData?.case?.status === statuses.QuotationSubmitted ||
          this.orderReceiptData?.case?.status === statuses.Inquiry) &&
        !this.orderReceiptData?.case?.delete_flag
      );
    },
    /**
     * @vuese
     * ワークフローコンポーネントの表示非表示設定
     */
    IsWorkflow() {
      return (
        (this.hasPerm("adcomStaff") && this.isAdcomContact) ||
        (this.hasPerm("adcomManager") &&
          this.orderReceiptWorkflowStatus !== workfrowStatuses.NoWorkfrow)
      );
    },
    /**
     * @vuese
     * アドコム担当者での制限
     */
    isAdcomContact() {
      return (
        this.orderReceiptData?.case?.contact_user?.id ===
          this.currentUser?.id ||
        this.orderReceiptData?.case?.contact_user?.proxy_user ===
          this.currentUser?.id
      );
    },
    /**
     * @vuese
     * 稟議案件か否かの判定
     */
    isApproval() {
      return (
        this.orderReceiptData?.case?.case_category === caseCategorys.Approval
      );
    },
  },
};
</script>
