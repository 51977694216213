<!--
見積一覧
-->
<template>
  <v-container class="lighten-5">
    <v-card class="mx-auto" max-width="100%">
      <v-system-bar color="indigo darken-2" dark class="pa-5">
        {{ $t("resources.commons.panels.filters") }}
      </v-system-bar>
      <v-form ref="filterForm" lazy-validation>
        <v-container fluid class="pa-10">
          <v-row class="d-flex flex-wrap justify-space-between align-baseline">
            <ReferenceAutocomplete
              resource="users"
              action="sameIdentity"
              v-model="filters.client_user"
              value="id"
              text="all_name"
              :label="$t('resources.cases.filters.client_user')"
              dense
              class="ma-4"
              :clearable="true"
            >
            </ReferenceAutocomplete>
            <ReferenceAutocomplete
              resource="departments"
              v-model="filters.client_department"
              value="id"
              text="all_name"
              :label="$t('resources.cases.filters.client_department')"
              dense
              class="ma-4"
              :clearable="true"
            ></ReferenceAutocomplete>
            <ReferenceAutocomplete
              resource="statuses"
              v-model="filters.status"
              text="client_name"
              value="code"
              :label="$t('resources.cases.filters.status')"
              dense
              multiple
              chips
              class="ma-4"
              :clearable="true"
            ></ReferenceAutocomplete>
            <v-autocomplete
              v-model="filters.report_name"
              :label="$t('resources.cases.filters.report_name')"
              :items="[
                { id: 1, value: 'quotation_id', name: '見積書' },
                { id: 2, value: 'order_receipt_id', name: '注文請書' },
                { id: 3, value: 'delivery_note_id', name: '納品確認書' },
                { id: 4, value: 'certificate_id', name: '検収確認書' },
              ]"
              item-text="name"
              item-value="value"
              :clearable="true"
              dense
              attach
              chips
              class="ma-4"
              multiple
            ></v-autocomplete>
            <ReferenceAutocomplete
              resource="departments"
              v-model="filters.cost_burden_department"
              value="id"
              text="all_name"
              :label="$t('resources.cases.filters.cost_burden_department')"
              dense
              class="ma-4"
              :clearable="true"
            ></ReferenceAutocomplete>
            <v-checkbox
              class="ml-6"
              v-model="filters.show_deleted"
              :label="$t('resources.cases.filters.show_delete')"
            ></v-checkbox>
          </v-row>
          <v-row class="d-flex flex-wrap justify-start">
            <DatePicker
              v-model="filters.order_date_after"
              :label="$t('resources.cases.filters.order_date_after')"
              class="ml-6"
            />
            <DatePicker
              v-model="filters.order_date_before"
              :label="$t('resources.cases.filters.order_date_before')"
              class="ml-6"
            />
            <DatePicker
              v-model="filters.scheduled_due_date_after"
              :label="$t('resources.cases.filters.scheduled_due_date_after')"
              class="ml-6"
            />
            <DatePicker
              v-model="filters.scheduled_due_date_before"
              :label="$t('resources.cases.filters.scheduled_due_date_before')"
              class="ml-6"
            />
            <v-text-field
              :label="$t('resources.cases.filters.quotation_amount_min')"
              v-model="filters.quotation_amount_min"
              class="mx-4"
              type="number"
            ></v-text-field>
            <v-text-field
              :label="$t('resources.cases.filters.quotation_amount_max')"
              v-model="filters.quotation_amount_max"
              class="mx-4"
              type="number"
            ></v-text-field>
            <v-text-field
              :label="$t('resources.cases.fields.c_no')"
              :placeholder="$t('resources.cases.fields.c_no')"
              v-model="filters.c_no"
              class="mx-4"
              clearable
            ></v-text-field>
            <v-text-field
              :label="$t('resources.cases.fields.c_revision_no')"
              :placeholder="$t('resources.cases.fields.c_revision_no')"
              v-model="filters.c_revision_no"
              class="mx-4"
              clearable
            ></v-text-field>
            <v-text-field
              :label="$t('resources.commons.filters.search.label')"
              :placeholder="$t('resources.commons.filters.search.placeholder')"
              v-model="filters.search"
              class="mx-4"
            ></v-text-field>
            <v-btn class="mx-4" @click="search" color="primary">
              {{ $t("actions.search") }}
            </v-btn>
            <v-btn class="mx-4" @click="clear" color="primary">
              {{ $t("actions.clear") }}
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card>

    <v-card class="mt-2 pa-4">
      <v-row>
        <v-col>
          <v-btn v-if="isShowCreateButton" @click="create(`1`)" color="primary">
            {{ $t("resources.cases.actions.create") }}
          </v-btn>
          <v-btn class="ml-4" @click="downloadCSV" color="primary">
            {{ $t("actions.export") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-container>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="data"
              :loading="loading"
              class="elevation-1"
              @click:row="edit"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:[`item.request_title`]="{ item }">
                <div class="request-title-column">
                  {{ item.request_title }}
                </div>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-card
                  :color="item.status.display_color"
                  dark
                  width="8rem"
                  class="text-center py-2"
                >
                  {{ item.status.client_name }}
                </v-card>
              </template>
              <template v-slot:[`item.quotation`]="{ item }">
                <v-btn
                  v-if="
                    item.case_category === '1' &&
                    item.status.code >= '30' &&
                    item.quotation_id
                  "
                  @click.stop="showQuotationReport(item.quotation_id)"
                  color="primary"
                >
                  {{ $t("resources.quotations.name") }}
                </v-btn>
              </template>
              <template v-slot:[`item.quotation_amount`]="{ item }">
                {{ toLocaleString(item.quotation_amount) }}
              </template>
              <template v-slot:[`item.order_receipt`]="{ item }">
                <v-btn
                  v-if="item.order_receipt_id"
                  @click.stop="showOrderReceiptReport(item.order_receipt_id)"
                  color="primary"
                >
                  {{ $t("resources.orderReceipts.name") }}
                </v-btn>
              </template>
              <template v-slot:[`item.invoice`]="{ item }">
                <v-btn
                  v-if="item.delivery_note_id"
                  @click.stop="showInvoiceReport(item.delivery_note_id)"
                  color="primary"
                >
                  {{ $t("resources.deliveryNotes.name") }}
                </v-btn>
              </template>
              <template v-slot:[`item.certificate`]="{ item }">
                <v-btn
                  v-if="item.certificate_id"
                  @click.stop="showCertificateReport(item.certificate_id)"
                  color="primary"
                >
                  {{ $t("resources.certificates.name") }}
                </v-btn>
              </template>
              <template v-slot:[`item.delete_category`]="{ item }">
                {{ deleteCategoryDisplay(item) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>
<script>
import singlePageListMixin from "../../mixins/singlePageListMixin";
import groupPermissionMixin from "../../mixins/groupPermissionMixin";
import { mapGetters } from "vuex";

/**
 * @group 受注管理
 * 案件一覧（クライアント）<br>
 * 機能仕様書：4.1, 6.2, 6.3
 */
export default {
  resource: "cases",
  mixins: [singlePageListMixin, groupPermissionMixin],
  data() {
    return {
      filters: {
        client_user: null,
        client_department: null,
        status: null,
        cost_burden_department: null,
        login_user: null,
        order_date_after: null,
        order_date_before: null,
        scheduled_due_date_after: null,
        scheduled_due_date_before: null,
        quotation_amount_min: null,
        quotation_amount_max: null,
        search: "",
        show_deleted: false,
        report_name: null,
        c_no: null,
        c_revision_no: null,
      },
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("resources.cases.fields.order_date"),
          value: "order_date",
        },
        {
          text: this.$t("resources.cases.fields.request_title"),
          value: "request_title",
        },
        {
          text: this.$t("resources.cases.fields.client_user"),
          value: "client_user",
        },
        {
          text: this.$t("resources.cases.fields.client_department"),
          value: "client_department",
        },
        {
          text: this.$t("resources.cases.fields.quotation_amount"),
          value: "quotation_amount",
        },
        {
          text: this.$t("resources.cases.fields.status"),
          sortable: false,
          value: "status",
        },
        {
          text: this.$t("resources.cases.fields.scheduled_due_date"),
          value: "scheduled_due_date",
        },
        {
          text: this.$t("resources.cases.fields.case_no"),
          value: "case_no",
        },
        {
          text: this.$t("resources.quotations.name"),
          sortable: false,
          value: "quotation",
        },
        {
          text: this.$t("resources.orderReceipts.name"),
          sortable: false,
          value: "order_receipt",
        },
        {
          text: this.$t("resources.deliveryNotes.name"),
          sortable: false,
          value: "invoice",
        },
        {
          text: this.$t("resources.certificates.name"),
          sortable: false,
          value: "certificate",
        },
        {
          text: this.$t("resources.cases.fields.contact_user"),
          value: "contact_user",
        },
        //{
        //  text: this.$t("resources.cases.fields.contact_department"),
        //  value: "client_department",
        //},
        {
          text: this.$t("resources.cases.fields.delete_category"),
          sortable: false,
          value: "delete_category",
        },
      ];
    },

    /**
     * @vuese
     * ログインユーザ情報を取得
     */
    ...mapGetters(["currentUser"]),
    /**
     * @vuese
     * 新規作成ボタンの表示非表示設定
     */
    isShowCreateButton() {
      return this.hasPerm("clientStaff");
    },
  },
  // インスタンスの初期化後処理
  async created() {
    //条件としてログインユーザIDをセット
    if (this.hasPerm("clientStaff")) {
      this.filters.client_user = this.currentUser.id; //to return null in the v-model if logged in as the client staff
    }
  },
  methods: {
    showQuotationReport(id) {
      this.$router.push({
        name: `quotationsReport`,
        params: { id },
      });
    },
    showOrderReceiptReport(id) {
      this.$router.push({
        name: `orderReceiptsReport`,
        params: { id },
      });
    },
    showInvoiceReport(id) {
      this.$router.push({
        name: `deliveryNotesReport`,
        params: { id },
      });
    },
    showCertificateReport(id) {
      this.$router.push({
        name: `certificatesReport`,
        params: { id },
      });
    },
    deleteCategoryDisplay({ delete_category }) {
      if (["1", "2", "3"].includes(delete_category)) {
        return this.$t(
          `resources.cases.constants.delete_category.client.${delete_category}`
        );
      }
      return "";
    },
  },
};
</script>

<style>
.request-title-column {
  max-width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
