import ja from "vuetify/lib/locale/ja";

export default {
  $vuetify: ja,
  hello: {
    welcome: "ようこそ",
  },
  adcomTitle: "島津総合サービス　プロモーションセンター　注文管理システム",
  httpErrors: {
    304: "別ユーザから更新が行われた可能性があります。情報を最新化してから処理を行ってください。（エラーコード:304 未更新）",
    400: "予期しないエラーが発生しました。システム管理者へお問い合わせください。（エラーコード:400 リクエストが不正である）",
    403: "予期しないエラーが発生しました。システム管理者へお問い合わせください。（エラーコード:403 禁止されている）",
    404: "予期しないエラーが発生しました。システム管理者へお問い合わせください。（エラーコード:404 未検出）",
    405: "予期しないエラーが発生しました。システム管理者へお問い合わせください。（エラーコード:405 許可されていないメソッド）",
    408: "予期しないエラーが発生しました。システム管理者へお問い合わせください。（エラーコード:408 リクエストタイムアウト）",
    500: "予期しないエラーが発生しました。システム管理者へお問い合わせください。（エラーコード:500 サーバ内部エラー）",
    501: "予期しないエラーが発生しました。システム管理者へお問い合わせください。（エラーコード:501 実装されていない）",
    502: "予期しないエラーが発生しました。システム管理者へお問い合わせください。（エラーコード:502 不正なゲートウェイ）",
    503: "予期しないエラーが発生しました。システム管理者へお問い合わせください。（エラーコード:503 サービス利用不可）",
    504: "予期しないエラーが発生しました。システム管理者へお問い合わせください。（エラーコード:504 ゲートウェイタイムアウト）",
    505: "予期しないエラーが発生しました。システム管理者へお問い合わせください。（エラーコード:505 サポートしていないHTTPバージョン）",
    default: `HTTPエラー: {error}`,
    httpCancel: "リクエストがキャンセルされました。",
    httpTimeout: "リクエストがタイムアウトしました。",
    duplicatedRequest: "{url}へのリクエストが重複しています。",
  },
  messages: {
    validations: {
      required: "{attribute}を入力してください。",
      email: "正しいメールアドレスを入力してください。",
      requiredSelect: "{attribute}を選択してください。",
      unique: "{attribute}が重複しないように入力してください。",
      numeric: "{attribute}は数字で入力してください。",
      decimal: "{attribute}は数字で入力してください。",
      numLength: "{attribute}は{length}桁の数字で入力してください。",
      numMaxValue: "{attribute}は{value}以下の数字で入力してください。",
      restrictDate: "{attribute}に{rst}の日付を入力してください。",
      noLesserThanFromDate: "{attribute}が{name}以降の日付を入力してください。",
      minLength: "{attribute}は{min}桁以上で入力してください。",
      maxLength: "{attribute}は{max}桁以内で入力してください。",
      justLength: "{attribute}は{min}桁で入力してください。",
      maxValue: "{attribute}は{max}以下で入力してください。",
      mixValue: "{attribute}は{min}以上で入力してください。",
      sameAsPassword: "{attribute}にパスワードと同じ値を入力してください。",
      sameAsAmount: "{attribute}が金額と一致していません。",
      sameAsRate: "{attribute}は100%となるように入力してください。",
      sameAsTotalAmount: "見積金額と{attribute}が一致していません。",
      roleRequired: "権限は必ず選択してください。",
      betweenValue: "{attribute}には{min} - {max}で入力してください。",
      alreadyExist: "すでに登録済みの{attribute}のため申請できません。",
      differenceCheck: "依頼数は保管数よりも大きくなければなりません。",
      decimalPlaceValidation: "小数点以下は2桁まで入力可能です。",
      maxFileSize: "ファイルサイズは10 MB未満である必要があります",
    },
    actions: {
      confirmSignup: "ユーザー登録申請を実行しますか？",
      confirmDelete: "対象の明細を削除します。よろしいですか。",
      confirmCreateQuotation: "見積書を新たに作成します。よろしいですか。",
      confirmCreateDeliveryNote:
        "納品確認書を新たに作成します。よろしいですか。",
      confirmAddQuotation: "見積依頼を実行しますか？",
      confirmSendEmail: "WEB担当者にメールを送信します。よろしいですか？",
      confirmReQuotation:
        "見積書に対して再見積もりを依頼しますか？再見積もりを行うと最新の見積書は破棄されます。",
      confirmReQuotationAdcom:
        "再見積を実行すると、見積書を再作成する必要がありますが、よろしいですか？",
      confirmDrawal: "依頼を取り下げしますか？",
      confirmCaseDelete: "案件を削除します。よろしいですか？",
      confirmCaseDeleteCancel: "案件の削除を取消します。よろしいですか？",
      confirmDirectCompletion: "社内承認をせずに完了させますか？",
      confirmCompletCancel:
        "完了のステータスを取り消すことができます。続行しますか？",
    },
    success: {
      create: "登録が完了しました。",
      update: "更新が完了しました。",
      delete: "削除が完了しました。",
      signup: "ユーザー登録が完了しました。",
      passwordReset:
        "パスワードリセットが完了しました。新しいパスワードでログインしてください。",
      quotationReportCreated: "見積書を作成しました。",
      orderReceiptReportCreated: "注文請書を作成しました。",
      deliveryNoteReportCreated: "納品確認書を作成しました。",
      quotationRequested:
        "見積依頼が完了しました。見積書到着までしばらくお待ちください。",
      caseSavedAndEmailSend: "メールを送信しました。",
      caseCompletCancel: "完了のステータスを取り消しました。",
      workflows: {
        reject: "却下が完了しました。",
        applicate: "申請が完了しました。",
        approve: "承認が完了しました。",
        cancel: "申請キャンセルが完了しました。",
        applicate_client: "発注申請が完了しました。",
        approve_client: "発注承認が完了しました。",
      },
      caseComplete: "案件を完了しました。",
    },
    warnings: {
      sendMailError:
        "メール送信中にエラーが発生しました。システム管理者へ連絡してください。",
      CompletCancelError: "月次締日月日が過ぎています。",
      draft_file_link_error: "外部リンク先が存在しません。",
      frameAlert: "4月1日は、メンテナンスのためシステムを停止いたします。",
    },
    auth: {
      authCheckError: "認証に失敗しました。再度ログインしてください",
      loginError: "認証に失敗しました。PIDまたはパスワードが正しくありません。",
      signupError: "ユーザー登録が失敗しました。",
      userNotExistError: "ユーザーが存在しません。",
    },
  },
  auth: {
    login: "ログイン",
    logout: "ログアウト",
    signup: "ユーザー登録",
    passwordReset: {
      entry: "パスワードを忘れた方はこちら",
      title: "パスワードを忘れた方",
      helperText:
        "1. 以下にPIDを入力して送信してください。{0}2. 登録済みのメールアドレスへメールが送信されます。{0}3. メールの内容に従ってパスワードを再設定してください。",
      actions: {
        send: "送信",
        back: "戻る",
      },
    },
    passwordResetConfirm: {
      title: "パスワードのリセット",
      helperText: "新しいパスワードを入力して送信してください。",
    },
  },
  resources: {
    commons: {
      panels: {
        filters: "検索条件",
      },
      fields: {
        pid: "PID",
        username: "PID",
        name: "名称",
        code: "コード",
        email: "メールアドレス",
        password: "パスワード",
      },
      filters: {
        search: {
          label: "フリー記述",
          placeholder:
            "キーワードを入力してください。すべてのテキスト項目より検索を行います。",
        },
      },
    },
    users: {
      fields: {
        pid: "PID",
        last_name: "姓",
        first_name: "名",
        username: "ユーザー名",
        email: "メールアドレス",
        department: "所属部門",
        tel: "電話番号",
        password: "パスワード",
        verify: "パスワード確認",
        proxy_user: "代理担当者",
        adcom_group_email: "アドコム担当Gメールアドレス",
        adcom_group_name: "アドコム担当G名",
        role_normal: "一般権限",
        role_approver: "承認権限",
        workflow_user1: "デフォルト承認者1",
        workflow_user2: "デフォルト承認者2",
      },
    },
    cases: {
      name: "案件",
      list_name: "案件一覧",
      quotation_list_name: "見積一覧",
      create_name: "案件詳細",
      create_order_name: "稟議案件",
      client_Info: "顧客情報",
      order_Info: "依頼情報",
      invoice_Info: "費用処理方法",
      search_Title: "検索条件",
      operation_detail_name: "依頼先情報",
      obic_detail_name: "OBIC情報",
      obic_cost_burden_department_detail_name: "費用負担部門(OBIC)",
      fields: {
        preferred_due_date: "希望納期",
        order_date: "発注日",
        delivery_destination: "納品先",
        delivery_destination_manual: "直送先入力の場合",
        storage_number: "依頼課保管部数",
        catalog_storage_quantity: "カタログ倉庫部数",
        request_title: "依頼タイトル",
        scheduled_due_date: "確定納期",
        total_purchase_amount: "仕入合計(OBIC)",
        total_selling_price: "売価合計(OBIC)",
        client_user: "依頼者氏名",
        client_department: "依頼所属部門",
        client_tel: "電話番号",
        client_mail: "メールアドレス",
        contact_user: "SGS担当者",
        web_contact_user: "WEB担当者",
        contact_department: "島津総合サービス宣伝部担当グループ",
        total_cost: "原価合計",
        total_cost_obic: "原価合計(OBIC)",
        quotation_amount: "見積額",
        budget_amount: "予算額",
        cost_ratio: "原価率",
        draft_file: "原稿ファイル",
        draft_file_link: "原稿ファイルリンク",
        product_file: "納品ファイル",
        delivery_file_link: "納品ファイルリンク",
        general: "一般",
        catalog: "カタログ（C番号管理）",
        medical_ad_data: "広告データ",
        medical_ad_data_header_message:
          "稟議書起案が無い、または稟議内で予算を見ていない案件に限る",
        hospital_panel_data: "院内パネル",
        special_printing_detail: "特殊印刷詳細",
        special_printing_detail_header: "特殊印刷 (オンデマンド印刷等)",
        media_name: "媒体名",
        is_ad_specs: "広告仕様書の有・無",
        ad_sepecs_file: "広告仕様書",
        plate_size: "版型・サイズ",
        plate_height: "天地",
        plate_width: "左右",
        ad_format: "原稿形態",
        color: "原稿色",
        other_color: "その他",
        ad_no: "広告No.",
        ad_name: "広告名",
        desired_panel_no: "希望院内パネル番号",
        panel_size: "パネルサイズ",
        panel_height: "H",
        panel_width: "W",
        hospital_name: "病院名",
        status: "ステータス",
        case_no: "案件番号",
        quotation_request_date: "見積依頼日",
        request_remarks: "備考",
        c_no: "C番号",
        c_revision_no: "C番号改定記号",
        old_c_revision_no: "旧版のC番号改定記号",
        edition: "新版/改訂版/再版 指示",
        size: "規格サイズ",
        page_count: "ページ数",
        delivery_form: "納品形態",
        language_class: "言語種別",
        part_number: "印刷部数",
        old_c_no: "旧版のC番号",
        old_processing: "旧版の処理",
        request_count: "依頼数（部数）",
        expense_account: "費目",
        billing_category_1: "振替処理",
        billing_category_2: "本社調達部からの発注書での処理",
        billing_category_3: "請求書発行処理",
        billing_user: "請求書送付先担当者",
        billing_department: "請求書送付先所属",
        billing_address: "請求書送付先住所",
        rate_total: "費用負担率計",
        approval_no: "稟議書番号",
        gross_margin: "粗利",
        gross_margin_ratio: "粗利率",
        delete_category: "削除区分",
        direct_delivery: "直送先",
      },
      filters: {
        client_user: "依頼者",
        contact_user: "SGS担当者",
        client_department: "依頼所属部門",
        quotation_detail_copy_search_matter: "見積明細複製元案件",
        status: "ステータス",
        cost_burden_department: "費用負担部門",
        preferred_due_date_after: "希望納期from",
        preferred_due_date_before: "希望納期to",
        order_date_after: "発注日From",
        order_date_before: "発注日To",
        scheduled_due_date_after: "確定納期From",
        scheduled_due_date_before: "確定納期To",
        quotation_amount_min: "見積額From",
        quotation_amount_max: "見積額To",
        show_delete: "削除案件を含む",
        report_name: "帳票",
      },
      actions: {
        create: "新規案件作成",
        adcom_create: "代理案件作成",
        order_create: "稟議案件作成",
        reversalAndCorrecting_create: "赤黒案件作成",
        quotationReportCreate: "見積書作成",
        deliveryReportCreate: "納品確認書作成",
        orderReceiptReportCreate: "注文請書作成",
        withdrawal: "取下げ",
        reapplication: "再見積",
        caseDelete: "案件削除",
        caseDeleteCancel: "案件削除取消",
        completeCancel: "完了取消",
        invoicingsWorkflow: "請求書発行承認",
        returnPurchaseOrdersWorkflow: "調達部伝票処理発行許可",
        redBlackCompletionsWorkflow: "社内承認",
        recompletionsWorkflow: "社内承認",
        directCompletion: "社内承認不要",
        workflowLog: "承認履歴",
        obicAlignment: "OBIC連携",
        quotationReport: "見積書",
        orderReceiptReport: "注文請書",
        deliveryReport: "納品確認書",
        certificatesReport: "検収確認書",
      },
      constants: {
        delete_category: {
          adcom: {
            1: "失注",
            2: "発注後失注",
            3: "削除",
          },
          client: {
            1: "発注前キャンセル",
            2: "発注後キャンセル",
            3: "削除",
          },
        },
      },
      messages: {
        sumRate:
          "受注入力負担先の 入力負担率 が不正です。OBICの受注入力負担先を確認してください。",
        sameDepartments:
          "受注入力負担先の 負担先得意先 が不正です。OBICの受注入力負担先を確認してください。",
        existsProvisionalPrice:
          "仮単価にチェックが入っています。OBICの受注明細を確認してください。",
        request_count: "値 0 を入力できます。",
        draft_file:
          "登録できるデータは1種となりますので複数データを登録する場合はフォルダを圧縮し登録ください。また、データサイズが10Mを超える場合は、Simpostや外部サービスを活用し、備考欄にURLをご記載ください。",
        input_request:
          "お手数をお掛けいたしますが、見積依頼の際には依頼内容を一旦保存していただく必要がございます。また、費用振替処理時の費用負担割合の入力につきましては、保存後でしか入力できませんのでご注意ください。",
        old_c_no:
          "*旧版のC番号に関しては改訂記号を含めた入力をお願いいたします。例C501-0022A",
        preferredDueDateOver: "希望納期が過ぎています。",
        costRatio90Over: "原価率が90%を超えています。",
        NotDetailCompleted:
          "受発注行、発注行のステータスが“完了”になっていません",
        NotRateAndCodeEqual:
          "【費用負担部門エラー】案件とOBICの部門コード、率で差異があります。",
        NotRateAndCodeEqualConfirm:
          "案件とOBICの部門コード、率で差異があります。保存してよろしいですか。",
        saveFileUploadAndLink:
          "原稿情報を保存してもよろしいですか。原稿情報以外は保存されません。",
      },
      buttonOpenClose: {
        showFileButton: "複数ファイルのアップロードはこちら",
        hideFileButton: "ファイルのアップロードを隠す",
      },
      webContactSendEmailButton: "送信",
      approvalLog: "承認履歴",
    },
    caseDetails: {
      name: "依頼情報明細",
      fields: {
        request_content: "依頼項目名",
        production: "制作部",
        vendor: "外部ベンダー",
        productionOverview: "制作部概要",
        vendorQuotes: "外部ベンダー見積",
        competitiveQuotesFirst: "競合見積1",
        competitiveQuotesSecond: "競合見積2",
        cost: "原価",
        selling_price: "売価",
        client_category: "依頼先区分",
        inhouseSellingPrice: "内製売価",
        inhouseManufactureManCost: "内製工数原価",
        budget: "予算",
        operation: "運用G",
        creative: "クリエイティブG",
        Other: "その他",
        remarks: "備考",
        trade_date: "取引日",
        trade_date_title: "取引日タイトル",
        report_category: "帳票区分",
      },
    },
    quotations: {
      name: "見積書",
      header_title: "見積情報",
      approvalFlow: "承認フロー",
      approvalLog: "承認履歴",
      fields: {
        request_title: "件名",
        client_user: "ご担当者",
        quotation_amount: "金額",
        expiration_date: "見積有効期限",
        delivery_destination: "納品先",
        request_remarks: "備考",
        preferred_due_date: "希望納期",
        quotation_no: "見積書番号",
        quotation_date: "見積日",
        case_no: "案件番号",
        expense_account: "費目",
        request_count: "依頼数(部数)",
        client_user_tel: "電話番号",
        remarks: "備考",
        sub_total: "小計",
        c_no: "C番号",
        c_revision_no: "C番号改定記号",
        old_c_revision_no: "旧版のC番号改定記号",
        edition: "新版/改訂版/再版 指示",
        size: "規格サイズ",
        page_count: "ページ数",
        delivery_form: "納品形態",
        language_class: "言語種別",
        part_number: "印刷部数",
        old_c_no: "旧版のC番号",
        old_processing: "旧版の処理",
      },
      actions: {
        save: "保存",
        create: "見積書作成",
        print: "見積書出力",
        printPurchaseReport: "注文書出力",
        reapplication: "再見積",
        application: "申請",
        approval: "承認",
        rejection: "却下",
      },
      messages: {
        subTotalAsAmount:
          "小計が金額と一致していない場合、見積書の申請はできません。",
        quotation_detail_copy_search_matter:
          "選択した案件の見積書より明細をコピーしてよろしいですか。現在の明細情報は破棄されます",
        external_vendor_quotation_presence:
          "外部ベンダーにファイルがアッロードされていない明細があります。",
        reject_flag_true:
          "この見積書は有効な見積書ではありません。最新の見積書をご確認ください。",
      },
    },
    orderReceipts: {
      name: "注文請書",
      header_title: "注文情報",
      approvalFlow: "承認フロー",
      approvalLog: "承認履歴",
      fields: {
        request_title: "件名",
        client_user: "ご担当者",
        quotation_amount: "金額",
        budget_amount: "予算金額",
        expiration_date: "見積有効期限",
        delivery_destination: "納品先",
        request_remarks: "備考",
        preferred_due_date: "希望納期",
        order_receipt_no: "注文請書番号",
        quotation_date: "見積日",
        case_no: "案件番号",
        approval_no: "稟議書番号",
        expense_account: "費目",
        request_count: "依頼数(部数)",
        client_user_tel: "電話番号",
        remarks: "備考",
        sub_total: "小計",
        c_no: "C番号",
        c_revision_no: "C番号改定記号",
        old_c_revision_no: "旧版のC番号改定記号",
        edition: "新版/改訂版/再版 指示",
        size: "規格サイズ",
        page_count: "ページ数",
        delivery_form: "納品形態",
        language_class: "言語種別",
        old_c_no: "旧版のC番号",
        old_processing: "旧版の処理",
      },
      actions: {
        save: "保存",
        create: "見積書作成",
        print: "注文請書出力",
        rejection: "却下",
        withdrawal: "取下げ",
        reapplication: "再見積",
        application: "申請",
        approval: "承認",
      },
    },
    quotationDetails: {
      name: "見積明細",
      fields: {
        detail_no: "No",
        item: "項目",
        content: "内容",
        quantity: "数量",
        unit: "単位",
        unit_cost: "単価",
        amount: "金額",
      },
    },
    deliveryNotes: {
      name: "納品確認書",
      header_title: "納品情報",
      delivery_note_name: "納品確認書",
      certificate_name: "検収確認書",
      transferDepartment_name: "費用負担",
      approvalFlow: "承認フロー",
      approvalLog: "承認履歴",
      fields: {
        request_title: "件名",
        client_user: "ご担当者",
        quotation_amount: "金額",
        expiration_date: "見積有効期限",
        delivery_destination: "納品先",
        request_remarks: "備考",
        preferred_due_date: "希望納期",
        delivery_note_no: "納品確認書No",
        quotation_date: "見積日",
        case_no: "案件番号",
        expense_account: "費目",
        request_count: "依頼数",
        client_user_tel: "電話番号",
        remarks: "備考",
        sub_total: "小計",
        c_no: "C番号",
        c_revision_no: "C番号改定記号",
        old_c_revision_no: "旧版のC番号改定記号",
        edition: "新版/改訂版/再版 指示",
        size: "規格サイズ",
        page_count: "ページ数",
        delivery_form: "納品形態",
        language_class: "言語種別",
        old_c_no: "旧版のC番号",
        old_processing: "旧版の処理",
        issue_date: "発行日",
        department_code: "所属部門コード",
        scheduled_due_date: "確定納期",
        sell_price: "金額",
      },
      actions: {
        save: "保存",
        certificate_create: "検収確認書作成",
        print: "納品確認書出力",
        rejection: "却下",
        application: "申請",
        approval: "承認",
      },
    },
    certificates: {
      name: "検収確認書",
      header_title: "検収情報",
      approvalFlow: "承認フロー",
      approvalLog: "承認履歴",
      fields: {
        request_title: "件名",
        client_user: "ご担当者",
        quotation_amount: "金額",
        expiration_date: "見積有効期限",
        delivery_destination: "納品先",
        request_remarks: "備考",
        preferred_due_date: "希望納期",
        certificate_no: "検収確認書No",
        quotation_date: "見積日",
        case_no: "案件番号",
        expense_account: "費目",
        request_count: "依頼数",
        client_user_tel: "電話番号",
        remarks: "備考",
        sub_total: "小計",
        c_no: "C番号",
        c_revision_no: "C番号改定記号",
        old_c_revision_no: "旧版のC番号改定記号",
        edition: "新版/改訂版/再版 指示",
        size: "規格サイズ",
        page_count: "ページ数",
        delivery_form: "納品形態",
        language_class: "言語種別",
        old_c_no: "旧版のC番号",
        old_processing: "旧版の処理",
        issue_date: "発行日",
        department_code: "所属部門コード",
        scheduled_due_date: "確定納期",
        sell_price: "金額",
      },
      actions: {
        save: "保存",
        print: "検収確認書出力",
        rejection: "却下",
        application: "申請",
        approval: "承認",
      },
    },
    orderDetails: {
      name: "受注明細",
      fields: {
        project_no: "プロジェクト番号",
        order_no: "受注番号",
        project_name: "項目名",
        supplier_name: "仕入先名",
        buy_quantity: "仕入数量",
        buy_unit_cost: "仕入単価",
        buy_amount: "仕入総計",
        sell_quantity: "売上数量",
        sell_unit_cost: "売上単価",
        sell_amount: "売上総計",
        orderIssueDate: "発注書発行日",
        completionFlag: "到着確認フラグ",
        invoiceDocumentNumber: "外部ベンダー請求書文書番号",
        deliverable: "納品物ファイル",
        finish_flag: "完了フラグ",
        invalid_flag: "無効",
        finish_date: "完了日",
        file_upload: "ファイルアップロード",
        insourcing_selling_price: "内製売価",
        insourcing_cost: "内製工数原価(見込)",
        actual_insourcing_cost: "内製工数原価(実績)",
        trade_date: "取引日",
        trade_date_title: "取引日タイトル",
        report_category: "帳票区分",
      },
      messages: {
        finish_flag:
          "請求書到着もしくは制作が完了した時にチェックを入れてください。",
        checkObicSupplierNameAndFileUpload:
          "完了フラグをクリックできません。ファイルがアップロードされていないか、Obicサプライヤー名が正当でない可能性があります。",
      },
    },
    status: {
      name: "ステータス",
    },
    costBurdenDepartments: {
      name: "費用負担部門",
      fields: {
        department: "所属部門",
        rate: "率",
      },
    },
    noName: {
      name: " ",
    },
    workflows: {
      name: "承認フロー",
      titles: {
        clientReject: "申請者却下",
      },
      actions: {
        reject: "却下",
        applicate: "申請",
        approve: "承認",
        applicate_client: "発注申請",
        approve_client: "発注承認",
        cancel: "申請キャンセル",
      },
      forms: {
        applicant: "申請者",
        approver1: "承認者1",
        approver2: "承認者2",
        action_comment: "コメント",
      },
      messages: {
        confirm: {
          reject: "却下しますか？",
          applicate: "申請しますか？",
          approve: "承認しますか？",
          applicate_client: "発注申請しますか？",
          approve_client: "発注承認しますか？",
        },
        success: {
          reject: "却下が完了しました。",
          applicate: "申請が完了しました。",
          approve: "承認が完了しました。",
          applicate_client: "発注申請が完了しました。",
          approve_client: "発注承認が完了しました。",
          cancel: "取消しました。",
        },
      },
      statuses: {
        unapproved: "未承認",
        applicate: "申請",
        approve: "承認",
        reject: "却下",
        suspension: "中止",
      },
    },
    bulkApprove: {
      name: "一括承認",
      actions: {
        bulkApprove: "一括承認",
      },
      fields: {
        reportType: "帳票種別",
        reportNo: "帳票番号",
      },
      messages: {
        confirm: "選択された帳票を一括承認します。{0}よろしいですか。",
        success: "完了しました。",
      },
    },
  },
  actions: {
    yes: "はい",
    no: "いいえ",
    confirm: "確認",
    search: "検索",
    clear: "クリア",
    export: "エクスポート",
    insert: "新規登録",
    update: "更新",
    add: "追加",
    delete: "削除",
    cancel: "キャンセル",
    save: "保存",
    close: "閉じる",
    reference: "参照",
    warning: "警告",
  },
  add: "追加",
  delete: "削除",
  department: "部門",
  departmentCode: "部門コード",
  departmentName: "部門名",
  rate: "率",
  remarks: "備考",
  cancel: "キャンセル",
  search: "検索",
  save: "保存",
  saveFileUploadAndLink: "原稿情報保存",
  request: "見積依頼",
  draft: "下書き",
  rejection: "却下",
  approval: "承認",
  addRow: "行追加",
  Clear: "クリア",
  export: "エクスポート",
  allApproval: "一括承認",
  newQuote: "新規案件作成",
  newOrder: "稟議案件作成",
  deliveryPpdfCreate: "納品確認書出力",
  orderReceiptPpdfCreate: "注文請書出力",
  certPdfCreate: "検収確認書出力",
  certReportCreate: "検収確認書作成",
  quotationPpdfCreate: "見積書出力",
  moveTop: "TOPへ戻る",
  moveCase: "案件へ戻る",
  routes: {
    quotationList: "案件一覧",
    caseList: "案件一覧",
    certificatesReport: "検収確認書",
    deliveryNotesReport: "納品確認書",
    orderReceiptsReport: "注文請書",
    casesCreate: "案件作成",
    casesEdit: "案件編集",
    quotationsReport: "見積書",
    quotationRequestCreate: "見積依頼先登録",
    quotationRequestEdit: "見積依頼先更新",
    orderDetail: "案件明細",
    workFlowList: "発注一覧",
    ordersMultipleApprovalList: "受領済見積書一括承認",
    acceptanceInspectionMultipleApprovalList: "検収確認一括承認",
    deliveryNotesConfirmationApprovalList: "納品確認書一括承認",
    completeList: "完了可能案件一覧",
    history: "承認履歴一覧",
    specificRoute: "特別承認",
    bulkApprove: "一括承認",
    specificApprovalRoutesWorkflow: "社内承認フロー",
    invoicingsWorkflow: "請求書発行承認",
    returnPurchaseOrdersWorkflow: "調達部伝票処理発行許可",
    redBlackCompletionsWorkflow: "社内承認",
    recompletionsWorkflow: "社内承認",
    completedCases: "納品可能一覧",
  },
};
