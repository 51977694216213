<template>
  <v-app id="passwordResetConfirm">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <form-wrapper :validator="$v.passwordResetConfirmForm">
            <v-form lazy-validation @submit.prevent="send">
              <v-card>
                <v-card-title>
                  {{ $t("auth.passwordResetConfirm.title") }}
                </v-card-title>
                <v-card-text>
                  <p class="text--primary mb-5">
                    {{ $t("auth.passwordResetConfirm.helperText") }}
                  </p>
                  <form-group
                    :validator="$v.passwordResetConfirmForm.new_password1"
                    attribute="resources.users.fields.password"
                  >
                    <template v-slot="{ attrs }">
                      <v-text-field
                        v-bind="attrs"
                        id="new_password1"
                        prepend-icon="mdi-lock"
                        :label="$t('resources.users.fields.password')"
                        v-model="passwordResetConfirmForm.new_password1"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        counter
                        @click:append="showPassword = !showPassword"
                        outlined
                        dense
                      ></v-text-field>
                    </template>
                  </form-group>
                  <form-group
                    :validator="$v.passwordResetConfirmForm.new_password2"
                    attribute="resources.users.fields.verify"
                  >
                    <template v-slot="{ attrs }">
                      <v-text-field
                        v-bind="attrs"
                        id="new_password2"
                        prepend-icon="mdi-lock-check"
                        :label="$t('resources.users.fields.verify')"
                        v-model="passwordResetConfirmForm.new_password2"
                        :type="showPassword ? 'text' : 'password'"
                        counter
                        outlined
                        dense
                      ></v-text-field>
                    </template>
                  </form-group>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" type="sumbit" :disabled="loading">
                    送信
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </form-wrapper>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import FormGroup from "../particals/common/FormGroup.vue";

/**
 * @group 認証
 * パスワードリセット（パスワード変更）画面<br>
 * 機能仕様書：3.4
 */
export default {
  components: { FormGroup },
  name: "passwordResetConfirm",
  props: {
    uid: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  mixins: [validationMixin],
  validations: {
    passwordResetConfirmForm: {
      new_password1: { required, minLength: minLength(6) },
      new_password2: { required, sameAsPassword: sameAs("new_password1") },
    },
  },
  data() {
    return {
      loading: false,
      passwordResetConfirmForm: {
        new_password1: "",
        new_password2: "",
      },
      showPassword: false,
    };
  },
  methods: {
    /**
     * @vuese
     * パスワード情報送信
     */
    async send() {
      this.$v.passwordResetConfirmForm.$touch();
      if (!this.$v.passwordResetConfirmForm.$invalid) {
        try {
          this.loading = true;
          const payload = {
            ...this.passwordResetConfirmForm,
            uid: this.uid,
            token: this.token,
          };
          await this.$store.dispatch("passwordResetConfirm", payload);
          this.$router.push({ name: "login" });
          this.$message.success(this.$t("messages.success.passwordReset"));
        } catch (err) {
          if (err?.response) {
            const { status } = err.response;
            this.$message.error(this.$utils.translateHttpCode(status));
            this.passwordResetConfirmForm.new_password1 = "";
            this.passwordResetConfirmForm.new_password2 = "";
          }
        } finally {
          this.$v.passwordResetConfirmForm.$reset();
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style></style>
