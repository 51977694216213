<template>
  <v-list-item :to="{ name: link, params }">
    <v-list-item-icon>
      <v-tooltip right :disabled="tooltipDisabled">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-on="on" v-bind="attrs">{{ icon }}</v-icon>
        </template>
        <span>{{ title }}</span>
      </v-tooltip>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ title }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
    tooltipDisabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style></style>
