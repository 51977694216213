<template>
  <v-app v-cloak>
    <!-- <v-navigation-drawer app v-model="showMenu"> -->
    <AppMenu :showMenu="showMenu"></AppMenu>

    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click="showMenu = !showMenu"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title>{{ $t("adcomTitle") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title>{{ fullName }}</v-toolbar-title>
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="logout">
            <v-list-item-title>{{ $t("auth.logout") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <Confirm ref="confirm"></Confirm>
  </v-app>
</template>

<script>
import Confirm from "../particals/Confirm.vue";
import AppMenu from "../particals/AppMenu";
import { mapGetters } from "vuex";
export default {
  name: "AppFrame",
  data() {
    return {
      showMenu: true,
    };
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open;
  },
  methods: {
    async logout() {
      try {
        await this.$store.dispatch("logout");
      } finally {
        this.$router.push({ name: "login" });
      }
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    title() {
      return this.$t(`routes.${this.$route.name}`);
    },
    fullName() {
      return this.currentUser?.full_name || "";
    },
  },
  components: { AppMenu, Confirm },
};
</script>
