<template>
  <v-container class="lighten-5" id="main_container">
    <v-form ref="inputForm" lazy-validation>
      <v-row v-if="propsStandardItem" class="d-flex justify-center mt-1">
        <v-col cols="12" lg="6" md="4">
          <v-autocomplete
            v-model="refId"
            :loading="loading"
            :search-input.sync="keyword"
            :items="items"
            :item-text="getCaseDisplay"
            no-filter
            hide-no-data
            hide-selected
            :label="
              $t('resources.cases.filters.quotation_detail_copy_search_matter')
            "
            dense
            return-object
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-card class="mx-auto mt-5" width="60rem">
        <v-system-bar color="indigo darken-2" dark class="pa-5">
          {{ $t("resources.quotations.header_title") }}
        </v-system-bar>
        <v-container fluid class="pa-5">
          <v-card class="d-flex justify-center text-h4 mb-6" flat tile>
            {{ $t("resources.quotations.name") }}
          </v-card>

          <v-row class="mx-10">
            <v-card class="d-flex flex-column mr-auto" flat tile>
              <v-card class="text-h5" flat tile>
                {{ $get("quotationData.case.client_department.name", "") }}
                御中
              </v-card>
              <v-card class="pt-2" flat tile>
                {{ $get("quotationData.case.client_user.full_name", "") }}
                様
              </v-card>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.quotations.fields.request_title") }}：
                {{ $get("quotationData.case.request_title", "") }}
              </v-card>
              <v-card class="pt-4 text-h5" flat tile>
                {{ $t("resources.quotations.fields.quotation_amount") }}：
                {{ quotationPriceFormatted }} 円（税別）
              </v-card>
              <v-card class="pt-4" flat tile>
                {{ $t("resources.quotations.fields.expiration_date") }}： 30日間
              </v-card>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.quotations.fields.delivery_destination") }}：
                {{ deliveryDestinationValue }}
              </v-card>
              <v-card class="pt-2" flat tile v-if="isStorageNumberInput">
                {{ $t("resources.cases.fields.storage_number") }}：
                {{ storageNumberFormatted }}
              </v-card>
              <v-card class="pt-2" flat tile v-if="isStorageNumberInput">
                {{ $t("resources.cases.fields.catalog_storage_quantity") }}：
                {{ catalogStorageQuantityFormatted }}
              </v-card>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.quotations.fields.preferred_due_date") }}：
                {{ $get("quotationData.case.preferred_due_date", "") }}
              </v-card>
            </v-card>
            <v-card class="d-flex flex-column ml-auto" flat tile>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.quotations.fields.quotation_no") }}：
                {{ quotationNoFormatted }}
              </v-card>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.quotations.fields.case_no") }}：
                {{ $get("quotationData.case.case_no", "") }}
              </v-card>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.quotations.fields.quotation_date") }}：
                {{ quotationDateFormatted }}
              </v-card>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.quotations.fields.expense_account") }}：
                {{ $get("quotationData.case.expense_account.all_name", "") }}
              </v-card>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.quotations.fields.request_count") }}：
                {{ requestCountFormatted }}
              </v-card>
            </v-card>
          </v-row>

          <v-row v-if="isShowRequestCategory" class="mx-10">
            <v-card class="d-flex flex-column mr-auto" flat tile>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.quotations.fields.c_no") }}：
                {{ $get("quotationData.case.c_no", "") }}
              </v-card>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.quotations.fields.size") }}：
                {{ $get("quotationData.case.size", "") }}
              </v-card>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.quotations.fields.language_class") }}：
                {{ $get("quotationData.case.language_class.name", "") }}
              </v-card>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.quotations.fields.old_c_revision_no") }}：
                {{ $get("quotationData.case.old_c_revision_no", "") }}
              </v-card>
            </v-card>

            <v-card class="d-flex flex-column text-xs-center" flat tile>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.quotations.fields.c_revision_no") }}：
                {{ $get("quotationData.case.c_revision_no", "") }}
              </v-card>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.quotations.fields.page_count") }}：
                {{ $get("quotationData.case.page_count", "") }}
              </v-card>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.quotations.fields.old_c_no") }}：
                {{ $get("quotationData.case.old_c_no", "") }}
              </v-card>
            </v-card>

            <v-card class="d-flex flex-column ml-auto" flat tile>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.quotations.fields.edition") }}：
                {{ $get("quotationData.case.edition.name", "") }}
              </v-card>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.quotations.fields.delivery_form") }}：
                {{ $get("quotationData.case.delivery_form.name", "") }}
              </v-card>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.quotations.fields.old_processing") }}：
                {{ $get("quotationData.case.old_processing.name", "") }}
              </v-card>
            </v-card>
          </v-row>
          <v-row v-if="isShowRequestCategoryAdData" class="mx-10">
            <v-card class="d-flex flex-column mr-auto" flat tile>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.cases.fields.media_name") }}：
                {{ $get("quotationData.case.media_name", "") }}
              </v-card>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.cases.fields.ad_name") }}：
                {{ $get("quotationData.case.ad_name", "") }}
              </v-card>
            </v-card>
            <v-card class="d-flex flex-column mr-auto" flat tile>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.cases.fields.plate_size") }}：
                {{ $get("quotationData.case.plate_size.name", "") }}
              </v-card>
              <v-card
                class="pt-2"
                flat
                tile
                v-if="
                  quotationData.case.plate_height || quotationData.plate_height
                "
              >
                {{ $t("resources.cases.fields.plate_height") }}：
                {{ plateHeightFormatted }}
              </v-card>
              <v-card
                class="pt-2"
                flat
                tile
                v-if="
                  quotationData.case.plate_width || quotationData.plate_width
                "
              >
                {{ $t("resources.cases.fields.plate_width") }}：
                {{ plateWidthFormatted }}
              </v-card>
            </v-card>
            <v-card class="d-flex flex-column ml-auto" flat tile>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.cases.fields.ad_format") }}：
                {{ $get("quotationData.case.ad_format.name", "") }}
              </v-card>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.cases.fields.color") }}：
                {{ $get("quotationData.case.color.name", "") }}
              </v-card>
              <v-card
                class="pt-2"
                flat
                tile
                v-if="
                  quotationData.case.other_color || quotationData.other_color
                "
              >
                {{ $t("resources.cases.fields.other_color") }}：
                {{ $get("quotationData.case.other_color", "") }}
              </v-card>
            </v-card>
          </v-row>
          <v-row v-if="isShowRequestCategoryPanelData" class="mx-10">
            <v-card class="d-flex flex-column mr-auto" flat tile>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.cases.fields.desired_panel_no") }}：
                {{ $get("quotationData.case.desired_panel_no", "") }}
              </v-card>
            </v-card>
            <v-card class="d-flex flex-column mr-auto" flat tile>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.cases.fields.hospital_name") }}：
                {{ $get("quotationData.case.hospital_name", "") }}
              </v-card>
            </v-card>
            <v-card class="d-flex flex-column mr-auto" flat tile>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.cases.fields.panel_size") }}：
                {{ $get("quotationData.case.panel_size.name", "") }}
              </v-card>
              <v-card
                class="pt-2"
                flat
                tile
                v-if="
                  quotationData.case.panel_height || quotationData.panel_height
                "
              >
                {{ $t("resources.cases.fields.panel_height") }}：
                {{ panelHeightFormatted }}
              </v-card>
              <v-card
                class="pt-2"
                flat
                tile
                v-if="
                  quotationData.case.panel_width || quotationData.panel_width
                "
              >
                {{ $t("resources.cases.fields.panel_width") }}：
                {{ panelWidthFormatted }}
              </v-card>
            </v-card>
          </v-row>
          <v-row
            v-if="isShowRequestCategorySpecialPrintingDetails"
            class="mx-10"
          >
            <v-card class="mr-auto" flat tile>
              <v-card class="pt-2" flat tile>
                {{ $t("resources.cases.fields.special_printing_detail") }}：
              </v-card>
            </v-card>
            <v-textarea
              v-model="formData.special_printing_detail"
              class="pt-0"
              :readonly="true"
              :rounded="true"
            >
            </v-textarea>
          </v-row>

          <v-row justify="start">
            <v-col cols="12">
              <DataTable
                resource="quotationDetails"
                parentRelationName="quotation"
                :parentId="this.id"
                :defaultItem="defaultDetailItem"
                :headers="quotationDetailHeaders"
                :showAddButton="propsStandardItem"
                :showEditButton="propsStandardItem"
                :showDeleteButton="propsStandardItem"
                :itemValidations="quotationDetailValidations"
                v-on:create="quotationAmount"
                v-on:update="quotationAmount"
                v-on:delete="quotationAmount"
                ref="detailTable"
              >
                <template v-slot:[`item.amount`]="{ item }">
                  {{ toLocaleString(item.amount) }}
                </template>
                <template v-slot:[`item.unit_cost`]="{ item }">
                  {{ toLocaleString(item.unit_cost) }}
                </template>
                <template v-slot:[`item.quantity`]="{ item }">
                  {{ toLocaleString(item.quantity) }}
                </template>
                <template v-slot:dialogForm="{ item, validator }">
                  <!-- validaterの設定にeditedItemを指定するとエラーになる。 -->
                  <form-group
                    :validator="validator.editedItem.detail_no"
                    attribute="resources.quotationDetails.fields.detail_no"
                  >
                    <template v-slot="{ attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-model="item.detail_no"
                        v-model.number="item.detail_no"
                        maxlength="3"
                        :label="
                          $t('resources.quotationDetails.fields.detail_no')
                        "
                      ></v-text-field>
                    </template>
                  </form-group>
                  <form-group
                    :validator="validator.editedItem.item"
                    attribute="resources.quotationDetails.fields.item"
                  >
                    <template v-slot="{ attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-model="item.item"
                        maxlength="100"
                        :label="$t('resources.quotationDetails.fields.item')"
                      ></v-text-field>
                    </template>
                  </form-group>
                  <form-group
                    :validator="validator.editedItem.content"
                    attribute="resources.quotationDetails.fields.content"
                  >
                    <template v-slot="{ attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-model="item.content"
                        maxlength="100"
                        :label="$t('resources.quotationDetails.fields.content')"
                      ></v-text-field>
                    </template>
                  </form-group>
                  <v-row>
                    <v-col cols="12" lg="10">
                      <form-group
                        :validator="validator.editedItem.quantity"
                        attribute="resources.quotationDetails.fields.quantity"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="item.quantity"
                            v-model.number="item.quantity"
                            maxlength="6"
                            type="numeric"
                            :label="
                              $t('resources.quotationDetails.fields.quantity')
                            "
                            @change="calculationAmount(item)"
                          ></v-text-field>
                        </template>
                      </form-group>
                    </v-col>
                    <v-col cols="12" lg="2">
                      <form-group
                        :validator="validator.editedItem.unit"
                        attribute="resources.quotationDetails.fields.unit"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="item.unit"
                            maxlength="1"
                          ></v-text-field>
                        </template>
                      </form-group>
                    </v-col>
                  </v-row>
                  <form-group
                    :validator="validator.editedItem.unit_cost"
                    attribute="resources.quotationDetails.fields.unit_cost"
                  >
                    <template v-slot="{ attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-model="item.unit_cost"
                        :label="
                          $t('resources.quotationDetails.fields.unit_cost')
                        "
                        maxlength="11"
                        @change="calculationAmount(item)"
                      ></v-text-field>
                    </template>
                  </form-group>
                  <form-group
                    :validator="validator.editedItem.amount"
                    attribute="resources.quotationDetails.fields.amount"
                  >
                    <template v-slot="{ attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-model="item.amount"
                        maxlength="11"
                        :label="$t('resources.quotationDetails.fields.amount')"
                      ></v-text-field>
                    </template>
                  </form-group>
                </template>
              </DataTable>
            </v-col>
          </v-row>
          <v-card class="d-flex justify-end" flat tile>
            <v-card class="pt-2 mr-10" flat tile>
              <v-text-field
                :value="totalAmountFormatted"
                flat
                reverse
                prefix="円（税別）"
                :suffix="$t(`resources.quotations.fields.sub_total`)"
                readonly
              ></v-text-field>
            </v-card>
          </v-card>
          <v-textarea
            v-model="formData.remarks"
            class="mt-6"
            :label="$t('resources.quotations.fields.remarks')"
            :outlined="propsStandardItem"
            :readonly="!propsStandardItem"
            :rounded="!propsStandardItem"
          ></v-textarea>
        </v-container>
      </v-card>

      <v-card v-show="IsAlert" class="mx-auto mt-5" width="60rem">
        <v-alert dense border="left" type="error">
          {{ $t("resources.quotations.messages.subTotalAsAmount") }}
        </v-alert>
      </v-card>
      <!--承認フロー-->
      <WorkflowAction
        v-show="IsWorkflow"
        :id="displayWorkflowId"
        @updated="changeWorkflow"
      ></WorkflowAction>

      <WorkflowClientReject
        v-show="IsWorkflowReject"
        :report="quotationData"
        @updated="moveCase"
      ></WorkflowClientReject>

      <!--承認履歴-->
      <WorkflowHistory
        :refreshKey="workflowRefreshKey"
        :workflow_id="workflowId"
      ></WorkflowHistory>
      <!--Todo: will show the message if there is no presence of -->
      <v-card v-show="!external_vendor_quotation" class="mx-auto" width="60rem">
        <v-alert color="orange darken-2" dark dense>
          {{
            $t(
              "resources.quotations.messages.external_vendor_quotation_presence"
            )
          }}
        </v-alert>
      </v-card>
      <!-- Todo: show the message if the reject flag is true -->
      <v-card v-show="isRejectFlag" class="mx-auto" width="60rem">
        <v-alert dense border="left" type="error">
          {{ $t("resources.quotations.messages.reject_flag_true") }}
        </v-alert>
      </v-card>
      <!-- ToDO: blank card to maintain the margin top above bottom-navigation bar -->
      <v-card class="mx-auto mb-15" width="60rem"> </v-card>
      <v-bottom-navigation fixed horizontal scroll-target="#main_container">
        <v-btn @click="moveTop()" :disabled="loading" text>
          <span>{{ $t("moveTop") }}</span>
          <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn @click="moveCase()" :disabled="loading" text>
          <span>{{ $t("moveCase") }}</span>
          <v-icon>mdi-clipboard-text</v-icon>
        </v-btn>
        <v-btn v-if="isShowSave" @click="save()" :disabled="loading" text>
          <span>{{ $t("save") }}</span>
          <v-icon>mdi-content-save-outline</v-icon>
        </v-btn>
        <v-btn v-if="isShowPrint" @click="print()" :disabled="loading" text>
          <span>{{ $t("resources.quotations.actions.print") }}</span>
          <v-icon>mdi-file-download-outline</v-icon>
        </v-btn>
        <v-btn
          v-if="isShowPrintPurchase"
          @click="purchasePrint()"
          :disabled="loading"
          text
        >
          <span>{{
            $t("resources.quotations.actions.printPurchaseReport")
          }}</span>
          <v-icon>mdi-file-download-outline</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-form>
  </v-container>
</template>
<script>
import groupPermissionMixin from "../../mixins/groupPermissionMixin";
import updateMixin from "../../mixins/updateMixin";
import relationMixin from "../../mixins/relationMixin";
import fieldPermissionMixin from "../../mixins/fieldPermissionMixin";
import { validationMixin } from "vuelidate";
import headersMixin from "../../mixins/headersMixin";
import { numeric, decimal, between } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { statuses } from "../../../configs/statusConfig";
import { requestCategorys } from "../../../configs/requestCategoryConfig";
import { workfrowStatuses } from "../../../configs/workfrowStatusConfig";
import { saveAs } from "file-saver";
import { debounce } from "lodash";

/**
 * @vuese
 * @group 帳票
 * 見積書画面<br>
 * 機能仕様書：4.4, 4.5, 5.2, 5.3, 5.4, 5.5, 5.6, 5.7, 5.13, 5.18, 6.1, 6.2, 6.4
 */
export default {
  name: "Quotations.Report",
  resource: "quotations",

  mixins: [
    groupPermissionMixin,
    fieldPermissionMixin,
    updateMixin,
    validationMixin,
    headersMixin,
    relationMixin,
  ],
  data() {
    return {
      refId: null,
      keyword: "",
      loading: false,
      formData: {
        quotation_no: "",
        quotation_date: "",
        remarks: "",
        special_printing_detail: "",
      },
      totalAmount: null,
      quotationData: {},
      dialog: false,
      workflowRefreshKey: 0,
      items: [],
      /**
       * @vuese
       * ToDo: the value will be calculated from mounted function after checking the presence of file
       */
      external_vendor_quotation: true,
      /**
       * @vuese
       * ToDo: the data should be used inside the function where the "external_vendor_quotation" is assigned
       */
      external_vendor_quotation_flag: 0,
      /**
       * @vuese
       * 見積書のワークフローステータスの情報（すべて）
       */
      quotationWorkflowStatus: workfrowStatuses.NoWorkfrow,
      /**
       * @vuese
       * 見積書のワークフローステータスの情報（クライアント）
       */
      quotationWorkflowClientStatus: workfrowStatuses.NoWorkfrow,
      /**
       * @vuese
       * ToDo: Client Completed Final Workflow Approve
       */
      hasClientCompletedFinalApproval: false,
      /**
       * @vuese
       * 見積書のワークフローステータスの情報（アドコム）
       */
      quotationWorkflowAdcomStatus: workfrowStatuses.NoWorkfrow,
    };
  },
  watch: {
    keyword(val) {
      if (!val) {
        this.items = [];
        return;
      }
      if (this.refId && val === this.getCaseDisplay(this.refId)) {
        return;
      }
      this.getRefCases(val);
    },
    refId(val) {
      if (!val?.id) {
        return;
      }
      this.input(val.id);
    },
  },
  /**
   * @vuese
   * ToDO: will assign the value to external_vendor_quotation after checking the function
   */
  async mounted() {
    this.external_vendor_quotation =
      await this.checkExternalVendorQuotationFile();
  },
  methods: {
    async fetchData(id) {
      this.loading = true;
      try {
        const [quotationData] = await Promise.all([
          this.$api.getOne(this.$options.resource, id),
          this.quotationAmount(),
          this.getQuotationWorkflowStatus(),
          this.getQuotationWorkflowClientStatus(),
          this.getQuotationWorkflowAdcomStatus(),
          this.getFinalApproveClientInfo(),
        ]);
        this.quotationData = quotationData;
        this.$utils.update(this.formData, quotationData);
        // fetch case
        await this.join(this.quotationData, [
          { name: "case", resource: "cases" },
        ]);
        if (quotationData.quotation_no) {
          //case.idを更新前に退避
          const case_id = this.quotationData.case.id;
          // 見積書に保持している情報で表示するために、案件情報の内容を変更する
          this.$utils.update(this.quotationData.case, quotationData);
          //case.idを更新後に戻す
          this.quotationData.case.id = case_id;
        }
        // 特殊印刷詳細の値の割り当て
        this.formData.special_printing_detail =
          this.quotationData.case.special_printing_detail;

        await this.join(this.quotationData.case, [
          { name: "client_user", resource: "users" },
          { name: "client_department", resource: "departments" },
          { name: "delivery_destination", resource: "deliveryDestinations" },
          { name: "expense_account", resource: "expenseAccounts" },
          { name: "language_class", resource: "languageClasses" },
          { name: "delivery_form", resource: "deliveryForms" },
          { name: "old_processing", resource: "processings" },
          { name: "edition", resource: "editions" },
          { name: "contact_user", resource: "users" },
          { name: "color", resource: "colors" },
          { name: "ad_format", resource: "adFormats" },
          { name: "panel_size", resource: "panelSizes" },
          { name: "plate_size", resource: "plateSizes" },
        ]);
        // this.displayFormat();
      } finally {
        this.loading = false;
      }
    },
    getRefCases: debounce(async function (keyword) {
      this.loading = true;
      try {
        this.items = await this.$api.getMany("caseAutoComplete", {
          keyword,
        });
      } finally {
        this.loading = false;
      }
    }, 500),
    getCaseDisplay(item) {
      return `${item.request_title}, ${item.case_no}`;
    },
    async input(id) {
      if (!id) {
        return;
      }
      if (
        !(await this.$root.$confirm(
          "actions.confirm",
          "resources.quotations.messages.quotation_detail_copy_search_matter"
        ))
      ) {
        return;
      }
      let selectedCaseData = await this.$api.getOne("cases", id);
      let currentQuotationData = await this.$api.getOne("quotations", this.id);
      let currentCaseData = await this.$api.getOne(
        "cases",
        currentQuotationData.case
      );
      let currentQuotationDetail = await this.$api.getMany("quotationDetails", {
        ["quotation"]: currentCaseData.quotation_id,
      });

      let selectedQuotationDetail = await this.$api.getMany(
        "quotationDetails",
        {
          ["quotation"]: selectedCaseData.quotation_id,
        }
      );
      if (
        currentQuotationDetail.length !== 0 &&
        selectedQuotationDetail.length !== 0
      ) {
        // delete the present quotation detail information
        for (let i = 0; i < currentQuotationDetail.length; i++) {
          await this.$api.delete(
            "quotationDetails",
            currentQuotationDetail[i].id
          );
        }
      }
      let newQuotationDetails = [];
      if (selectedQuotationDetail.length !== 0) {
        for (let i = 0; i < selectedQuotationDetail.length; i++) {
          selectedQuotationDetail[i].id = null;
          selectedQuotationDetail[i].quotation = undefined;

          newQuotationDetails.push(selectedQuotationDetail[i]);
        }
      }

      if (newQuotationDetails.length !== 0) {
        // create the new quotation detail information
        for (let i = 0; i < newQuotationDetails.length; i++) {
          await this.$api.create("quotationDetails", {
            ...newQuotationDetails[i],
            ["quotation"]: this.id,
          });
        }
      }
      // update the total amount
      await this.quotationAmount();
      // reload the datatable component
      await this.$refs.detailTable.load(this.id);
    },
    async quotationAmount() {
      const { total_amount } = await this.$api.dispatch(
        "GET",
        this.$options.resource,
        "total_amount",
        this.id
      );
      this.totalAmount = total_amount;
    },
    /**
     * @vuese
     * 見積書のワークフロー状況取得メソッド
     */
    async getQuotationWorkflowStatus() {
      const { status } = await this.$api.dispatch(
        "GET",
        this.$options.resource,
        "get_workflow_status",
        this.id
      );
      this.quotationWorkflowStatus = status;
    },
    /**
     * @vuese
     * 見積書（クライアント）のワークフロー状況取得メソッド
     */
    async getFinalApproveClientInfo() {
      const get_client_workflow = await this.$api.dispatch(
        "GET",
        this.$options.resource,
        "get_client_workflow_approval_complete_info",
        this.id
      );

      this.hasClientCompletedFinalApproval =
        get_client_workflow?.is_client_final_approve_complete;
    },
    /**
     * @vuese
     * 見積書（クライアント）のワークフロー状況取得メソッド
     */
    async getQuotationWorkflowClientStatus() {
      const { status } = await this.$api.dispatch(
        "GET",
        this.$options.resource,
        "get_client_workflow_status",
        this.id
      );
      this.quotationWorkflowClientStatus = status;
    },
    /**
     * @vuese
     * 見積書（アドコム）のワークフロー状況取得メソッド
     */
    async getQuotationWorkflowAdcomStatus() {
      const { status } = await this.$api.dispatch(
        "GET",
        this.$options.resource,
        "get_adcom_workflow_status",
        this.id
      );
      this.quotationWorkflowAdcomStatus = status;
    },
    /**
     * @vuese
     * ワークフロー更新時の処理
     */
    async changeWorkflow(args) {
      this.workflowRefreshKey++;
      await Promise.all([
        this.getQuotationWorkflowStatus(),
        this.getQuotationWorkflowClientStatus(),
        this.getQuotationWorkflowAdcomStatus(),
        this.quotetionRelord(),
      ]);
      if (args.action === "applicate") {
        // 申請の場合は備考が変更されている可能性があるので、見積書情報を更新する
        const quotationData = await this.$api.getOne(
          this.$options.resource,
          this.id
        );
        const remarks = this.formData.remarks;
        quotationData.remarks = remarks;
        this.$utils.update(this.formData, quotationData);
        await this.save("messages.success.workflows.applicate");
      } else {
        // 申請以外の場合はメッセージのみ表示
        this.$message.success(
          this.$t("messages.success.workflows." + args.action)
        );
      }
      await this.fetchData(this.id);
    },
    /**
     * @vuese
     * PDF出力
     */
    async print() {
      const { data, filename } = await this.$api.getPDF(
        this.$options.resource,
        this.id
      );
      saveAs(data, filename);
    },
    /**
     * @vuese
     * PDF出力 はつちゅのため
     */
    async purchasePrint() {
      const { data, filename } = await this.$api.getPDF(
        this.$options.resource,
        this.id,
        { report: "purchase-order" }
      );
      saveAs(data, filename);
    },
    /**
     * @vuese
     * 金額計算
     */
    calculationAmount(item) {
      if (item.unit_cost && item.quantity) {
        if (!isNaN(item.unit_cost) && !isNaN(item.quantity)) {
          item.amount = item.unit_cost * item.quantity;
        }
      }
    },
    /**
     * @vuese
     * 検索一覧移動メソッド
     */
    async moveTop() {
      if (this.hasPerm("adcom")) {
        await this.$router.push({
          name: "caseList",
        });
      } else {
        await this.$router.push({
          name: "quotationList",
        });
      }
    },
    /**
     * @vuese
     * 案件詳細移動メソッド
     */
    async moveCase(args) {
      if (args) {
        this.$message.success(
          this.$t("messages.success.workflows." + args.action)
        );
      }
      await this.$router.push({
        name: "casesEdit",
        params: { id: this.quotationData.case.id },
      });
    },
    /**
     * @vuese
     * 見積書情報更新メソッド
     */
    async quotetionRelord() {
      const [quotationData] = await Promise.all([
        this.$api.getOne(this.$options.resource, this.id),
      ]);
      this.quotationData.quotation_no = quotationData.quotation_no;
      this.quotationData.quotation_no_seq = quotationData.quotation_no_seq;
      this.quotationData.quotation_date = quotationData.quotation_date;
    },
    toLocaleString(number) {
      return number?.toLocaleString() || "";
    },
    /**
     * @vuese
     * ToDO: to check if the external_vendor_quotation file is present for the current case_id
     */
    async checkExternalVendorQuotationFile() {
      const quotationData = await this.$api.getOne(
        this.$options.resource,
        this.id
      );
      let caseDetailData = await this.$api.getMany("caseDetails", {
        case: quotationData.case,
      });
      caseDetailData = await caseDetailData.filter(
        (data) => data.client_category === "2"
      );
      if (caseDetailData.length) {
        for (let i = 0; i < caseDetailData.length; i++) {
          if (
            caseDetailData[i].external_vendor_quotation_1 ||
            caseDetailData[i].external_vendor_quotation_2 ||
            caseDetailData[i].external_vendor_quotation_3
          ) {
            this.external_vendor_quotation_flag += 1;
          }
        }
        return caseDetailData.length === this.external_vendor_quotation_flag;
      }
      return true;
    },
  },
  computed: {
    /**
     * @vuese
     * ログインユーザ情報を取得
     */
    ...mapGetters(["currentUser"]),
    /**
     * @vuese
     * 明細の入力ダイアログ用Validation設定
     */
    quotationDetailValidations() {
      function validateDecimalWithMaxTwoDigits(value) {
        // Allow null or empty string values
        if (value === null || value === "") {
          return true;
        }
        // Check if the value is a valid decimal with up to three digits after the decimal point
        const regex = /^-?\d+(\.\d{1,2})?$/;
        return regex.test(value);
      }
      return {
        detail_no: { numeric, betweenValue: between(1, 999) },
        item: {},
        content: {},
        quantity: { numeric, betweenValue: between(1, 999999) },
        unit: {},
        unit_cost: {
          decimal,
          betweenValue: between(-2147483648, 2147483647),
          decimalPlaceValidation: validateDecimalWithMaxTwoDigits,
        },
        amount: {
          decimal,
          betweenValue: between(-2147483648, 2147483647),
          decimalPlaceValidation: validateDecimalWithMaxTwoDigits,
        },
      };
    },
    /**
     * @vuese
     * 見積金額をカンマ編集して取得
     */
    quotationPriceFormatted() {
      if (this.quotationData?.quotation_no) {
        return this.quotationData?.amount?.toLocaleString();
      } else {
        return this.quotationData?.case?.total_selling_price?.toLocaleString();
      }
    },
    /**
     * @vuese
     * 依頼数（部数)をカンマ編集して取得
     */
    requestCountFormatted() {
      if (this.quotationData?.quotation_no) {
        return this.quotationData?.request_count?.toLocaleString();
      } else {
        return this.quotationData?.case?.request_count?.toLocaleString();
      }
    },
    /**
     * @vuese
     * 保管部数をカンマ編集して取得
     */
    storageNumberFormatted() {
      if (this.quotationData?.storage_number) {
        return this.quotationData?.storage_number?.toLocaleString();
      } else {
        return this.quotationData?.case?.storage_number?.toLocaleString();
      }
    },
    /**
     * @vuese
     * ToDo: カタログ倉庫部数をカンマ編集して取得
     */
    catalogStorageQuantityFormatted() {
      if (this.quotationData?.storage_number) {
        return (
          this.quotationData?.request_count - this.quotationData?.storage_number
        )?.toLocaleString();
      } else {
        return (
          this.quotationData?.case?.request_count -
          this.quotationData?.case?.storage_number
        )?.toLocaleString();
      }
    },
    /**
     * @vuese
     * 天地をカンマ編集して取得
     */
    plateHeightFormatted() {
      if (this.quotationData?.plate_height) {
        return this.quotationData?.plate_height?.toLocaleString();
      } else {
        return this.quotationData?.case?.plate_height?.toLocaleString();
      }
    },
    /**
     * @vuese
     * 左右をカンマ編集して取得
     */
    plateWidthFormatted() {
      if (this.quotationData?.plate_width) {
        return this.quotationData?.plate_width?.toLocaleString();
      } else {
        return this.quotationData?.case?.plate_width?.toLocaleString();
      }
    },
    /**
     * @vuese
     * Hをカンマ編集して取得
     */
    panelHeightFormatted() {
      if (this.quotationData?.panel_height) {
        return this.quotationData?.panel_height?.toLocaleString();
      } else {
        return this.quotationData?.case?.panel_height?.toLocaleString();
      }
    },
    /**
     * @vuese
     * Wをカンマ編集して取得
     */
    panelWidthFormatted() {
      if (this.quotationData?.panel_width) {
        return this.quotationData?.panel_width?.toLocaleString();
      } else {
        return this.quotationData?.case?.panel_width?.toLocaleString();
      }
    },
    /**
     * @vuese
     * 見積NOの表示設定
     */
    quotationNoFormatted() {
      if (this.quotationData?.quotation_no) {
        return (
          this.quotationData?.quotation_no +
          "-" +
          ("0000" + this.quotationData?.quotation_no_seq).slice(-4)
        );
      } else {
        return "";
      }
    },
    /**
     * @vuese
     * 見積日の表示設定
     */
    quotationDateFormatted() {
      return this.quotationData?.quotation_date;
    },
    totalAmountFormatted() {
      return this.totalAmount?.toLocaleString() || "";
    },
    deliveryDestinationValue() {
      if (this.quotationData?.case?.delivery_destination_manual) {
        return this.quotationData?.case?.delivery_destination_manual;
      } else {
        return this.quotationData?.case?.delivery_destination?.name || "";
      }
    },
    isStorageNumberInput() {
      return this.quotationData?.case?.delivery_destination?.code === "3";
    },
    defaultDetailItem() {
      return {
        id: null,
        quotation: this.formData.id,
        detail_no: null,
        item: null,
        content: null,
        quantity: null,
        unit: null,
        unit_cost: null,
        amount: null,
      };
    },
    workflowId() {
      return [
        this.quotationData?.adcom_workflow,
        this.quotationData?.client_workflow,
      ].filter((id) => id);
    },
    displayWorkflowId() {
      return this.hasPerm(`adcomStaff`, `adcomManager`)
        ? this.quotationData?.adcom_workflow || null
        : this.quotationData?.client_workflow || null;
    },

    /**
     * @vuese
     * カタログ情報の表示非表示設定
     */
    isShowRequestCategory() {
      return (
        this.quotationData?.case?.request_category === requestCategorys.Catalog
      );
    },
    /**
     * @vuese
     * 医用広告データ情報の表示非表示設定
     */
    isShowRequestCategoryAdData() {
      return (
        this.quotationData?.case?.request_category ===
        requestCategorys.medical_ad_data
      );
    },
    /**
     * @vuese
     * 院内パネル情報の表示非表示設定
     */
    isShowRequestCategoryPanelData() {
      return (
        this.quotationData?.case?.request_category ===
        requestCategorys.hospital_panel_data
      );
    },
    /**
     * @vuese
     * 特殊印刷詳細情報の表示非表示設定
     */
    isShowRequestCategorySpecialPrintingDetails() {
      return (
        this.quotationData?.case?.request_category ===
        requestCategorys.special_printing_detail
      );
    },
    /**
     * @vuese
     * 保存ボタンの表示非表示設定
     */
    isShowSave() {
      return (
        this.hasPerm("adcom") &&
        (!this.quotationWorkflowStatus ||
          this.quotationWorkflowStatus === workfrowStatuses.NoWorkfrow)
      );
    },
    /**
     * @vuese
     * 印刷ボタンの表示非表示設定
     */
    isShowPrint() {
      return this.quotationWorkflowAdcomStatus === workfrowStatuses.Approval;
    },
    /**
     * @vuese
     * ToDO: condition for Print Purchase Order Button to be visible
     */
    isShowPrintPurchase() {
      return this.hasClientCompletedFinalApproval;
    },
    /**
     * @vuese
     * ワークフロー申請および削除時入力制御
     */
    isInput() {
      return (
        this.quotationWorkflowStatus == workfrowStatuses.NoWorkfrow &&
        (this.quotationData?.case?.status === statuses.CreateQuotation ||
          this.quotationData?.case?.status === statuses.ChangingOrderDetails) &&
        !this.quotationData?.case?.delete_flag
      );
    },
    /**
     * @vuese
     * 標準項目入力項目制御
     */
    propsStandardItem() {
      return this.isInput
        ? this.hasPerm(
            "adcomStaff",
            "adcomManager",
            "adcomProcurer",
            "adcomProvision"
          )
        : false;
    },
    /**
     * @vuese
     * ワークフローコンポーネントの表示非表示設定
     */
    IsWorkflow() {
      return (
        ((this.hasPerm("adcomStaff") &&
          this.checkAmount &&
          this.isAdcomContact &&
          this.external_vendor_quotation) ||
          (this.hasPerm("adcomManager") &&
            this.quotationWorkflowStatus !== workfrowStatuses.NoWorkfrow) ||
          (this.hasPerm("clientStaff") &&
            this.isClientContact &&
            this.quotationData.rejected_flag === false &&
            ((this.quotationWorkflowStatus !== workfrowStatuses.Rejection &&
              this.quotationWorkflowStatus == workfrowStatuses.Approval &&
              this.quotationWorkflowClientStatus ===
                workfrowStatuses.NoWorkfrow) ||
              this.quotationWorkflowClientStatus !==
                workfrowStatuses.NoWorkfrow)) ||
          (this.hasPerm("clientManager") &&
            this.quotationWorkflowClientStatus !==
              workfrowStatuses.NoWorkfrow)) &&
        !this.isRejectFlag
      );
    },
    /**
     * @vuese
     * 却下ワークフローコンポーネントの表示非表示設定
     */
    IsWorkflowReject() {
      return (
        this.hasPerm("clientStaff") &&
        this.isClientContact &&
        this.quotationWorkflowStatus == workfrowStatuses.Approval &&
        this.quotationWorkflowClientStatus === workfrowStatuses.NoWorkfrow
      );
    },
    /**
     * @vuese
     * アラート表記の表示非表示設定
     */
    IsAlert() {
      return this.hasPerm("adcomStaff") && !this.checkAmount;
    },
    /**
     * @vuese
     * クライアント担当者での制限
     */
    isClientContact() {
      return (
        this.quotationData?.case?.client_user?.id === this.currentUser?.id ||
        this.quotationData?.case?.client_user?.proxy_user ===
          this.currentUser?.id
      );
    },
    /**
     * @vuese
     * アドコム担当者での制限
     */
    isAdcomContact() {
      return (
        this.quotationData?.case?.contact_user?.id === this.currentUser?.id ||
        this.quotationData?.case?.contact_user?.proxy_user ===
          this.currentUser?.id
      );
    },
    /**
     * @vuese
     * 見積書の明細金額と案件の金額の正当性チェック
     */
    checkAmount() {
      return (
        this.totalAmount ===
        (this.quotationData?.quotation_no
          ? this.quotationData?.amount
          : this.quotationData?.case?.total_selling_price)
      );
    },
    /**
     * @vuese
     * ToDO: Check if the reject flag is present
     */
    isRejectFlag() {
      return this.quotationData.rejected_flag;
    },
  },
};
</script>
