<template>
  <v-card flat tile>
    <v-card height="4rem">
      <v-btn class="mt-4 ml-2" width="8rem">{{ $t("cancel") }}</v-btn>
      <v-btn class="mt-4 ml-2" width="8rem">{{
        $t("users.create.registration")
      }}</v-btn>
    </v-card>
    <v-card class="mt-4">
      <v-container fluid class="pa-5">
        <v-row no-gutters>
          <v-col>
            <v-row class="d-flex">
              <v-col cols="12" lg="3">
                <v-text-field
                  outlined
                  :label="$t('users.create.userName')"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="d-flex">
              <v-col cols="12" lg="4">
                <v-text-field
                  outlined
                  :label="$t('users.create.userMail')"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="d-flex">
              <v-col cols="12" lg="4">
                <v-text-field
                  outlined
                  :label="$t('users.create.password')"
                  :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showpass ? 'text' : 'password'"
                  @click:append="showpass = !showpass"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="d-flex">
              <v-col cols="12" lg="8">
                <v-text-field
                  outlined
                  :label="$t('users.create.companyName')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex">
              <v-col cols="12" lg="8">
                <v-text-field
                  outlined
                  :label="$t('users.create.divisionName')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex">
              <v-col cols="12" lg="3">
                <v-text-field
                  outlined
                  :label="$t('users.create.tel')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="1">
                <v-text-field
                  outlined
                  :label="$t('users.create.indoorWiring')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex">
              <v-col cols="12" lg="4">
                <v-text-field
                  outlined
                  :label="$t('users.create.approverFirst')"
                  placeholder="承認者が１人の場合は、ここに最終承認者を設定してください"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="4">
                <v-text-field
                  outlined
                  :label="$t('users.create.approverSecond')"
                  placeholder="承認者が２人の場合は、ここに最終承認者を設定してください"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex">
              <v-col cols="12" lg="3">
                <v-select
                  outlined
                  :items="searchitemauthority"
                  :label="$t('users.create.authority')"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      showpass: false,
      searchitemiraisya: ["山田 太郎", "鈴木 一郎", "佐藤 栞里", "斎藤 啓介"],
      searchitembumon: ["A社 営業部", "B社 営業部", "C社 営業部", "D社 営業部"],
      searchitembumonCode: ["4000", "5000", "6000", "7000"],
      searchitemauthority: ["一般", "上長"],
    };
  },
};
</script>
