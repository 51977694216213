export const StringType = {
  parse(value) {
    return value && typeof value === "string" ? value : "";
  },
  serialize(value) {
    return value || undefined;
  },
};

export const BooleanType = {
  parse(value) {
    switch (value) {
      case "1":
        return true;
      case "0":
        return false;
      default:
        return false;
    }
  },
  serialize(value) {
    if (value === undefined || value === null) {
      return undefined;
    } else {
      return value ? "1" : "0";
    }
  },
};

export const NumberType = {
  parse(value) {
    if (!value || typeof value !== "string") {
      return NaN;
    } else {
      return Number(value);
    }
  },
  serialize(value) {
    if (value === undefined || value === null || Number.isNaN(value)) {
      return undefined;
    } else {
      return String(value);
    }
  },
};

export const StringArrayType = {
  parse(value) {
    if (!Array.isArray(value)) {
      return [value];
    } else {
      return value.map((i) => String(i));
    }
  },
  serialize(value) {
    if (!Array.isArray(value)) {
      return [];
    } else {
      return value.map((i) => String(i));
    }
  },
};

export const NumberArrayType = {
  parse(value) {
    if (!Array.isArray(value)) {
      return Array.from(String(value), Number);
    } else {
      return value.map((i) => Number(i));
    }
  },
  serialize(value) {
    if (!Array.isArray(value)) {
      return [];
    } else {
      return value.map((i) => Number(i));
    }
  },
};
