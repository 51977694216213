<template>
  <v-app id="passwordReset">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <form-wrapper :validator="$v.passwordResetForm">
            <v-form lazy-validation @submit.prevent="send">
              <v-card>
                <v-card-title>
                  {{ $t("auth.passwordReset.title") }}
                </v-card-title>
                <v-card-text>
                  <i18n
                    tag="p"
                    class="text--primary mb-5"
                    path="auth.passwordReset.helperText"
                    ><br
                  /></i18n>
                  <form-group name="username">
                    <template v-slot="{ attrs }">
                      <v-text-field
                        v-bind="attrs"
                        prepend-icon="mdi-mail"
                        :label="$t('resources.users.fields.pid')"
                        v-model="passwordResetForm.username"
                        type="text"
                        outlined
                        maxlength="6"
                        dense
                      ></v-text-field>
                    </template>
                  </form-group>
                </v-card-text>
                <v-card-actions class="justify-space-between">
                  <v-btn
                    :disabled="loading"
                    @click="$router.push({ name: 'login' })"
                  >
                    {{ $t("auth.passwordReset.actions.back") }}
                  </v-btn>
                  <v-btn color="primary" type="sumbit" :disabled="loading">
                    {{ $t("auth.passwordReset.actions.send") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </form-wrapper>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, numeric, minLength } from "vuelidate/lib/validators";

/**
 * @group 認証
 * パスワードリセット（PID入力）画面<br>
 * 機能仕様書：3.4, 6.2
 */
export default {
  name: "passwordReset",
  mixins: [validationMixin],
  validations: {
    passwordResetForm: {
      username: {
        required,
        numeric,
        justLength: minLength(6),
      },
    },
  },
  data() {
    return {
      loading: false,
      passwordResetForm: {
        username: "",
      },
    };
  },
  methods: {
    /**
     * @vuese
     * PID送信
     */
    async send() {
      this.$v.passwordResetForm.$touch();
      if (!this.$v.passwordResetForm.$invalid) {
        try {
          this.loading = true;
          await this.$store.dispatch("passwordReset", this.passwordResetForm);
          this.$router.push({ name: "login" });
        } catch (err) {
          if (err?.response) {
            const { status } = err.response;
            if (status === 400) {
              this.$message.error(this.$t("messages.auth.userNotExistError"));
            }
            this.passwordResetForm.username = "";
          }
        } finally {
          this.$v.passwordResetForm.$reset();
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style></style>
