//import { CRUD_OPERATION } from "../../configs/crudConfig";
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    messageSource: { type: String, required: false },
  },
  validations: {},
  data() {
    return {
      loading: false,
      formData: { version: null },
    };
  },
  async created() {
    await this.fetchData(this.id);
  },
  methods: {
    back() {
      this.$router.back();
    },
    // @vuese
    // データ取得
    async fetchData(id) {
      this.loading = true;
      try {
        const data = await this.$api.getOne(this.$options.resource, id);
        this.$utils.update(this.formData, data);
      } finally {
        this.loading = false;
      }
    },
    // @vuese
    // 更新/一覧画面へ遷移
    async save(messageSource) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        try {
          const updated = await this.$api.update(
            this.$options.resource,
            this.id,
            this.formData
          );
          this.$utils.update(this.formData, updated);

          if (!messageSource) {
            messageSource = "messages.success.update";
          }
          this.$message.success(this.$t(messageSource));
          // this.$router.push({
          //   name: `${this.$options.resource}${CRUD_OPERATION.LIST}`,
          // });
        } finally {
          this.$v.$reset();
          this.loading = false;
        }
      }
    },
  },
};
