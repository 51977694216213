<template>
  <!--承認フロー-->
  <v-card class="mx-auto mt-5" width="60rem" v-if="show" :loading="loading">
    <v-system-bar color="orange darken-2" dark class="pa-5">
      {{ $t("resources.workflows.titles.clientReject") }}
    </v-system-bar>
    <v-card-text class="px-7 pt-7 pb-0">
      <form-wrapper :validator="$v.formData">
        <v-form lazy-validation>
          <v-textarea
            v-model="formData.action_comment"
            outlined
            label="コメント"
          >
          </v-textarea>
        </v-form>
      </form-wrapper>
    </v-card-text>
    <v-card-actions class="px-7 pb-7">
      <v-btn @click="clientReject" color="primary" width="8rem">{{
        $t("resources.workflows.actions.reject")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  resource: "workflows",
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  validations() {
    return {
      formData: {},
    };
  },
  data() {
    return {
      show: false,
      loading: false,
      formData: {
        action_comment: "",
      },
      workflowData: {},
    };
  },
  watch: {
    report: async function ({
      adcom_workflow,
      client_workflow,
      rejected_flag,
    }) {
      if (adcom_workflow && client_workflow) {
        await this.fetchInitData(
          adcom_workflow,
          client_workflow,
          rejected_flag
        );
      } else {
        this.show = false;
      }
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    async fetchInitData(adcom_workflow, client_workflow, rejected_flag) {
      if (!rejected_flag) {
        try {
          this.loading = true;
          const { client_reject } = await this.$api.dispatch(
            "GET",
            this.$options.resource,
            "clientReject",
            null,
            {
              client_workflow,
              adcom_workflow,
            }
          );
          if (client_reject) {
            this.workflowData = await this.$api.getOne(
              this.$options.resource,
              adcom_workflow
            );
            this.show = true;
          } else {
            this.show = false;
          }
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
        }
      } else {
        this.show = false;
      }
    },
    async clientReject() {
      if (
        !(await this.$root.$confirm(
          "actions.confirm",
          "resources.workflows.messages.confirm.reject"
        ))
      ) {
        return;
      }

      const action = "reject";
      try {
        this.loading = true;
        const payload = {
          ...this.formData,
          version: this.workflowData.version,
        };
        const workflow_details_payload = [
          ...this.workflowData.workflow_details,
        ];
        workflow_details_payload.push({
          user: this.currentUser.id,
        });
        payload.workflow_details = workflow_details_payload;
        await this.$api.dispatch(
          "PATCH",
          this.$options.resource,
          action,
          this.report.adcom_workflow,
          payload
        );
        this.$emit("updated", { action: action });
        this.show = false;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
