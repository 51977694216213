export default {
  data() {
    return {
      loading: false,
      formPerm: {},
    };
  },
  async created() {
    this.loading = true;
    try {
      const perm = await this.$api.permission(this.$options.resource);
      Object.assign(this.formPerm, perm);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    // @vuese
    // 項目の表示可否を取得
    display(field) {
      return !(this.formPerm[field] === false);
    },
  },
};
