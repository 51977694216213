<template>
  <v-container class="lighten-5" id="main_container">
    <v-overlay :value="obicAlignment">
      <v-progress-circular indeterminate color="primary"></v-progress-circular
    ></v-overlay>
    <form-wrapper :validator="$v.formData">
      <v-form ref="inputForm" lazy-validation>
        <v-card flat class="d-flex justify-start">
          <template v-if="formData.case_category == 1">
            <h2>{{ $t("resources.cases.create_name") }}</h2>
          </template>
          <template v-if="formData.case_category == 2">
            <v-icon>mdi-format-float-none</v-icon>
            <h2>{{ $t("resources.cases.create_order_name") }}</h2>
          </template>
        </v-card>
        <v-alert v-if="alartflag" dense outlined type="error"> error</v-alert>

        <v-card class="mx-auto mt-5" max-width="100%">
          <v-system-bar color="indigo darken-2" dark class="pa-5">
            {{ $t("resources.cases.client_Info") }}
          </v-system-bar>
          <v-container fluid class="pa-5">
            <v-row no-gutters>
              <v-col>
                <v-row class="d-flex">
                  <v-col cols="12" lg="2" md="4">
                    <v-text-field
                      class="text-field-border-radius"
                      v-model="formData.quotation_request_date"
                      :label="
                        $t('resources.cases.fields.quotation_request_date')
                      "
                      readonly
                      rounded
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="2" md="4">
                    <v-text-field
                      class="text-field-border-radius"
                      :value="$get('clientUser.full_name', '')"
                      :label="$t('resources.cases.fields.client_user')"
                      readonly
                      rounded
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="2" md="4">
                    <v-text-field
                      class="text-field-border-radius"
                      :value="$get('clientDepartment.name', '')"
                      :label="$t('resources.cases.fields.client_department')"
                      readonly
                      rounded
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="2" md="4">
                    <v-text-field
                      class="text-field-border-radius"
                      :value="$get('clientUser.tel', '')"
                      :label="$t('resources.cases.fields.client_tel')"
                      readonly
                      rounded
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="4" md="4">
                    <v-text-field
                      class="text-field-border-radius"
                      :value="$get('clientUser.email', '')"
                      :label="$t('resources.cases.fields.client_mail')"
                      readonly
                      rounded
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <!-- order info card -->
        <v-card class="mx-auto mt-5" max-width="100%">
          <v-system-bar color="indigo darken-2" dark class="pa-5">
            {{ $t("resources.cases.order_Info") }}
          </v-system-bar>
          <v-container fluid class="pa-5">
            <v-row no-gutters>
              <v-col>
                <v-row>
                  <v-col cols="12" lg="2" md="4">
                    <v-text-field
                      class="text-field-border-radius"
                      :label="$t('resources.cases.fields.case_no')"
                      v-model="formData.case_no"
                      readonly
                      rounded
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="2" md="4">
                    <v-text-field
                      v-show="isApproval"
                      v-model="formData.approval_no"
                      :label="$t('resources.cases.fields.approval_no')"
                      readonly
                      rounded
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="2" md="4">
                    <form-group
                      :validator="$v.formData.contact_user"
                      attribute="resources.cases.fields.contact_user"
                    >
                      <template v-slot="{ attrs }">
                        <ReferenceAutocomplete
                          v-bind="attrs"
                          resource="users"
                          :params="{
                            identities: ['is_adcom', 'is_staff'],
                          }"
                          v-model="formData.contact_user"
                          value="id"
                          text="all_name"
                          :label="$t('resources.cases.fields.contact_user')"
                          @input:item="contactUserSelected"
                          :readonly="!propsContactUser"
                          :rounded="!propsContactUser"
                          :clearable="propsContactUser"
                          :outlined="propsContactUser"
                        >
                        </ReferenceAutocomplete>
                      </template>
                    </form-group>
                  </v-col>
                  <v-col cols="12" lg="2" md="4"></v-col>
                  <v-col
                    cols="12"
                    lg="2"
                    md="4"
                    v-if="isShowAdcomUser && !isApproval"
                  >
                    <form-group
                      :validator="$v.formData.web_contact_user"
                      attribute="resources.cases.fields.web_contact_user"
                    >
                      <template v-slot="{ attrs }">
                        <ReferenceAutocomplete
                          v-bind="attrs"
                          resource="users"
                          :params="{
                            identities: ['is_adcom', 'is_staff'],
                          }"
                          v-model="formData.web_contact_user"
                          value="id"
                          text="all_name"
                          :label="$t('resources.cases.fields.web_contact_user')"
                          @input:item="webContactUserSelected"
                          :readonly="!propsContactUser"
                          :rounded="!propsContactUser"
                          :clearable="propsContactUser"
                          :outlined="propsContactUser"
                        >
                        </ReferenceAutocomplete>
                      </template>
                    </form-group>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="2"
                    md="4"
                    class="mt-3"
                    v-if="isShowAdcomUser && !isApproval"
                  >
                    <v-btn
                      color="primary"
                      depressed
                      @click="saveCaseAndWebContactEmail()"
                      :disabled="loading || !formData.web_contact_user"
                    >
                      <span>{{
                        $t("resources.cases.webContactSendEmailButton")
                      }}</span>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="12">
                    <form-group
                      :validator="$v.formData.request_title"
                      attribute="resources.cases.fields.request_title"
                    >
                      <template v-slot="{ attrs }">
                        <v-text-field
                          v-bind="attrs"
                          v-model="formData.request_title"
                          maxlength="140"
                          :label="$t('resources.cases.fields.request_title')"
                          :outlined="propsStandardItem"
                          :readonly="!propsStandardItem"
                          :rounded="!propsStandardItem"
                        ></v-text-field>
                      </template>
                    </form-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="3" md="4">
                    <form-group
                      :validator="$v.formData.preferred_due_date"
                      attribute="resources.cases.fields.preferred_due_date"
                    >
                      <template v-slot="{ attrs }">
                        <DatePicker
                          v-bind="attrs"
                          :label="
                            $t('resources.cases.fields.preferred_due_date')
                          "
                          :class="isOverDays ? 'red--text' : ''"
                          v-model="formData.preferred_due_date"
                          :outlined="propsStandardItem"
                          :readonly="!propsStandardItem"
                          :rounded="!propsStandardItem"
                          :clearable="propsStandardItem"
                        />
                      </template>
                    </form-group>
                    <!-- <v-alert
                      v-if="isShowAdcomUser && isOverDays"
                      dense
                      border="left"
                      type="error"
                      >{{
                        $t("resources.cases.messages.preferredDueDateOver")
                      }}</v-alert
                    > -->
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="12">
                    <v-alert
                      dense
                      border="left"
                      color="orange darken-2"
                      dark
                      v-if="
                        hasPerm('adcom') &&
                        isNormal &&
                        isOrdered &&
                        !isCodeAndRateCheck1 &&
                        formData.billing_category === '1'
                      "
                      width="100%"
                    >
                      {{ $t("resources.cases.messages.NotRateAndCodeEqual") }}
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      class="text-field-border-radius"
                      :label="$t('resources.cases.fields.scheduled_due_date')"
                      v-model="formData.scheduled_due_date"
                      readonly
                      rounded
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-if="
                        hasPerm(
                          'adcomStaff',
                          'adcomManager',
                          'adcomProcurer',
                          'adcomProvision',
                          'adcomDuty'
                        )
                      "
                      class="text-field-border-radius"
                      :label="$t('resources.cases.fields.total_cost')"
                      v-model="totalCostFormatted"
                      readonly
                      rounded
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      :value="totalSellingPriceFormatted"
                      class="text-field-border-radius"
                      :label="
                        isApproval
                          ? $t('resources.cases.fields.budget_amount')
                          : $t('resources.cases.fields.quotation_amount')
                      "
                      readonly
                      rounded
                    >
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-if="
                        hasPerm(
                          'adcomStaff',
                          'adcomManager',
                          'adcomProcurer',
                          'adcomProvision',
                          'adcomDuty'
                        )
                      "
                      v-model="costRatio"
                      class="text-field-border-radius"
                      :label="$t('resources.cases.fields.cost_ratio')"
                      readonly
                      rounded
                      suffix="%"
                    ></v-text-field>
                    <!-- <v-alert
                      v-if="isShowAdcomUser && costRatio >= 90"
                      dense
                      border="left"
                      type="warning"
                      >{{
                        $t("resources.cases.messages.costRatio90Over")
                      }}</v-alert
                    > -->
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="2">
                    <v-text-field
                      v-if="
                        hasPerm(
                          'adcomStaff',
                          'adcomManager',
                          'adcomProcurer',
                          'adcomProvision',
                          'adcomDuty'
                        ) && isNormal
                      "
                      class="text-field-border-radius"
                      :label="
                        $t('resources.cases.fields.total_purchase_amount')
                      "
                      readonly
                      rounded
                      :value="
                        obicTotalValues_total_ex_tax_detail_buy_amountFormatted
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <v-text-field
                      v-if="
                        hasPerm(
                          'adcomStaff',
                          'adcomManager',
                          'adcomProcurer',
                          'adcomProvision',
                          'adcomDuty'
                        ) && isNormal
                      "
                      class="text-field-border-radius"
                      :label="$t('resources.cases.fields.total_cost_obic')"
                      readonly
                      rounded
                      :value="obicTotalValues_total_costFormatted"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <form-group
                      v-if="
                        hasPerm(
                          'adcomStaff',
                          'adcomManager',
                          'adcomProcurer',
                          'adcomProvision',
                          'adcomDuty'
                        ) && isNormal
                      "
                      :validator="
                        $v.obicTotalValues.total_ex_tax_detail_sell_price
                      "
                      attribute="resources.cases.fields.total_selling_price"
                    >
                      <template v-slot="{ attrs }">
                        <v-text-field
                          v-bind="attrs"
                          class="text-field-border-radius"
                          :label="
                            $t('resources.cases.fields.total_selling_price')
                          "
                          readonly
                          rounded
                          :value="
                            obicTotalValues_total_ex_tax_detail_sell_priceFormatted
                          "
                        ></v-text-field>
                      </template>
                    </form-group>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-text-field
                      v-if="
                        hasPerm(
                          'adcomStaff',
                          'adcomManager',
                          'adcomProcurer',
                          'adcomProvision',
                          'adcomDuty'
                        ) && isNormal
                      "
                      class="text-field-border-radius"
                      :label="$t('resources.cases.fields.gross_margin')"
                      readonly
                      rounded
                      :value="obicTotalValues_total_gross_interestFormatted"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-text-field
                      v-if="
                        hasPerm(
                          'adcomStaff',
                          'adcomManager',
                          'adcomProcurer',
                          'adcomProvision',
                          'adcomDuty'
                        ) && isNormal
                      "
                      class="text-field-border-radius"
                      :label="$t('resources.cases.fields.gross_margin_ratio')"
                      readonly
                      rounded
                      :value="
                        obicTotalValues_average_gross_interest_rateFormatted
                      "
                      suffix="%"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="12">
                    <v-row>
                      <v-col>
                        <form-group :validator="$v.formData.draft_file_0">
                          <template v-slot="{ attrs }">
                            <FileInput
                              ref="draftFileInput1"
                              v-bind="attrs"
                              outlined
                              v-model="formData.draft_file[0]"
                              accept="*"
                              :disableUpload="!propsStandardItem"
                              :visivleDelete="propsStandardItem"
                              :label="$t('resources.cases.fields.draft_file')"
                              :messages="
                                $t('resources.cases.messages.draft_file')
                              "
                            >
                            </FileInput>
                          </template>
                        </form-group>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="mb-1 d-flex justify-end">
                  <v-btn
                    class="text-right"
                    color="primary"
                    depressed
                    @click="showMultipleFileUpload = !showMultipleFileUpload"
                  >
                    <div v-if="!showMultipleFileUpload">
                      {{ $t("resources.cases.buttonOpenClose.showFileButton") }}
                    </div>
                    <div v-else>
                      {{ $t("resources.cases.buttonOpenClose.hideFileButton") }}
                    </div>
                  </v-btn>
                </v-row>
                <v-container v-if="showMultipleFileUpload">
                  <v-row>
                    <v-col cols="12" lg="12">
                      <v-row>
                        <v-col>
                          <form-group :validator="$v.formData.draft_file_1">
                            <template v-slot="{ attrs }">
                              <FileInput
                                ref="draftFileInput2"
                                v-bind="attrs"
                                outlined
                                v-model="formData.draft_file[1]"
                                accept="*"
                                :disableUpload="!propsStandardItem"
                                :visivleDelete="propsStandardItem"
                                :label="$t('resources.cases.fields.draft_file')"
                              >
                              </FileInput>
                            </template>
                          </form-group>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="12">
                      <v-row>
                        <v-col>
                          <form-group :validator="$v.formData.draft_file_2">
                            <template v-slot="{ attrs }">
                              <FileInput
                                ref="draftFileInput3"
                                v-bind="attrs"
                                outlined
                                v-model="formData.draft_file[2]"
                                accept="*"
                                :disableUpload="!propsStandardItem"
                                :visivleDelete="propsStandardItem"
                                :label="$t('resources.cases.fields.draft_file')"
                              >
                              </FileInput>
                            </template>
                          </form-group>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="12">
                      <v-row>
                        <v-col>
                          <form-group :validator="$v.formData.draft_file_3">
                            <template v-slot="{ attrs }">
                              <FileInput
                                ref="draftFileInput4"
                                v-bind="attrs"
                                outlined
                                v-model="formData.draft_file[3]"
                                accept="*"
                                :disableUpload="!propsStandardItem"
                                :visivleDelete="propsStandardItem"
                                :label="$t('resources.cases.fields.draft_file')"
                              >
                              </FileInput>
                            </template>
                          </form-group>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="12">
                      <v-row>
                        <v-col>
                          <form-group :validator="$v.formData.draft_file_4">
                            <template v-slot="{ attrs }">
                              <FileInput
                                ref="draftFileInput5"
                                v-bind="attrs"
                                outlined
                                v-model="formData.draft_file[4]"
                                accept="*"
                                :disableUpload="!propsStandardItem"
                                :visivleDelete="propsStandardItem"
                                :label="$t('resources.cases.fields.draft_file')"
                              >
                              </FileInput>
                            </template>
                          </form-group>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
                <v-row>
                  <v-col cols="12" lg="12">
                    <v-row>
                      <v-col>
                        <FileLinkInput
                          ref="draftFileLinkInput"
                          outlined
                          v-model="formData.draft_file_link"
                          :disableUpload="!propsStandardItem"
                          :visivleDelete="propsStandardItem"
                          :label="$t('resources.cases.fields.draft_file_link')"
                        >
                        </FileLinkInput>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-radio-group
                      row
                      v-model="formData.request_category"
                      :readonly="!propsStandardItem"
                    >
                      <v-radio
                        :label="$t('resources.cases.fields.general')"
                        value="1"
                      ></v-radio>
                      <v-radio
                        v-if="isNormal"
                        :label="$t('resources.cases.fields.catalog')"
                        value="2"
                      ></v-radio>
                      <v-radio
                        v-if="isNormal"
                        :label="$t('resources.cases.fields.medical_ad_data')"
                        value="3"
                      ></v-radio>
                      <v-radio
                        v-if="isNormal"
                        :label="
                          $t('resources.cases.fields.hospital_panel_data')
                        "
                        value="4"
                      ></v-radio>
                      <v-radio
                        v-if="isNormal"
                        :label="
                          $t(
                            'resources.cases.fields.special_printing_detail_header'
                          )
                        "
                        value="5"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <div v-show="formData.request_category == '2'">
                  <div class="d-flex justify-space-between flex-wrap">
                    <div>
                      <form-group
                        :validator="$v.formData.c_no"
                        attribute="resources.cases.fields.c_no"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.c_no"
                            maxlength="15"
                            :label="$t('resources.cases.fields.c_no')"
                            :outlined="propsStandardItem"
                            :readonly="!propsStandardItem"
                            :rounded="!propsStandardItem"
                          ></v-text-field>
                        </template>
                      </form-group>
                    </div>
                    <div>
                      <form-group
                        :validator="$v.formData.edition"
                        attribute="resources.cases.fields.edition"
                      >
                        <template v-slot="{ attrs }">
                          <ReferenceSelect
                            v-bind="attrs"
                            resource="editions"
                            v-model="formData.edition"
                            :label="$t('resources.cases.fields.edition')"
                            :outlined="propsStandardItem"
                            :readonly="!propsStandardItem"
                            :rounded="!propsStandardItem"
                          >
                          </ReferenceSelect>
                        </template>
                      </form-group>
                    </div>
                    <div>
                      <form-group
                        :validator="$v.formData.c_revision_no"
                        attribute="resources.cases.fields.c_revision_no"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.c_revision_no"
                            maxlength="2"
                            :label="$t('resources.cases.fields.c_revision_no')"
                            :outlined="propsStandardItem"
                            :readonly="!propsStandardItem"
                            :rounded="!propsStandardItem"
                          ></v-text-field>
                        </template>
                      </form-group>
                    </div>
                    <div>
                      <form-group
                        :validator="$v.formData.language_class"
                        attribute="resources.cases.fields.language_class"
                      >
                        <template v-slot="{ attrs }">
                          <ReferenceSelect
                            v-bind="attrs"
                            resource="languageClasses"
                            v-model="formData.language_class"
                            @input:item="sizeLanguageToggle"
                            :label="$t('resources.cases.fields.language_class')"
                            :outlined="propsStandardItem"
                            :readonly="!propsStandardItem"
                            :rounded="!propsStandardItem"
                          >
                          </ReferenceSelect>
                        </template>
                      </form-group>
                    </div>
                    <div>
                      <form-group
                        :validator="$v.formData.page_count"
                        attribute="resources.cases.fields.page_count"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.page_count"
                            maxlength="3"
                            :label="$t('resources.cases.fields.page_count')"
                            :outlined="propsStandardItem"
                            :readonly="!propsStandardItem"
                            :rounded="!propsStandardItem"
                          ></v-text-field>
                        </template>
                      </form-group>
                    </div>
                    <div>
                      <form-group
                        :validator="$v.formData.delivery_form"
                        attribute="resources.cases.fields.delivery_form"
                      >
                        <template v-slot="{ attrs }">
                          <ReferenceSelect
                            v-bind="attrs"
                            resource="deliveryForms"
                            v-model="formData.delivery_form"
                            :label="$t('resources.cases.fields.delivery_form')"
                            :outlined="propsStandardItem"
                            :readonly="!propsStandardItem"
                            :rounded="!propsStandardItem"
                          >
                          </ReferenceSelect>
                        </template>
                      </form-group>
                    </div>
                    <div>
                      <form-group
                        :validator="$v.formData.size"
                        attribute="resources.cases.fields.size"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.size"
                            maxlength="15"
                            :label="$t('resources.cases.fields.size')"
                            :outlined="propsStandardItem"
                            :readonly="!propsStandardItem"
                            :rounded="!propsStandardItem"
                          ></v-text-field>
                        </template>
                      </form-group>
                    </div>
                    <div>
                      <form-group
                        :validator="$v.formData.old_c_no"
                        attribute="resources.cases.fields.old_c_no"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.old_c_no"
                            maxlength="15"
                            :label="$t('resources.cases.fields.old_c_no')"
                            :outlined="propsStandardItem"
                            :readonly="!propsStandardItem"
                            :rounded="!propsStandardItem"
                          ></v-text-field>
                        </template>
                      </form-group>
                    </div>
                    <div>
                      <form-group
                        :validator="$v.formData.old_c_revision_no"
                        attribute="resources.cases.fields.old_c_revision_no"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.old_c_revision_no"
                            maxlength="2"
                            :label="
                              $t('resources.cases.fields.old_c_revision_no')
                            "
                            :outlined="propsStandardItem"
                            :readonly="!propsStandardItem"
                            :rounded="!propsStandardItem"
                          ></v-text-field>
                        </template>
                      </form-group>
                    </div>
                    <div>
                      <form-group
                        :validator="$v.formData.old_processing"
                        attribute="resources.cases.fields.old_processing"
                      >
                        <template v-slot="{ attrs }">
                          <ReferenceSelect
                            v-bind="attrs"
                            resource="processings"
                            v-model="formData.old_processing"
                            :label="$t('resources.cases.fields.old_processing')"
                            :outlined="propsStandardItem"
                            :readonly="!propsStandardItem"
                            :rounded="!propsStandardItem"
                          >
                          </ReferenceSelect>
                        </template>
                      </form-group>
                    </div>
                  </div>
                </div>
                <div v-show="formData.request_category == '3'">
                  <v-row>
                    <v-col cols="12" lg="4">
                      <v-alert
                        dense
                        border="left"
                        color="orange darken-2"
                        dark
                        >{{
                          $t(
                            "resources.cases.fields.medical_ad_data_header_message"
                          )
                        }}</v-alert
                      >
                    </v-col>
                  </v-row>
                  <v-row class="d-flex">
                    <v-col cols="12" lg="8">
                      <form-group
                        :validator="$v.formData.media_name"
                        attribute="resources.cases.fields.media_name"
                        class="mr-5"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.media_name"
                            maxlength="100"
                            :label="$t('resources.cases.fields.media_name')"
                            :outlined="propsStandardItem"
                            :readonly="!propsStandardItem"
                            :rounded="!propsStandardItem"
                          ></v-text-field>
                        </template>
                      </form-group>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <form-group
                        :validator="$v.formData.ad_format"
                        attribute="resources.cases.fields.ad_format"
                      >
                        <template v-slot="{ attrs }">
                          <ReferenceSelect
                            v-bind="attrs"
                            resource="adFormats"
                            v-model="formData.ad_format"
                            :label="$t('resources.cases.fields.ad_format')"
                            @input:item="adFormatSelected"
                            :outlined="propsStandardItem"
                            :readonly="!propsStandardItem"
                            :rounded="!propsStandardItem"
                          >
                          </ReferenceSelect>
                        </template>
                      </form-group>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex">
                    <v-col cols="12" lg="3">
                      <form-group
                        :validator="$v.formData.is_ad_specs"
                        attribute="resources.cases.fields.is_ad_specs"
                      >
                        <template v-slot="{ attrs }">
                          <v-checkbox
                            v-bind="attrs"
                            v-model="formData.is_ad_specs"
                            :label="$t('resources.cases.fields.is_ad_specs')"
                            :outlined="propsStandardItem"
                            :readonly="!propsStandardItem"
                            :rounded="!propsStandardItem"
                          >
                          </v-checkbox>
                        </template>
                      </form-group>
                    </v-col>
                    <v-col cols="12" lg="9" v-if="formData.is_ad_specs">
                      <form-group :validator="$v.formData.ad_sepecs_file">
                        <template v-slot="{ attrs }">
                          <FileInput
                            ref="adSpecsFileInput"
                            outlined
                            v-bind="attrs"
                            v-model="formData.ad_sepecs_file"
                            accept="*"
                            :disableUpload="!formData.is_ad_specs"
                            :visivleDelete="propsStandardItem"
                            :label="$t('resources.cases.fields.ad_sepecs_file')"
                            :messages="
                              $t('resources.cases.messages.draft_file')
                            "
                          >
                          </FileInput>
                        </template>
                      </form-group>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex">
                    <v-col cols="12" lg="4">
                      <form-group
                        :validator="$v.formData.plate_size"
                        attribute="resources.cases.fields.plate_size"
                      >
                        <template v-slot="{ attrs }">
                          <ReferenceSelect
                            v-bind="attrs"
                            v-model="formData.plate_size"
                            resource="plateSizes"
                            :label="$t('resources.cases.fields.plate_size')"
                            @input:item="plateSizeSelected"
                            :outlined="propsStandardItem"
                            :readonly="!propsStandardItem"
                            :rounded="!propsStandardItem"
                            :crealable="propsStandardItem"
                          >
                          </ReferenceSelect>
                        </template>
                      </form-group>
                    </v-col>
                    <v-col cols="12" lg="4" class="d-flex">
                      <form-group
                        v-if="
                          isPlateSizeInputOther || formData.plate_size === 4
                        "
                        class="mr-5"
                        :validator="$v.formData.plate_height"
                        attribute="resources.cases.fields.plate_height"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.plate_height"
                            maxlength="5"
                            :label="$t('resources.cases.fields.plate_height')"
                            :placeholder="
                              $t('resources.cases.fields.plate_height')
                            "
                            :outlined="isPlateSizeInputOther"
                            :readonly="!isPlateSizeInputOther"
                            :rounded="!isPlateSizeInputOther"
                          >
                          </v-text-field>
                        </template>
                      </form-group>
                      <form-group
                        v-if="
                          isPlateSizeInputOther || formData.plate_size === 4
                        "
                        :validator="$v.formData.plate_width"
                        attribute="resources.cases.fields.plate_width"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.plate_width"
                            maxlength="5"
                            :label="$t('resources.cases.fields.plate_width')"
                            :placeholder="
                              $t('resources.cases.fields.plate_width')
                            "
                            :outlined="isPlateSizeInputOther"
                            :readonly="!isPlateSizeInputOther"
                            :rounded="!isPlateSizeInputOther"
                          >
                          </v-text-field>
                        </template>
                      </form-group>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex">
                    <v-col cols="12" lg="4">
                      <form-group
                        :validator="$v.formData.color"
                        attribute="resources.cases.fields.color"
                      >
                        <template v-slot="{ attrs }">
                          <ReferenceSelect
                            v-bind="attrs"
                            v-model="formData.color"
                            resource="colors"
                            :label="$t('resources.cases.fields.color')"
                            @input:item="colorSelected"
                            :outlined="propsStandardItem"
                            :readonly="!propsStandardItem"
                            :rounded="!propsStandardItem"
                          >
                          </ReferenceSelect>
                        </template>
                      </form-group>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <form-group
                        v-if="isColorInputOther || formData.color === 3"
                        class="mr-5"
                        :validator="$v.formData.other_color"
                        attribute="resources.cases.fields.other_color"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.other_color"
                            maxlength="20"
                            :label="$t('resources.cases.fields.other_color')"
                            :placeholder="
                              $t('resources.cases.fields.other_color')
                            "
                            :outlined="isColorInputOther"
                            :readonly="!isColorInputOther"
                            :rounded="!isColorInputOther"
                          >
                          </v-text-field>
                        </template>
                      </form-group>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex">
                    <v-col cols="12" lg="3">
                      <form-group
                        :validator="$v.formData.ad_no"
                        attribute="resources.cases.fields.ad_no"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.ad_no"
                            maxlength="20"
                            :label="$t('resources.cases.fields.ad_no')"
                            :outlined="propsStandardItem"
                            :readonly="!propsStandardItem"
                            :rounded="!propsStandardItem"
                          ></v-text-field>
                        </template>
                      </form-group>
                    </v-col>
                    <v-col cols="12" lg="9">
                      <form-group
                        :validator="$v.formData.ad_name"
                        attribute="resources.cases.fields.ad_name"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.ad_name"
                            maxlength="50"
                            :label="$t('resources.cases.fields.ad_name')"
                            :outlined="propsStandardItem"
                            :readonly="!propsStandardItem"
                            :rounded="!propsStandardItem"
                          ></v-text-field>
                        </template>
                      </form-group>
                    </v-col>
                  </v-row>
                </div>
                <div v-show="formData.request_category == '4'">
                  <v-row class="d-flex">
                    <v-col cols="12" lg="3">
                      <form-group
                        :validator="$v.formData.desired_panel_no"
                        attribute="resources.cases.fields.desired_panel_no"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.desired_panel_no"
                            maxlength="10"
                            :label="
                              $t('resources.cases.fields.desired_panel_no')
                            "
                            :outlined="propsStandardItem"
                            :readonly="!propsStandardItem"
                            :rounded="!propsStandardItem"
                          ></v-text-field>
                        </template>
                      </form-group>
                    </v-col>
                    <v-col cols="12" lg="9">
                      <form-group
                        :validator="$v.formData.hospital_name"
                        attribute="resources.cases.fields.hospital_name"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.hospital_name"
                            maxlength="50"
                            :label="$t('resources.cases.fields.hospital_name')"
                            :outlined="propsStandardItem"
                            :readonly="!propsStandardItem"
                            :rounded="!propsStandardItem"
                          ></v-text-field>
                        </template>
                      </form-group>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex">
                    <v-col cols="12" lg="3">
                      <form-group
                        :validator="$v.formData.panel_size"
                        attribute="resources.cases.fields.panel_size"
                      >
                        <template v-slot="{ attrs }">
                          <ReferenceSelect
                            v-bind="attrs"
                            v-model="formData.panel_size"
                            resource="panelSizes"
                            :label="$t('resources.cases.fields.panel_size')"
                            @input:item="panelSelected"
                            :outlined="propsStandardItem"
                            :readonly="!propsStandardItem"
                            :rounded="!propsStandardItem"
                          >
                          </ReferenceSelect>
                        </template>
                      </form-group>
                    </v-col>
                    <v-col cols="12" lg="5" class="d-flex">
                      <form-group
                        v-if="
                          isPanelSizeInputOther || formData.panel_size === 2
                        "
                        class="mr-5"
                        :validator="$v.formData.panel_height"
                        attribute="resources.cases.fields.panel_height"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.panel_height"
                            maxlength="5"
                            :label="$t('resources.cases.fields.panel_height')"
                            :placeholder="
                              $t('resources.cases.fields.panel_height')
                            "
                            :outlined="isPanelSizeInputOther"
                            :readonly="!isPanelSizeInputOther"
                            :rounded="!isPanelSizeInputOther"
                          >
                          </v-text-field>
                        </template>
                      </form-group>
                      <form-group
                        v-if="
                          isPanelSizeInputOther || formData.panel_size === 2
                        "
                        class="mr-5"
                        :validator="$v.formData.panel_width"
                        attribute="resources.cases.fields.panel_width"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.panel_width"
                            maxlength="5"
                            :label="$t('resources.cases.fields.panel_width')"
                            :placeholder="
                              $t('resources.cases.fields.panel_width')
                            "
                            :outlined="isPanelSizeInputOther"
                            :readonly="!isPanelSizeInputOther"
                            :rounded="!isPanelSizeInputOther"
                          >
                          </v-text-field>
                        </template>
                      </form-group>
                    </v-col>
                  </v-row>
                </div>
                <div v-show="formData.request_category == '5'">
                  <form-group
                    :validator="$v.formData.special_printing_detail"
                    attribute="resources.cases.fields.special_printing_detail"
                  >
                    <template v-slot="{ attrs }">
                      <v-textarea
                        v-bind="attrs"
                        v-model="formData.special_printing_detail"
                        name="input-7-1"
                        :label="
                          $t('resources.cases.fields.special_printing_detail')
                        "
                        :outlined="propsStandardItem"
                        :readonly="!propsStandardItem"
                        :rounded="!propsStandardItem"
                      ></v-textarea>
                    </template>
                  </form-group>
                </div>
                <v-row class="d-flex">
                  <v-col> </v-col>
                </v-row>
                <v-row class="d-flex">
                  <v-col cols="12" lg="2">
                    <form-group
                      v-model="formData.request_count"
                      :validator="$v.formData.request_count"
                      attribute="resources.cases.fields.request_count"
                      showActionButton="true"
                    >
                      <template v-slot="{ attrs }">
                        <v-text-field
                          v-bind="attrs"
                          v-model="formData.request_count"
                          maxlength="6"
                          :label="$t('resources.cases.fields.request_count')"
                          :messages="
                            $t('resources.cases.messages.request_count')
                          "
                          @input="requestCountStorageAmountDifferemce()"
                          :outlined="propsStandardItem"
                          :readonly="!propsStandardItem"
                          :rounded="!propsStandardItem"
                        ></v-text-field>
                      </template>
                    </form-group>
                  </v-col>
                </v-row>
                <v-row class="d-flex">
                  <v-col cols="12" lg="3">
                    <form-group
                      :validator="$v.formData.delivery_destination"
                      attribute="resources.cases.fields.delivery_destination"
                    >
                      <template v-slot="{ attrs }">
                        <ReferenceSelect
                          v-bind="attrs"
                          resource="deliveryDestinations"
                          v-model="formData.delivery_destination"
                          :label="
                            $t('resources.cases.fields.delivery_destination')
                          "
                          @input:item="deliveryDestinationSelected"
                          :outlined="propsStandardItem"
                          :readonly="!propsStandardItem"
                          :rounded="!propsStandardItem"
                          :crealable="propsStandardItem"
                        >
                        </ReferenceSelect>
                      </template>
                    </form-group>
                  </v-col>
                  <!-- ToDO: delivery_destination=1 if the option "直送先入力" is selected in "納品先" 
                  and likewise delivery_destination=3 for "カタログ倉庫＋依頼課保管分" -->
                  <v-col
                    cols="12"
                    lg="9"
                    v-if="
                      isDeliveryDestinationManualInput ||
                      formData.delivery_destination === 1
                    "
                  >
                    <form-group
                      v-if="
                        isDeliveryDestinationManualInput ||
                        formData.delivery_destination === 1
                      "
                      :validator="$v.formData.delivery_destination_manual"
                      attribute="resources.cases.fields.direct_delivery"
                    >
                      <template v-slot="{ attrs }">
                        <v-text-field
                          v-bind="attrs"
                          v-model="formData.delivery_destination_manual"
                          maxlength="100"
                          :label="
                            $t(
                              'resources.cases.fields.delivery_destination_manual'
                            )
                          "
                          :placeholder="
                            $t(
                              'resources.cases.fields.delivery_destination_manual'
                            )
                          "
                          :outlined="isDeliveryDestinationManualInput"
                          :readonly="!isDeliveryDestinationManualInput"
                          :rounded="!isDeliveryDestinationManualInput"
                        ></v-text-field>
                      </template>
                    </form-group>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="2"
                    v-if="
                      isStorageNumberInput ||
                      formData.delivery_destination === 3
                    "
                  >
                    <form-group
                      v-if="
                        isStorageNumberInput ||
                        formData.delivery_destination === 3
                      "
                      :validator="$v.formData.storage_number"
                      attribute="resources.cases.fields.storage_number"
                    >
                      <template v-slot="{ attrs }">
                        <v-text-field
                          v-bind="attrs"
                          v-model="formData.storage_number"
                          maxlength="6"
                          :label="$t('resources.cases.fields.storage_number')"
                          :placeholder="
                            $t('resources.cases.fields.storage_number')
                          "
                          @input="requestCountStorageAmountDifferemce()"
                          :outlined="isStorageNumberInput"
                          :readonly="!isStorageNumberInput"
                          :rounded="!isStorageNumberInput"
                        ></v-text-field>
                      </template>
                    </form-group>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="2"
                    v-if="
                      isStorageNumberInput ||
                      formData.delivery_destination === 3
                    "
                  >
                    <form-group
                      v-if="
                        isStorageNumberInput ||
                        formData.delivery_destination === 3
                      "
                      :validator="$v.formData.catalog_storage_quantity"
                    >
                      <template v-slot="{ attrs }">
                        <v-text-field
                          v-bind="attrs"
                          v-model="formData.catalog_storage_quantity"
                          maxlength="6"
                          :label="
                            $t(
                              'resources.cases.fields.catalog_storage_quantity'
                            )
                          "
                          :placeholder="
                            $t(
                              'resources.cases.fields.catalog_storage_quantity'
                            )
                          "
                          outlined
                          readonly
                        ></v-text-field>
                      </template>
                    </form-group>
                  </v-col>
                </v-row>

                <v-row class="d-flex mb-6">
                  <v-col cols="12" lg="12">
                    <form-group
                      :validator="$v.formData.request_remarks"
                      attribute="remarks"
                    >
                      <template v-slot="{ attrs }">
                        <v-textarea
                          v-bind="attrs"
                          v-model="formData.request_remarks"
                          name="input-7-1"
                          :label="$t('remarks')"
                          :outlined="propsStandardItem"
                          :readonly="!propsStandardItem"
                          :rounded="!propsStandardItem"
                        ></v-textarea>
                      </template>
                    </form-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <DataTable
                      v-if="
                        hasPerm(
                          'adcomStaff',
                          'adcomManager',
                          'adcomProcurer',
                          'adcomProvision',
                          'adcomDuty'
                        )
                      "
                      resource="caseDetails"
                      parentRelationName="case"
                      :parentId="id"
                      :defaultItem="{
                        case: id,
                        request_content: '',
                        cost: 0,
                        selling_price: 0,
                        client_category: isApproval ? '3' : '1',
                        contact_group_category: '1',
                        trade_date_1: null,
                        trade_date_2: null,
                        trade_date_3: null,
                        trade_date_4: null,
                        trade_date_5: null,
                        trade_date_6: null,
                        trade_date_7: null,
                        trade_date_8: null,
                        trade_date_9: null,
                        trade_date_10: null,
                        report_category_1: null,
                        report_category_2: null,
                        report_category_3: null,
                        report_category_4: null,
                        report_category_5: null,
                        report_category_6: null,
                        report_category_7: null,
                        report_category_8: null,
                        report_category_9: null,
                        report_category_10: null,
                        invalid_flag_1: false,
                        invalid_flag_2: false,
                        invalid_flag_3: false,
                        invalid_flag_4: false,
                        invalid_flag_5: false,
                        invalid_flag_6: false,
                        invalid_flag_7: false,
                        invalid_flag_8: false,
                        invalid_flag_9: false,
                        invalid_flag_10: false,
                        external_vendor_quotation_1: {
                          file: null,
                          link: null,
                          deleted: false,
                        },
                        external_vendor_quotation_2: {
                          file: null,
                          link: null,
                          deleted: false,
                        },
                        external_vendor_quotation_3: {
                          file: null,
                          link: null,
                          deleted: false,
                        },
                        external_vendor_quotation_4: {
                          file: null,
                          link: null,
                          deleted: false,
                        },
                        external_vendor_quotation_5: {
                          file: null,
                          link: null,
                          deleted: false,
                        },
                        external_vendor_quotation_6: {
                          file: null,
                          link: null,
                          deleted: false,
                        },
                        external_vendor_quotation_7: {
                          file: null,
                          link: null,
                          deleted: false,
                        },
                        external_vendor_quotation_8: {
                          file: null,
                          link: null,
                          deleted: false,
                        },
                        external_vendor_quotation_9: {
                          file: null,
                          link: null,
                          deleted: false,
                        },
                        external_vendor_quotation_10: {
                          file: null,
                          link: null,
                          deleted: false,
                        },
                        remarks: '',
                      }"
                      :fileFields="[
                        'external_vendor_quotation_1',
                        'external_vendor_quotation_2',
                        'external_vendor_quotation_3',
                        'external_vendor_quotation_4',
                        'external_vendor_quotation_5',
                        'external_vendor_quotation_6',
                        'external_vendor_quotation_7',
                        'external_vendor_quotation_8',
                        'external_vendor_quotation_9',
                        'external_vendor_quotation_10',
                      ]"
                      :headers="caseDetailHeaders"
                      :showAddButton="propsCaseDetail"
                      :showEditButton="propsCaseDetail"
                      :showDeleteButton="propsCaseDetail"
                      :showReferenceButton="!propsCaseDetail"
                      :itemValidations="caseDetailValidations"
                      :showParticularRowDeleteButton="
                        caseDetailParticularRowDeleteButton
                      "
                      dialogFormMaxWidth="1000px"
                      v-on:create="caseTotal"
                      v-on:update="caseTotal"
                      v-on:delete="caseTotal"
                      v-on:edit="caseDetailDataTableOpen"
                    >
                      <template v-slot:[`item.selling_price`]="{ item }">
                        {{ toLocaleString(item.selling_price) }}
                      </template>
                      <template v-slot:[`item.cost`]="{ item }">
                        {{ toLocaleString(item.cost) }}
                      </template>
                      <template v-slot:dialogForm="{ item, validator }">
                        <form-group
                          :validator="validator.editedItem.request_content"
                          attribute="resources.caseDetails.fields.request_content"
                        >
                          <template v-slot="{ attrs }">
                            <v-text-field
                              v-bind="attrs"
                              outlined
                              :label="
                                $t(
                                  'resources.caseDetails.fields.request_content'
                                )
                              "
                              v-model="item.request_content"
                              maxlength="40"
                              :readonly="!propsCaseDetail"
                            ></v-text-field>
                          </template>
                        </form-group>
                        <v-radio-group
                          row
                          v-model="item.client_category"
                          @change="itemAmountFormat(item)"
                          :readonly="!propsCaseDetail"
                        >
                          <v-radio
                            v-if="isNormal || isReversalAndCorrecting"
                            :label="
                              $t('resources.caseDetails.fields.production')
                            "
                            value="1"
                          ></v-radio>
                          <v-radio
                            v-if="isNormal"
                            :label="$t('resources.caseDetails.fields.vendor')"
                            value="2"
                          ></v-radio>
                          <v-radio
                            v-if="isApproval"
                            :label="$t('resources.caseDetails.fields.budget')"
                            value="3"
                          ></v-radio>
                        </v-radio-group>
                        <v-card flat v-show="item.client_category === '1'">
                          <v-row>
                            <v-col>
                              <form-group
                                :validator="validator.editedItem.selling_price"
                                attribute="resources.caseDetails.fields.inhouseSellingPrice"
                              >
                                <template v-slot="{ attrs }">
                                  <v-text-field
                                    v-bind="attrs"
                                    outlined
                                    :label="
                                      $t(
                                        'resources.caseDetails.fields.inhouseSellingPrice'
                                      )
                                    "
                                    v-model="item.selling_price"
                                    maxlength="11"
                                    :readonly="!propsCaseDetail"
                                  ></v-text-field>
                                </template>
                              </form-group>
                            </v-col>
                            <v-col>
                              <form-group
                                :validator="validator.editedItem.cost"
                                attribute="resources.caseDetails.fields.inhouseManufactureManCost"
                              >
                                <template v-slot="{ attrs }">
                                  <v-text-field
                                    v-bind="attrs"
                                    outlined
                                    :label="
                                      $t(
                                        'resources.caseDetails.fields.inhouseManufactureManCost'
                                      )
                                    "
                                    maxlength="11"
                                    v-model="item.cost"
                                    :readonly="!propsCaseDetail"
                                  ></v-text-field>
                                </template>
                              </form-group>
                            </v-col>
                          </v-row>
                          <v-radio-group
                            row
                            v-model="item.contact_group_category"
                            :readonly="!propsCaseDetail"
                          >
                            <v-radio
                              :label="
                                $t('resources.caseDetails.fields.operation')
                              "
                              value="1"
                            ></v-radio>
                            <v-radio
                              :label="
                                $t('resources.caseDetails.fields.creative')
                              "
                              value="2"
                            ></v-radio>
                            <v-radio
                              :label="$t('resources.caseDetails.fields.Other')"
                              value="3"
                            ></v-radio>
                          </v-radio-group>
                        </v-card>
                        <v-card flat v-show="item.client_category === '2'">
                          <v-container
                            :class="[
                              { 'disabled-row': item.invalid_flag_1 },
                              { 'bordered-row': true },
                            ]"
                          >
                            <v-row>
                              <v-col
                                :cols="
                                  item.external_vendor_quotation_1.link
                                    ? 10
                                    : 12
                                "
                              >
                                <form-group
                                  :validator="
                                    validator.editedItem
                                      .external_vendor_quotation_1
                                  "
                                >
                                  <template v-slot="{ attrs }">
                                    <FileInput
                                      outlined
                                      v-bind="attrs"
                                      accept="*"
                                      v-model="item.external_vendor_quotation_1"
                                      :label="
                                        $t(
                                          'resources.caseDetails.fields.vendorQuotes'
                                        )
                                      "
                                      :disableUpload="
                                        !propsCaseDetail ||
                                        item.external_vendor_quotation_1
                                          .link !== null
                                      "
                                      :visivleDelete="
                                        propsCaseDetail &&
                                        item.external_vendor_quotation_1
                                          .link === null
                                      "
                                    ></FileInput>
                                  </template>
                                </form-group>
                              </v-col>
                              <v-col
                                :cols="
                                  item.external_vendor_quotation_1.link ? 2 : 0
                                "
                                v-if="
                                  item.external_vendor_quotation_1.link !== null
                                "
                              >
                                <v-checkbox
                                  v-model="item.invalid_flag_1"
                                  :disabled="!propsCaseDetail"
                                  :label="
                                    $t(
                                      'resources.orderDetails.fields.invalid_flag'
                                    )
                                  "
                                  class="text-center"
                                ></v-checkbox>
                              </v-col>
                            </v-row>

                            <v-row class="mt-0 pt-0" no-gutters dense>
                              <v-col>
                                <form-group
                                  :validator="
                                    validator.editedItem.report_category_1
                                  "
                                >
                                  <template v-slot="{ attrs }">
                                    <ReferenceAutocomplete
                                      v-bind="attrs"
                                      class="mt-5"
                                      resource="reportCategorys"
                                      :label="
                                        $t(
                                          'resources.caseDetails.fields.report_category'
                                        )
                                      "
                                      v-model="item.report_category_1"
                                      value="id"
                                      text="name"
                                      :clearable="true"
                                      :readonly="!propsCaseDetail"
                                      dense
                                      attach
                                    ></ReferenceAutocomplete>
                                  </template>
                                </form-group>
                              </v-col>
                              <v-col>
                                <form-group
                                  :validator="validator.editedItem.trade_date_1"
                                >
                                  <template v-slot="{ attrs }">
                                    <DatePicker
                                      v-bind="attrs"
                                      v-model="item.trade_date_1"
                                      :readonly="!propsCaseDetail"
                                      :label="
                                        $t(
                                          'resources.caseDetails.fields.trade_date'
                                        )
                                      "
                                      class="ml-6"
                                    />
                                  </template>
                                </form-group>
                              </v-col>
                            </v-row>
                          </v-container>
                          <v-row class="d-flex justify-end ma-1 pa-0">
                            <v-btn
                              class="text-right mt-3"
                              color="primary"
                              depressed
                              @click="
                                showMultipleCaseDetailFile =
                                  !showMultipleCaseDetailFile
                              "
                            >
                              <div v-if="!showMultipleCaseDetailFile">
                                {{
                                  $t(
                                    "resources.cases.buttonOpenClose.showFileButton"
                                  )
                                }}
                              </div>
                              <div v-else>
                                {{
                                  $t(
                                    "resources.cases.buttonOpenClose.hideFileButton"
                                  )
                                }}
                              </div>
                            </v-btn>
                          </v-row>
                          <v-container v-if="showMultipleCaseDetailFile">
                            <v-container
                              fluid
                              v-for="(list, index) in [
                                2, 3, 4, 5, 6, 7, 8, 9, 10,
                              ]"
                              :key="index"
                              :class="[
                                {
                                  'disabled-row': item['invalid_flag_' + list],
                                },
                                { 'bordered-row': true },
                              ]"
                            >
                              <v-row class="mb-0 pb-0" no-gutters dense>
                                <v-col
                                  :cols="
                                    item['external_vendor_quotation_' + list]
                                      .link
                                      ? 10
                                      : 12
                                  "
                                >
                                  <form-group
                                    :validator="
                                      validator.editedItem[
                                        'external_vendor_quotation_' + list
                                      ]
                                    "
                                  >
                                    <template v-slot="{ attrs }">
                                      <FileInput
                                        outlined
                                        v-bind="attrs"
                                        accept="*"
                                        v-model="
                                          item[
                                            'external_vendor_quotation_' + list
                                          ]
                                        "
                                        :label="
                                          $t(
                                            'resources.caseDetails.fields.vendorQuotes'
                                          )
                                        "
                                        :disableUpload="
                                          !propsCaseDetail ||
                                          item[
                                            'external_vendor_quotation_' + list
                                          ].link !== null
                                        "
                                        :visivleDelete="
                                          propsCaseDetail &&
                                          item[
                                            'external_vendor_quotation_' + list
                                          ].link === null
                                        "
                                      ></FileInput>
                                    </template>
                                  </form-group>
                                </v-col>
                                <v-col
                                  :cols="
                                    item['external_vendor_quotation_' + list]
                                      .link
                                      ? 2
                                      : 0
                                  "
                                  v-if="
                                    item['external_vendor_quotation_' + list]
                                      .link !== null
                                  "
                                >
                                  <v-checkbox
                                    v-model="item['invalid_flag_' + list]"
                                    :disabled="!propsCaseDetail"
                                    :label="
                                      $t(
                                        'resources.orderDetails.fields.invalid_flag'
                                      )
                                    "
                                    class="text-center"
                                  ></v-checkbox>
                                </v-col>
                              </v-row>
                              <v-row class="mt-0 pt-0" no-gutters dense>
                                <v-col>
                                  <form-group
                                    :validator="
                                      validator.editedItem[
                                        'report_category_' + list
                                      ]
                                    "
                                  >
                                    <template v-slot="{ attrs }">
                                      <ReferenceAutocomplete
                                        v-bind="attrs"
                                        class="mt-5"
                                        resource="reportCategorys"
                                        :label="
                                          $t(
                                            'resources.caseDetails.fields.report_category'
                                          )
                                        "
                                        v-model="
                                          item['report_category_' + list]
                                        "
                                        :readonly="!propsCaseDetail"
                                        value="id"
                                        text="name"
                                        :clearable="true"
                                        dense
                                        attach
                                      ></ReferenceAutocomplete>
                                    </template>
                                  </form-group>
                                </v-col>
                                <v-col>
                                  <form-group
                                    :validator="
                                      validator.editedItem['trade_date_' + list]
                                    "
                                  >
                                    <template v-slot="{ attrs }">
                                      <DatePicker
                                        v-bind="attrs"
                                        v-model="item['trade_date_' + list]"
                                        :readonly="!propsCaseDetail"
                                        :label="
                                          $t(
                                            'resources.caseDetails.fields.trade_date'
                                          )
                                        "
                                        class="ml-6"
                                      />
                                    </template>
                                  </form-group>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-container>
                          <v-row>
                            <v-col>
                              <form-group
                                :validator="validator.editedItem.selling_price"
                                attribute="resources.caseDetails.fields.selling_price"
                              >
                                <template v-slot="{ attrs }">
                                  <v-text-field
                                    v-bind="attrs"
                                    outlined
                                    :label="
                                      $t(
                                        'resources.caseDetails.fields.selling_price'
                                      )
                                    "
                                    v-model="item.selling_price"
                                    maxlength="11"
                                    :readonly="!propsCaseDetail"
                                  ></v-text-field>
                                </template>
                              </form-group>
                            </v-col>
                            <v-col>
                              <form-group
                                :validator="validator.editedItem.cost"
                                attribute="resources.caseDetails.fields.cost"
                              >
                                <template v-slot="{ attrs }">
                                  <v-text-field
                                    v-bind="attrs"
                                    outlined
                                    :label="
                                      $t('resources.caseDetails.fields.cost')
                                    "
                                    v-model="item.cost"
                                    maxlength="11"
                                    :readonly="!propsCaseDetail"
                                  ></v-text-field>
                                </template>
                              </form-group>
                            </v-col>
                          </v-row>
                        </v-card>
                        <v-card flat v-show="item.client_category === '3'">
                          <form-group
                            :validator="validator.editedItem.selling_price"
                            attribute="resources.caseDetails.fields.budget"
                          >
                            <template v-slot="{ attrs }">
                              <v-text-field
                                v-bind="attrs"
                                outlined
                                :label="
                                  $t('resources.caseDetails.fields.budget')
                                "
                                v-model="item.selling_price"
                                maxlength="11"
                                :readonly="!propsCaseDetail"
                              ></v-text-field>
                            </template>
                          </form-group>
                        </v-card>
                        <v-textarea
                          outlined
                          :label="$t('resources.caseDetails.fields.remarks')"
                          v-model="item.remarks"
                          :readonly="!propsCaseDetail"
                        ></v-textarea>
                      </template>
                    </DataTable>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <DataTable
                      v-if="
                        hasPerm(
                          'adcomStaff',
                          'adcomManager',
                          'adcomProcurer',
                          'adcomProvision',
                          'adcomDuty'
                        ) &&
                        isNormal &&
                        $v.obicCostBurdenDepartments.sumRate &&
                        $v.obicCostBurdenDepartments.sameDepartments &&
                        $v.obicCostBurdenDepartments.sameRates &&
                        $v.obicCostBurdenDepartments
                          .notIncludeSellTempUnitPriceCategory &&
                        $v.obicCostBurdenDepartments
                          .notIncludeBuyTempUnitPriceCategory
                      "
                      ref="orderDetailTable"
                      resource="orderDetails"
                      parentRelationName="case"
                      :parentId="id"
                      :defaultItem="{
                        finish_flag: false,
                        finish_date: '',
                        insourcing_selling_price: null,
                        insourcing_cost: null,
                        actual_insourcing_cost: null,
                        invalid_flag_1: false,
                        invalid_flag_2: false,
                        invalid_flag_3: false,
                        invalid_flag_4: false,
                        invalid_flag_5: false,
                        invalid_flag_6: false,
                        invalid_flag_7: false,
                        invalid_flag_8: false,
                        invalid_flag_9: false,
                        invalid_flag_10: false,
                        trade_date_1: null,
                        trade_date_2: null,
                        trade_date_3: null,
                        trade_date_4: null,
                        trade_date_5: null,
                        trade_date_6: null,
                        trade_date_7: null,
                        trade_date_8: null,
                        trade_date_9: null,
                        trade_date_10: null,
                        report_category_1: null,
                        report_category_2: null,
                        report_category_3: null,
                        report_category_4: null,
                        report_category_5: null,
                        report_category_6: null,
                        report_category_7: null,
                        report_category_8: null,
                        report_category_9: null,
                        report_category_10: null,
                        deliverable_list: {
                          file: null,
                          link: null,
                          deleted: false,
                        },
                        deliverable_list_2: {
                          file: null,
                          link: null,
                          deleted: false,
                        },
                        deliverable_list_3: {
                          file: null,
                          link: null,
                          deleted: false,
                        },
                        deliverable_list_4: {
                          file: null,
                          link: null,
                          deleted: false,
                        },
                        deliverable_list_5: {
                          file: null,
                          link: null,
                          deleted: false,
                        },
                        deliverable_list_6: {
                          file: null,
                          link: null,
                          deleted: false,
                        },
                        deliverable_list_7: {
                          file: null,
                          link: null,
                          deleted: false,
                        },
                        deliverable_list_8: {
                          file: null,
                          link: null,
                          deleted: false,
                        },
                        deliverable_list_9: {
                          file: null,
                          link: null,
                          deleted: false,
                        },
                        deliverable_list_10: {
                          file: null,
                          link: null,
                          deleted: false,
                        },
                      }"
                      :fileFields="[
                        'deliverable_list',
                        'deliverable_list_2',
                        'deliverable_list_3',
                        'deliverable_list_4',
                        'deliverable_list_5',
                        'deliverable_list_6',
                        'deliverable_list_7',
                        'deliverable_list_8',
                        'deliverable_list_9',
                        'deliverable_list_10',
                      ]"
                      :headers="obicHeaders"
                      :showEditButton="canEditObicTableRow"
                      :showDeleteButton="propsObicTable"
                      :showReferenceButton="!propsObicTable"
                      :itemValidations="orderDetailValidations"
                      :showParticularRowDeleteButton="
                        orderDetailParticularRowDeleteButton
                      "
                      dialogFormMaxWidth="1000px"
                      v-on:edit="checkSupplierNameAndFileUploads"
                      v-on:update="formatDetailCompleted"
                      v-on:delete="formatDetailCompleted"
                    >
                      <template
                        v-slot:[`item.total_ex_tax_detail_buy_amount`]="{
                          item,
                        }"
                      >
                        {{
                          toLocaleString(item.total_ex_tax_detail_buy_amount)
                        }}
                      </template>
                      <template
                        v-slot:[`item.total_ex_tax_detail_sell_price`]="{
                          item,
                        }"
                      >
                        {{
                          toLocaleString(item.total_ex_tax_detail_sell_price)
                        }}
                      </template>
                      <template v-slot:dialogForm="{ item, validator }">
                        <v-row>
                          <v-col>
                            <v-checkbox
                              v-model="item.finish_flag"
                              @change="finishDate(item)"
                              :label="
                                $t('resources.orderDetails.fields.finish_flag')
                              "
                              :messages="
                                $t(
                                  'resources.orderDetails.messages.finish_flag'
                                )
                              "
                              :readonly="!propsObicTable"
                            ></v-checkbox>
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="item.finish_date"
                              class="text-field-border-radius"
                              :label="
                                $t('resources.orderDetails.fields.finish_date')
                              "
                              readonly
                              rounded
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <form-group
                              :validator="
                                validator.editedItem.insourcing_selling_price
                              "
                              attribute="resources.orderDetails.fields.insourcing_selling_price"
                            >
                              <template v-slot="{ attrs }">
                                <v-text-field
                                  v-bind="attrs"
                                  outlined
                                  :label="
                                    $t(
                                      'resources.orderDetails.fields.insourcing_selling_price'
                                    )
                                  "
                                  v-model="item.insourcing_selling_price"
                                  :readonly="!propsObicTable"
                                ></v-text-field>
                              </template>
                            </form-group>
                          </v-col>
                          <v-col>
                            <form-group
                              :validator="validator.editedItem.insourcing_cost"
                              attribute="resources.orderDetails.fields.insourcing_cost"
                            >
                              <template v-slot="{ attrs }">
                                <v-text-field
                                  v-bind="attrs"
                                  outlined
                                  :label="
                                    $t(
                                      'resources.orderDetails.fields.insourcing_cost'
                                    )
                                  "
                                  v-model="item.insourcing_cost"
                                  :readonly="!propsObicTable"
                                ></v-text-field>
                              </template>
                            </form-group>
                          </v-col>
                          <v-col>
                            <form-group
                              :validator="
                                validator.editedItem.actual_insourcing_cost
                              "
                              attribute="resources.orderDetails.fields.actual_insourcing_cost"
                            >
                              <template v-slot="{ attrs }">
                                <v-text-field
                                  v-bind="attrs"
                                  outlined
                                  :label="
                                    $t(
                                      'resources.orderDetails.fields.actual_insourcing_cost'
                                    )
                                  "
                                  v-model="item.actual_insourcing_cost"
                                  :readonly="!propsObicTable"
                                ></v-text-field>
                              </template>
                            </form-group>
                          </v-col>
                        </v-row>
                        <v-container class="d-flex flex-column">
                          <v-container
                            :class="[
                              { 'disabled-row': item.invalid_flag_1 },
                              { 'bordered-row': true },
                            ]"
                          >
                            <v-row>
                              <v-col
                                :cols="item.deliverable_list.link ? 10 : 12"
                              >
                                <form-group
                                  :validator="
                                    validator.editedItem.deliverable_list
                                  "
                                >
                                  <template v-slot="{ attrs }">
                                    <FileInput
                                      outlined
                                      accept="*"
                                      v-bind="attrs"
                                      v-model="item.deliverable_list"
                                      :disableUpload="
                                        !propsObicTable ||
                                        item.deliverable_list.link !== null
                                      "
                                      :visivleDelete="
                                        propsObicTable &&
                                        item.deliverable_list.link === null
                                      "
                                      :label="
                                        $t(
                                          'resources.orderDetails.fields.file_upload'
                                        )
                                      "
                                      @input="inputFunc(item)"
                                    ></FileInput>
                                  </template>
                                </form-group>
                              </v-col>

                              <v-col
                                :cols="item.deliverable_list.link ? 2 : 0"
                                v-if="item.deliverable_list.link !== null"
                              >
                                <v-checkbox
                                  v-model="item.invalid_flag_1"
                                  @change="finishDate(item)"
                                  :disabled="!propsObicTable"
                                  :label="
                                    $t(
                                      'resources.orderDetails.fields.invalid_flag'
                                    )
                                  "
                                  class="text-center"
                                ></v-checkbox>
                              </v-col>
                            </v-row>
                            <v-row class="mt-0 pt-0" no-gutters dense>
                              <v-col>
                                <form-group
                                  :validator="
                                    validator.editedItem.report_category_1
                                  "
                                >
                                  <template v-slot="{ attrs }">
                                    <ReferenceAutocomplete
                                      v-bind="attrs"
                                      class="mt-5"
                                      resource="reportCategorys"
                                      :label="
                                        $t(
                                          'resources.orderDetails.fields.report_category'
                                        )
                                      "
                                      v-model="item.report_category_1"
                                      value="id"
                                      text="name"
                                      :clearable="true"
                                      :readonly="!propsObicTable"
                                      dense
                                      attach
                                    ></ReferenceAutocomplete>
                                  </template>
                                </form-group>
                              </v-col>
                              <v-col>
                                <form-group
                                  :validator="validator.editedItem.trade_date_1"
                                >
                                  <template v-slot="{ attrs }">
                                    <DatePicker
                                      v-bind="attrs"
                                      v-model="item.trade_date_1"
                                      :readonly="!propsObicTable"
                                      :label="
                                        $t(
                                          'resources.orderDetails.fields.trade_date'
                                        )
                                      "
                                      class="ml-6"
                                    />
                                  </template>
                                </form-group>
                              </v-col>
                            </v-row>
                          </v-container>
                          <v-row class="d-flex justify-end ma-1 pa-0">
                            <v-btn
                              class="text-right mt-3"
                              color="primary"
                              depressed
                              @click="
                                showMultipleorderDetailFile =
                                  !showMultipleorderDetailFile
                              "
                            >
                              <div v-if="!showMultipleorderDetailFile">
                                {{
                                  $t(
                                    "resources.cases.buttonOpenClose.showFileButton"
                                  )
                                }}
                              </div>
                              <div v-else>
                                {{
                                  $t(
                                    "resources.cases.buttonOpenClose.hideFileButton"
                                  )
                                }}
                              </div>
                            </v-btn>
                          </v-row>
                          <v-container v-if="showMultipleorderDetailFile">
                            <v-container
                              v-for="(list, index) in [
                                2, 3, 4, 5, 6, 7, 8, 9, 10,
                              ]"
                              :key="index"
                              :class="[
                                {
                                  'disabled-row': item['invalid_flag_' + list],
                                },
                                { 'bordered-row': true },
                              ]"
                            >
                              <v-row class="mb-0 pb-0" no-gutters dense>
                                <v-col
                                  :cols="
                                    item['deliverable_list_' + list].link
                                      ? 10
                                      : 12
                                  "
                                >
                                  <form-group
                                    :validator="
                                      validator.editedItem[
                                        'deliverable_list_' + list
                                      ]
                                    "
                                  >
                                    <template v-slot="{ attrs }">
                                      <FileInput
                                        outlined
                                        v-bind="attrs"
                                        accept="*"
                                        v-model="
                                          item['deliverable_list_' + list]
                                        "
                                        :disableUpload="
                                          !propsObicTable ||
                                          item['deliverable_list_' + list]
                                            .link !== null
                                        "
                                        :visivleDelete="
                                          propsObicTable &&
                                          item['deliverable_list_' + list]
                                            .link === null
                                        "
                                        :label="
                                          $t(
                                            'resources.orderDetails.fields.file_upload'
                                          )
                                        "
                                        @input="inputFunc(item)"
                                      ></FileInput>
                                    </template>
                                  </form-group>
                                </v-col>
                                <v-col
                                  :cols="
                                    item['deliverable_list_' + list].link
                                      ? 2
                                      : 0
                                  "
                                  v-if="
                                    item['deliverable_list_' + list].link !==
                                    null
                                  "
                                >
                                  <v-checkbox
                                    v-model="item['invalid_flag_' + list]"
                                    @change="finishDate(item)"
                                    :disabled="!propsObicTable"
                                    :label="
                                      $t(
                                        'resources.orderDetails.fields.invalid_flag'
                                      )
                                    "
                                    class="text-center"
                                  ></v-checkbox>
                                </v-col>
                              </v-row>
                              <v-row class="mt-0 pt-0" no-gutters dense>
                                <v-col>
                                  <form-group
                                    :validator="
                                      validator.editedItem[
                                        'report_category_' + list
                                      ]
                                    "
                                  >
                                    <template v-slot="{ attrs }">
                                      <ReferenceAutocomplete
                                        v-bind="attrs"
                                        class="mt-5"
                                        resource="reportCategorys"
                                        :label="
                                          $t(
                                            'resources.orderDetails.fields.report_category'
                                          )
                                        "
                                        v-model="
                                          item['report_category_' + list]
                                        "
                                        :readonly="!propsObicTable"
                                        value="id"
                                        text="name"
                                        :clearable="true"
                                        dense
                                        attach
                                      ></ReferenceAutocomplete>
                                    </template>
                                  </form-group>
                                </v-col>
                                <v-col>
                                  <form-group
                                    :validator="
                                      validator.editedItem['trade_date_' + list]
                                    "
                                  >
                                    <template v-slot="{ attrs }">
                                      <DatePicker
                                        v-bind="attrs"
                                        v-model="item['trade_date_' + list]"
                                        :readonly="!propsObicTable"
                                        :label="
                                          $t(
                                            'resources.orderDetails.fields.trade_date'
                                          )
                                        "
                                        class="ml-6"
                                      />
                                    </template>
                                  </form-group>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-container>
                        </v-container>
                        <v-alert
                          v-if="
                            !orderDetailsContainsObicSupplierName &&
                            !isDeliveryListFile
                          "
                          dense
                          border="left"
                          color="orange darken-2"
                        >
                          {{
                            $t(
                              "resources.orderDetails.messages.checkObicSupplierNameAndFileUpload"
                            )
                          }}
                        </v-alert>
                      </template>
                    </DataTable>
                  </v-col>
                </v-row>
                <!-- 費用負担割合（OBIC） -->
                <v-row
                  v-if="
                    hasPerm(
                      'adcomStaff',
                      'adcomManager',
                      'adcomProcurer',
                      'adcomProvision',
                      'adcomDuty'
                    ) &&
                    isNormal &&
                    $v.obicCostBurdenDepartments.sumRate &&
                    $v.obicCostBurdenDepartments.sameDepartments &&
                    $v.obicCostBurdenDepartments.sameRates &&
                    $v.obicCostBurdenDepartments
                      .notIncludeSellTempUnitPriceCategory &&
                    $v.obicCostBurdenDepartments
                      .notIncludeBuyTempUnitPriceCategory
                  "
                >
                  <v-col>
                    <v-card flat class="ml-3 text-h6">
                      {{
                        $t(
                          "resources.cases.obic_cost_burden_department_detail_name"
                        )
                      }}
                    </v-card>
                    <v-data-table
                      :items="obicCostBurdenDepartments"
                      :headers="obicCostBurdenDepartmentHeaders"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:[`item.total_burden_amount`]="{ item }">
                        {{ toLocaleString(item.total_burden_amount) }}
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-alert
                    dense
                    type="error"
                    v-if="
                      hasPerm('adcom') &&
                      isNormal &&
                      isOrdered &&
                      !isDetailCompleted
                    "
                    width="100%"
                  >
                    {{ $t("resources.cases.messages.NotDetailCompleted") }}
                  </v-alert>
                  <v-alert
                    dense
                    type="error"
                    v-if="
                      hasPerm('adcom') &&
                      isNormal &&
                      (!$v.obicCostBurdenDepartments.sumRate ||
                        !$v.obicCostBurdenDepartments.sameRates)
                    "
                    width="100%"
                  >
                    {{ $t("resources.cases.messages.sumRate") }}
                  </v-alert>
                  <v-alert
                    dense
                    type="error"
                    v-if="
                      hasPerm('adcom') &&
                      isNormal &&
                      !$v.obicCostBurdenDepartments.sameDepartments
                    "
                    width="100%"
                  >
                    {{ $t("resources.cases.messages.sameDepartments") }}
                  </v-alert>
                  <v-alert
                    dense
                    type="error"
                    v-if="
                      hasPerm('adcom') &&
                      isNormal &&
                      (!$v.obicCostBurdenDepartments
                        .notIncludeSellTempUnitPriceCategory ||
                        !$v.obicCostBurdenDepartments
                          .notIncludeBuyTempUnitPriceCategory)
                    "
                    width="100%"
                  >
                    {{ $t("resources.cases.messages.existsProvisionalPrice") }}
                  </v-alert>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <!-- invoice info card -->

        <v-card class="mx-auto mt-5 mb-6" max-width="100%">
          <v-system-bar color="indigo darken-2" dark class="pa-5">
            {{ $t("resources.cases.invoice_Info") }}
          </v-system-bar>
          <v-container fluid class="pa-5">
            <v-row no-gutters>
              <v-col>
                <v-row>
                  <v-col>
                    <form-group
                      :validator="$v.formData.expense_account"
                      attribute="resources.cases.fields.expense_account"
                    >
                      <template v-slot="{ attrs }">
                        <ReferenceAutocomplete
                          v-bind="attrs"
                          resource="expenseAccounts"
                          v-model="formData.expense_account"
                          value="id"
                          text="all_name"
                          :label="$t('resources.cases.fields.expense_account')"
                          :outlined="propsNewItem"
                          :readonly="!propsNewItem"
                          :rounded="!propsNewItem"
                          :crealable="propsNewItem"
                        ></ReferenceAutocomplete>
                      </template>
                    </form-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-radio-group
                      v-show="isNormal"
                      row
                      v-model="formData.billing_category"
                      :readonly="!propsStandardItem"
                    >
                      <v-radio
                        v-if="this.client_department_code.length < 5"
                        :label="$t('resources.cases.fields.billing_category_1')"
                        value="1"
                      ></v-radio>
                      <v-radio
                        v-if="this.client_department_code.length < 5"
                        :label="$t('resources.cases.fields.billing_category_2')"
                        value="2"
                      ></v-radio>
                      <v-radio
                        :label="$t('resources.cases.fields.billing_category_3')"
                        value="3"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-card flat v-show="formData.billing_category === '3'">
                  <v-row class="d-flex justify-space-between mb-6">
                    <v-col cols="12" lg="6">
                      <div>
                        <form-group
                          :validator="$v.formData.billing_user"
                          attribute="resources.cases.fields.billing_user"
                        >
                          <template v-slot="{ attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-model="formData.billing_user"
                              maxlength="100"
                              :label="$t('resources.cases.fields.billing_user')"
                              :outlined="propsStandardItem"
                              :readonly="!propsStandardItem"
                              :rounded="!propsStandardItem"
                            ></v-text-field>
                          </template>
                        </form-group>
                      </div>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <div>
                        <form-group
                          :validator="$v.formData.billing_department"
                          attribute="resources.cases.fields.billing_department"
                        >
                          <template v-slot="{ attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-model="formData.billing_department"
                              maxlength="100"
                              :label="
                                $t('resources.cases.fields.billing_department')
                              "
                              :outlined="propsStandardItem"
                              :readonly="!propsStandardItem"
                              :rounded="!propsStandardItem"
                            ></v-text-field>
                          </template>
                        </form-group>
                      </div>
                    </v-col>
                    <v-col cols="12" lg="12">
                      <form-group
                        :validator="$v.formData.billing_address"
                        attribute="resources.cases.fields.billing_address"
                      >
                        <template v-slot="{ attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-model="formData.billing_address"
                            maxlength="100"
                            :label="
                              $t('resources.cases.fields.billing_address')
                            "
                            :outlined="propsStandardItem"
                            :readonly="!propsStandardItem"
                            :rounded="!propsStandardItem"
                          ></v-text-field>
                        </template>
                      </form-group>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <v-card
          class="mx-auto mt-5 mb-6"
          max-width="100%"
          v-show="formData.billing_category == '1'"
        >
          <v-system-bar color="indigo darken-2" dark class="pa-5">
            {{ $t("resources.costBurdenDepartments.name") }}
          </v-system-bar>
          <v-container fluid class="pa-5">
            <v-row no-gutters>
              <v-col>
                <DataTable
                  resource="costBurdenDepartments"
                  parentRelationName="case"
                  :parentId="this.id"
                  :defaultItem="defaultDetailItem"
                  :headers="costBurdenDepartmentHeaders"
                  :showAddButton="propsStandardItem"
                  :showEditButton="propsStandardItem"
                  :showDeleteButton="propsStandardItem"
                  :itemValidations="costBurdenDepartmentValidations"
                  v-on:create="caseTotal"
                  v-on:update="caseTotal"
                  v-on:delete="caseTotal"
                >
                  <template v-slot:dialogForm="{ item, validator }">
                    <form-group
                      :validator="validator.editedItem.department"
                      attribute="resources.costBurdenDepartments.fields.department"
                    >
                      <template v-slot="{ attrs }">
                        <ReferenceAutocomplete
                          v-bind="attrs"
                          resource="departments"
                          v-model="item.department"
                          value="id"
                          text="all_name"
                          outlined
                          :label="
                            $t(
                              'resources.costBurdenDepartments.fields.department'
                            )
                          "
                        ></ReferenceAutocomplete>
                      </template>
                    </form-group>

                    <form-group
                      :validator="validator.editedItem.rate"
                      attribute="resources.costBurdenDepartments.fields.rate"
                    >
                      <template v-slot="{ attrs }">
                        <v-text-field
                          v-bind="attrs"
                          v-model="item.rate"
                          :label="
                            $t('resources.costBurdenDepartments.fields.rate')
                          "
                          outlined
                          maxlength="3"
                        />
                      </template>
                    </form-group>
                  </template>
                </DataTable>
              </v-col>
            </v-row>
            <v-card class="d-flex justify-center" flat tile>
              <v-card class="pt-2 mr-10" flat tile>
                <form-group
                  :validator="$v.formData.total_cost_burden_rate"
                  attribute="resources.cases.fields.rate_total"
                >
                  <template v-slot="{ attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-model="formData.total_cost_burden_rate"
                      solo
                      flat
                      reverse
                      prefix="%"
                      :suffix="$t(`resources.cases.fields.rate_total`)"
                      type="numeric"
                      readonly
                    ></v-text-field>
                  </template>
                </form-group>
              </v-card>
            </v-card>
          </v-container>
        </v-card>
        <v-card class="mx-auto mt-5 mb-15" flat>
          <form-group :validator="$v.formData.product_file">
            <template v-slot="{ attrs }">
              <FileInput
                ref="productFileInput"
                v-bind="attrs"
                v-if="isNormal"
                outlined
                v-model="formData.product_file"
                accept="*"
                :disableUpload="!isProductFile"
                :visivleDelete="isProductFile"
                :label="$t('resources.cases.fields.product_file')"
              >
              </FileInput>
            </template>
          </form-group>
          <FileLinkInput
            ref="deliveryFileLinkInput"
            v-if="isNormal"
            outlined
            v-model="formData.delivery_file_link"
            accept="*"
            :disableUpload="!isProductFile"
            :visivleDelete="isProductFile"
            :label="$t('resources.cases.fields.delivery_file_link')"
          >
          </FileLinkInput>
        </v-card>
        <div>
          <!-- 画面下段のボタンメニュー -->
          <v-bottom-navigation fixed horizontal scroll-target="#main_container">
            <!-- TOPへ戻る -->
            <v-btn
              v-if="isShowMoveTop"
              @click="moveTop()"
              :disabled="loading"
              text
            >
              <span>{{ $t("moveTop") }}</span>
              <v-icon>mdi-home</v-icon>
            </v-btn>

            <!-- 保存 -->
            <v-btn
              v-if="isShowSave"
              @click="saveWhileSaveButtonClick()"
              :disabled="loading"
              text
            >
              <span>{{ $t("save") }}</span>
              <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
            <!-- 原稿ファイル保存 -->
            <v-btn
              v-if="isShowFileUploadSave"
              @click="saveFileUpload()"
              :disabled="loading"
              text
            >
              <span>{{ $t("saveFileUploadAndLink") }}</span>
              <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
            <!-- 見積依頼 -->
            <v-btn v-if="isShowRequest" @click="request()" :disabled="loading">
              <span>{{ $t("request") }}</span>
              <v-icon>mdi-file-send-outline</v-icon>
            </v-btn>

            <!-- 再見積 -->
            <v-btn
              v-if="isShowReApplication"
              @click="reApplication()"
              :disabled="loading"
            >
              <span>{{ $t("resources.cases.actions.reapplication") }}</span>
              <v-icon>mdi-file-restore-outline</v-icon>
            </v-btn>

            <!-- 取下げ -->
            <v-btn
              v-if="isShowWithDrawal"
              :disabled="loading"
              @click="showWithDrawal()"
            >
              <span>{{ $t("resources.cases.actions.withdrawal") }}</span>
              <v-icon>mdi-file-remove-outline</v-icon>
            </v-btn>

            <!-- 承認履歴 -->
            <v-btn :disabled="loading" @click="showHistory(id)">
              <span>{{ $t("resources.cases.actions.workflowLog") }}</span>
              <v-icon>mdi-format-list-numbered</v-icon>
            </v-btn>

            <!-- 見積書作成 -->
            <v-btn
              v-if="isShowQuotationReportCreate"
              :disabled="loading"
              @click="quotationReportCreate()"
            >
              <span>{{
                $t("resources.cases.actions.quotationReportCreate")
              }}</span>
              <v-icon>mdi-file-question-outline</v-icon>
            </v-btn>

            <!-- 見積書 -->
            <v-btn
              v-if="isShowQuotationReport"
              :disabled="loading"
              @click="quotationReport()"
            >
              <span>{{ $t("resources.cases.actions.quotationReport") }}</span>
              <v-icon>mdi-file-document-outline</v-icon>
            </v-btn>

            <!-- 注文請書作成 -->
            <v-btn
              v-if="isShowOrderReceiptReportCreate"
              :disabled="loading"
              @click="orderReceiptReportCreate()"
            >
              <span>{{
                $t("resources.cases.actions.orderReceiptReportCreate")
              }}</span>
              <v-icon>mdi-file-document-outline</v-icon>
            </v-btn>
            <!-- 注文請書 -->
            <v-btn
              v-if="isShowOrderReceiptReport"
              :disabled="loading"
              @click="orderReceiptReport()"
            >
              <span>{{
                $t("resources.cases.actions.orderReceiptReport")
              }}</span>
              <v-icon>mdi-file-document-outline</v-icon>
            </v-btn>

            <!-- 納品確認書作成作成 -->
            <v-btn
              v-if="isShowDeliveryReportCreate"
              :disabled="loading"
              @click="deliveryReportCreate()"
            >
              <span>{{
                $t("resources.cases.actions.deliveryReportCreate")
              }}</span>
              <v-icon>mdi-file-sign</v-icon>
            </v-btn>

            <!-- 納品確認書 -->
            <v-btn
              v-if="isShowDeliveryReport"
              :disabled="loading"
              @click="deliveryReport()"
            >
              <span>{{ $t("resources.cases.actions.deliveryReport") }}</span>
              <v-icon>mdi-file-document-outline</v-icon>
            </v-btn>

            <!-- 検収確認書 -->
            <v-btn
              v-if="isShowCertificateReport"
              :disabled="loading"
              @click="certificateReport()"
            >
              <span>{{
                $t("resources.cases.actions.certificatesReport")
              }}</span>
              <v-icon>mdi-file-document-outline</v-icon>
            </v-btn>

            <!-- 完了取消 -->
            <v-btn
              v-if="isShowCompleteCancel"
              :disabled="loading"
              @click="completeCancel()"
            >
              <span>{{ $t("resources.cases.actions.completeCancel") }}</span>
              <v-icon>mdi-tag-remove-outline</v-icon>
            </v-btn>

            <!-- 請求書発行許可 -->
            <v-btn
              v-if="isShowInvoicingsWorkflow"
              :disabled="loading"
              @click="invoicingWorkflow()"
            >
              <span>{{
                $t("resources.cases.actions.invoicingsWorkflow")
              }}</span>
              <v-icon>mdi-account-check-outline</v-icon>
            </v-btn>

            <!-- 調達部発注書処理 -->
            <v-btn
              v-if="isShowReturnPurchaseOrdersWorkflow"
              :disabled="loading"
              @click="returnPurchaseOrderWorkflow()"
            >
              <span>{{
                $t("resources.cases.actions.returnPurchaseOrdersWorkflow")
              }}</span>
              <v-icon>mdi-account-check-outline</v-icon>
            </v-btn>

            <!-- 社内承認（再完了） -->
            <v-btn
              v-if="isShowRecompletionsWorkflow"
              :disabled="loading"
              @click="recompletionWorkflow()"
            >
              <span>{{
                $t("resources.cases.actions.recompletionsWorkflow")
              }}</span>
              <v-icon>mdi-account-check-outline</v-icon>
            </v-btn>

            <!-- 社内承認（赤黒） -->
            <v-btn
              v-if="isShowRedBlackCompletionsWorkflow"
              :disabled="loading"
              @click="RedBlackCompletionsWorkflow()"
            >
              <span>{{
                $t("resources.cases.actions.redBlackCompletionsWorkflow")
              }}</span>
              <v-icon>mdi-account-check-outline</v-icon>
            </v-btn>

            <!-- 社内承認不要 -->
            <v-btn
              v-if="isShowDirectCompletion"
              :disabled="loading"
              @click="directCompletion()"
            >
              <span>{{ $t("resources.cases.actions.directCompletion") }}</span>
              <v-icon>mdi-account-check-outline</v-icon>
            </v-btn>

            <!-- OBIC連携 -->
            <v-btn
              v-if="isShowObicAlignment"
              :disabled="loading"
              @click="doObicAlignment"
            >
              <span>{{ $t("resources.cases.actions.obicAlignment") }}</span>
              <v-icon>mdi-link</v-icon>
            </v-btn>

            <!-- 案件削除 -->
            <v-btn
              v-if="isShowCaseDelete"
              :disabled="loading"
              @click="showCaseDelete()"
            >
              <span>{{ $t("resources.cases.actions.caseDelete") }}</span>
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>

            <!-- 案件削除取消 -->
            <v-btn
              v-if="isShowCaseDeleteCancel"
              :disabled="loading"
              @click="caseDeleteCancel()"
            >
              <span>{{ $t("resources.cases.actions.caseDeleteCancel") }}</span>
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </v-bottom-navigation>
          <confirm ref="confirm"></confirm>
        </div>
      </v-form>
    </form-wrapper>
  </v-container>
</template>
<style scoped>
.disabled-row {
  background-color: #c9c9c9;
}
.bordered-row {
  border-top: 0.1px dashed #5e4949;
  margin: 0;
  padding: 0.1;
}
</style>
<script>
import updateMixin from "../../mixins/updateMixin";
import groupPermissionMixin from "../../mixins/groupPermissionMixin";
import fieldPermissionMixin from "../../mixins/fieldPermissionMixin";
import headersMixin from "../../mixins/headersMixin";
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  numeric,
  sameAs,
  between,
  decimal,
  minValue,
  requiredIf,
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { statuses } from "../../../configs/statusConfig";
import { billingCategorys } from "../../../configs/billingCategoryConfig";
import { deleteCategorys } from "../../../configs/deleteCategoryConfig";
import { requestCategorys } from "../../../configs/requestCategoryConfig";
import { workfrowStatuses } from "../../../configs/workfrowStatusConfig";
import { caseCategorys } from "../../../configs/caseCategoryConfig";
import relationMixin from "../../mixins/relationMixin";
import { checkMaxFileSize } from "../../../utils";

/**
 * @group 受注管理
 * 案件更新画面<br>
 * 機能仕様書：4.2, 4.3, 5.9, 5.10, 6.2
 */
export default {
  resource: "cases",
  mixins: [
    fieldPermissionMixin,
    updateMixin,
    validationMixin,
    headersMixin,
    groupPermissionMixin,
    relationMixin,
  ],
  validations() {
    const formData = {
      preferred_delivery_date: {},
      delivery_destination: {},
      delivery_destination_manual: { maxLength: maxLength(100) },
      storage_number: {
        numeric,
        betweenValue: between(1, 999999),
      },
      client_user: {},
      contact_user: {},
      web_contact_user: {},
      request_title: { required, maxLength: maxLength(140) },
      expense_account: { requiredSelect: required },
      request_category: {},
      request_count: {
        required,
        numeric,
        betweenValue: between(0, 999999),
      },
      c_no: { maxLength: maxLength(20) },
      ad_no: { numeric, maxLength: maxLength(20) },
      desired_panel_no: { numeric, maxLength: maxLength(10) },
      ad_name: { maxLength: maxLength(50) },
      hospital_name: { maxLength: maxLength(50) },
      media_name: { maxLength: maxLength(100) },
      plate_size: {},
      plate_height: { numeric, betweenValue: between(1, 999999) },
      plate_width: { numeric, betweenValue: between(1, 999999) },
      panel_height: { numeric, betweenValue: between(1, 999999) },
      panel_width: { numeric, betweenValue: between(1, 999999) },
      ad_format: {},
      color: {},
      other_color: { maxLength: maxLength(20) },
      is_ad_specs: {},
      c_revision_no: { maxLength: maxLength(4) },
      old_c_revision_no: { maxLength: maxLength(4) },
      edition: {},
      panel_size: {},
      version_instructions: {},
      size: { maxLength: maxLength(20) },
      page_count: {
        numeric,
        maxLength: maxLength(5),
        betweenValue: between(1, 999),
      },
      delivery_form: {},
      language_class: {},
      old_c_no: { maxLength: maxLength(15) },
      old_processing: {},
      request_remarks: {},
      special_printing_detail: {},
      quotation_no: {},
      quotation_date: {},
      quotation_remarks: {},
      status: {},
      scheduled_due_date: {},
      total_cost: 0,
      quotation_amount: {},
      cost_ratio: {},
      billing_user: { maxLength: maxLength(100) },
      billing_department: { maxLength: maxLength(100) },
      billing_address: { maxLength: maxLength(100) },
      total_cost_burden_rate: 0,
      approval_no: {},
      //extra fields
      ad_sepecs_file: {},
      draft_file: {},
      draft_file_link: {},
      product_file: {},
      delivery_file_link: {},
      draft_file_1: {},
      draft_file_2: {},
      draft_file_3: {},
      draft_file_4: {},
      draft_file_0: {},
    };
    formData.product_file = {
      maxFileSize: () => checkMaxFileSize(this.formData.product_file),
    };
    formData.draft_file_0 = {
      maxFileSize: () => checkMaxFileSize(this.formData.draft_file[0]),
    };
    formData.draft_file_1 = {
      maxFileSize: () => checkMaxFileSize(this.formData.draft_file[1]),
    };
    formData.draft_file_2 = {
      maxFileSize: () => checkMaxFileSize(this.formData.draft_file[2]),
    };
    formData.draft_file_3 = {
      maxFileSize: () => checkMaxFileSize(this.formData.draft_file[3]),
    };
    formData.draft_file_4 = {
      maxFileSize: () => checkMaxFileSize(this.formData.draft_file[4]),
    };
    //ToDo: request_category = 1 for "一般"
    // request_category =2 for "カタログ（C番号管理）"
    // and like wise, request_category=5 for "特殊印刷"
    if (this.formData.request_category === "5") {
      formData.special_printing_detail = {
        required,
      };
    } else {
      formData.special_printing_detail = {};
    }
    if (this.selectedDeliveryDestination?.description_flag) {
      formData.delivery_destination_manual = {
        required,
        maxLength: maxLength(100),
      };
    } else {
      formData.delivery_destination_manual = {
        maxLength: maxLength(100),
      };
    }
    if (this.selectedDeliveryDestination?.code === "3") {
      formData.storage_number = {
        numeric,
        betweenValue: between(1, 999999),
        required,
      };
    } else {
      formData.storage_number = {
        numeric,
        betweenValue: between(1, 999999),
      };
    }
    if (this.formData.request_count && this.formData.storage_number) {
      formData.storage_number = {
        numeric,
        betweenValue: between(1, 999999),
        differenceCheck: function () {
          return minValue(0)(
            this.formData.request_count - this.formData.storage_number
          );
        },
      };
    }
    if (this.formData.is_ad_specs) {
      formData.ad_sepecs_file = {
        required,
        maxFileSize: () => checkMaxFileSize(this.formData.ad_sepecs_file),
      };
    }
    if (this.selectedPanelSize?.description_flag) {
      formData.panel_height = {
        required,
        maxLength: maxLength(5),
      };
      formData.panel_width = {
        required,
        maxLength: maxLength(5),
      };
    } else {
      formData.panel_height = {
        maxLength: maxLength(5),
      };
      formData.panel_width = {
        maxLength: maxLength(5),
      };
    }
    if (this.selectedColor?.description_flag) {
      formData.other_color = {
        required,
        maxLength: maxLength(20),
      };
    } else {
      formData.other_color = {
        maxLength: maxLength(20),
      };
    }
    if (this.selectedPlateSize?.description_flag) {
      formData.plate_height = {
        required,
        maxLength: maxLength(5),
      };
      formData.plate_width = {
        required,
        maxLength: maxLength(5),
      };
    } else {
      formData.plate_height = {
        maxLength: maxLength(5),
      };
      formData.plate_width = {
        maxLength: maxLength(5),
      };
    }
    if (this.formData.billing_category == "1") {
      formData.total_cost_burden_rate = {
        sameAsRate: sameAs(function () {
          return 100;
        }),
      };
    }
    const obicTotalValues = {
      total_ex_tax_detail_sell_price: {
        sameAsTotalAmount: (value) => {
          if (this.formData.status >= statuses.Ordered && this.isNormal) {
            return (
              value !== null &&
              this.formData.total_selling_price !== null &&
              value === this.formData.total_selling_price
            );
          }
          return true;
        },
      },
    };
    const obicCostBurdenDepartments = {};
    if (this.hasPerm("adcom") && this.isNormal) {
      // 費用負担部門の負担率の平均の合計が100%か0%かどうかのチェック
      obicCostBurdenDepartments.sumRate = (value) => {
        const sumRate = value.reduce(
          (acc, { average_input_burden_rate }) =>
            average_input_burden_rate ? acc + average_input_burden_rate : acc,
          0
        );
        return sumRate === 100 || sumRate === 0;
      };
      // 費用負担部門が含まれる明細行が全て同じかどうか
      obicCostBurdenDepartments.sameDepartments = (value) => {
        const orderDetailids = value.map((item) => {
          return item.order_detail_ids;
        });
        console.log("orderDetailids", orderDetailids);
        const isAllEqual = (ids) =>
          ids.every((item) => JSON.stringify(item) === JSON.stringify(ids[0]));
        return isAllEqual(orderDetailids);
      };
      // 費用負担部門が含まれる明細行の負担率が全て同じかどうか
      obicCostBurdenDepartments.sameRates = (value) => {
        const orderRates = value.map((item) => {
          return item.order_input_burden_rates;
        });
        console.log("orderRates", orderRates);
        const isAllEqual = (rates) => rates.every((item) => item === rates[0]);
        let result = true;
        orderRates.forEach((rates) => {
          result &= isAllEqual(rates);
        });
        return result;
      };
      // 売上仮単価区分にTrueの明細行が存在しないかどうか
      obicCostBurdenDepartments.notIncludeSellTempUnitPriceCategory = (
        value
      ) => {
        const flags = value.map((item) => {
          return item.sell_temp_unit_price_category;
        });
        return !flags.includes(true);
      };
      // 仕入仮単価区分にTrueの明細行が存在しないかどうか
      obicCostBurdenDepartments.notIncludeBuyTempUnitPriceCategory = (
        value
      ) => {
        const flags = value.map((item) => {
          return item.buy_temp_unit_price_category;
        });
        return !flags.includes(true);
      };
    }
    return { formData, obicTotalValues, obicCostBurdenDepartments };
  },
  data() {
    return {
      /**
       * @vuese
       * 原稿ファイルボタンの動作制御
       */
      showMultipleFileUpload: true,
      /**
       * @vuese
       * ToDO: caseDetail fileUpload section show/hide
       */
      showMultipleCaseDetailFile: false,
      /**
       * @vuese
       * ToDO: orderDetail fileUpload section show/hide
       */
      showMultipleorderDetailFile: false,
      /**
       * @vuese
       * OBIC連携の実行フラグ
       */
      obicAlignment: false,
      /**
       * @vuese
       * 依頼種別の動作制御
       */
      general: false,
      /**
       * @vuese
       * 請求方法の動作制御
       */
      invoicing: false,
      /**
       * @vuese
       * 納品先の選択時情報を保持
       */
      selectedDeliveryDestination: { description_flag: false },
      /**
       * @vuese
       * 原稿形態を保持
       */
      selectedAdFormat: {},
      /**
       * @vuese
       * 版型を保持
       */
      selectedPlateSize: { description_flag: false },
      /**
       * @vuese
       * 原稿色を保持
       */
      selectedColor: { description_flag: false },
      /**
       * @vuese
       * パネルサイズを保持
       */
      selectedPanelSize: { description_flag: false },
      /**
       * @vuese
       * 部門の情報を保持
       */
      departments: [],
      /**
       * @vuese
       * 依頼担当者の情報を保持
       */
      clientUser: null,
      /**
       * @vuese
       * 依頼部門の情報を保持
       */
      clientDepartment: null,
      /**
       * @vuese
       * ToDO: this data will be used in v-alert box of the orderDetails DataTable
       */
      orderDetailsContainsObicSupplierName: true,
      /**
       * @vuese
       * 費用負担部門、率のチェック結果を保持
       */
      resultCodeAndRateCheck: true,
      /**
       * @vuese
       * ToDo: will check if the deliveryListFile is present at the start and when the file is changed
       */
      isDeliveryListFile: true,
      /**
       * @vuese
       * 画面項目の情報を保持
       */
      formData: {
        case_no: "",
        quotation_request_date: this.$dateStr(),
        preferred_due_date: this.$dateStr(),
        delivery_destination: null,
        delivery_destination_manual: "",
        storage_number: null,
        catalog_storage_quantity: null,
        client_user: null,
        contact_user: null,
        web_contact_user: null,
        request_title: "",
        request_category: "",
        request_count: null,
        c_no: "",
        c_revision_no: "",
        old_c_revision_no: "",
        edition: null,
        size: "",
        page_count: null,
        delivery_form: null,
        language_class: null,
        part_number: null,
        old_c_no: "",
        old_processing: null,
        expense_account: null,
        request_remarks: "",
        special_printing_detail: "",
        billing_category: "",
        billing_user: null,
        billing_department: null,
        billing_address: "",
        quotation_id: null,
        total_cost: 0,
        quotation_date: this.$dateStr(),
        quotation_amount: null,
        quotation_remarks: "",
        gross_margin: null,
        gross_margin_ratio: null,
        status: "",
        client_department: null,
        contact_department: null,
        total_cost_burden_rate: 0,
        total_selling_price: 0,
        approval_no: "",
        media_name: "",
        is_ad_specs: false,
        plate_size: null,
        plate_height: null,
        plate_width: null,
        panel_height: null,
        panel_width: null,
        other_size: false,
        ad_format: null,
        color: null,
        other_color: "",
        ad_no: "",
        ad_name: "",
        desired_panel_no: "",
        panel_size: null,
        hospital_name: "",
        delete_flag: false,
        delete_category: "",
        case_category: "",
        completion_date: null,
        scheduled_due_date: this.$dateStr(),
        // extra fields
        draft_file_link: {
          file: null,
          link: null,
          deleted: false,
        },
        ad_sepecs_file: {
          file: null,
          link: null,
          deleted: false,
        },
        product_file: {
          file: null,
          link: null,
          deleted: false,
        },
        delivery_file_link: {
          file: null,
          link: null,
          deleted: false,
        },
        draft_file: [
          {
            file: null,
            link: null,
            deleted: false,
          },
          {
            file: null,
            link: null,
            deleted: false,
          },
          {
            file: null,
            link: null,
            deleted: false,
          },
          {
            file: null,
            link: null,
            deleted: false,
          },
          {
            file: null,
            link: null,
            deleted: false,
          },
        ],
      },
      /**
       * @vuese
       * 案件の情報を保持
       */
      caseData: {},
      /**
       * @vuese
       * 原稿ファイルの情報を保持
       */
      draftFileData: [],
      /**
       * @vuese
       * 原稿ファイルの情報を保持
       */
      adSpecsFileData: {},
      /**
       * @vuese
       * 広告仕様書を保持
       */
      productFileData: {},
      /**
       * @vuese
       * 注文請書の情報を保持
       */
      orderReceipt: [],
      /**
       * @vuese
       * 納品確認書の情報を保持
       */
      deliveryNote: [],
      /**
       * @vuese
       * 却下された納品確認書の情報を保持
       */
      deliveryNoteReject: [],
      /**
       * @vuese
       * 検収確認書の情報を保持
       */
      certificate: [],
      /**
       * @vuese
       * 請求書発行許可の情報を保持
       */
      invoicingData: [],
      /**
       * @vuese
       * 案件完了取消後完了承認の情報を保持
       */
      recompletionData: [],
      /**
       * @vuese
       * 赤黒案件完了承認の情報を保持
       */
      redBlackCompletionData: [],
      /**
       * @vuese
       * 調達部発注書処理の情報を保持
       */
      returnPurchaseOrderData: [],
      /**
       * @vuese
       * OBICの費用負担部門情報
       */
      obicCostBurdenDepartments: [],
      /**
       * @vuese
       * OBICのトータル金額等の情報
       */
      obicTotalValues: {},
      /**
       * @vuese
       * 費用負担部門の入力ダイアログ用Validation定義
       */
      costBurdenDepartmentData: {
        department: { required },
        rate: { required, numeric, maxLength: maxLength(5) },
      },
      /**
       * @vuese
       * 見積書のワークフローステータスの情報（すべて）
       */
      quotationWorkflowStatus: workfrowStatuses.NoWorkfrow,
      /**
       * @vuese
       * 見積書のワークフローステータスの情報（クライアント）
       */
      quotationWorkflowClientStatus: workfrowStatuses.NoWorkfrow,
      /**
       * @vuese
       * 注文請書のワークフローステータスの情報
       */
      orderReceiptWorkflowStatus: workfrowStatuses.NoWorkfrow,
      /**
       * @vuese
       * 納品確認書のワークフローステータスの情報
       */
      deliveryNoteWorkflowStatus: workfrowStatuses.NoWorkfrow,
      /**
       * @vuese
       * 検収確認書のワークフローステータスの情報
       */
      certificateWorkflowStatus: workfrowStatuses.NoWorkfrow,
      /**
       * @vuese
       * 請求書発行許可のワークフローステータスの情報
       */
      invoicingWorkflowStatus: workfrowStatuses.NoWorkfrow,
      /**
       * @vuese
       * 調達部返送許可のワークフローステータスの情報
       */
      returnPurchaseOrderWorkflowStatus: workfrowStatuses.NoWorkfrow,
      /**
       * @vuese
       * 社内完了（再完了）のワークフローステータスの情報
       */
      recompletionWorkflowStatus: workfrowStatuses.NoWorkfrow,
      /**
       * @vuese
       * 社内完了（赤黒）のワークフローステータスの情報
       */
      redBlackCompletionWorkflowStatus: workfrowStatuses.NoWorkfrow,
      /**
       * @vuese
       * 納品完了確認
       */
      is_detail_completed: true,
      /**
       * @vuese
       * アラートメッセージの表示制御
       */
      alartflag: false,
    };
  },
  /**
   * @vuese
   * 費用負担部門、率のチェック結果を取得
   */
  async mounted() {
    this.resultCodeAndRateCheck = await this.codeAndRateCheck(this.id);
  },
  computed: {
    /**
     * @vuese
     * ログインユーザ情報を取得
     */
    ...mapGetters(["currentUser"]),
    /**
     * @vuese
     * 依頼情報明細の入力ダイアログ用Validation定義
     */
    caseDetailValidations() {
      function isContainFileUpload(item, index) {
        const external_vendor_quotation = `external_vendor_quotation_${index}`;
        return (
          item[external_vendor_quotation]?.file !== null ||
          item[external_vendor_quotation]?.link !== null
        );
      }
      return {
        request_content: { maxLengthValue: maxLength(40) },
        selling_price: {
          decimal,
          betweenValue: between(-2147483648, 2147483647),
        },
        cost: {
          decimal,
          betweenValue: between(-2147483648, 2147483647),
        },
        budget: {
          decimal,
          betweenValue: between(-2147483648, 2147483647),
        },

        trade_date_1: {
          required: requiredIf((item) => isContainFileUpload(item, 1)),
        },
        report_category_1: {
          required: requiredIf((item) => isContainFileUpload(item, 1)),
        },
        external_vendor_quotation_1: {
          maxFileSize: (item) => checkMaxFileSize(item),
        },
        trade_date_2: {
          required: requiredIf((item) => isContainFileUpload(item, 2)),
        },
        report_category_2: {
          required: requiredIf((item) => isContainFileUpload(item, 2)),
        },
        external_vendor_quotation_2: {
          maxFileSize: (item) => checkMaxFileSize(item),
        },
        trade_date_3: {
          required: requiredIf((item) => isContainFileUpload(item, 3)),
        },
        report_category_3: {
          required: requiredIf((item) => isContainFileUpload(item, 3)),
        },
        external_vendor_quotation_3: {
          maxFileSize: (item) => checkMaxFileSize(item),
        },

        trade_date_4: {
          required: requiredIf((item) => isContainFileUpload(item, 4)),
        },
        report_category_4: {
          required: requiredIf((item) => isContainFileUpload(item, 4)),
        },
        external_vendor_quotation_4: {
          maxFileSize: (item) => checkMaxFileSize(item),
        },

        trade_date_5: {
          required: requiredIf((item) => isContainFileUpload(item, 5)),
        },
        report_category_5: {
          required: requiredIf((item) => isContainFileUpload(item, 5)),
        },
        external_vendor_quotation_5: {
          maxFileSize: (item) => checkMaxFileSize(item),
        },

        trade_date_6: {
          required: requiredIf((item) => isContainFileUpload(item, 6)),
        },
        report_category_6: {
          required: requiredIf((item) => isContainFileUpload(item, 6)),
        },
        external_vendor_quotation_6: {
          maxFileSize: (item) => checkMaxFileSize(item),
        },

        trade_date_7: {
          required: requiredIf((item) => isContainFileUpload(item, 7)),
        },
        report_category_7: {
          required: requiredIf((item) => isContainFileUpload(item, 7)),
        },
        external_vendor_quotation_7: {
          maxFileSize: (item) => checkMaxFileSize(item),
        },

        trade_date_8: {
          required: requiredIf((item) => isContainFileUpload(item, 8)),
        },
        report_category_8: {
          required: requiredIf((item) => isContainFileUpload(item, 8)),
        },
        external_vendor_quotation_8: {
          maxFileSize: (item) => checkMaxFileSize(item),
        },

        trade_date_9: {
          required: requiredIf((item) => isContainFileUpload(item, 9)),
        },
        report_category_9: {
          required: requiredIf((item) => isContainFileUpload(item, 9)),
        },
        external_vendor_quotation_9: {
          maxFileSize: (item) => checkMaxFileSize(item),
        },

        trade_date_10: {
          required: requiredIf((item) => isContainFileUpload(item, 10)),
        },
        report_category_10: {
          required: requiredIf((item) => isContainFileUpload(item, 10)),
        },
        external_vendor_quotation_10: {
          maxFileSize: (item) => checkMaxFileSize(item),
        },
      };
    },
    /**
     * @vuese
     * 受注明細の入力ダイアログ用Validation定義
     */
    orderDetailValidations() {
      function isContainFileUpload(item, index) {
        const deliverable_list = `deliverable_list_${index}`;
        return (
          item[deliverable_list]?.file !== null ||
          item[deliverable_list]?.link !== null
        );
      }
      function isContainFirstFileUpload(item) {
        return (
          item.deliverable_list?.file !== null ||
          item.deliverable_list?.link !== null
        );
      }
      return {
        insourcing_selling_price: {
          decimal,
          betweenValue: between(-2147483648, 2147483647),
        },
        insourcing_cost: {
          decimal,
          betweenValue: between(-2147483648, 2147483647),
        },
        actual_insourcing_cost: {
          decimal,
          betweenValue: between(-2147483648, 2147483647),
        },
        trade_date_1: {
          required: requiredIf(isContainFirstFileUpload),
        },
        report_category_1: {
          required: requiredIf(isContainFirstFileUpload),
        },
        deliverable_list: {
          maxFileSize: (item) => checkMaxFileSize(item),
        },
        trade_date_2: {
          required: requiredIf((item) => isContainFileUpload(item, 2)),
        },
        report_category_2: {
          required: requiredIf((item) => isContainFileUpload(item, 2)),
        },
        deliverable_list_2: {
          maxFileSize: (item) => checkMaxFileSize(item),
        },
        trade_date_3: {
          required: requiredIf((item) => isContainFileUpload(item, 3)),
        },
        report_category_3: {
          required: requiredIf((item) => isContainFileUpload(item, 3)),
        },
        deliverable_list_3: {
          maxFileSize: (item) => checkMaxFileSize(item),
        },
        trade_date_4: {
          required: requiredIf((item) => isContainFileUpload(item, 4)),
        },
        report_category_4: {
          required: requiredIf((item) => isContainFileUpload(item, 4)),
        },
        deliverable_list_4: {
          maxFileSize: (item) => checkMaxFileSize(item),
        },
        trade_date_5: {
          required: requiredIf((item) => isContainFileUpload(item, 5)),
        },
        report_category_5: {
          required: requiredIf((item) => isContainFileUpload(item, 5)),
        },
        deliverable_list_5: {
          maxFileSize: (item) => checkMaxFileSize(item),
        },
        trade_date_6: {
          required: requiredIf((item) => isContainFileUpload(item, 6)),
        },
        report_category_6: {
          required: requiredIf((item) => isContainFileUpload(item, 6)),
        },
        deliverable_list_6: {
          maxFileSize: (item) => checkMaxFileSize(item),
        },
        trade_date_7: {
          required: requiredIf((item) => isContainFileUpload(item, 7)),
        },
        report_category_7: {
          required: requiredIf((item) => isContainFileUpload(item, 7)),
        },
        deliverable_list_7: {
          maxFileSize: (item) => checkMaxFileSize(item),
        },
        trade_date_8: {
          required: requiredIf((item) => isContainFileUpload(item, 8)),
        },
        report_category_8: {
          required: requiredIf((item) => isContainFileUpload(item, 8)),
        },
        deliverable_list_8: {
          maxFileSize: (item) => checkMaxFileSize(item),
        },
        trade_date_9: {
          required: requiredIf((item) => isContainFileUpload(item, 9)),
        },
        report_category_9: {
          required: requiredIf((item) => isContainFileUpload(item, 9)),
        },
        deliverable_list_9: {
          maxFileSize: (item) => checkMaxFileSize(item),
        },
        trade_date_10: {
          required: requiredIf((item) => isContainFileUpload(item, 10)),
        },
        report_category_10: {
          required: requiredIf((item) => isContainFileUpload(item, 10)),
        },
        deliverable_list_10: {
          maxFileSize: (item) => checkMaxFileSize(item),
        },
      };
    },
    /**
     * @vuese
     * 費用負担部門の入力ダイアログ用Validation定義
     */
    costBurdenDepartmentValidations() {
      return {
        department: { requiredSelect: required },
        rate: {
          required,
          numeric,
          betweenValue: between(0, 100),
        },
      };
    },
    /**
     * @vuese
     * TOPへ戻るボタンの表示非表示設定
     */
    isShowMoveTop() {
      return true;
    },
    /**
     * @vuese
     * 保存ボタンの表示非表示設定
     * ユーザ権限、案件ステータス、案件区分での入力制御
     */
    isShowSave() {
      return (
        this.isInput &&
        ((this.isNormal &&
          ((this.hasPerm("clientStaff") &&
            this.isClientContact &&
            this.formData.status === statuses.PendingRequests) ||
            (this.hasPerm("adcomStaff", "adcomManager") &&
              this.formData.status <= statuses.ReversalAndCorrectingEntry))) ||
          (this.isApproval &&
            this.hasPerm("adcomStaff") &&
            this.formData.status <= statuses.ReversalAndCorrectingEntry) ||
          (this.isReversalAndCorrecting && this.hasPerm("adcomDuty")))
      );
    },
    /**
     * @vuese
     * ステータスが受注済(50)の場合の原稿ファイル保存ボタンの表示/非表示設定
     * ユーザー権限、案件ステータス、案件分類による入力制御
     */
    isShowFileUploadSave() {
      return (
        this.isInput &&
        this.isNormal &&
        this.hasPerm("clientStaff") &&
        this.isClientContact &&
        this.formData.status === statuses.Ordered
      );
    },
    /**
     * @vuese
     * 見積依頼ボタンの表示非表示設定
     * ユーザ権限、案件ステータス、依頼担当者、案件区分での制御
     */
    isShowRequest() {
      return (
        this.isInput &&
        this.isNormal &&
        this.hasPerm("clientStaff") &&
        this.isClientContact &&
        this.formData.status === statuses.PendingRequests
      );
    },
    /**
     * @vuese
     * 再見積ボタンの表示非表示設定
     * ユーザ権限、案件ステータス、案件区分での制御
     */
    isShowReApplication() {
      return (
        this.isInput &&
        this.isNormal &&
        ((this.hasPerm("clientStaff") &&
          this.isClientContact &&
          (this.formData.status === statuses.CreateQuotation ||
            this.formData.status === statuses.QuotationSubmitted ||
            this.formData.status === statuses.ChangingOrderDetails ||
            this.formData.status === statuses.Ordered)) ||
          (this.hasPerm("adcomStaff", "adcomManager", "adcomProcurer") &&
            this.formData.status === statuses.Ordered))
      );
    },
    /**
     * @vuese
     * 取下げボタンの表示非表示設定
     * ユーザ権限、案件ステータス、案件区分での制御
     */
    isShowWithDrawal() {
      return (
        this.isInput &&
        this.isNormal &&
        this.hasPerm("clientStaff") &&
        this.isClientContact &&
        this.formData.status === statuses.QuotationSubmitted &&
        this.quotationWorkflowStatus === workfrowStatuses.Rejection
      );
    },
    /**
     * @vuese
     * 見積書作成ボタンの表示非表示設定
     * ユーザ権限、案件ステータス、案件区分での制御
     */
    isShowQuotationReportCreate() {
      return (
        this.isInput &&
        this.isNormal &&
        this.hasPerm("adcomStaff", "adcomManager", "adcomProvision") &&
        (this.formData.status === statuses.CreateQuotation ||
          this.formData.status === statuses.ChangingOrderDetails)
      );
    },
    /**
     * @vuese
     * 見積書ボタンの表示非表示設定
     * ユーザ権限、ワークフローステータス、案件区分での制御
     */
    isShowQuotationReport() {
      return (
        this.isNormal &&
        this.isQuotationReportExists &&
        ((this.hasPerm("client") &&
          (this.quotationWorkflowStatus === workfrowStatuses.Approval ||
            this.quotationWorkflowClientStatus !==
              workfrowStatuses.NoWorkfrow)) ||
          this.hasPerm("adcom"))
      );
    },
    /**
     * @vuese
     * 注文請書作成ボタンの表示非表示設定
     * ユーザ権限、案件ステータス、ワークフローステータス、案件区分での制御
     */
    isShowOrderReceiptReportCreate() {
      return (
        this.isInput &&
        !this.isReversalAndCorrecting &&
        !this.isOrderReceiptReportExists &&
        this.hasPerm("adcomStaff") &&
        ((this.formData.status === statuses.QuotationSubmitted &&
          this.quotationWorkflowClientStatus === workfrowStatuses.Approval) ||
          this.isApproval)
      );
    },
    /**
     * @vuese
     * 注文請書ボタンの表示非表示設定
     * ユーザ権限、ワークフローステータス、案件区分での制御
     */
    isShowOrderReceiptReport() {
      return (
        !this.isReversalAndCorrecting &&
        this.isOrderReceiptReportExists &&
        ((this.hasPerm("client") &&
          this.orderReceiptWorkflowStatus === workfrowStatuses.Approval) ||
          this.hasPerm("adcom"))
      );
    },
    /**
     * @vuese
     * 納品確認書作成ボタンの表示非表示設定
     * ユーザ権限、案件ステータス、案件区分、請求区分での制御
     */
    isShowDeliveryReportCreate() {
      return (
        this.isInput &&
        this.isNormal &&
        this.hasPerm("adcomStaff") &&
        this.formData.status === statuses.Ordered &&
        this.formData.billing_category === billingCategorys.Change
      );
    },
    /**
     * @vuese
     * 納品確認書ボタンの表示非表示設定
     * ユーザ権限、ワークフローステータス、案件区分での制御
     */
    isShowDeliveryReport() {
      return (
        this.isNormal &&
        ((this.isDeliveryReportExists &&
          ((this.hasPerm("client") &&
            this.deliveryNoteWorkflowStatus === workfrowStatuses.Approval) ||
            this.hasPerm("adcom"))) ||
          (this.isDeliveryReportRejectExists &&
            ((this.hasPerm("client") &&
              this.deliveryNoteWorkflowStatus === workfrowStatuses.Rejection) ||
              this.hasPerm("adcom"))))
      );
    },
    /**
     * @vuese
     * 検収確認書ボタンの表示非表示設定
     * 案件区分、ワークフローステータスでの制御
     */
    isShowCertificateReport() {
      return this.isNormal && this.isCertificateReportExists;
    },
    /**
     * @vuese
     * 完了取消ボタンの表示非表示設定
     * ユーザ権限、案件ステータス、案件区分での制御
     */
    isShowCompleteCancel() {
      return (
        this.isNormal &&
        this.hasPerm("adcomDuty") &&
        this.formData.status === statuses.Completion
      );
    },
    /**
     * @vuese
     * 案件削除ボタンの表示非表示設定
     * ユーザ権限、案件区分での制御
     */
    isShowCaseDelete() {
      return (
        this.isInput &&
        (this.hasPerm("adcomStaff") ||
          (this.hasPerm("adcomDuty") && this.isReversalAndCorrecting) ||
          (this.hasPerm("clientStaff") &&
            this.formData.status === statuses.PendingRequests &&
            this.isClientContact))
      );
    },
    /**
     * @vuese
     * 案件削除取消ボタンの表示非表示設定
     * ユーザ権限、案件区分、削除フラグでの制御
     */
    isShowCaseDeleteCancel() {
      return (
        this.formData.delete_flag &&
        (this.hasPerm("adcomStaff") ||
          (this.hasPerm("adcomDuty") && this.isReversalAndCorrecting))
      );
    },
    /**
     * @vuese
     * 請求書発行承認ボタンの表示非表示設定
     * ユーザ権限、案件ステータス、案件区分、請求区分での制御
     */
    isShowInvoicingsWorkflow() {
      return (
        this.isNormal &&
        this.formData.billing_category === billingCategorys.Bill &&
        ((this.hasPerm("adcomStaff") &&
          this.formData.status >= statuses.Ordered) ||
          (this.hasPerm("adcomManager") &&
            this.formData.status >= statuses.Ordered))
      );
    },
    /**
     * @vuese
     * 調達部発注書処理ボタンの表示非表示設定
     * ユーザ権限、案件ステータス、案件区分、請求区分での制御
     */
    isShowReturnPurchaseOrdersWorkflow() {
      return (
        this.isNormal &&
        this.formData.billing_category === billingCategorys.OrderForm &&
        ((this.hasPerm("adcomStaff") &&
          this.formData.status >= statuses.Ordered) ||
          (this.hasPerm("adcomManager") &&
            this.formData.status >= statuses.Ordered))
      );
    },
    /**
     * @vuese
     * 社内承認（再完了）ボタンの表示非表示設定
     * ユーザ権限、案件ステータス、ワークフローステータス、案件区分での制御
     */
    isShowRecompletionsWorkflow() {
      return (
        this.formData.status < statuses.Completion &&
        (this.certificateWorkflowStatus === workfrowStatuses.Approval ||
          this.invoicingWorkflowStatus === workfrowStatuses.Approval ||
          this.returnPurchaseOrderWorkflowStatus ===
            workfrowStatuses.Approval) &&
        this.isNormal &&
        ((this.hasPerm("adcomStaff") &&
          this.formData.status >= statuses.Ordered) ||
          (this.hasPerm("adcomManager") &&
            this.formData.status >= statuses.Ordered))
      );
    },
    /**
     * @vuese
     * 社内承認（赤黒）ボタンの表示非表示設定
     * ユーザ権限、案件ステータス、案件区分での制御
     */
    isShowRedBlackCompletionsWorkflow() {
      return (
        this.formData.status < statuses.Completion &&
        this.isReversalAndCorrecting &&
        (this.hasPerm("adcomDuty") || this.hasPerm("adcomManager"))
      );
    },
    /**
     * @vuese
     * 社内承認不要ボタンの表示非表示設定
     * 再完了ボタン表示非表示、ワークフローステータスでの制御
     */
    isShowDirectCompletion() {
      return (
        this.recompletionWorkflowStatus === workfrowStatuses.NoWorkfrow &&
        this.redBlackCompletionWorkflowStatus === workfrowStatuses.NoWorkfrow &&
        (this.isShowRecompletionsWorkflow ||
          this.isShowRedBlackCompletionsWorkflow)
      );
    },
    /**
     * @vuese
     * OBIC連携ボタンの表示非表示設定
     * ユーザ権限、案件ステータスでの制御
     */
    isShowObicAlignment() {
      return (
        this.isInput &&
        this.hasPerm("adcom") &&
        (this.formData.status === statuses.ChangingOrderDetails ||
          this.formData.status === statuses.Ordered ||
          this.formData.status === statuses.Inquiry ||
          this.formData.status === statuses.ReversalAndCorrectingEntry)
      );
    },
    /**
     * @vuese
     * 通常案件か否かの判定
     */
    isNormal() {
      return this.formData.case_category === caseCategorys.Normal;
    },
    /**
     * @vuese
     * 稟議書案件か否かの判定
     */
    isApproval() {
      return this.formData.case_category === caseCategorys.Approval;
    },
    /**
     * @vuese
     * 赤黒案件か否かの判定
     */
    isReversalAndCorrecting() {
      return (
        this.formData.case_category === caseCategorys.reversalAndCorrecting
      );
    },
    /**
     * @vuese
     * 原価のカンマ編集
     */
    totalCostFormatted() {
      return this.formData.total_cost?.toLocaleString() || "";
    },
    /**
     * @vuese
     * 見積額のカンマ編集
     */
    totalSellingPriceFormatted() {
      return this.formData.total_selling_price?.toLocaleString() || "";
    },
    obicTotalValues_total_ex_tax_detail_sell_priceFormatted() {
      return (
        this.obicTotalValues.total_ex_tax_detail_sell_price?.toLocaleString() ||
        ""
      );
    },
    obicTotalValues_total_gross_interestFormatted() {
      return this.obicTotalValues.total_gross_interest?.toLocaleString() || "";
    },
    obicTotalValues_total_costFormatted() {
      return this.obicTotalValues.total_cost?.toLocaleString() || "";
    },
    obicTotalValues_average_gross_interest_rateFormatted() {
      return (
        this.obicTotalValues.average_gross_interest_rate?.toLocaleString() || ""
      );
    },
    obicTotalValues_total_ex_tax_detail_buy_amountFormatted() {
      return (
        this.obicTotalValues.total_ex_tax_detail_buy_amount?.toLocaleString() ||
        ""
      );
    },
    /**
     * @vuese
     * 原価率の計算
     */
    costRatio() {
      if (this.formData.total_cost || this.formData.total_selling_price) {
        if (this.formData.total_selling_price > 0) {
          return (
            Math.round(
              (this.formData.total_cost / this.formData.total_selling_price) *
                100 *
                10
            ) / 10
          );
        }
      }
      return 0;
    },
    /**
     * @vuese
     * アドコム権限の表示非表示設定
     */
    isShowAdcomUser() {
      return this.hasPerm("adcom");
    },
    /**
     * @vuese
     * 依頼部門のコード
     */
    client_department_code() {
      return this.clientDepartment?.code || "";
    },
    /**
     * @vuese
     * 費用負担部門明細の明細初期値
     */
    defaultDetailItem() {
      return {
        department: this.clientDepartment?.id,
        rate:
          100 - this.formData.total_cost_burden_rate > 0
            ? 100 - this.formData.total_cost_burden_rate
            : 0,
      };
    },
    /**
     * @vuese
     * ワークフロー申請中および削除時入力制御
     * 見積書、注文請書、納品確認書、検収確認書、請求書発行承認、調達部からの返送承認、社内完了承認
     * 全てのワークフローが動いておらずステータスが60以下で案件に削除フラグが立っていない
     */
    isInput() {
      return (
        this.quotationWorkflowStatus !== workfrowStatuses.Unapproved &&
        this.orderReceiptWorkflowStatus !== workfrowStatuses.Unapproved &&
        this.deliveryNoteWorkflowStatus !== workfrowStatuses.Unapproved &&
        this.certificateWorkflowStatus !== workfrowStatuses.Unapproved &&
        this.invoicingWorkflowStatus !== workfrowStatuses.Unapproved &&
        this.returnPurchaseOrderWorkflowStatus !==
          workfrowStatuses.Unapproved &&
        this.redBlackCompletionWorkflowStatus !== workfrowStatuses.Unapproved &&
        this.recompletionWorkflowStatus !== workfrowStatuses.Unapproved &&
        this.formData.status < statuses.CreateDeliveryNote &&
        !this.formData.delete_flag
      );
    },
    /**
     * @vuese
     * クライアント担当者の制限
     */
    isClientContact() {
      return (
        this.clientUser?.id === this.currentUser?.id ||
        this.clientUser?.proxy_user === this.currentUser?.id
      );
    },
    /**
     * @vuese
     * 見積書の存在有無
     */
    isQuotationReportExists() {
      return this.formData.quotation_id !== null;
    },
    /**
     * @vuese
     * 注文請書の存在有無
     */
    isOrderReceiptReportExists() {
      return this.orderReceipt && this.orderReceipt.length > 0;
    },
    /**
     * @vuese
     * 納品確認書の存在有無
     */
    isDeliveryReportExists() {
      return this.deliveryNote && this.deliveryNote.length > 0;
    },
    /**
     * @vuese
     * 却下された納品確認書の存在有無
     */
    isDeliveryReportRejectExists() {
      return this.deliveryNoteReject && this.deliveryNoteReject.length > 0;
    },
    /**
     * @vuese
     * 社内承認（再完了）フローの存在有無
     */
    isRecompletionExists() {
      return this.recompletionData && this.recompletionData.length > 0;
    },
    /**
     * @vuese
     * 納品確認書のID
     */
    DeliveryNoteId() {
      if (this.deliveryNote && this.deliveryNote.length > 0) {
        return this.deliveryNote[0].id;
      }
      if (this.deliveryNoteReject && this.deliveryNoteReject.length > 0) {
        // TODO:要確認：直前に却下された納品確認書のID取得のため、最後尾データを参照
        return this.deliveryNoteReject.slice(-1)[0].id;
      }
      return 0;
    },
    /**
     * @vuese
     * 検収確認書の存在有無
     */
    isCertificateReportExists() {
      return this.certificate && this.certificate.length > 0;
    },
    /**
     * @vuese
     * 直送先の手入力項目制御
     * @type String
     */
    isDeliveryDestinationManualInput() {
      return (
        this.propsStandardItem &&
        this.selectedDeliveryDestination.description_flag
      );
    },
    /**
     * @vuese
     * カタログ倉庫＋依頼課保管分の手入力項目制御
     * @type String
     */
    isStorageNumberInput() {
      return (
        this.propsStandardItem && this.selectedDeliveryDestination.code === "3"
      );
    },
    /**
     * @vuese
     * パネルサイズ入力の手動入力項目管理
     * @type String
     */
    isPanelSizeInputOther() {
      return this.propsStandardItem && this.selectedPanelSize.description_flag;
    },
    /**
     * @vuese
     * プレートサイズ入力の手動入力項目管理
     * @type String
     */
    isPlateSizeInputOther() {
      return this.propsStandardItem && this.selectedPlateSize.description_flag;
    },
    /**
     * @vuese
     * カラー入力の手動入力項目制御
     * @type String
     */
    isColorInputOther() {
      return this.propsStandardItem && this.selectedColor.description_flag;
    },
    /**
     * @vuese
     * 納品ファイルの項目制御
     * @type String
     */
    isProductFile() {
      return this.isInput
        ? this.hasPerm("adcomStaff") && this.formData.status <= statuses.Ordered
        : false;
    },
    /**
     * @vuese
     * 標準項目入力項目制御
     */
    propsStandardItem() {
      return this.isInput
        ? (this.hasPerm("clientStaff") &&
            this.isClientContact &&
            this.formData.status <= statuses.Ordered) ||
            (this.hasPerm("adcomStaff") &&
              this.formData.status === statuses.PendingRequests) ||
            this.isApproval ||
            (this.isReversalAndCorrecting && this.hasPerm("adcomDuty"))
        : false;
    },
    /**
     *  @vuese
     * 費目 セクションでステータスが 50 以下の値にアクセス制御
     */
    propsExtraItem() {
      return this.isInput
        ? this.formData.status <= statuses.Ordered && this.hasPerm("adcomStaff")
        : false;
    },
    /**
     *  @vuese
     * 費目 部分のみ制御
     */
    propsNewItem() {
      return this.propsStandardItem || this.propsExtraItem;
    },
    /**
     * @vuese
     * OBIC関連テーブル入力項目制御
     */
    propsObicTable() {
      return this.isInput
        ? this.hasPerm("adcomStaff", "adcomProvision") &&
            this.formData.status < statuses.CreateDeliveryNote
        : false;
    },
    /**
     * @vuese
     * OBIC関連テーブル入力項目制御（編集）
     */
    canEditObicTableRow() {
      if (
        this.isInput &&
        this.hasPerm("adcomStaff", "adcomProvision") &&
        this.formData.status < statuses.CreateDeliveryNote
      ) {
        return function (item) {
          return item.order_category !== "12";
        };
      }
      return false;
    },
    /**
     * @vuese
     * 依頼情報明細（案件内訳情報）入力制御
     */
    propsCaseDetail() {
      return this.isInput
        ? ((this.hasPerm("adcomStaff") || this.hasPerm("adcomProvision")) &&
            this.formData.status !== statuses.QuotationSubmitted &&
            this.formData.status !== statuses.Ordered &&
            !this.isReversalAndCorrecting) ||
            (this.isReversalAndCorrecting && this.hasPerm("adcomDuty"))
        : false;
    },
    /**
     * @vuese
     * アドコム担当者入力項目制御
     */
    propsContactUser() {
      return this.isInput
        ? ((this.hasPerm("adcomStaff") || this.hasPerm("adcomManager")) &&
            this.formData.status <= statuses.Inquiry) ||
            (this.isReversalAndCorrecting && this.hasPerm("adcomDuty"))
        : false;
    },
    /**
     * @vuese
     * 希望納期の超過
     */
    isOverDays() {
      return (
        this.$dateStr() > this.formData.preferred_due_date &&
        this.formData.status < statuses.Completion
      );
    },
    /**
     * @vuese
     * 納品完了確認
     */
    isDetailCompleted() {
      return this.is_detail_completed;
    },
    isOrdered() {
      return this.formData.status === statuses.Ordered;
    },
    /**
     * @vuese
     * 費用負担部門、率のチェック結果
     */
    isCodeAndRateCheck1() {
      return this.resultCodeAndRateCheck;
    },
  },
  methods: {
    /**
     * @vuese
     * 案件データ取得
     * @arg id - 案件id
     */
    async fetchData(id) {
      this.loading = true;
      this.$v.$touch();
      try {
        [
          this.caseData,
          this.orderReceipt,
          this.obicCostBurdenDepartments,
          this.obicTotalValues,
          this.invoicingData,
          this.recompletionData,
          this.redBlackCompletionData,
          this.returnPurchaseOrderData,
        ] = await Promise.all([
          this.$api.getOne("cases", id),
          this.$api.getMany("orderReceipts", {
            case: id,
            rejected_flag: false,
          }),
          this.$api.dispatch(
            "GET",
            "cases",
            "get_obic_cost_burden_departments",
            id
          ),
          this.$api.dispatch("GET", "cases", "get_obic_total_values", id),
          this.$api.getMany("invoicings", { case: id, rejected_flag: false }),
          this.$api.getMany("recompletions", {
            case: id,
            rejected_flag: false,
          }),
          this.$api.getMany("redBlackCompletions", {
            case: id,
            rejected_flag: false,
          }),
          this.$api.getMany("returnPurchaseOrders", {
            case: id,
            rejected_flag: false,
          }),
        ]);

        await this.join(this.caseData, [
          { name: "case_detail", resource: "caseDetails" },
        ]);
        this.$utils.update(this.formData, this.caseData);
        // instead of single data, we take the array of data and push it into new draftFileData
        if (this.caseData.draft_files.length) {
          this.draftFileData = [...this.caseData.draft_files];
        } else {
          this.draftFileData = [];
        }
        if (this.draftFileData.length !== null) {
          for (let i = 0; i < this.draftFileData.length; i++) {
            this.formData.draft_file[i].link = this.draftFileData[i].draft_file;
          }
        }
        this.adSpecsFileData = this.caseData.ad_sepecs_files[0] || {};
        if (this.adSpecsFileData.ad_sepecs_file) {
          this.formData.ad_sepecs_file.link =
            this.adSpecsFileData.ad_sepecs_file;
        }
        this.draftFileLinkData = this.caseData.draft_file_links[0] || {};
        if (this.draftFileLinkData.draft_file_link) {
          this.formData.draft_file_link.link =
            this.draftFileLinkData.draft_file_link;
        }
        this.productFileData = this.caseData.deliverables?.[0] || {};
        if (this.productFileData?.file_name) {
          this.formData.product_file.link = this.productFileData.file_name;
        }
        this.deliveryFileLinkData = this.caseData.delivery_file_links[0] || {};
        if (this.deliveryFileLinkData.delivery_file_link) {
          this.formData.delivery_file_link.link =
            this.deliveryFileLinkData.delivery_file_link;
        }
        [this.clientUser, this.clientDepartment] = await Promise.all([
          this.$api.getOne("users", this.formData.client_user),
          this.$api.getOne("departments", this.formData.client_department),
        ]);
        if (this.formData.delivery_destination) {
          [this.selectedDeliveryDestination] = await Promise.all([
            (this.selectedDeliveryDestination = this.$api.getOne(
              "deliveryDestinations",
              this.formData.delivery_destination
            )),
          ]);
        }
        if (this.formData.plate_size) {
          [this.selectedPlateSize] = await Promise.all([
            (this.selectedPlateSize = this.$api.getOne(
              "plateSizes",
              this.formData.plate_size
            )),
          ]);
        }
        if (this.formData.panel_size) {
          [this.selectedPanelSize] = await Promise.all([
            (this.selectedPanelSize = this.$api.getOne(
              "panelSizes",
              this.formData.panel_size
            )),
          ]);
        }
        if (this.formData.color) {
          [this.selectedColor] = await Promise.all([
            (this.selectedColor = this.$api.getOne(
              "colors",
              this.formData.color
            )),
          ]);
        }
        if (this.formData.ad_format) {
          [this.selectedAdFormat] = await Promise.all([
            (this.selectedAdFormat = this.$api.getOne(
              "adFormats",
              this.formData.ad_format
            )),
          ]);
        }
        if (this.formData.quotation_id) {
          [this.deliveryNote, this.certificate, this.deliveryNoteReject] =
            await Promise.all([
              this.$api.getMany("deliveryNotes", {
                quotation: this.formData.quotation_id,
                rejected_flag: false,
              }),
              this.$api.getMany("certificates", {
                quotation: this.formData.quotation_id,
                rejected_flag: false,
              }),
              this.$api.getMany("deliveryNotes", {
                quotation: this.formData.quotation_id,
                rejected_flag: true,
              }),
            ]);
        }
        if (this.formData.request_count && this.formData.storage_number) {
          this.formData.catalog_storage_quantity =
            this.formData.request_count - this.formData.storage_number;
        }
        await Promise.all([
          this.getQuotationWorkflowStatus(),
          this.getQuotationWorkflowClientStatus(),
          this.getOrderReceiptWorkflowStatus(),
          this.getDeliveryNoteWorkflowStatus(),
          this.getCertificateWorkflowStatus(),
          this.getRecompletionWorkflowStatus(),
          this.getInvoicingWorkflowStatus(),
          this.getReturnPurchaseOrderWorkflowStatus(),
          this.getRedBlackCompletionsWorkflow(),
          // this.getIsDetailCompleted(),
        ]);
        this.formatting();
        // this.displayFormat();
      } finally {
        this.loading = false;
      }
    },
    async caseTotal() {
      this.caseData = await this.$api.getOne("cases", this.id);
      this.formData.total_cost = this.caseData.total_cost;
      this.formData.total_selling_price = this.caseData.total_selling_price;
      this.formData.total_cost_burden_rate =
        this.caseData.total_cost_burden_rate;
    },
    /**
     * @vuese
     * 納品先 選択時メソッド
     * @arg item - 納品先選択時情報
     */
    deliveryDestinationSelected(item) {
      this.selectedDeliveryDestination = item;
    },
    /**
     * @vuese
     * 版型・サイズ選択時メソッド
     * @arg item - 版型・サイズ選択時情報
     */
    plateSizeSelected(item) {
      this.selectedPlateSize = item;
    },
    /**
     * *@vuese
     * * 言言語種別選択時の規格サイズ変更
     * ToDo: idで比較しない、テーブルのサイズを参照する
     * id=1 and size(規格サイズ)= 'A4' for '日本語'
     * id=2 and size(規格サイズ)= '国際版' for '英語'
     */

    sizeLanguageToggle(item) {
      item.size ? (this.formData.size = item.size) : "";
    },
    /**
     * @vuese
     * 原稿形態選択時メソッド
     * @arg item - 原稿形態選択時情報
     */
    adFormatSelected(item) {
      this.selectedAdFormat = item;
    },
    /**
     * @vuese
     * 原稿色選択時メソッド
     * @arg item - 原稿色選択時情報
     */
    colorSelected(item) {
      this.selectedColor = item;
    },
    /**
     * @vuese
     * パネルサイズ選択時メソッド
     * @arg item - パネルサイズ選択時情報
     */
    panelSelected(item) {
      this.selectedPanelSize = item;
    },
    /**
     * @vuese
     * アドコム担当者選択時メソッド
     * @arg item - アドコム担当者選択時情報
     */
    contactUserSelected(item) {
      this.selectedContactUser = item;
      this.formData.contact_department = item.department.id;
    },
    /**
     * @vuese
     * ToDo: Web Contact user
     * for email send
     */
    webContactUserSelected(item) {
      this.selectedWebContactUser = item;
    },
    /**
     * @vuese
     * ToDO: 依頼数とカタログ倉庫部数の差
     */
    requestCountStorageAmountDifferemce() {
      if (this.formData.request_count && this.formData.storage_number) {
        if (
          !isNaN(this.formData.request_count) &&
          !isNaN(this.formData.storage_number)
        ) {
          this.formData.catalog_storage_quantity =
            this.formData.request_count - this.formData.storage_number;
        }
      } else {
        this.formData.catalog_storage_quantity = null;
      }
    },
    /**
     * @vuese
     * 画面項目制御情報初期化メソッド
     */
    formatting() {
      this.general =
        this.formData.request_category === requestCategorys.Catalog;
      this.invoicing = this.formData.billing_category === billingCategorys.Bill;
    },
    /**
     * @vuese
     * ToDo: To show/hide particular row's delete button as per the file uploaded of Case Detail Datatable
     */
    caseDetailParticularRowDeleteButton(item) {
      const hasExternalVendorQuotation =
        item?.external_vendor_quotation_1 ||
        item?.external_vendor_quotation_2 ||
        item?.external_vendor_quotation_3 ||
        item?.external_vendor_quotation_4 ||
        item?.external_vendor_quotation_5 ||
        item?.external_vendor_quotation_6 ||
        item?.external_vendor_quotation_7 ||
        item?.external_vendor_quotation_8 ||
        item?.external_vendor_quotation_9 ||
        item?.external_vendor_quotation_10
          ? true
          : false;
      return hasExternalVendorQuotation;
    },
    /**
     * @vuese
     * ToDo: To show/hide particular row's delete button as per the file uploaded of Order Detail Datatable
     */
    orderDetailParticularRowDeleteButton(item) {
      const hasDeliverableListFile =
        item?.deliverable_list ||
        item?.deliverable_list_2 ||
        item?.deliverable_list_3 ||
        item?.deliverable_list_4 ||
        item?.deliverable_list_5 ||
        item?.deliverable_list_6 ||
        item?.deliverable_list_7 ||
        item?.deliverable_list_8 ||
        item?.deliverable_list_9 ||
        item?.deliverable_list_10
          ? true
          : false;
      return hasDeliverableListFile;
    },
    /**
     * @vuese
     * 見積依頼メソッド
     */
    async request() {
      this.$v.formData.$touch();
      if (!this.$v.formData.$invalid) {
        if (
          await this.$refs.confirm.open(
            this.$t("actions.confirm"),
            this.$t("messages.actions.confirmAddQuotation")
          )
        ) {
          this.formData.status = statuses.CreateQuotation;
          await this.save("messages.success.quotationRequested");
        }
      }
    },
    /**
     * @vuese
     * toDO: to Send Email and save case for Web_Contact_user
     */
    async saveCaseAndWebContactEmail(
      messageSource = "messages.success.caseSavedAndEmailSend"
    ) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (
          await this.$refs.confirm.open(
            this.$t("actions.confirm"),
            this.$t("messages.actions.confirmSendEmail")
          )
        ) {
          this.loading = true;
          const {
            ad_sepecs_file,
            draft_file,
            product_file,
            delivery_file_link,
            draft_file_link,
            ...caseData
          } = this.formData;
          try {
            const updated = await this.$api.httpClient(
              "PATCH",
              `${this.$options.resource}/${this.id}/`,
              caseData,
              { params: { "web-contact-user": true } }
            );
            this.$utils.update(this.formData, updated.data);
          } catch {
            this.loading = false;
            return;
          }
          // ToDO: multiple api function call for draft_file delete, update, and create
          try {
            if (draft_file.length) {
              const updatePromises = [];
              const createPromises = [];
              for (const file of draft_file) {
                if (file) {
                  if (file.deleted && file.file === null) {
                    const idx = this.draftFileData.findIndex(
                      (d) => file.link === d.draft_file
                    );
                    if (idx > -1) {
                      await this.forDraftFileDeleteAPI(
                        file,
                        this.draftFileData[idx].id
                      );
                      this.draftFileData[idx] = {
                        file: null,
                        link: null,
                        deleted: false,
                      };
                    }
                  } else if (file.file !== null) {
                    const draftFileData = this.draftFileData.find(
                      (data) => data.draft_file === file.link
                    );
                    if (draftFileData) {
                      updatePromises.push(
                        this.forDraftFileUpdateAPI(file, draftFileData.id)
                      );
                    } else if (file.link === null) {
                      createPromises.push(
                        this.forDraftFileCreateAPI(file, this.id)
                      );
                    }
                  }
                }
              }
              if (updatePromises.length) {
                let updatedDraftFileData = await Promise.all(updatePromises);
                for (let i = 0; i < this.draftFileData.length; i++) {
                  for (let j = 0; j < updatedDraftFileData.length; j++) {
                    if (
                      this.draftFileData[i].id === updatedDraftFileData[j].id
                    ) {
                      this.draftFileData[i].draft_file =
                        updatedDraftFileData[j].draft_file;
                    }
                  }
                }
              }
              if (createPromises.length) {
                this.draftFileData = this.draftFileData.concat(
                  await Promise.all(createPromises)
                );
              }
            }
          } finally {
            let newFiles = [];
            for (let i = 0; i < this.draftFileData.length; i++) {
              if (this.draftFileData[i].link === null) {
                newFiles[i] = {
                  link: null,
                  file: null,
                  deleted: false,
                };
              } else {
                newFiles[i] = {
                  link: this.draftFileData[i]?.draft_file,
                  file: null,
                  deleted: false,
                };
              }
            }
            newFiles = newFiles.filter((nf) => nf.link !== null);
            this.formData.draft_file = this.formData.draft_file = [...newFiles];
            this.$refs.draftFileInput1?.clear();
            this.$refs.draftFileInput2?.clear();
            this.$refs.draftFileInput3?.clear();
            this.$refs.draftFileInput4?.clear();
            this.$refs.draftFileInput5?.clear();
          }
          try {
            let adSpecsFileId = this.adSpecsFileData.id;
            const { file, deleted } = ad_sepecs_file;
            if (deleted && adSpecsFileId) {
              // 広告仕様書を削除する
              await this.$api.delete("adSpecsFiles", adSpecsFileId);
              this.adSpecsFileData = {};
              adSpecsFileId = null;
            }
            if (file) {
              const adSpecsFileData = new FormData();
              if (adSpecsFileId) {
                // 広告仕様書の更新
                adSpecsFileData.append("ad_sepecs_file", file);
                adSpecsFileData.append("version", this.adSpecsFileData.version);
                const updatedAdSpecsFile = await this.$api.update(
                  "adSpecsFiles",
                  adSpecsFileId,
                  adSpecsFileData,
                  true
                );
                this.adSpecsFileData = updatedAdSpecsFile;
              } else {
                // 広告仕様書記録作成
                adSpecsFileData.append("case", this.id);
                adSpecsFileData.append("ad_sepecs_file", file);
                const createdAdSpecsFile = await this.$api.create(
                  "adSpecsFiles",
                  adSpecsFileData,
                  true
                );
                this.adSpecsFileData = createdAdSpecsFile;
              }
            }
          } finally {
            this.formData.ad_sepecs_file = {
              link: this.adSpecsFileData?.ad_sepecs_file,
              file: null,
              deleted: false,
            };
            this.$refs.adSpecsFileInput?.clear();
            this.loading = false;
          }
          try {
            let draftFileLinkId = this.draftFileLinkData.id;
            const { file, deleted } = draft_file_link;
            if (deleted && draftFileLinkId) {
              // 広告仕様書を削除する
              await this.$api.delete("draftFileLinks", draftFileLinkId);
              this.draftFileLinkData = {};
              draftFileLinkId = null;
            }
            if (file) {
              const draftFileLinkData = new FormData();
              if (draftFileLinkId) {
                // 広告仕様書の更新
                draftFileLinkData.append("draft_file_link", file);
                draftFileLinkData.append(
                  "version",
                  this.draftFileLinkData.version
                );
                const updatedDraftFileLink = await this.$api.update(
                  "draftFileLinks",
                  draftFileLinkId,
                  draftFileLinkData,
                  true
                );
                this.draftFileLinkData = updatedDraftFileLink;
              } else {
                // 広告仕様書記録作成
                draftFileLinkData.append("case", this.id);
                draftFileLinkData.append("draft_file_link", file);
                const createdDraftFileLink = await this.$api.create(
                  "draftFileLinks",
                  draftFileLinkData,
                  true
                );
                this.draftFileLinkData = createdDraftFileLink;
              }
            }
          } finally {
            this.formData.draft_file_link = {
              link: this.draftFileLinkData?.draft_file_link,
              file: null,
              deleted: false,
            };
            this.$refs.draftFileLinkInput?.clear();
            this.loading = false;
          }
          try {
            let deliveryFileLinkId = this.deliveryFileLinkData.id;
            const { file, deleted } = delivery_file_link;
            if (deleted && deliveryFileLinkId) {
              // 広告仕様書を削除する
              await this.$api.delete("deliveryFileLinks", deliveryFileLinkId);
              this.deliveryFileLinkData = {};
              deliveryFileLinkId = null;
            }
            if (file) {
              const deliveryFileLinkData = new FormData();
              if (deliveryFileLinkId) {
                // 広告仕様書の更新
                deliveryFileLinkData.append("delivery_file_link", file);
                deliveryFileLinkData.append(
                  "version",
                  this.deliveryFileLinkData.version
                );
                const updateddeliveryFileLink = await this.$api.update(
                  "deliveryFileLinks",
                  deliveryFileLinkId,
                  deliveryFileLinkData,
                  true
                );
                this.deliveryFileLinkData = updateddeliveryFileLink;
              } else {
                // 広告仕様書記録作成
                deliveryFileLinkData.append("case", this.id);
                deliveryFileLinkData.append("delivery_file_link", file);
                const createddeliveryFileLink = await this.$api.create(
                  "deliveryFileLinks",
                  deliveryFileLinkData,
                  true
                );
                this.deliveryFileLinkData = createddeliveryFileLink;
              }
            }
          } finally {
            this.formData.delivery_file_link = {
              link: this.deliveryFileLinkData?.delivery_file_link,
              file: null,
              deleted: false,
            };
            this.$refs.deliveryFileLinkInput?.clear();
            this.loading = false;
          }
          try {
            let productFileId = this.productFileData.id;
            const { file, deleted } = product_file;
            if (deleted && productFileId) {
              // 納品ファイルレコード削除
              await this.$api.delete("deliverables", productFileId);
              this.draftFileData = {};
              productFileId = null;
            }

            if (file) {
              const productFileData = new FormData();
              if (productFileId) {
                // 納品ファイルレコード更新
                productFileData.append("file_name", file);
                productFileData.append("version", this.productFileData.version);
                const updatedDraftFile = await this.$api.update(
                  "deliverables",
                  productFileId,
                  productFileData,
                  true
                );
                this.productFileData = updatedDraftFile;
              } else {
                // 納品ファイルレコード作成
                productFileData.append("case", this.id);
                productFileData.append("file_name", file);
                const createdFileName = await this.$api.create(
                  "deliverables",
                  productFileData,
                  true
                );
                this.productFileData = createdFileName;
              }
            }
          } finally {
            this.formData.product_file = {
              link: this.productFileData?.file_name,
              file: null,
              deleted: false,
            };
            this.$refs.productFileInput?.clear();
            this.loading = false;
          }
          this.$v.$reset();
          this.loading = false;
          this.$message.success(this.$t(messageSource));
        }
      }
    },
    /**
     * @vuese
     * 再見積メソッド
     */
    async reApplication() {
      this.$v.formData.$touch();
      if (!this.$v.formData.$invalid) {
        if (
          await this.$refs.confirm.open(
            this.$t("actions.confirm"),
            this.hasPerm("adcom")
              ? this.$t("messages.actions.confirmReQuotationAdcom")
              : this.$t("messages.actions.confirmReQuotation")
          )
        ) {
          switch (this.formData.status) {
            case statuses.QuotationSubmitted:
              this.formData.status = statuses.CreateQuotation;
              break;
            case statuses.Ordered:
              this.formData.status = statuses.ChangingOrderDetails;
              break;
          }
          await this.save();
          await this.$api.dispatch(
            "PUT",
            "cases",
            "update_quotation_report",
            this.id,
            this.formData
          );
        }
      }
    },
    /**
     * @vuese
     * 履歴一覧遷移メソッド
     */
    showHistory(id) {
      this.$router.push({
        name: `history`,
        params: { id },
      });
    },
    /**
     * @vuese
     * 見積明細関連項目の初期化メソッド
     */
    itemAmountFormat(item) {
      item.cost = 0;
      item.selling_price = 0;
    },
    /**
     * @vuese
     * ToDO: to make th file upload sections in the initialState at the time of opening the caseDetailDataTable
     */
    async caseDetailDataTableOpen() {
      this.showMultipleCaseDetailFile = false;
    },
    /**
     * @vuese
     * ToDO: checks if the authentic supplier_name is present and assign the value to the variable, while we open the orderDetails dataTable
     */
    async checkSupplierNameAndFileUploads(item) {
      this.orderDetailsContainsObicSupplierName =
        item.supplier_name?.includes("島津アドコム") ||
        item.supplier_name?.includes("SGS");
      const lists = [
        item.deliverable_list,
        item.deliverable_list_2,
        item.deliverable_list_3,
        item.deliverable_list_4,
        item.deliverable_list_5,
        item.deliverable_list_6,
        item.deliverable_list_7,
        item.deliverable_list_8,
        item.deliverable_list_9,
        item.deliverable_list_10,
      ];
      this.isDeliveryListFile = await lists.some((item) => item);
      this.showMultipleorderDetailFile = false; //ToDo: to make the file upload sections in the initialState at the time of opening it
    },
    /**
     * @vuese
     * 受注明細の完了日設定メソッド
     */
    async finishDate(item) {
      let obicDataValue =
        this.orderDetailsContainsObicSupplierName ||
        (await this.toggleObicDataValue(item));
      item.finish_flag = obicDataValue && item.finish_flag;
      item.finish_date = item.finish_flag ? this.$dateStr() : null;
    },
    /**
     * @vuese
     * 受注明細のアップロードファイル状態
     */
    async toggleObicDataValue(item) {
      const lists = [
        item.deliverable_list,
        item.deliverable_list_2,
        item.deliverable_list_3,
        item.deliverable_list_4,
        item.deliverable_list_5,
        item.deliverable_list_6,
        item.deliverable_list_7,
        item.deliverable_list_8,
        item.deliverable_list_9,
        item.deliverable_list_10,
      ];
      const invalid_flags = [
        item.invalid_flag_1,
        item.invalid_flag_2,
        item.invalid_flag_3,
        item.invalid_flag_4,
        item.invalid_flag_5,
        item.invalid_flag_6,
        item.invalid_flag_7,
        item.invalid_flag_8,
        item.invalid_flag_9,
        item.invalid_flag_10,
      ];
      const nonEmptyLists = lists.filter(
        ({ file, deleted, link }) => file || deleted || link
      );
      const numberOfNonEmptyLists = nonEmptyLists.length;
      const numberOfInvalidFlags = invalid_flags.filter(
        (flag) => flag === true
      ).length;
      const isAllDeliverableFileInvalid =
        numberOfNonEmptyLists === numberOfInvalidFlags;

      this.isDeliveryListFile = await lists.some(
        ({ file, deleted, link }) =>
          (file || deleted || link) && (!deleted || file)
      );
      return this.isDeliveryListFile && !isAllDeliverableFileInvalid;
    },
    /**
     * @vuese
     * 受注明細の完了フラグ制御
     */
    async inputFunc(item) {
      if ((await this.toggleObicDataValue(item)) === false) {
        item.finish_flag = false;
        item.finish_date = null;
      }
    },
    /**
     * @vuese
     * 移動メソッド
     */
    async moveTop() {
      if (this.hasPerm("adcom")) {
        await this.$router.push({
          name: "caseList",
        });
      } else {
        await this.$router.push({
          name: "quotationList",
        });
      }
    },
    /**
     * @vuese
     * ToDO:複数保存メソッド
     * 案件データおよび原稿、納品ファイルの更新処理
     */
    forDraftFileDeleteAPI(fileObject, id) {
      const { deleted } = fileObject;
      if (deleted && id) {
        return this.$api.delete("draftFiles", id);
      }
    },
    forDraftFileUpdateAPI(fileObject, id) {
      const { file } = fileObject;
      const draftFileData = new FormData();
      if (id) {
        draftFileData.append("draft_file", file);
        return this.$api.update("draftFiles", id, draftFileData, true);
      }
    },
    forDraftFileCreateAPI(fileObject, id) {
      const { file } = fileObject;
      const draftFileData = new FormData();
      draftFileData.append("case", id);
      draftFileData.append("draft_file", file);
      return this.$api.create("draftFiles", draftFileData, true);
    },

    /**
     * @vuese
     * 保存ボタンの保存メソッド
     * 案件データおよび原稿、納品ファイルの更新処理, また  チェックも行います。
     */
    async saveWhileSaveButtonClick(messageSource = "messages.success.create") {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        //ToDO: 案件とOBICの部門コードに関して、率に差異があります。
        if (
          this.hasPerm("adcom") &&
          this.isNormal &&
          this.isOrdered &&
          !this.isCodeAndRateCheck1 &&
          this.formData.billing_category === "1" &&
          !(await this.$root.$confirm(
            "actions.confirm",
            "resources.cases.messages.NotRateAndCodeEqualConfirm"
          ))
        ) {
          return;
        }
        this.loading = true;
        const {
          ad_sepecs_file,
          draft_file,
          product_file,
          delivery_file_link,
          draft_file_link,
          ...caseData
        } = this.formData;
        try {
          const updated = await this.$api.update(
            this.$options.resource,
            this.id,
            caseData
          );
          this.$utils.update(this.formData, updated);
        } catch {
          this.loading = false;
          return;
        }
        // ToDO: multiple api function call for draft_file delete, update, and create
        try {
          if (draft_file.length) {
            const updatePromises = [];
            const createPromises = [];
            for (const file of draft_file) {
              if (file) {
                if (file.deleted && file.file === null) {
                  const idx = this.draftFileData.findIndex(
                    (d) => file.link === d.draft_file
                  );
                  if (idx > -1) {
                    await this.forDraftFileDeleteAPI(
                      file,
                      this.draftFileData[idx].id
                    );
                    this.draftFileData[idx] = {
                      file: null,
                      link: null,
                      deleted: false,
                    };
                  }
                } else if (file.file !== null) {
                  const draftFileData = this.draftFileData.find(
                    (data) => data.draft_file === file.link
                  );
                  if (draftFileData) {
                    updatePromises.push(
                      this.forDraftFileUpdateAPI(file, draftFileData.id)
                    );
                  } else if (file.link === null) {
                    createPromises.push(
                      this.forDraftFileCreateAPI(file, this.id)
                    );
                  }
                }
              }
            }
            if (updatePromises.length) {
              let updatedDraftFileData = await Promise.all(updatePromises);
              for (let i = 0; i < this.draftFileData.length; i++) {
                for (let j = 0; j < updatedDraftFileData.length; j++) {
                  if (this.draftFileData[i].id === updatedDraftFileData[j].id) {
                    this.draftFileData[i].draft_file =
                      updatedDraftFileData[j].draft_file;
                  }
                }
              }
            }
            if (createPromises.length) {
              this.draftFileData = this.draftFileData.concat(
                await Promise.all(createPromises)
              );
            }
          }
        } catch {
          this.loading = false;
          return;
        } finally {
          let newFiles = [];
          for (let i = 0; i < this.draftFileData.length; i++) {
            if (this.draftFileData[i].link === null) {
              newFiles[i] = {
                link: null,
                file: null,
                deleted: false,
              };
            } else {
              newFiles[i] = {
                link: this.draftFileData[i]?.draft_file,
                file: null,
                deleted: false,
              };
            }
          }
          newFiles = newFiles.filter((nf) => nf.link !== null);
          this.formData.draft_file = this.formData.draft_file = [...newFiles];
          this.$refs.draftFileInput1?.clear();
          this.$refs.draftFileInput2?.clear();
          this.$refs.draftFileInput3?.clear();
          this.$refs.draftFileInput4?.clear();
          this.$refs.draftFileInput5?.clear();
        }
        try {
          let adSpecsFileId = this.adSpecsFileData.id;
          const { file, deleted } = ad_sepecs_file;
          if (deleted && adSpecsFileId) {
            // 広告仕様書を削除する
            await this.$api.delete("adSpecsFiles", adSpecsFileId);
            this.adSpecsFileData = {};
            adSpecsFileId = null;
          }
          if (file) {
            const adSpecsFileData = new FormData();
            if (adSpecsFileId) {
              // 広告仕様書の更新
              adSpecsFileData.append("ad_sepecs_file", file);
              adSpecsFileData.append("version", this.adSpecsFileData.version);
              const updatedAdSpecsFile = await this.$api.update(
                "adSpecsFiles",
                adSpecsFileId,
                adSpecsFileData,
                true
              );
              this.adSpecsFileData = updatedAdSpecsFile;
            } else {
              // 広告仕様書記録作成
              adSpecsFileData.append("case", this.id);
              adSpecsFileData.append("ad_sepecs_file", file);
              const createdAdSpecsFile = await this.$api.create(
                "adSpecsFiles",
                adSpecsFileData,
                true
              );
              this.adSpecsFileData = createdAdSpecsFile;
            }
          }
        } finally {
          this.formData.ad_sepecs_file = {
            link: this.adSpecsFileData?.ad_sepecs_file,
            file: null,
            deleted: false,
          };
          this.$refs.adSpecsFileInput?.clear();
          this.loading = false;
        }
        try {
          let draftFileLinkId = this.draftFileLinkData.id;
          const { file, deleted } = draft_file_link;
          if (deleted && draftFileLinkId) {
            // 広告仕様書を削除する
            await this.$api.delete("draftFileLinks", draftFileLinkId);
            this.draftFileLinkData = {};
            draftFileLinkId = null;
          }
          if (file) {
            const draftFileLinkData = new FormData();
            if (draftFileLinkId) {
              // 広告仕様書の更新
              draftFileLinkData.append("draft_file_link", file);
              draftFileLinkData.append(
                "version",
                this.draftFileLinkData.version
              );
              const updatedDraftFileLink = await this.$api.update(
                "draftFileLinks",
                draftFileLinkId,
                draftFileLinkData,
                true
              );
              this.draftFileLinkData = updatedDraftFileLink;
            } else {
              // 広告仕様書記録作成
              draftFileLinkData.append("case", this.id);
              draftFileLinkData.append("draft_file_link", file);
              const createdDraftFileLink = await this.$api.create(
                "draftFileLinks",
                draftFileLinkData,
                true
              );
              this.draftFileLinkData = createdDraftFileLink;
            }
          }
        } finally {
          this.formData.draft_file_link = {
            link: this.draftFileLinkData?.draft_file_link,
            file: null,
            deleted: false,
          };
          this.$refs.draftFileLinkInput?.clear();
          this.loading = false;
        }
        try {
          let deliveryFileLinkId = this.deliveryFileLinkData.id;
          const { file, deleted } = delivery_file_link;
          if (deleted && deliveryFileLinkId) {
            // 広告仕様書を削除する
            await this.$api.delete("deliveryFileLinks", deliveryFileLinkId);
            this.deliveryFileLinkData = {};
            deliveryFileLinkId = null;
          }
          if (file) {
            const deliveryFileLinkData = new FormData();
            if (deliveryFileLinkId) {
              // 広告仕様書の更新
              deliveryFileLinkData.append("delivery_file_link", file);
              deliveryFileLinkData.append(
                "version",
                this.deliveryFileLinkData.version
              );
              const updateddeliveryFileLink = await this.$api.update(
                "deliveryFileLinks",
                deliveryFileLinkId,
                deliveryFileLinkData,
                true
              );
              this.deliveryFileLinkData = updateddeliveryFileLink;
            } else {
              // 広告仕様書記録作成
              deliveryFileLinkData.append("case", this.id);
              deliveryFileLinkData.append("delivery_file_link", file);
              const createddeliveryFileLink = await this.$api.create(
                "deliveryFileLinks",
                deliveryFileLinkData,
                true
              );
              this.deliveryFileLinkData = createddeliveryFileLink;
            }
          }
        } finally {
          this.formData.delivery_file_link = {
            link: this.deliveryFileLinkData?.delivery_file_link,
            file: null,
            deleted: false,
          };
          this.$refs.deliveryFileLinkInput?.clear();
          this.loading = false;
        }
        try {
          let productFileId = this.productFileData.id;
          const { file, deleted } = product_file;
          if (deleted && productFileId) {
            // 納品ファイルレコード削除
            await this.$api.delete("deliverables", productFileId);
            this.draftFileData = {};
            productFileId = null;
          }

          if (file) {
            const productFileData = new FormData();
            if (productFileId) {
              // 納品ファイルレコード更新
              productFileData.append("file_name", file);
              productFileData.append("version", this.productFileData.version);
              const updatedDraftFile = await this.$api.update(
                "deliverables",
                productFileId,
                productFileData,
                true
              );
              this.productFileData = updatedDraftFile;
            } else {
              // 納品ファイルレコード作成
              productFileData.append("case", this.id);
              productFileData.append("file_name", file);
              const createdFileName = await this.$api.create(
                "deliverables",
                productFileData,
                true
              );
              this.productFileData = createdFileName;
            }
          }
        } finally {
          this.formData.product_file = {
            link: this.productFileData?.file_name,
            file: null,
            deleted: false,
          };
          this.$refs.productFileInput?.clear();
          this.loading = false;
        }
        this.$v.$reset();
        this.loading = false;
        this.$message.success(this.$t(messageSource));
      }
    },
    /**
     * @vuese
     * 保存メソッド
     * 案件データおよび原稿、納品ファイルの更新処理
     */
    async save(messageSource = "messages.success.create") {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        const {
          ad_sepecs_file,
          draft_file,
          product_file,
          delivery_file_link,
          draft_file_link,
          ...caseData
        } = this.formData;
        try {
          const updated = await this.$api.update(
            this.$options.resource,
            this.id,
            caseData
          );
          this.$utils.update(this.formData, updated);
        } catch {
          this.loading = false;
          return;
        }
        // ToDO: multiple api function call for draft_file delete, update, and create
        try {
          if (draft_file.length) {
            const updatePromises = [];
            const createPromises = [];
            for (const file of draft_file) {
              if (file) {
                if (file.deleted && file.file === null) {
                  const idx = this.draftFileData.findIndex(
                    (d) => file.link === d.draft_file
                  );
                  if (idx > -1) {
                    await this.forDraftFileDeleteAPI(
                      file,
                      this.draftFileData[idx].id
                    );
                    this.draftFileData[idx] = {
                      file: null,
                      link: null,
                      deleted: false,
                    };
                  }
                } else if (file.file !== null) {
                  const draftFileData = this.draftFileData.find(
                    (data) => data.draft_file === file.link
                  );
                  if (draftFileData) {
                    updatePromises.push(
                      this.forDraftFileUpdateAPI(file, draftFileData.id)
                    );
                  } else if (file.link === null) {
                    createPromises.push(
                      this.forDraftFileCreateAPI(file, this.id)
                    );
                  }
                }
              }
            }
            if (updatePromises.length) {
              let updatedDraftFileData = await Promise.all(updatePromises);
              for (let i = 0; i < this.draftFileData.length; i++) {
                for (let j = 0; j < updatedDraftFileData.length; j++) {
                  if (this.draftFileData[i].id === updatedDraftFileData[j].id) {
                    this.draftFileData[i].draft_file =
                      updatedDraftFileData[j].draft_file;
                  }
                }
              }
            }
            if (createPromises.length) {
              this.draftFileData = this.draftFileData.concat(
                await Promise.all(createPromises)
              );
            }
          }
        } catch {
          this.loading = false;
          return;
        } finally {
          let newFiles = [];
          for (let i = 0; i < this.draftFileData.length; i++) {
            if (this.draftFileData[i].link === null) {
              newFiles[i] = {
                link: null,
                file: null,
                deleted: false,
              };
            } else {
              newFiles[i] = {
                link: this.draftFileData[i]?.draft_file,
                file: null,
                deleted: false,
              };
            }
          }
          newFiles = newFiles.filter((nf) => nf.link !== null);
          this.formData.draft_file = this.formData.draft_file = [...newFiles];
          this.$refs.draftFileInput1?.clear();
          this.$refs.draftFileInput2?.clear();
          this.$refs.draftFileInput3?.clear();
          this.$refs.draftFileInput4?.clear();
          this.$refs.draftFileInput5?.clear();
        }
        try {
          let adSpecsFileId = this.adSpecsFileData.id;
          const { file, deleted } = ad_sepecs_file;
          if (deleted && adSpecsFileId) {
            // 広告仕様書を削除する
            await this.$api.delete("adSpecsFiles", adSpecsFileId);
            this.adSpecsFileData = {};
            adSpecsFileId = null;
          }
          if (file) {
            const adSpecsFileData = new FormData();
            if (adSpecsFileId) {
              // 広告仕様書の更新
              adSpecsFileData.append("ad_sepecs_file", file);
              adSpecsFileData.append("version", this.adSpecsFileData.version);
              const updatedAdSpecsFile = await this.$api.update(
                "adSpecsFiles",
                adSpecsFileId,
                adSpecsFileData,
                true
              );
              this.adSpecsFileData = updatedAdSpecsFile;
            } else {
              // 広告仕様書記録作成
              adSpecsFileData.append("case", this.id);
              adSpecsFileData.append("ad_sepecs_file", file);
              const createdAdSpecsFile = await this.$api.create(
                "adSpecsFiles",
                adSpecsFileData,
                true
              );
              this.adSpecsFileData = createdAdSpecsFile;
            }
          }
        } finally {
          this.formData.ad_sepecs_file = {
            link: this.adSpecsFileData?.ad_sepecs_file,
            file: null,
            deleted: false,
          };
          this.$refs.adSpecsFileInput?.clear();
          this.loading = false;
        }
        try {
          let draftFileLinkId = this.draftFileLinkData.id;
          const { file, deleted } = draft_file_link;
          if (deleted && draftFileLinkId) {
            // 広告仕様書を削除する
            await this.$api.delete("draftFileLinks", draftFileLinkId);
            this.draftFileLinkData = {};
            draftFileLinkId = null;
          }
          if (file) {
            const draftFileLinkData = new FormData();
            if (draftFileLinkId) {
              // 広告仕様書の更新
              draftFileLinkData.append("draft_file_link", file);
              draftFileLinkData.append(
                "version",
                this.draftFileLinkData.version
              );
              const updatedDraftFileLink = await this.$api.update(
                "draftFileLinks",
                draftFileLinkId,
                draftFileLinkData,
                true
              );
              this.draftFileLinkData = updatedDraftFileLink;
            } else {
              // 広告仕様書記録作成
              draftFileLinkData.append("case", this.id);
              draftFileLinkData.append("draft_file_link", file);
              const createdDraftFileLink = await this.$api.create(
                "draftFileLinks",
                draftFileLinkData,
                true
              );
              this.draftFileLinkData = createdDraftFileLink;
            }
          }
        } finally {
          this.formData.draft_file_link = {
            link: this.draftFileLinkData?.draft_file_link,
            file: null,
            deleted: false,
          };
          this.$refs.draftFileLinkInput?.clear();
          this.loading = false;
        }
        try {
          let deliveryFileLinkId = this.deliveryFileLinkData.id;
          const { file, deleted } = delivery_file_link;
          if (deleted && deliveryFileLinkId) {
            // 広告仕様書を削除する
            await this.$api.delete("deliveryFileLinks", deliveryFileLinkId);
            this.deliveryFileLinkData = {};
            deliveryFileLinkId = null;
          }
          if (file) {
            const deliveryFileLinkData = new FormData();
            if (deliveryFileLinkId) {
              // 広告仕様書の更新
              deliveryFileLinkData.append("delivery_file_link", file);
              deliveryFileLinkData.append(
                "version",
                this.deliveryFileLinkData.version
              );
              const updateddeliveryFileLink = await this.$api.update(
                "deliveryFileLinks",
                deliveryFileLinkId,
                deliveryFileLinkData,
                true
              );
              this.deliveryFileLinkData = updateddeliveryFileLink;
            } else {
              // 広告仕様書記録作成
              deliveryFileLinkData.append("case", this.id);
              deliveryFileLinkData.append("delivery_file_link", file);
              const createddeliveryFileLink = await this.$api.create(
                "deliveryFileLinks",
                deliveryFileLinkData,
                true
              );
              this.deliveryFileLinkData = createddeliveryFileLink;
            }
          }
        } finally {
          this.formData.delivery_file_link = {
            link: this.deliveryFileLinkData?.delivery_file_link,
            file: null,
            deleted: false,
          };
          this.$refs.deliveryFileLinkInput?.clear();
          this.loading = false;
        }
        try {
          let productFileId = this.productFileData.id;
          const { file, deleted } = product_file;
          if (deleted && productFileId) {
            // 納品ファイルレコード削除
            await this.$api.delete("deliverables", productFileId);
            this.draftFileData = {};
            productFileId = null;
          }

          if (file) {
            const productFileData = new FormData();
            if (productFileId) {
              // 納品ファイルレコード更新
              productFileData.append("file_name", file);
              productFileData.append("version", this.productFileData.version);
              const updatedDraftFile = await this.$api.update(
                "deliverables",
                productFileId,
                productFileData,
                true
              );
              this.productFileData = updatedDraftFile;
            } else {
              // 納品ファイルレコード作成
              productFileData.append("case", this.id);
              productFileData.append("file_name", file);
              const createdFileName = await this.$api.create(
                "deliverables",
                productFileData,
                true
              );
              this.productFileData = createdFileName;
            }
          }
        } finally {
          this.formData.product_file = {
            link: this.productFileData?.file_name,
            file: null,
            deleted: false,
          };
          this.$refs.productFileInput?.clear();
          this.loading = false;
        }
        this.$v.$reset();
        this.loading = false;
        this.$message.success(this.$t(messageSource));
      }
    },
    /**
     * @vuese
     * 原稿ファイル保存 ステータス受信時のメソッド (50)
     */
    async saveFileUpload(messageSource = "messages.success.create") {
      this.loading = true;
      const { draft_file, draft_file_link } = this.formData;
      try {
        const updated = await this.$api.update(this.$options.resource, this.id);
        this.$utils.update(this.formData, updated);
      } catch {
        this.loading = false;
        return;
      }
      if (
        !(await this.$root.$confirm(
          "actions.confirm",
          "resources.cases.messages.saveFileUploadAndLink"
        ))
      ) {
        return;
      }
      // ToDo:multiple api function call for draft_file delete, update, and create
      try {
        if (draft_file.length) {
          const updatePromises = [];
          const createPromises = [];
          for (const file of draft_file) {
            if (file) {
              if (file.deleted && file.file === null) {
                const idx = this.draftFileData.findIndex(
                  (d) => file.link === d.draft_file
                );
                if (idx > -1) {
                  await this.forDraftFileDeleteAPI(
                    file,
                    this.draftFileData[idx].id
                  );
                  this.draftFileData[idx] = {
                    file: null,
                    link: null,
                    deleted: false,
                  };
                }
              } else if (file.file !== null) {
                const draftFileData = this.draftFileData.find(
                  (data) => data.draft_file === file.link
                );
                if (draftFileData) {
                  updatePromises.push(
                    this.forDraftFileUpdateAPI(file, draftFileData.id)
                  );
                } else if (file.link === null) {
                  createPromises.push(
                    this.forDraftFileCreateAPI(file, this.id)
                  );
                }
              }
            }
          }
          if (updatePromises.length) {
            let updatedDraftFileData = await Promise.all(updatePromises);
            for (let i = 0; i < this.draftFileData.length; i++) {
              for (let j = 0; j < updatedDraftFileData.length; j++) {
                if (this.draftFileData[i].id === updatedDraftFileData[j].id) {
                  this.draftFileData[i].draft_file =
                    updatedDraftFileData[j].draft_file;
                }
              }
            }
          }
          if (createPromises.length) {
            this.draftFileData = this.draftFileData.concat(
              await Promise.all(createPromises)
            );
          }
        }
      } catch {
        this.loading = false;
        return;
      } finally {
        let newFiles = [];
        for (let i = 0; i < this.draftFileData.length; i++) {
          if (this.draftFileData[i].link === null) {
            newFiles[i] = {
              link: null,
              file: null,
              deleted: false,
            };
          } else {
            newFiles[i] = {
              link: this.draftFileData[i]?.draft_file,
              file: null,
              deleted: false,
            };
          }
        }
        newFiles = newFiles.filter((nf) => nf.link !== null);
        this.formData.draft_file = this.formData.draft_file = [...newFiles];
        this.$refs.draftFileInput1?.clear();
        this.$refs.draftFileInput2?.clear();
        this.$refs.draftFileInput3?.clear();
        this.$refs.draftFileInput4?.clear();
        this.$refs.draftFileInput5?.clear();
      }
      try {
        let draftFileLinkId = this.draftFileLinkData.id;
        const { file, deleted } = draft_file_link;
        if (deleted && draftFileLinkId) {
          // 広告仕様書を削除する
          await this.$api.delete("draftFileLinks", draftFileLinkId);
          this.draftFileLinkData = {};
          draftFileLinkId = null;
        }
        if (file) {
          const draftFileLinkData = new FormData();
          if (draftFileLinkId) {
            // 広告仕様書の更新
            draftFileLinkData.append("draft_file_link", file);
            draftFileLinkData.append("version", this.draftFileLinkData.version);
            const updatedDraftFileLink = await this.$api.update(
              "draftFileLinks",
              draftFileLinkId,
              draftFileLinkData,
              true
            );
            this.draftFileLinkData = updatedDraftFileLink;
          } else {
            // 広告仕様書記録作成
            draftFileLinkData.append("case", this.id);
            draftFileLinkData.append("draft_file_link", file);
            const createdDraftFileLink = await this.$api.create(
              "draftFileLinks",
              draftFileLinkData,
              true
            );
            this.draftFileLinkData = createdDraftFileLink;
          }
        }
      } finally {
        this.formData.draft_file_link = {
          link: this.draftFileLinkData?.draft_file_link,
          file: null,
          deleted: false,
        };
        this.$refs.draftFileLinkInput?.clear();
        this.loading = false;
      }
      this.loading = false;
      this.$message.success(this.$t(messageSource));
    },
    /**
     * @vuese
     * 見積書作成メソッド
     */
    async quotationReportCreate() {
      this.$v.formData.$touch();
      if (!this.$v.formData.$invalid) {
        if (
          await this.$refs.confirm.open(
            this.$t("actions.confirm"),
            this.$t("messages.actions.confirmCreateQuotation")
          )
        ) {
          // if (this.isDeliveryReportExists) {
          //   //案件取消の場合は納品書も生きているのでrejected_flagを立てる必要がある
          //   this.$api.update("deliveryNotes", this.deliveryNote[0].id, {
          //     rejected_flag: true,
          //   });
          // }
          // if (this.isCertificateReportExists) {
          //   //案件取消の場合は検収書も生きているのでrejected_flagを立てる必要がある
          //   this.$api.update("certificates", this.certificate[0].id, {
          //     rejected_flag: true,
          //   });
          // }
          // const price = await this.$api.dispatch(
          //   "GET",
          //   "cases",
          //   "get_total_selling_price",
          //   this.id
          // );
          // const newQuotation = await this.$api.create("quotations", {
          //   case: this.id,
          //   amount: price?.total_selling_price,
          // });
          // this.formData.quotation_id = newQuotation.id;
          // await this.save("messages.success.quotationReportCreated");
          // await this.$router.push({
          //   name: `quotationsReport`,
          //   params: { id: newQuotation.id },
          // });
          // トランザクションの統一
          const { quotation_id } = await this.$api.dispatch(
            "PUT",
            "cases",
            "create_quotation_report",
            this.id,
            this.formData
          );
          if (quotation_id) {
            // 案件情報が取得できている場合は見積書IDから画面遷移する
            await this.$router.push({
              name: `quotationsReport`,
              params: { id: quotation_id },
            });
          }
        }
      }
    },
    /**
     * @vuese
     * 見積書遷移メソッド
     */
    async quotationReport() {
      await this.$router.push({
        name: `quotationsReport`,
        params: { id: this.formData.quotation_id },
      });
    },
    /**
     * @vuese
     * 請求書発行許可ワークフロー遷移メソッド
     */
    async invoicingWorkflow() {
      this.$v.$touch();
      await this.getIsDetailCompleted();
      if (!this.$v.$invalid && this.is_detail_completed) {
        let invoicingId;
        if (!this.invoicingData || this.invoicingData.length === 0) {
          // invoicingId = await this.$api.create("invoicings", {
          //   case: this.caseData.id,
          // });
          // await this.$api.update("cases", this.id, {
          //   status: statuses.IssuingPermission,
          // });
          // トランザクションの統一
          const { id } = await this.$api.dispatch(
            "PUT",
            "cases",
            "create_invoicing",
            this.id,
            this.formData
          );
          invoicingId = id;
        } else {
          // invoicingId = this.invoicingData[0];
          invoicingId = this.invoicingData[0].id;
        }
        await this.$router.push({
          name: `invoicingsWorkflow`,
          // params: { id: invoicingId.id },
          params: { id: invoicingId },
        });
      }
    },
    /**
     * @vuese
     * 社内承認（再完了）ワークフロー遷移メソッド
     */
    async recompletionWorkflow() {
      this.$v.$touch();
      await this.getIsDetailCompleted();
      if (!this.$v.$invalid && this.is_detail_completed) {
        // let recompletionId;
        // if (!this.recompletionData || this.recompletionData.length === 0) {
        //   recompletionId = await this.$api.create("recompletions", {
        //     case: this.caseData.id,
        //   });
        // } else {
        //   recompletionId = this.recompletionData[0];
        // }
        // // ワークフローが動いていない場合は保存を実行する
        // if (this.recompletionWorkflowStatus === workfrowStatuses.NoWorkfrow) {
        //   await this.save();
        // }
        // トランザクションの統一
        const { id } = await this.$api.dispatch(
          "PUT",
          "cases",
          "create_recompletion",
          this.id,
          this.formData
        );
        await this.$router.push({
          name: `recompletionsWorkflow`,
          params: { id: id },
        });
      }
    },
    /**
     * @vuese
     * 社内承認（赤黒）ワークフロー遷移メソッド
     */
    async RedBlackCompletionsWorkflow() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        // let redBlackCompletionId;
        // if (
        //   !this.redBlackCompletionData ||
        //   this.redBlackCompletionData.length === 0
        // ) {
        //   redBlackCompletionId = await this.$api.create("redBlackCompletions", {
        //     case: this.caseData.id,
        //   });
        // } else {
        //   redBlackCompletionId = this.redBlackCompletionData[0];
        // }
        // // ワークフローが動いていない場合は保存を実行する
        // if (
        //   this.redBlackCompletionWorkflowStatus === workfrowStatuses.NoWorkfrow
        // ) {
        //   await this.save();
        // }
        // トランザクションの統一
        const { id } = await this.$api.dispatch(
          "PUT",
          "cases",
          "create_red_black_completions",
          this.id,
          this.formData
        );
        await this.$router.push({
          name: `redBlackCompletionsWorkflow`,
          params: { id: id },
        });
      }
    },
    /**
     * @vuese
     * 社内承認不要処理メソッド
     */
    async directCompletion() {
      this.$v.formData.$touch();
      await this.getIsDetailCompleted();
      if (!this.$v.formData.$invalid && this.is_detail_completed) {
        if (
          await this.$refs.confirm.open(
            this.$t("actions.confirm"),
            this.$t("messages.actions.confirmDirectCompletion")
          )
        ) {
          this.formData.status = statuses.Completion;
          await this.save("messages.success.caseComplete");
        }
      }
    },
    /**
     * @vuese
     * 調達部発注書処理ワークフロー遷移メソッド
     */
    async returnPurchaseOrderWorkflow() {
      this.$v.$touch();
      await this.getIsDetailCompleted();
      if (!this.$v.$invalid && this.is_detail_completed) {
        let returnPurchaseOrderId;
        if (
          !this.returnPurchaseOrderData ||
          this.returnPurchaseOrderData.length === 0
        ) {
          // returnPurchaseOrderId = await this.$api.create(
          //   "returnPurchaseOrders",
          //   {
          //     case: this.caseData.id,
          //   }
          // );
          // await this.$api.update("cases", this.id, {
          //   status: statuses.ReturnPermission,
          // });
          // トランザクションの統一
          const { id } = await this.$api.dispatch(
            "PUT",
            "cases",
            "create_return_purchase_order",
            this.id,
            this.formData
          );
          returnPurchaseOrderId = id;
        } else {
          // returnPurchaseOrderId = this.returnPurchaseOrderData[0];
          returnPurchaseOrderId = this.returnPurchaseOrderData[0].id;
        }
        await this.$router.push({
          name: `returnPurchaseOrdersWorkflow`,
          // params: { id: returnPurchaseOrderId.id },
          params: { id: returnPurchaseOrderId },
        });
      }
    },
    /**
     * @vuese
     * OBIC連携処理
     */
    async doObicAlignment() {
      this.obicAlignment = true;
      await this.$api.httpClient("POST", "importCSV/");
      let monitor = setInterval(async () => {
        const {
          data: { running },
        } = await this.$api.httpClient("GET", "importCSV/");
        if (!running) {
          clearInterval(monitor);
          await this.fetchData(this.id);
          await this.$refs.orderDetailTable?.load(this.id);
          this.obicAlignment = false;
        }
      }, 1000);

      this.$once("hook:beforeDestroy", () => {
        clearInterval(monitor);
      });
    },
    /**
     * @vuese
     * 見積書のワークフロー状況取得メソッド
     */
    async getQuotationWorkflowStatus() {
      if (this.formData.quotation_id) {
        const { status } = await this.$api.dispatch(
          "GET",
          "quotations",
          "get_workflow_status",
          this.formData.quotation_id
        );
        this.quotationWorkflowStatus = status;
      }
    },
    /**
     * @vuese
     * 見積書（クライアント）のワークフロー状況取得メソッド
     */
    async getQuotationWorkflowClientStatus() {
      if (this.formData.quotation_id) {
        const { status } = await this.$api.dispatch(
          "GET",
          "quotations",
          "get_client_workflow_status",
          this.formData.quotation_id
        );
        this.quotationWorkflowClientStatus = status;
      }
    },
    /**
     * @vuese
     * 注文請書のワークフロー状況取得メソッド
     */
    async getOrderReceiptWorkflowStatus() {
      if (this.orderReceipt && this.orderReceipt.length > 0) {
        const { status } = await this.$api.dispatch(
          "GET",
          "orderReceipts",
          "get_workflow_status",
          this.orderReceipt[0].id
        );
        this.orderReceiptWorkflowStatus = status;
      }
    },
    /**
     * @vuese
     * 納品確認書のワークフロー状況取得メソッド
     */
    async getDeliveryNoteWorkflowStatus() {
      if (this.isDeliveryReportExists || this.isDeliveryReportRejectExists) {
        const { status } = await this.$api.dispatch(
          "GET",
          "deliveryNotes",
          "get_workflow_status",
          this.DeliveryNoteId
        );
        this.deliveryNoteWorkflowStatus = status;
      }
    },
    /**
     * @vuese
     * 検収確認書のワークフロー状況取得メソッド
     */
    async getCertificateWorkflowStatus() {
      if (this.certificate && this.certificate.length > 0) {
        const { status } = await this.$api.dispatch(
          "GET",
          "certificates",
          "get_workflow_status",
          this.certificate[0].id
        );
        this.certificateWorkflowStatus = status;
      }
    },
    /**
     * @vuese
     * 請求書発行許可のワークフロー状況取得メソッド
     */
    async getInvoicingWorkflowStatus() {
      if (this.invoicingData && this.invoicingData.length > 0) {
        const { status } = await this.$api.dispatch(
          "GET",
          "invoicings",
          "get_workflow_status",
          this.invoicingData[0].id
        );
        this.invoicingWorkflowStatus = status;
      }
    },
    /**
     * @vuese
     * 調達部返送許可のワークフロー状況取得メソッド
     */
    async getReturnPurchaseOrderWorkflowStatus() {
      if (
        this.returnPurchaseOrderData &&
        this.returnPurchaseOrderData.length > 0
      ) {
        const { status } = await this.$api.dispatch(
          "GET",
          "returnPurchaseOrders",
          "get_workflow_status",
          this.returnPurchaseOrderData[0].id
        );
        this.returnPurchaseOrderWorkflowStatus = status;
      }
    },
    /**
     * @vuese
     * 社内完了（再完了）のワークフロー状況取得メソッド
     */
    async getRecompletionWorkflowStatus() {
      if (this.recompletionData && this.recompletionData.length > 0) {
        const { status } = await this.$api.dispatch(
          "GET",
          "recompletions",
          "get_workflow_status",
          this.recompletionData[0].id
        );
        this.recompletionWorkflowStatus = status;
      }
    },
    /**
     * @vuese
     * 社内完了（赤黒）のワークフロー状況取得メソッド
     */
    async getRedBlackCompletionsWorkflow() {
      if (
        this.redBlackCompletionData &&
        this.redBlackCompletionData.length > 0
      ) {
        const { status } = await this.$api.dispatch(
          "GET",
          "redBlackCompletions",
          "get_workflow_status",
          this.redBlackCompletionData[0].id
        );
        this.redBlackCompletionWorkflowStatus = status;
      }
    },
    /**
     * @vuese
     * 注文請書作成メソッド
     */
    async orderReceiptReportCreate() {
      this.$v.formData.$touch();
      if (!this.$v.formData.$invalid) {
        // let quotationId;
        // let quotationRemarks;
        // if (this.formData.case_category === caseCategorys.Approval) {
        //   // 稟議書案件の場合は見積書データを先に作成する
        //   const newQuotation = await this.$api.create("quotations", {
        //     case: this.id,
        //     quotation_date: this.$dateStr(),
        //   });
        //   quotationId = newQuotation.id;
        //   quotationRemarks = "";
        //   // 案件情報の見積書Noも更新する
        //   this.formData.quotation_id = newQuotation.id;
        // } else {
        //   // 稟議書案件以外の場合は作成済の見積書情報を元に作成する
        //   const quotation = await this.$api.getOne(
        //     "quotations",
        //     this.caseData.quotation_id
        //   );
        //   quotationId = this.formData.quotation_id;
        //   // 備考は見積書の備考を初期値にする
        //   quotationRemarks = quotation.remarks;
        // }
        // const newOrderReceipt = await this.$api.create("orderReceipts", {
        //   case: this.id,
        //   quotation: quotationId,
        //   remarks: quotationRemarks,
        // });
        // await this.save("messages.success.orderReceiptReportCreated");
        // トランザクションの統一
        const { id } = await this.$api.dispatch(
          "PUT",
          "cases",
          "create_order_receipt_report",
          this.id,
          this.formData
        );
        await this.$router.push({
          name: `orderReceiptsReport`,
          params: { id: id },
        });
      }
    },
    /**
     * @vuese
     * 注文請書遷移メソッド
     */
    async orderReceiptReport() {
      await this.save();
      await this.$router.push({
        name: `orderReceiptsReport`,
        params: { id: this.orderReceipt[0].id },
      });
    },
    /**
     * @vuese
     * 納品確認書作成メソッド
     */
    async deliveryReportCreate() {
      this.$v.$touch();
      await this.getIsDetailCompleted();
      if (!this.$v.$invalid && this.is_detail_completed) {
        if (
          await this.$refs.confirm.open(
            this.$t("actions.confirm"),
            this.$t("messages.actions.confirmCreateDeliveryNote")
          )
        ) {
          // if (this.isDeliveryReportExists) {
          //   this.$api.update("deliveryNotes", this.deliveryNote[0].id, {
          //     rejected_flag: true,
          //   });
          // }
          // if (this.isCertificateReportExists) {
          //   //案件取消の場合は検収書も生きているのでrejected_flagを立てる必要がある
          //   this.$api.update("certificates", this.certificate[0].id, {
          //     rejected_flag: true,
          //   });
          // }
          // const newDeliveryReport = await this.$api.create("deliveryNotes", {
          //   quotation: this.caseData.quotation_id,
          // });
          // await this.save("messages.success.deliveryNoteReportCreated");
          // トランザクションの統一
          const { id } = await this.$api.dispatch(
            "PUT",
            "cases",
            "create_delivery_note_report",
            this.id,
            this.formData
          );
          await this.$router.push({
            name: `deliveryNotesReport`,
            params: { id: id },
          });
        }
      }
    },
    /**
     * @vuese
     * 納品確認書遷移メソッド
     */
    async deliveryReport() {
      await this.$router.push({
        name: `deliveryNotesReport`,
        params: { id: this.DeliveryNoteId },
      });
    },
    /**
     * @vuese
     * 検収確認書遷移メソッド
     */
    async certificateReport() {
      await this.$router.push({
        name: `certificatesReport`,
        params: { id: this.certificate[0].id },
      });
    },
    /**
     * @vuese
     * 完了取り消し処理
     */
    async completeCancel() {
      const { closing_day } = await this.$api.dispatch(
        "GET",
        "obicRetrievedOrderDatas",
        "getClosingDay"
      );
      if (closing_day > this.formData.completion_date) {
        // OBICのの締め年月日＞完了日の場合は取り消し出来ない
        await this.$refs.confirm.open(
          this.$t("actions.confirm"),
          this.$t("messages.warnings.CompletCancelError"),
          { cancelmessage: this.$t("actions.confirm"), cancelOnly: true }
        );
        return;
      }
      if (
        await this.$refs.confirm.open(
          this.$t("actions.confirm"),
          this.$t("messages.actions.confirmCompletCancel")
        )
      ) {
        // // 再完了が行われている場合、rejected_flagを立てる
        // if (this.isRecompletionExists) {
        //   this.$api.update("recompletions", this.recompletionData[0].id, {
        //     rejected_flag: true,
        //   });
        // }
        // switch (this.formData.case_category) {
        //   case caseCategorys.Normal:
        //     this.formData.status = statuses.Ordered;
        //     break;
        //   case caseCategorys.Approval:
        //     this.formData.status = statuses.Inquiry;
        //     break;
        //   case caseCategorys.reversalAndCorrecting:
        //     this.formData.status = statuses.ReversalAndCorrectingEntry;
        //     break;
        // }
        // await this.save("messages.success.caseCompletCancel");
        // トランザクションの統一
        await this.$api.dispatch(
          "PUT",
          "cases",
          "complete_cancel",
          this.id,
          this.formData
        );
        await this.fetchData(this.id);
      }
    },
    /**
     * @vuese
     * 取下げ確認処理
     */
    async showWithDrawal() {
      if (
        await this.$refs.confirm.open(
          this.$t("actions.confirm"),
          this.$t("messages.actions.confirmDrawal")
        )
      ) {
        await this.caseDelete();
      }
    },
    /**
     * @vuese
     * 案件削除確認処理
     */
    async showCaseDelete() {
      if (
        await this.$refs.confirm.open(
          this.$t("actions.warning"),
          this.$t("messages.actions.confirmCaseDelete"),
          { color: "red" }
        )
      ) {
        await this.caseDelete();
      }
    },
    /**
     * @vuese
     * 案件削除処理
     */
    async caseDelete() {
      await this.$api.update("cases", this.id, {
        delete_category:
          this.formData.status <= statuses.QuotationSubmitted
            ? this.hasPerm("client")
              ? deleteCategorys.PreOrder
              : deleteCategorys.Complete
            : deleteCategorys.Order,
        delete_flag: true,
      });
      await this.fetchData(this.id);
    },
    /**
     * @vuese
     * 案件削除取消処理
     */
    async caseDeleteCancel() {
      if (
        await this.$refs.confirm.open(
          this.$t("actions.warning"),
          this.$t("messages.actions.confirmCaseDeleteCancel"),
          { color: "red" }
        )
      ) {
        await this.$api.update("cases", this.id, {
          delete_category: null,
          delete_flag: false,
        });
        await this.fetchData(this.id);
      }
    },
    /**
     * @vuese
     * 納品完了状態取得
     */
    async getIsDetailCompleted() {
      if (this.formData.status === statuses.Ordered) {
        const { is_detail_completed } = await this.$api.dispatch(
          "GET",
          "cases",
          "is_detail_completed",
          this.id
        );
        this.is_detail_completed = is_detail_completed;
      }
    },
    /**
     * @vuese
     * 納品完了状態初期化
     */
    async formatDetailCompleted() {
      this.is_detail_completed = true;
    },
    toLocaleString(number) {
      return number?.toLocaleString() || "";
    },
    /**
     * @vuese
     * 費用負担部門、率のチェックメソッド
     */
    async codeAndRateCheck(id) {
      let costBurdenDepartmentsData = await this.$api.getMany(
        "costBurdenDepartments",
        { ["case"]: id }
      );
      costBurdenDepartmentsData.sort(
        (a, b) => parseFloat(a.department_code) - parseFloat(b.department_code)
      );
      this.obicCostBurdenDepartments.sort(
        (a, b) =>
          parseFloat(a.cost_burden_customer_code) -
          parseFloat(b.cost_burden_customer_code)
      );
      if (
        this.obicCostBurdenDepartments.length !==
        costBurdenDepartmentsData.length
      ) {
        return false;
      }
      for (let i = 0; i < this.obicCostBurdenDepartments.length; i++) {
        if (
          this.obicCostBurdenDepartments[i].cost_burden_customer_code !==
          costBurdenDepartmentsData[i].department_code
        ) {
          return false;
        }
        if (
          this.obicCostBurdenDepartments[i].average_input_burden_rate !==
          costBurdenDepartmentsData[i].rate
        ) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>
