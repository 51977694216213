import i18n from "../plugins/i18n";

const utils = {
  translateHttpCode: (status) => {
    let message;
    switch (status) {
      case 304:
        message = i18n.t("httpErrors.304");
        break;
      case 400:
        message = i18n.t("httpErrors.400");
        break;
      case 403:
        message = i18n.t("httpErrors.403");
        break;
      case 404:
        message = i18n.t("httpErrors.404");
        break;
      case 405:
        message = i18n.t("httpErrors.405");
        break;
      case 408:
        message = i18n.t("httpErrors.408");
        break;
      case 500:
        message = i18n.t("httpErrors.500");
        break;
      case 501:
        message = i18n.t("httpErrors.501");
        break;
      case 502:
        message = i18n.t("httpErrors.502");
        break;
      case 503:
        message = i18n.t("httpErrors.503");
        break;
      case 504:
        message = i18n.t("httpErrors.504");
        break;
      case 505:
        message = i18n.t("httpErrors.505");
        break;
      default:
        message = i18n.t("httpErrors.default", {
          error: status,
        });
    }
    return message;
  },
  update(object, source) {
    if (!object || !source) return;
    Object.keys(object).forEach((field) => {
      if (source[field] !== undefined) {
        object[field] = source[field];
      }
    });
  },
};

export default utils;

export function checkMaxFileSize(data) {
  const MAX_FILE_SIZE = 10485760;
  if (data && data.file != null) {
    return data.file.size <= MAX_FILE_SIZE;
  } else {
    return true;
  }
}
