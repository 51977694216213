<template>
  <v-container class="lighten-5" id="main_container">
    <v-form ref="inputForm" lazy-validation>
      <!--承認履歴-->
      <v-card class="mx-auto mt-5 mb-15" width="60rem">
        <v-system-bar color="orange darken-2" dark class="pa-5">
          {{ $t("resources.cases.approvalLog") }}
        </v-system-bar>
        <v-container fluid class="pa-5">
          <v-container fluid>
            <v-row align="center">
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="workFlowDetailData"
                  hide-default-footer
                  disable-pagination
                  @click:row="showReport"
                >
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </v-card>
      <v-bottom-navigation fixed horizontal scroll-target="#main_container">
        <v-btn @click="moveTop()" :disabled="loading" text>
          <span>{{ $t("moveTop") }}</span>
          <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn @click="moveCase()" :disabled="loading" text>
          <span>{{ $t("moveCase") }}</span>
          <v-icon>mdi-clipboard-text</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-form>
  </v-container>
</template>
<script>
import fieldPermissionMixin from "../../mixins/fieldPermissionMixin";
import groupPermissionMixin from "../../mixins/groupPermissionMixin";

/**
 * @group 受注管理
 * 承認履歴画面<br>
 * 機能仕様書：6.1
 */
export default {
  resource: "cases",
  mixins: [groupPermissionMixin, fieldPermissionMixin],

  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "対象",
          value: "report_name",
          sortable: false,
        },
        {
          text: "帳票No",
          value: "workflow_parent_report_no",
          sortable: false,
        },
        {
          text: "承認者",
          value: "user_name",
          sortable: false,
        },
        {
          text: "ステータス",
          value: "status_name",
          sortable: false,
        },
        {
          text: "更新日時",
          value: "processing_date_format",
          sortable: false,
        },
      ],
      workFlowDetailData: [],
    };
  },
  /**
   * @vuese
   * インスタンス作成後処理
   */
  async created() {
    console.log("id", this.id);
    const workFlowDetails = await this.$api.dispatch(
      "GET",
      "cases",
      "get_history",
      this.id
    );
    // 表示用に加工
    workFlowDetails.forEach((data) => {
      data.report_name = this.getReportName(data.workflow_parent_model_name);
      if (data.processing_date) {
        data.processing_date_format = this.$date(data.processing_date).format(
          "YYYY/MM/DD HH:mm"
        );
      } else {
        data.processing_date_format = "";
      }
    });
    this.workFlowDetailData = workFlowDetails;
  },
  methods: {
    /**
     * @vuese
     * 帳票名の取得
     */
    getReportName(model_name) {
      switch (model_name) {
        case "Quotation":
          return this.$t("resources.quotations.name");
        case "OrderReceipt":
          return this.$t("resources.orderReceipts.name");
        case "DeliveryNote":
          return this.$t("resources.deliveryNotes.name");
        case "Certificate":
          return this.$t("resources.certificates.name");
        case "Invoicing":
          return this.$t("resources.cases.actions.invoicingsWorkflow");
        case "Recompletion":
          return this.$t("resources.cases.actions.recompletionsWorkflow");
        case "RedBlackCompletion":
          return this.$t("resources.cases.actions.redBlackCompletionsWorkflow");
        case "ReturnPurchaseOrder":
          return this.$t(
            "resources.cases.actions.returnPurchaseOrdersWorkflow"
          );
      }
    },
    /**
     * @vuese
     * 帳票画面表示
     */
    showReport({ workflow_parent_model_name, workflow_parent_pk }) {
      switch (workflow_parent_model_name) {
        case "Quotation":
          this.$router.push({
            name: `quotationsReport`,
            params: { id: workflow_parent_pk },
          });
          break;
        case "OrderReceipt":
          this.$router.push({
            name: `orderReceiptsReport`,
            params: { id: workflow_parent_pk },
          });
          break;
        case "DeliveryNote":
          break;
        case "Certificate":
          break;
      }
    },
    /**
     * @vuese
     * 検索一覧移動メソッド
     */
    async moveTop() {
      if (this.hasPerm("adcom")) {
        await this.$router.push({
          name: "caseList",
        });
      } else {
        await this.$router.push({
          name: "quotationList",
        });
      }
    },
    /**
     * @vuese
     * 案件詳細移動メソッド
     */
    async moveCase() {
      await this.$router.push({
        name: "casesEdit",
        params: { id: this.id },
      });
    },
  },
};
</script>
