<!--
一括承認一覧
-->
<template>
  <v-container class="lighten-5">
    <v-card class="mt-2 pa-4">
      <v-data-table
        item-key="id"
        v-model="selected"
        :headers="headers"
        :items="data"
        :server-items-length="total"
        :loading="loading"
        class="elevation-1"
        show-select
        hide-default-footer
      >
        <template v-slot:[`item.workflow_case.status`]="{ item }">
          <v-card
            :color="item.workflow_case.status.display_color"
            dark
            width="8rem"
            class="text-center py-2"
          >
            {{ item.workflow_case.status.client_name }}
          </v-card>
        </template>
        <template v-slot:[`item.workflow_case.request_title`]="{ item }">
          <div class="request-title-column">
            {{ item.workflow_case.request_title }}
          </div>
        </template>
        <template v-slot:[`item.report`]="{ item }">
          <v-btn @click.stop="showReport(item)" color="primary">
            {{ $t(`resources.${resource}.name`) }}
          </v-btn>
        </template>
        <template v-slot:[`item.case`]="{ item }">
          <v-btn @click.stop="showCase(item)" color="primary">
            {{ $t("resources.cases.name") }}
          </v-btn>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-btn v-if="isSelected" color="primary" @click="bulkApprove">
          {{ $t("resources.bulkApprove.actions.bulkApprove") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import groupPermissionMixin from "../../mixins/groupPermissionMixin";

/**
 * @group 受領済見積書一括承認画面 / 検収一括承認画面
 * 受領済見積書一括承認画面 / 検収一括承認画面<br>
 * 機能仕様書：4.9, 6.2
 */
export default {
  mixins: [groupPermissionMixin],
  props: {
    resource: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      total: 0,
      data: [],
      dialog: false,
      /**
       * @vuese
       * 一括更新対象となる明細の保持用リスト
       */
      selected: [],
    };
  },
  computed: {
    /**
     * @vuese
     * 明細のヘッダ項目情報
     */
    headers() {
      return [
        {
          text: this.$t("resources.cases.fields.order_date"),
          sortable: false,
          value: "workflow_case.order_date",
        },
        {
          text: this.$t("resources.cases.fields.request_title"),
          sortable: false,
          value: "workflow_case.request_title",
        },
        {
          text: this.$t("resources.cases.fields.client_user"),
          sortable: false,
          value: "workflow_case.client_user",
        },
        {
          text: this.$t("resources.cases.fields.client_department"),
          sortable: false,
          value: "workflow_case.client_department",
        },
        {
          text: this.$t("resources.cases.fields.quotation_amount"),
          sortable: false,
          value: "workflow_case.quotation_amount",
        },
        {
          text: this.$t("resources.cases.fields.status"),
          sortable: false,
          value: "workflow_case.status",
        },
        {
          text: this.$t("resources.cases.fields.scheduled_due_date"),
          sortable: false,
          value: "workflow_case.scheduled_due_date",
        },
        {
          text: this.$t("resources.cases.fields.case_no"),
          sortable: false,
          value: "workflow_case.case_no",
        },
        {
          text: this.$t(`resources.${this.resource}.name`),
          sortable: false,
          value: "report",
        },
        {
          text: this.$t("resources.cases.name"),
          sortable: false,
          value: "case",
        },
        {
          text: this.$t("resources.cases.fields.contact_user"),
          sortable: false,
          value: "workflow_case.contact_user",
        },
      ];
    },
    /**
     * @vuese
     * 一括承認ボタンの表示非表示設定
     */
    isSelected() {
      return this.selected.length > 0;
    },
  },
  async created() {
    await this.search();
  },
  watch: {
    resource: async function (newVal) {
      if (newVal) {
        await this.search();
      }
    },
  },
  methods: {
    /**
     * @vuese
     * 承認待ちの帳票取得メソッド
     */
    async search() {
      this.loading = true;
      this.selected = [];
      try {
        const { total, data } = await this.$api.getList("bulkApprove", {
          resource: this.resource,
        });
        this.total = total;
        this.data = data;
      } finally {
        this.loading = false;
      }
    },
    /**
     * @vuese
     * 帳票種別取得メソッド
     * @arg item - 選択された帳票の明細情報
     */
    getReportType(item) {
      return this.$t(`resources.${item.resource}.name`);
    },
    /**
     * @vuese
     * 帳票遷移メソッド
     * @arg item - 選択された帳票の明細情報
     */
    showReport({ resource, id }) {
      this.$router.push({
        name: `${resource}Report`,
        params: { id },
      });
    },
    /**
     * @vuese
     * 案件遷移メソッド
     * @arg item - 選択された帳票の明細情報
     */
    showCase({ workflow_case }) {
      if (workflow_case.id) {
        this.$router.push({
          name: "casesEdit",
          params: { id: workflow_case.id },
        });
      }
    },
    /**
     * @vuese
     * 一括承認メソッド
     */
    async bulkApprove() {
      if (
        await this.$root.$confirm(
          "resources.bulkApprove.actions.bulkApprove",
          "resources.bulkApprove.messages.confirm"
        )
      ) {
        if (this.hasPerm("adcom")) {
          try {
            const tasks = this.selected.map(
              ({ adcom_workflow: { id, version } }) =>
                this.$api.dispatch("PATCH", "workflows", "approve", id, {
                  version,
                })
            );
            await Promise.all(tasks);
          } finally {
            await this.search();
          }
        } else {
          try {
            const tasks = this.selected.map(
              ({ client_workflow: { id, version } }) =>
                this.$api.dispatch("PATCH", "workflows", "approve", id, {
                  version,
                })
            );
            await Promise.all(tasks);
          } finally {
            await this.search();
          }
        }
      }
    },
  },
};
</script>

<style>
.request-title-column {
  max-width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
