import Vue from "vue";
import Vuex from "vuex";

import axios from "../plugins/axios";

import * as MUTATIONS from "./mutation-types";
import { authPayload, identities } from "../configs/authConfig";

Vue.use(Vuex);

const ajax = axios.create();

if (
  process.env.NODE_ENV === "development" &&
  process.env.VUE_APP_USE_MOCK === "true"
)
  require("../mock/auth").default(ajax);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN),
    isAuthenticated: false,
    user: null,
    identity: {},
  },
  mutations: {
    [MUTATIONS.SET_USER](state, user) {
      state.user = { ...user };
    },
    [MUTATIONS.CLEAR_USER](state) {
      state.user = null;
    },
    [MUTATIONS.SET_AUTHENTICATED](state) {
      state.isAuthenticated = true;
    },
    [MUTATIONS.SET_UNAUTHENTICATED](state) {
      state.isAuthenticated = false;
    },
    [MUTATIONS.UPDATE_TOKEN](state, newToken) {
      localStorage.setItem(process.env.VUE_APP_ACCESS_TOKEN, newToken);
      state.token = newToken;
    },
    [MUTATIONS.REMOVE_TOKEN](state) {
      localStorage.removeItem(process.env.VUE_APP_ACCESS_TOKEN);
      state.token = null;
    },
    [MUTATIONS.SET_IDENTITY](
      state,
      { is_superuser = false, identities: userIdentities = [] }
    ) {
      state.identity = Object.fromEntries(
        Object.entries(identities).map(([key, value]) => [
          key,
          is_superuser ||
            value.every((identity) => userIdentities.includes(identity)),
        ])
      );
    },
    [MUTATIONS.CLEAR_IDENTITY](state) {
      state.identity = {};
    },
  },
  actions: {
    async login({ commit }, payload) {
      const { data } = await ajax.post(authPayload.login, payload);
      commit(MUTATIONS.UPDATE_TOKEN, data.access_token);
      commit(MUTATIONS.SET_AUTHENTICATED);
      commit(MUTATIONS.SET_USER, data.user);
      commit(MUTATIONS.SET_IDENTITY, data.user);
    },
    async signup(store, payload) {
      const { data } = await ajax.post(authPayload.signup, payload);
      return data;
      // commit(MUTATIONS.UPDATE_TOKEN, data.access_token);
      // commit(MUTATIONS.SET_AUTHENTICATED);
      // commit(MUTATIONS.SET_USER, data.user);
      // commit(MUTATIONS.SET_IDENTITY, data.user);
    },
    async verifyToken({ state, commit, dispatch }) {
      try {
        await ajax.post(
          authPayload.verifyToken,
          { token: state.token },
          { withCredentials: true }
        );
        const { data: user } = await ajax.get(authPayload.user, {
          headers: { authorization: `Bearer ${state.token}` },
        });
        commit(MUTATIONS.SET_AUTHENTICATED);
        commit(MUTATIONS.SET_USER, user);
        commit(MUTATIONS.SET_IDENTITY, user);
      } catch (error) {
        if (error?.response?.data) {
          const { code } = error.response.data;
          if (code === process.env.VUE_APP_TOKEN_NOT_VALID_CODE) {
            await dispatch("refreshToken");
            return;
          }
        }
        await dispatch("logout");
        throw error;
      }
    },
    async refreshToken({ commit, dispatch }) {
      try {
        const {
          data: { access },
        } = await ajax.post(
          authPayload.refreshToken,
          {},
          { withCredentials: true }
        );
        const { data: user } = await ajax.get(authPayload.user, {
          headers: { authorization: `Bearer ${access}` },
        });
        commit(MUTATIONS.UPDATE_TOKEN, access);
        commit(MUTATIONS.SET_AUTHENTICATED);
        commit(MUTATIONS.SET_USER, user);
        commit(MUTATIONS.SET_IDENTITY, user);
      } catch (error) {
        await dispatch("logout");
        throw error;
      }
    },
    logout({ commit }) {
      commit(MUTATIONS.SET_UNAUTHENTICATED);
      commit(MUTATIONS.CLEAR_USER);
      commit(MUTATIONS.CLEAR_IDENTITY);
      commit(MUTATIONS.REMOVE_TOKEN);
    },
    async passwordReset({ dispatch }, payload) {
      await ajax.post(authPayload.passwordReset, payload);
      await dispatch("logout");
    },
    async passwordResetConfirm({ dispatch }, payload) {
      await ajax.post(authPayload.passwordResetConfirm, payload);
      await dispatch("logout");
    },
  },
  getters: {
    token: (state) => {
      return state.token;
    },
    isAuthenticated: (state) => {
      return state.isAuthenticated;
    },
    currentUser: (state) => {
      return state.user;
    },
    identity: (state) => {
      return state.identity;
    },
  },
  modules: {},
});
