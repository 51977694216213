const fullMenus = [
  {
    key: "quotationList",
    title: "案件一覧",
    icon: "mdi-view-list",
    identities: ["clientManager", "clientStaff"],
  },
  {
    key: "caseList",
    title: "案件一覧",
    icon: "mdi-view-list",
    identities: [
      "adcomManager",
      "adcomStaff",
      "adcomProcurer",
      "adcomProvision",
      "adcomDuty",
    ],
  },
  {
    key: "bulkApprove-quotations",
    title: "受領済見積書一括承認",
    icon: "mdi-playlist-check",
    params: { resource: "quotations" },
    identities: ["clientManager"],
  },
  {
    key: "bulkApprove-quotations",
    title: "見積書一括承認",
    icon: "mdi-playlist-check",
    params: { resource: "quotations" },
    identities: ["adcomManager"],
  },
  {
    key: "bulkApprove-certificates",
    title: "検収確認一括承認",
    icon: "mdi-playlist-check",
    params: { resource: "certificates" },
    identities: ["clientManager"],
  },
  {
    key: "bulkApprove-deliveryNotes",
    title: "納品確認書一括承認",
    icon: "mdi-playlist-check",
    params: { resource: "deliveryNotes" },
    identities: ["adcomManager"],
  },
  {
    key: "completedCases",
    title: "納品可能一覧",
    icon: "mdi-view-list",
    identities: [
      "adcomManager",
      "adcomStaff",
      "adcomProcurer",
      "adcomProvision",
      "adcomDuty",
    ],
  },
];

export { fullMenus };
