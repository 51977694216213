import download from "downloadjs";

function downloadUrl(url) {
  if (url && url.length < 2048) {
    // if no filename and no mime, assume a url was passed as the only argument
    const defaultMime = "application/octet-stream";
    const anchor = document.createElement("a");
    const fileName = decodeURI(url.split("/").pop().split("?")[0]);
    anchor.href = url; // assign href prop to temp anchor
    if (anchor.href.indexOf(url) !== -1) {
      // if the browser determines that it's a potentially valid url path:
      var ajax = new XMLHttpRequest();
      ajax.open("GET", url, true);
      ajax.responseType = "blob";
      ajax.onload = function (e) {
        download(e.target.response, fileName, defaultMime);
      };
      setTimeout(function () {
        ajax.send();
      }, 0); // allows setting custom ajax headers using the return:
      return ajax;
    } // end if valid url?
  } // end if url?
}
export default downloadUrl;
