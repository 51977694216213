<template>
  <v-card class="d-flex flex-row" flat tile>
    <v-select
      outlined
      :items="items"
      :label="$t('invoices.quotes.create.placeOfDelivery')"
      item-value="code"
      item-text="value"
    ></v-select>
    <v-text-field
      outlined
      :rules="rules"
      placeholder="フリー入力の場合"
      class="ml-3"
    ></v-text-field>
  </v-card>
</template>

<script>
export default {
  setup() {},
  data: () => ({
    items: [
      { code: "1", value: "全数カタログ倉庫" },
      { code: "2", value: "カタログ倉庫＋依頼課保管分" },
      { code: "3", value: "拠点発送" },
      { code: "4", value: "データ納品" },
      { code: "5", value: "フリー入力" },
    ],
  }),
};
</script>
