<template>
  <v-container class="lighten-5" id="main_container">
    <v-form ref="inputForm" lazy-validation>
      <!--承認フロー-->
      <v-card class="mx-auto mt-5" width="60rem">
        <WorkflowAction
          v-show="IsWorkflow"
          :id="displayWorkflowId"
          v-on:updated="changeWorkflow"
        ></WorkflowAction>
      </v-card>

      <!--承認履歴-->
      <WorkflowHistory
        :refreshKey="workflowHistoryKey"
        :workflow_id="workflowId"
      ></WorkflowHistory>

      <v-bottom-navigation fixed horizontal scroll-target="#main_container">
        <v-btn @click="moveTop()" :disabled="loading" text>
          <span>{{ $t("moveTop") }}</span>
          <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn @click="moveCase()" :disabled="loading" text>
          <span>{{ $t("moveCase") }}</span>
          <v-icon>mdi-clipboard-text</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-form>
  </v-container>
</template>
<script>
import groupPermissionMixin from "../../mixins/groupPermissionMixin";
import updateMixin from "../../mixins/updateMixin";
import relationMixin from "../../mixins/relationMixin";
import fieldPermissionMixin from "../../mixins/fieldPermissionMixin";
import { validationMixin } from "vuelidate";
import headersMixin from "../../mixins/headersMixin";
import { mapGetters } from "vuex";
import { caseCategorys } from "../../../configs/caseCategoryConfig";

/**
 * @group 承認フロー
 * 赤黒処理承認画面<br>
 * 機能仕様書：5.17
 */
export default {
  name: "RedBlackCompletions.Workflow",
  resource: "redBlackCompletions",

  mixins: [
    groupPermissionMixin,
    fieldPermissionMixin,
    updateMixin,
    validationMixin,
    headersMixin,
    relationMixin,
  ],
  data() {
    return {
      formData: {
        case_id: 0,
        division: "",
      },
      redBlackCompletionData: {},
      dialog: false,
      workflowHistoryKey: 0,
    };
  },
  methods: {
    async fetchData(id) {
      this.loading = true;
      try {
        this.redBlackCompletionData = await this.$api.getOne(
          this.$options.resource,
          id
        );
        this.$utils.update(this.formData, this.redBlackCompletionData);
        await this.join(this.redBlackCompletionData, [
          { name: "case", resource: "cases" },
        ]);
        await this.join(this.redBlackCompletionData.case, [
          {
            name: "contact_user",
            resource: "users",
          },
        ]);
      } finally {
        this.loading = false;
      }
    },
    /**
     * @vuese
     * ワークフロー更新時の処理
     */
    async changeWorkflow(args) {
      this.workflowHistoryKey++;
      // メッセージを表示
      this.$message.success(
        this.$t("messages.success.workflows." + args.action)
      );
    },
    /**
     * @vuese
     * 検索一覧移動メソッド
     */
    async moveTop() {
      if (this.hasPerm("adcom")) {
        await this.$router.push({
          name: "caseList",
        });
      } else {
        await this.$router.push({
          name: "quotationList",
        });
      }
    },
    /**
     * @vuese
     * 案件詳細移動メソッド
     */
    async moveCase() {
      await this.$router.push({
        name: "casesEdit",
        params: { id: this.redBlackCompletionData.case.id },
      });
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    workflowId() {
      return [
        this.redBlackCompletionData?.adcom_workflow,
        this.redBlackCompletionData?.client_workflow,
      ].filter((id) => id);
    },
    displayWorkflowId() {
      return this.hasPerm(`adcomStaff`, `adcomManager`, "adcomDuty")
        ? this.redBlackCompletionData?.adcom_workflow || null
        : this.redBlackCompletionData?.client_workflow || null;
    },
    /**
     * @vuese
     * ワークフローコンポーネントの表示非表示設定
     */
    IsWorkflow() {
      return (
        (this.hasPerm("adcomStaff") && this.isAdcomContact) ||
        this.hasPerm("adcomManager") ||
        (this.hasPerm("adcomDuty") && this.isReversalAndCorrecting)
      );
    },
    /**
     * @vuese
     * アドコム担当者での制限
     */
    isAdcomContact() {
      return (
        this.invoicingData?.case?.contact_user?.id === this.currentUser?.id ||
        this.invoicingData?.case?.contact_user?.proxy_user ===
          this.currentUser?.id
      );
    },
    /**
     * @vuese
     * 赤黒案件か否かの判定
     */
    isReversalAndCorrecting() {
      return (
        this.redBlackCompletionData?.case?.case_category ===
        caseCategorys.reversalAndCorrecting
      );
    },
  },
};
</script>
