var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"login"}},[_c('v-main',[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm8":"","md4":""}},[_c('v-tabs',{staticClass:"elevation-12 rounded-t",attrs:{"background-color":"primary","show-arrows":"","icons-and-text":"","dark":"","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_vm._l((_vm.tabs),function(i){return _c('v-tab',{key:i.name},[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(i.icon))]),_c('div',{staticClass:"caption py-1"},[_vm._v(_vm._s(i.text))])],1)}),_c('v-tab-item',[_c('form-wrapper',{attrs:{"validator":_vm.$v.loginForm}},[_c('v-form',{attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('form-group',{attrs:{"name":"username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"prepend-icon":"mdi-mail","label":_vm.$t('resources.users.fields.pid'),"type":"text","outlined":"","maxlength":"6","dense":""},model:{value:(_vm.loginForm.username),callback:function ($$v) {_vm.$set(_vm.loginForm, "username", $$v)},expression:"loginForm.username"}},'v-text-field',attrs,false))]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('form-group',{attrs:{"name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"id":"password","prepend-icon":"mdi-lock","label":_vm.$t('resources.users.fields.password'),"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"counter":"","outlined":"","dense":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.loginForm.password),callback:function ($$v) {_vm.$set(_vm.loginForm, "password", $$v)},expression:"loginForm.password"}},'v-text-field',attrs,false))]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('a',{on:{"click":_vm.gotoPasswordReset}},[_vm._v(" "+_vm._s(_vm.$t("auth.passwordReset.entry"))+" ")])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","type":"sumbit","disabled":_vm.loading}},[_vm._v(_vm._s(_vm.$t("auth.login")))])],1)],1)],1)],1)],1),_c('v-tab-item',[_c('form-wrapper',{attrs:{"validator":_vm.$v.signupForm}},[_c('v-form',{ref:"signupForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.signup.apply(null, arguments)}}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('form-group',{attrs:{"validator":_vm.$v.signupForm.username,"attribute":"resources.users.fields.pid"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"label":_vm.$t('resources.users.fields.pid'),"maxLength":"6","outlined":""},on:{"change":function($event){return _vm.resetServerError('username')}},model:{value:(_vm.signupForm.username),callback:function ($$v) {_vm.$set(_vm.signupForm, "username", $$v)},expression:"signupForm.username"}},'v-text-field',attrs,false))]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('form-group',{attrs:{"validator":_vm.$v.signupForm.last_name,"attribute":"resources.users.fields.last_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"label":_vm.$t('resources.users.fields.last_name'),"maxLength":"15","outlined":""},model:{value:(_vm.signupForm.last_name),callback:function ($$v) {_vm.$set(_vm.signupForm, "last_name", $$v)},expression:"signupForm.last_name"}},'v-text-field',attrs,false))]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('form-group',{attrs:{"validator":_vm.$v.signupForm.first_name,"attribute":"resources.users.fields.first_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"label":_vm.$t('resources.users.fields.first_name'),"maxLength":"15","outlined":""},model:{value:(_vm.signupForm.first_name),callback:function ($$v) {_vm.$set(_vm.signupForm, "first_name", $$v)},expression:"signupForm.first_name"}},'v-text-field',attrs,false))]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('form-group',{attrs:{"validator":_vm.$v.signupForm.email,"attribute":"resources.users.fields.email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"label":_vm.$t('resources.users.fields.email'),"maxlength":"254","outlined":""},on:{"change":function($event){return _vm.resetServerError('email')}},model:{value:(_vm.signupForm.email),callback:function ($$v) {_vm.$set(_vm.signupForm, "email", $$v)},expression:"signupForm.email"}},'v-text-field',attrs,false))]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('form-group',{attrs:{"validator":_vm.$v.signupForm.department,"attribute":"resources.users.fields.department"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-autocomplete',_vm._b({attrs:{"item-value":"id","item-text":"all_name","items":_vm.departments,"clearable":"","label":_vm.$t('resources.users.fields.department'),"outlined":""},model:{value:(_vm.signupForm.department),callback:function ($$v) {_vm.$set(_vm.signupForm, "department", $$v)},expression:"signupForm.department"}},'v-autocomplete',attrs,false))]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('form-group',{attrs:{"validator":_vm.$v.signupForm.tel,"attribute":"resources.users.fields.tel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"label":_vm.$t('resources.users.fields.tel'),"maxlength":"21","outlined":""},model:{value:(_vm.signupForm.tel),callback:function ($$v) {_vm.$set(_vm.signupForm, "tel", $$v)},expression:"signupForm.tel"}},'v-text-field',attrs,false))]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('form-group',{attrs:{"validator":_vm.$v.signupForm.password1,"attribute":"resources.users.fields.password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"label":_vm.$t('resources.users.fields.password'),"counter":"","maxlength":"128","minlength":"6","outlined":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.signupForm.password1),callback:function ($$v) {_vm.$set(_vm.signupForm, "password1", $$v)},expression:"signupForm.password1"}},'v-text-field',attrs,false))]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('form-group',{attrs:{"validator":_vm.$v.signupForm.password2,"attribute":"resources.users.fields.verify"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({attrs:{"block":"","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"label":_vm.$t('resources.users.fields.verify'),"counter":"","maxlength":"128","outlined":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.signupForm.password2),callback:function ($$v) {_vm.$set(_vm.signupForm, "password2", $$v)},expression:"signupForm.password2"}},'v-text-field',attrs,false))]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('form-group',{attrs:{"validator":_vm.$v.signupForm.workflow_user1,"attribute":"resources.users.fields.workflow_user1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-autocomplete',_vm._b({attrs:{"items":_vm.managerUsers,"item-value":"id","item-text":"all_name","label":_vm.$t('resources.users.fields.workflow_user1'),"outlined":""},model:{value:(_vm.signupForm.workflow_user1),callback:function ($$v) {_vm.$set(_vm.signupForm, "workflow_user1", $$v)},expression:"signupForm.workflow_user1"}},'v-autocomplete',attrs,false))]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('form-group',{attrs:{"validator":_vm.$v.signupForm.workflow_user2,"attribute":"resources.users.fields.workflow_user2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-autocomplete',_vm._b({attrs:{"items":_vm.managerUsers,"item-value":"id","item-text":"all_name","label":_vm.$t('resources.users.fields.workflow_user2'),"outlined":""},model:{value:(_vm.signupForm.workflow_user2),callback:function ($$v) {_vm.$set(_vm.signupForm, "workflow_user2", $$v)},expression:"signupForm.workflow_user2"}},'v-autocomplete',attrs,false))]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('form-group',{attrs:{"validator":_vm.$v.signupForm.proxy_user,"attribute":"resources.users.fields.proxy_user"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-autocomplete',_vm._b({attrs:{"items":_vm.users,"item-value":"id","item-text":"all_name","label":_vm.$t('resources.users.fields.proxy_user'),"outlined":""},model:{value:(_vm.signupForm.proxy_user),callback:function ($$v) {_vm.$set(_vm.signupForm, "proxy_user", $$v)},expression:"signupForm.proxy_user"}},'v-autocomplete',attrs,false))]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('form-group',{attrs:{"validator":_vm.$v.signupForm.role_normal,"attribute":"resources.users.fields.role_normal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-checkbox',_vm._b({attrs:{"label":_vm.$t('resources.users.fields.role_normal'),"color":"primary"},model:{value:(_vm.signupForm.role_normal),callback:function ($$v) {_vm.$set(_vm.signupForm, "role_normal", $$v)},expression:"signupForm.role_normal"}},'v-checkbox',attrs,false))]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('form-group',{attrs:{"validator":_vm.$v.signupForm.role_approver,"attribute":"resources.users.fields.role_approver"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-checkbox',_vm._b({attrs:{"label":_vm.$t('resources.users.fields.role_approver'),"color":"primary"},model:{value:(_vm.signupForm.role_approver),callback:function ($$v) {_vm.$set(_vm.signupForm, "role_approver", $$v)},expression:"signupForm.role_approver"}},'v-checkbox',attrs,false))]}}])})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":_vm.loading}},[_vm._v(_vm._s(_vm.$t("auth.signup")))])],1)],1)],1)],1)],1)],2)],1)],1)],1)],1),_c('Confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }