import Vue from "vue";
import VueRouter from "vue-router";

import AppFrame from "@/components/views/AppFrame";
import store from "../store";
import { Notify } from "vuetify-message-snackbar";
import i18n from "../plugins/i18n";
import {
  BooleanType,
  NumberType,
  NumberArrayType,
  StringType,
  StringArrayType,
} from "../configs/filterTypeConfig";

Vue.use(VueRouter);

const castId = (route) => {
  const id = Number.parseInt(route.params.id, 10);
  if (Number.isNaN(id)) {
    return 0;
  }
  return { id };
};

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/components/views/Login.vue"),
    beforeEnter: async (to, from, next) => {
      if (store.getters.isAuthenticated) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/password-reset",
    name: "passwordReset",
    component: () => import("@/components/views/PasswordReset.vue"),
  },
  {
    path: "/password-reset/confirm/:uid/:token",
    name: "passwordResetConfirm",
    component: () => import("@/components/views/PasswordResetConfirm.vue"),
    props: true,
  },
  {
    path: "/",
    name: "app-frame",
    beforeEnter: (to, from, next) => {
      if (to.path === "/") {
        if (store.getters.identity["adcom"]) {
          next({ name: "caseList" });
        } else {
          next({ name: "quotationList" });
        }
      }
      next();
    },
    component: AppFrame,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        // 案件管理
        path: "cm",
        name: "cm",
        component: {
          render: (h) => h("router-view"),
        },
        children: [
          {
            // 案件
            path: "cases",
            name: "cases",
            component: {
              render: (h) => h("router-view"),
            },
            children: [
              //案件一覧
              {
                path: "CaseList",
                name: "caseList",
                component: () => import("@/components/cm/Cases/CaseList.vue"),
                meta: {
                  filterTypes: {
                    client_user: NumberType,
                    client_department: NumberType,
                    status: NumberArrayType,
                    report_name: StringArrayType,
                    cost_burden_department: NumberType,
                    contact_user: NumberType,
                    order_no: StringType,
                    preferred_due_date_after: StringType,
                    preferred_due_date_before: StringType,
                    order_date_after: StringType,
                    order_date_before: StringType,
                    scheduled_due_date_after: StringType,
                    scheduled_due_date_before: StringType,
                    quotation_amount_min: NumberType,
                    quotation_amount_max: NumberType,
                    search: StringType,
                    show_deleted: BooleanType,
                    c_no: StringType,
                    c_revision_no: StringType,
                  },
                },
              },
              //見積一覧
              {
                path: "QuotationList",
                name: "quotationList",
                component: () =>
                  import("@/components/cm/Cases/QuotationList.vue"),
                meta: {
                  filterTypes: {
                    client_user: NumberType,
                    client_department: NumberType,
                    status: NumberArrayType,
                    report_name: StringArrayType,
                    cost_burden_department: NumberType,
                    order_date_after: StringType,
                    order_date_before: StringType,
                    scheduled_due_date_after: StringType,
                    scheduled_due_date_before: StringType,
                    quotation_amount_min: NumberType,
                    quotation_amount_max: NumberType,
                    search: StringType,
                    show_deleted: BooleanType,
                    c_no: StringType,
                    c_revision_no: StringType,
                  },
                },
              },
              //案件登録
              {
                path: "create/:category",
                name: "casesCreate",
                component: () => import("@/components/cm/Cases/Create.vue"),
              },
              //案件更新
              {
                path: ":id",
                name: "casesEdit",
                component: () => import("@/components/cm/Cases/Edit.vue"),
                props: castId,
              },
              //承認履歴
              {
                path: ":id/history",
                name: "history",
                component: () => import("@/components/cm/Cases/History.vue"),
                props: castId,
              },
            ],
          },
          //完了一覧
          {
            path: "completedCases",
            name: "completedCases",
            component: () => import("@/components/cm/CompletedCases/index.vue"),
          },
          //検収確認書
          {
            path: "certificates/:id",
            name: "certificatesReport",
            component: () => import("@/components/cm/Certificates/Report.vue"),
            props: castId,
          },
          //納品確認書
          {
            path: "deliveryNotes/:id",
            name: "deliveryNotesReport",
            component: () => import("@/components/cm/DeliveryNotes/Report.vue"),
            props: castId,
          },
          //注文請書
          {
            path: "orderReceipts/:id",
            name: "orderReceiptsReport",
            component: () => import("@/components/cm/OrderReceipts/Report.vue"),
            props: castId,
          },
          //見積書
          {
            path: "quotations/:id",
            name: "quotationsReport",
            component: () => import("@/components/cm/Quotations/Report.vue"),
            props: castId,
          },
          //一括承認（クライアント）
          {
            path: "bulkApprove/:resource",
            name: "bulkApprove",
            component: () => import("@/components/cm/WorkFlow/List.vue"),
            props: true,
          },
          //特定承認
          {
            path: "specificApprovalRoutesWorkflow/:id",
            name: "specificApprovalRoutesWorkflow",
            component: () =>
              import("@/components/cm/SpecificApprovalRoutes/Workflow.vue"),
            props: castId,
          },
          //請求書発行許可ワークフロー
          {
            path: "invoicings/:id",
            name: "invoicingsWorkflow",
            component: () => import("@/components/cm/Invoicings/Workflow.vue"),
            props: castId,
          },
          //案件完了取消後完了承認ワークフロー
          {
            path: "recompletions/:id",
            name: "recompletionsWorkflow",
            component: () =>
              import("@/components/cm/Recompletions/Workflow.vue"),
            props: castId,
          },
          //赤黒案件完了承認ワークフロー
          {
            path: "redBlackCompletions/:id",
            name: "redBlackCompletionsWorkflow",
            component: () =>
              import("@/components/cm/RedBlackCompletions/Workflow.vue"),
            props: castId,
          },
          //調達部発注書処理ワークフロー
          {
            path: "returnPurchaseOrders/:id",
            name: "returnPurchaseOrdersWorkflow",
            component: () =>
              import("@/components/cm/ReturnPurchaseOrders/Workflow.vue"),
            props: castId,
          },
        ],
      },
      //ユーザ登録
      {
        path: "users/create",
        name: "usersCreate",
        component: () => import("@/components/Users/Create.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL || "/",
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (
    to.matched.some(
      (record) => record.meta?.requireAuth && !store.getters.isAuthenticated
    )
  ) {
    if (store.getters.token) {
      try {
        await store.dispatch("verifyToken");
      } catch (error) {
        Notify.error(i18n.t("messages.auth.authCheckError"));
      } finally {
        if (store.getters.isAuthenticated) {
          next();
        } else {
          const currentPath = to.path;
          next({ name: "login", params: { redirect: currentPath } });
        }
      }
    } else {
      const currentPath = to.path;
      next({ name: "login", params: { redirect: currentPath } });
    }
  } else {
    next();
  }
});

export default router;
