/** ステータス */
export const statuses = {
  /** 見積依頼作成中(10) */
  PendingRequests: "10",
  /** 見積書作成(20) */
  CreateQuotation: "20",
  /** 見積書提出済(30) */
  QuotationSubmitted: "30",
  /** 注文内容変更中(40) */
  ChangingOrderDetails: "40",
  /** 受注済(50) */
  Ordered: "50",
  /** 納品確認書作成(60) */
  CreateDeliveryNote: "60",
  /** 検収確認中(70) */
  CheckingAcceptance: "70",
  /** 完了(80) */
  Completion: "80",
  /** 別途問合せ(51) */
  Inquiry: "51",
  /** 赤黒処理中(52) */
  ReversalAndCorrectingEntry: "52",
  /** 発行許可中(61) */
  IssuingPermission: "61",
  /** 返送許可中(62) */
  ReturnPermission: "62",
  /** 発行準備中(71) */
  PublishPreparation: "71",
  /** 返送準備中(72) */
  ReplyPreparation: "72",
};
