import { CRUD_OPERATION } from "../../configs/crudConfig";
export default {
  data() {
    return {
      loading: false,
      formData: {},
    };
  },
  validations: {},
  methods: {
    back() {
      this.$router.back();
    },
    gotoEdit(id) {
      this.$router.push({
        name: `${this.$options.resource}${CRUD_OPERATION.EDIT}`,
        params: { id },
      });
    },
    // @vuese
    // 登録/画面遷移
    async save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        try {
          const { id } = await this.$api.create(
            this.$options.resource,
            this.formData
          );
          if (id) {
            this.$message.success(this.$t("messages.success.create"));
          }
          this.gotoEdit(id);
        } finally {
          this.$v.$reset();
          this.loading = false;
        }
      }
    },
  },
};
