import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["identity"]),
  },
  methods: {
    hasPerm() {
      const conds = [...arguments];
      const userIdentity = this.identity;
      return conds.some((cond) => userIdentity[cond]);
    },
  },
};
