<template>
  <v-container class="lighten-5" id="main_container">
    <form-wrapper :validator="$v.formData">
      <v-form ref="inputForm" lazy-validation>
        <v-card class="mx-auto mt-5" width="60rem">
          <v-system-bar color="indigo darken-2" dark class="pa-5">
            {{ $t("resources.deliveryNotes.header_title") }}
          </v-system-bar>
          <v-container fluid class="pa-5">
            <v-card class="d-flex justify-center text-h4 mb-6" flat tile>
              {{ $t("resources.deliveryNotes.name") }}
            </v-card>

            <v-row class="mx-10">
              <v-card class="d-flex flex-column mr-auto" flat tile>
                <v-card class="text-h5" flat tile>
                  {{
                    $get(
                      "deliveryNoteData.quotation.case.client_department.name",
                      ""
                    )
                  }}
                  御中
                </v-card>
                <v-card class="pt-2" flat tile>
                  {{
                    $get(
                      "deliveryNoteData.quotation.case.client_user.full_name",
                      ""
                    )
                  }}
                  様
                </v-card>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.deliveryNotes.fields.request_title") }}：
                  {{
                    $get("deliveryNoteData.quotation.case.request_title", "")
                  }}
                </v-card>
                <v-card class="pt-4 text-h5" flat tile>
                  {{ $t("resources.deliveryNotes.fields.quotation_amount") }}：
                  {{ quotationPriceFormatted }} 円（税別）
                </v-card>
                <v-card class="pt-4" flat tile>
                  {{ $t("resources.deliveryNotes.fields.department_code") }}：
                  {{
                    $get(
                      "deliveryNoteData.quotation.case.client_department.code",
                      ""
                    )
                  }}
                </v-card>
                <v-card class="pt-2" flat tile>
                  {{
                    $t("resources.deliveryNotes.fields.delivery_destination")
                  }}：
                  {{ deliveryDestinationValue }}
                </v-card>
                <v-card class="pt-2" flat tile v-if="isStorageNumberInput">
                  {{ $t("resources.cases.fields.storage_number") }}：
                  {{ storageNumberFormatted }}
                </v-card>
                <v-card class="pt-2" flat tile v-if="isStorageNumberInput">
                  {{ $t("resources.cases.fields.catalog_storage_quantity") }}：
                  {{ catalogStorageQuantityFormatted }}
                </v-card>
                <v-card class="pt-2" flat tile>
                  {{
                    $t("resources.deliveryNotes.fields.scheduled_due_date")
                  }}：
                  {{
                    $get(
                      "deliveryNoteData.quotation.case.scheduled_due_date",
                      ""
                    )
                  }}
                </v-card>
              </v-card>
              <v-card class="d-flex flex-column ml-auto" flat tile>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.deliveryNotes.fields.delivery_note_no") }}：
                  {{ deliveryNoteNoFormatted }}
                </v-card>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.deliveryNotes.fields.case_no") }}：
                  {{ $get("deliveryNoteData.quotation.case.case_no", "") }}
                </v-card>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.deliveryNotes.fields.issue_date") }}：
                  {{ $get("deliveryNoteData.issue_date", "") }}
                </v-card>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.deliveryNotes.fields.expense_account") }}：
                  {{
                    $get(
                      "deliveryNoteData.quotation.case.expense_account.name",
                      ""
                    )
                  }}
                  (
                  {{
                    $get(
                      "deliveryNoteData.quotation.case.expense_account.code",
                      ""
                    )
                  }}
                  )
                </v-card>
                <div v-if="deliveryNoteData && deliveryNoteData.company_name">
                  <template
                    v-for="(line, index) in deliveryNoteData.company_name.split(
                      '<br>'
                    )"
                  >
                    <v-card class="pt-2" flat tile :key="index">
                      {{ line.trim() }}
                      <!-- Render each line, trimming any leading/trailing whitespace -->
                    </v-card>
                  </template>
                </div>
                <v-card class="pt-2" flat tile>
                  {{
                    $get(
                      "deliveryNoteData.quotation.case.contact_user.full_name"
                    )
                  }}
                </v-card>
              </v-card>
            </v-row>

            <v-row v-if="isShowRequestCategory" class="mx-10">
              <v-card class="d-flex flex-column mr-auto" flat tile>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.deliveryNotes.fields.c_no") }}：
                  {{ $get("deliveryNoteData.quotation.case.c_no", "") }}
                </v-card>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.deliveryNotes.fields.size") }}：
                  {{ $get("deliveryNoteData.quotation.case.size", "") }}
                </v-card>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.deliveryNotes.fields.language_class") }}：
                  {{
                    $get(
                      "deliveryNoteData.quotation.case.language_class.name",
                      ""
                    )
                  }}
                </v-card>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.deliveryNotes.fields.old_c_revision_no") }}：
                  {{
                    $get(
                      "deliveryNoteData.quotation.case.old_c_revision_no",
                      ""
                    )
                  }}
                </v-card>
              </v-card>

              <v-card class="d-flex flex-column text-xs-center" flat tile>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.deliveryNotes.fields.c_revision_no") }}：
                  {{
                    $get("deliveryNoteData.quotation.case.c_revision_no", "")
                  }}
                </v-card>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.deliveryNotes.fields.page_count") }}：
                  {{ $get("deliveryNoteData.quotation.case.page_count", "") }}
                </v-card>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.deliveryNotes.fields.old_c_no") }}：
                  {{ $get("deliveryNoteData.quotation.case.old_c_no", "") }}
                </v-card>
              </v-card>

              <v-card class="d-flex flex-column ml-auto" flat tile>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.deliveryNotes.fields.edition") }}：
                  {{ $get("deliveryNoteData.quotation.case.edition.name", "") }}
                </v-card>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.deliveryNotes.fields.delivery_form") }}：
                  {{
                    $get(
                      "deliveryNoteData.quotation.case.delivery_form.name",
                      ""
                    )
                  }}
                </v-card>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.deliveryNotes.fields.old_processing") }}：
                  {{
                    $get(
                      "deliveryNoteData.quotation.case.old_processing.name",
                      ""
                    )
                  }}
                </v-card>
              </v-card>
            </v-row>
            <v-row v-if="isShowRequestCategoryAdData" class="mx-10">
              <v-card class="d-flex flex-column mr-auto" flat tile>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.cases.fields.media_name") }}：
                  {{ $get("deliveryNoteData.quotation.case.media_name", "") }}
                </v-card>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.cases.fields.ad_name") }}：
                  {{ $get("deliveryNoteData.quotation.case.ad_name", "") }}
                </v-card>
              </v-card>
              <v-card class="d-flex flex-column mr-auto" flat tile>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.cases.fields.plate_size") }}：
                  {{
                    $get("deliveryNoteData.quotation.case.plate_size.name", "")
                  }}
                </v-card>
                <v-card
                  class="pt-2"
                  flat
                  tile
                  v-if="deliveryNoteData.quotation.case.plate_height"
                >
                  {{ $t("resources.cases.fields.plate_height") }}：
                  {{ plateHeightFormatted }}
                </v-card>
                <v-card
                  class="pt-2"
                  flat
                  tile
                  v-if="deliveryNoteData.quotation.case.plate_width"
                >
                  {{ $t("resources.cases.fields.plate_width") }}：
                  {{ plateWidthFormatted }}
                </v-card>
              </v-card>
              <v-card class="d-flex flex-column ml-auto" flat tile>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.cases.fields.ad_format") }}：
                  {{
                    $get("deliveryNoteData.quotation.case.ad_format.name", "")
                  }}
                </v-card>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.cases.fields.color") }}：
                  {{ $get("deliveryNoteData.quotation.case.color.name", "") }}
                </v-card>
                <v-card
                  class="pt-2"
                  flat
                  tile
                  v-if="deliveryNoteData.quotation.case.other_color"
                >
                  {{ $t("resources.cases.fields.other_color") }}：
                  {{ $get("deliveryNoteData.quotation.case.other_color", "") }}
                </v-card>
              </v-card>
            </v-row>
            <v-row v-if="isShowRequestCategoryPanelData" class="mx-10">
              <v-card class="d-flex flex-column mr-auto" flat tile>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.cases.fields.desired_panel_no") }}：
                  {{
                    $get("deliveryNoteData.quotation.case.desired_panel_no", "")
                  }}
                </v-card>
              </v-card>
              <v-card class="d-flex flex-column mr-auto" flat tile>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.cases.fields.hospital_name") }}：
                  {{
                    $get("deliveryNoteData.quotation.case.hospital_name", "")
                  }}
                </v-card>
              </v-card>
              <v-card class="d-flex flex-column mr-auto" flat tile>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.cases.fields.panel_size") }}：
                  {{
                    $get("deliveryNoteData.quotation.case.panel_size.name", "")
                  }}
                </v-card>
                <v-card
                  class="pt-2"
                  flat
                  tile
                  v-if="deliveryNoteData.quotation.case.panel_height"
                >
                  {{ $t("resources.cases.fields.panel_height") }}：
                  {{ panelHeightFormatted }}
                </v-card>
                <v-card
                  class="pt-2"
                  flat
                  tile
                  v-if="deliveryNoteData.quotation.case.panel_width"
                >
                  {{ $t("resources.cases.fields.panel_width") }}：
                  {{ panelWidthFormatted }}
                </v-card>
              </v-card>
            </v-row>
            <v-row
              v-if="isShowRequestCategorySpecialPrintingDetails"
              class="mx-10"
            >
              <v-card class="mr-auto" flat tile>
                <v-card class="pt-2" flat tile>
                  {{ $t("resources.cases.fields.special_printing_detail") }}：
                </v-card>
              </v-card>
              <v-textarea
                v-model="formData.special_printing_detail"
                class="pt-0"
                :readonly="true"
                :rounded="true"
              >
              </v-textarea>
            </v-row>

            <v-row justify="start">
              <v-col>
                <v-card outlined>
                  <v-data-table
                    :items="obicCostBurdenDepartments"
                    :headers="obicCostBurdenDepartmentHeaders"
                    disable-pagination
                    hide-default-footer
                  >
                    <template v-slot:[`item.total_burden_amount`]="{ item }">
                      {{ toLocaleString(item.total_burden_amount) }}
                    </template>
                    <template
                      v-slot:[`item.average_input_burden_rate`]="{ item }"
                    >
                      {{ toLocaleString(item.average_input_burden_rate) }}
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>

            <v-row justify="start">
              <v-col cols="12" class="">
                <v-card outlined>
                  <DataTable
                    resource="quotationDetails"
                    parentRelationName="quotation"
                    :parentId="this.quotationId"
                    :defaultItem="defaultDetailItem"
                    :headers="quotationDetailHeadersReport"
                    :showAddButton="false"
                    :showEditButton="false"
                    :showDeleteButton="false"
                  >
                    <template v-slot:[`item.amount`]="{ item }">
                      {{ toLocaleString(item.amount) }}
                    </template>
                    <template v-slot:[`item.unit_cost`]="{ item }">
                      {{ toLocaleString(item.unit_cost) }}
                    </template>
                    <template v-slot:[`item.quantity`]="{ item }">
                      {{ toLocaleString(item.quantity) }}
                    </template>
                  </DataTable>
                </v-card>
              </v-col>
            </v-row>
            <v-card class="d-flex justify-end" flat tile>
              <v-card class="pt-2 mr-10" flat tile>
                <form-group
                  :validator="$v.total_amount"
                  attribute="resources.quotations.fields.sub_total"
                >
                  <template v-slot="{ attrs }">
                    <v-text-field
                      v-bind="attrs"
                      :value="totalAmountFormatted"
                      flat
                      reverse
                      prefix="円（税別）"
                      :suffix="$t(`resources.quotations.fields.sub_total`)"
                      readonly
                    ></v-text-field>
                  </template>
                </form-group>
              </v-card>
            </v-card>
            <v-textarea
              v-model="formData.remarks"
              class="mt-6"
              :label="$t('resources.deliveryNotes.fields.remarks')"
              :outlined="propsStandardItem"
              :readonly="!propsStandardItem"
              :rounded="!propsStandardItem"
            ></v-textarea>
          </v-container>
        </v-card>

        <!--承認フロー-->
        <WorkflowAction
          v-show="IsWorkflow"
          :id="displayWorkflowId"
          @updated="changeWorkflow"
        ></WorkflowAction>

        <WorkflowClientReject
          v-show="IsWorkflowReject"
          :report="deliveryNoteData"
          @updated="changeWorkflowReject"
        ></WorkflowClientReject>

        <!--承認履歴-->
        <WorkflowHistory
          :refreshKey="workflowRefreshKey"
          :workflow_id="workflowId"
        ></WorkflowHistory>

        <v-bottom-navigation fixed horizontal scroll-target="#main_container">
          <v-btn @click="moveTop()" :disabled="loading" text>
            <span>{{ $t("moveTop") }}</span>
            <v-icon>mdi-home</v-icon>
          </v-btn>
          <v-btn @click="moveCase()" :disabled="loading" text>
            <span>{{ $t("moveCase") }}</span>
            <v-icon>mdi-clipboard-text</v-icon>
          </v-btn>
          <v-btn v-if="isShowSave" @click="save()" :disabled="loading" text>
            <span>{{ $t("save") }}</span>
            <v-icon>mdi-content-save-outline</v-icon>
          </v-btn>
          <!-- 見積書番号採番用に準備したボタン、必要であれば復活させる
          -->
          <v-btn
            v-if="isShowCertificateReportCreate"
            @click="certificateReportCreate()"
            :disabled="loading"
            text
          >
            <span>{{
              $t("resources.deliveryNotes.actions.certificate_create")
            }}</span>
            <v-icon>mdi-file-document-edit-outline</v-icon>
          </v-btn>
          <v-btn v-if="isShowPrint" @click="print()" :disabled="loading" text>
            <span>{{ $t("resources.deliveryNotes.actions.print") }}</span>
            <v-icon>mdi-file-download-outline</v-icon>
          </v-btn>
        </v-bottom-navigation>
      </v-form>
    </form-wrapper>
  </v-container>
</template>
<script>
import groupPermissionMixin from "../../mixins/groupPermissionMixin";
import updateMixin from "../../mixins/updateMixin";
import relationMixin from "../../mixins/relationMixin";
import fieldPermissionMixin from "../../mixins/fieldPermissionMixin";
import { validationMixin } from "vuelidate";
import headersMixin from "../../mixins/headersMixin";
import { mapGetters } from "vuex";
import { requestCategorys } from "../../../configs/requestCategoryConfig";
import { statuses } from "../../../configs/statusConfig";
import { workfrowStatuses } from "../../../configs/workfrowStatusConfig";
import { saveAs } from "file-saver";

/**
 * @group 帳票
 * 納品確認書画面<br>
 * 機能仕様書：4.7, 5.9, 5.11, 5.12, 5.13, 5.18, 6.1, 6.2, 6.4
 */
export default {
  name: "DeliveryNotes.Report",
  // components: { WorkflowAction },
  resource: "deliveryNotes",
  mixins: [
    groupPermissionMixin,
    fieldPermissionMixin,
    updateMixin,
    validationMixin,
    headersMixin,
    relationMixin,
  ],

  validations() {
    const formData = {
      remarks: {},
    };
    return { formData };
  },
  data() {
    return {
      // loading: false,
      formData: {
        delivery_note_no: "",
        delivery_completion_date: "",
        issue_date: "",
        remarks: "",
        special_printing_detail: "",
      },
      deliveryNoteData: {},
      /**
       * @vuese
       * OBICの費用負担部門情報
       */
      obicCostBurdenDepartments: [],
      quotationData: {},
      totalAmount: null,
      dialog: false,
      workflowRefreshKey: 0,
      /**
       * @vuese
       * 検収確認書の情報を保持
       */
      certificate: [],
      /**
       * @vuese
       * 納品確認書のワークフローステータスの情報
       */
      deliveryNoteWorkflowStatus: workfrowStatuses.NoWorkfrow,
      /**
       * @vuese
       * 検収確認書のワークフローステータスの情報
       */
      certificateWorkflowStatus: workfrowStatuses.NoWorkfrow,
    };
  },
  async beforeCreate() {
    console.log("beforeCreate");
  },
  beforeMount() {
    console.log("beforeMount");
  },
  computed: {
    ...mapGetters(["currentUser"]),
    quotationPriceFormatted() {
      return (
        this.deliveryNoteData?.quotation?.case?.total_selling_price?.toLocaleString() ||
        ""
      );
    },
    storageNumberFormatted() {
      return (
        this.deliveryNoteData?.quotation?.case?.storage_number?.toLocaleString() ||
        ""
      );
    },
    catalogStorageQuantityFormatted() {
      return (
        (
          this.deliveryNoteData?.quotation?.case?.request_count -
          this.deliveryNoteData?.quotation?.case?.storage_number
        )?.toLocaleString() || ""
      );
    },
    plateHeightFormatted() {
      return (
        this.deliveryNoteData?.quotation?.case?.plate_height?.toLocaleString() ||
        ""
      );
    },
    plateWidthFormatted() {
      return (
        this.deliveryNoteData?.quotation?.case?.plate_width?.toLocaleString() ||
        ""
      );
    },
    panelHeightFormatted() {
      return (
        this.deliveryNoteData?.quotation?.case?.panel_height?.toLocaleString() ||
        ""
      );
    },
    panelWidthFormatted() {
      return (
        this.deliveryNoteData?.quotation?.case?.panel_width?.toLocaleString() ||
        ""
      );
    },
    totalAmountFormatted() {
      return this.totalAmount?.toLocaleString() || "";
    },
    deliveryNoteNoFormatted() {
      if (this.deliveryNoteData?.delivery_note_no) {
        return (
          this.deliveryNoteData?.delivery_note_no +
          "-" +
          ("0000" + this.deliveryNoteData?.delivery_note_no_seq).slice(-4)
        );
      } else {
        return "";
      }
    },
    deliveryDestinationValue() {
      if (this.deliveryNoteData?.quotation?.case?.delivery_destination_manual) {
        return this.deliveryNoteData?.quotation?.case
          ?.delivery_destination_manual;
      } else {
        return (
          this.deliveryNoteData?.quotation?.case?.delivery_destination?.name ||
          ""
        );
      }
    },
    isStorageNumberInput() {
      if (
        this.deliveryNoteData?.quotation?.case?.delivery_destination?.code ===
        "3"
      ) {
        return true;
      }
      return false;
    },
    defaultDetailItem() {
      return {
        id: null,
        quotation: this.formData.id,
        detail_no: null,
        item: null,
        content: null,
        quantity: null,
        unit: null,
        unit_cost: null,
        amount: null,
        version: null,
      };
    },
    workflowId() {
      return [
        this.deliveryNoteData?.adcom_workflow,
        this.deliveryNoteData?.client_workflow,
      ].filter((id) => id);
    },
    displayWorkflowId() {
      return this.hasPerm(`adcomStaff`, `adcomManager`)
        ? this.deliveryNoteData?.adcom_workflow || null
        : this.deliveryNoteData?.client_workflow || null;
    },

    /**
     * @vuese
     * カタログ情報の表示非表示設定
     */
    isShowRequestCategory() {
      return (
        this.deliveryNoteData.quotation?.case?.request_category ===
        requestCategorys.Catalog
      );
    },
    /**
     * @vuese
     * 医用広告データ情報の表示非表示設定
     */
    isShowRequestCategoryAdData() {
      return (
        this.deliveryNoteData.quotation?.case?.request_category ===
        requestCategorys.medical_ad_data
      );
    },
    /**
     * @vuese
     * 院内パネル情報の表示非表示設定
     */
    isShowRequestCategoryPanelData() {
      return (
        this.deliveryNoteData.quotation?.case?.request_category ===
        requestCategorys.hospital_panel_data
      );
    },
    /**
     * @vuese
     * 特殊印刷詳細情報の表示非表示設定
     */
    isShowRequestCategorySpecialPrintingDetails() {
      return (
        this.deliveryNoteData.quotation?.case?.request_category ===
        requestCategorys.special_printing_detail
      );
    },
    /**
     * @vuese
     * アドコム権限の表示非表示設定
     */
    isShowAdcomUser() {
      return this.hasPerm("adcom");
    },
    /**
     * @vuese
     * 検収確認書作成の表示非表示設定
     */
    isShowCertificateReportCreate() {
      return (
        this.hasPerm("client") &&
        this.isClientContact &&
        this.deliveryNoteWorkflowStatus === workfrowStatuses.Approval &&
        !this.isCertificateReportExists
      );
    },
    /**
     * @vuese
     * 案件情報ID
     */
    caseId() {
      return this.deliveryNoteData?.quotation?.case?.id;
    },
    /**
     * @vuese
     * 見積書ID
     */
    quotationId() {
      return this.deliveryNoteData?.quotation?.id;
    },
    /**
     * @vuese
     * 保存ボタンの表示非表示設定
     */
    isShowSave() {
      return (
        this.hasPerm("adcom") &&
        (!this.deliveryNoteWorkflowStatus ||
          this.deliveryNoteWorkflowStatus === workfrowStatuses.NoWorkfrow)
      );
    },
    /**
     * @vuese
     * 印刷ボタンの表示非表示設定
     */
    isShowPrint() {
      return this.deliveryNoteWorkflowStatus === workfrowStatuses.Approval;
    },
    /**
     * @vuese
     * ワークフロー申請および削除時入力制御
     */
    isInput() {
      return (
        this.deliveryNoteWorkflowStatus == workfrowStatuses.NoWorkfrow &&
        this.deliveryNoteData?.quotation?.case?.status === statuses.Ordered &&
        !this.deliveryNoteData?.quotation?.case?.delete_flag
      );
    },
    /**
     * @vuese
     * クライアント担当者での制限
     */
    isClientContact() {
      return (
        this.deliveryNoteData?.quotation?.case?.client_user?.id ===
          this.currentUser?.id ||
        this.deliveryNoteData?.quotation?.case?.client_user?.proxy_user ==
          this.currentUser?.id
      );
    },
    /**
     * @vuese
     * アドコム担当者での制限
     */
    isAdcomContact() {
      return (
        this.deliveryNoteData?.quotation?.case?.contact_user?.id ===
          this.currentUser?.id ||
        this.deliveryNoteData?.quotation?.case?.contact_user?.proxy_user ===
          this.currentUser?.id
      );
    },
    /**
     * @vuese
     * 検収確認書の存在有無
     */
    isCertificateReportExists() {
      return this.certificate && this.certificate.length > 0;
    },
    /**
     * @vuese
     * 標準項目入力項目制御
     */
    propsStandardItem() {
      return this.isInput ? this.hasPerm("adcomStaff") : false;
    },
    /**
     * @vuese
     * ワークフローコンポーネントの表示非表示設定
     */
    IsWorkflow() {
      return (
        (this.hasPerm("adcomStaff") && this.isAdcomContact) ||
        (this.hasPerm("adcomManager") &&
          this.deliveryNoteWorkflowStatus !== workfrowStatuses.NoWorkfrow)
      );
    },
    /**
     * @vuese
     * 却下ワークフローコンポーネントの表示非表示設定
     */
    IsWorkflowReject() {
      return (
        this.hasPerm("clientStaff") &&
        this.isClientContact &&
        this.deliveryNoteWorkflowStatus == workfrowStatuses.Approval &&
        !this.isCertificateReportExists
      );
    },
  },
  methods: {
    /**
     * @vuese
     * データ取得メソッド
     */
    async fetchData(id) {
      this.loading = true;
      try {
        const [deliveryNoteData] = await Promise.all([
          this.$api.getOne(this.$options.resource, id),
          this.getDeliveryNoteWorkflowStatus(),
        ]);
        this.deliveryNoteData = deliveryNoteData;
        this.$utils.update(this.formData, deliveryNoteData);

        await this.join(this.deliveryNoteData, [
          { name: "quotation", resource: "quotations" },
        ]);
        await Promise.all([
          this.join(this.deliveryNoteData.quotation, [
            { name: "case", resource: "cases" },
          ]),
          this.quotationAmount(),
        ]);
        [this.obicCostBurdenDepartments, this.certificate] = await Promise.all([
          this.$api.dispatch(
            "GET",
            "cases",
            "get_obic_cost_burden_departments",
            this.deliveryNoteData.quotation.case.id
          ),
          this.$api.getMany("certificates", {
            quotation: this.deliveryNoteData.quotation.id,
            rejected_flag: false,
          }),
        ]);
        //case.idを更新前に退避
        const case_id = this.deliveryNoteData.quotation.case.id;
        // 見積書に保持している情報で表示するために、案件情報の内容を変更する
        this.$utils.update(
          this.deliveryNoteData.quotation.case,
          this.deliveryNoteData.quotation
        );
        //case.idを更新後に戻す
        this.deliveryNoteData.quotation.case.id = case_id;

        //特殊印刷詳細の値の割り当て
        this.formData.special_printing_detail =
          this.deliveryNoteData.quotation.case.special_printing_detail;

        await this.join(this.deliveryNoteData, [
          {
            name: "client_user",
            resource: "users",
          },
          {
            name: "client_department",
            resource: "departments",
          },
          {
            name: "delivery_destination",
            resource: "deliveryDestinations",
          },
          {
            name: "edition",
            resource: "editions",
          },
          {
            name: "expense_account",
            resource: "expenseAccounts",
          },
          {
            name: "language_class",
            resource: "languageClasses",
          },
          {
            name: "delivery_form",
            resource: "deliveryForms",
          },
          {
            name: "old_processing",
            resource: "processings",
          },
          {
            name: "contact_user",
            resource: "users",
          },
          { name: "color", resource: "colors" },
          { name: "ad_format", resource: "adFormats" },
          { name: "panel_size", resource: "panelSizes" },
          { name: "plate_size", resource: "plateSizes" },
        ]);
        await this.join(this.deliveryNoteData.quotation.case, [
          {
            name: "client_user",
            resource: "users",
          },
          {
            name: "client_department",
            resource: "departments",
          },
          {
            name: "delivery_destination",
            resource: "deliveryDestinations",
          },
          {
            name: "edition",
            resource: "editions",
          },
          {
            name: "expense_account",
            resource: "expenseAccounts",
          },
          {
            name: "language_class",
            resource: "languageClasses",
          },
          {
            name: "delivery_form",
            resource: "deliveryForms",
          },
          {
            name: "old_processing",
            resource: "processings",
          },
          {
            name: "contact_user",
            resource: "users",
          },
          { name: "color", resource: "colors" },
          { name: "ad_format", resource: "adFormats" },
          { name: "panel_size", resource: "panelSizes" },
          { name: "plate_size", resource: "plateSizes" },
        ]);
        // toDO: If the data is present in newly build deliveryNote model then use the value of deliveryNote
        // we check using request_count because it is Required field
        if (this.deliveryNoteData.request_count) {
          this.$utils.update(
            this.deliveryNoteData.quotation.case,
            this.deliveryNoteData
          );
          this.deliveryNoteData.quotation.case.id = case_id;
          this.formData.special_printing_detail =
            this.deliveryNoteData.special_printing_detail;
        }
      } finally {
        this.loading = false;
      }
    },
    /**
     * @vuese
     * 見積額取得メソッド
     */
    async quotationAmount() {
      const { total_amount } = await this.$api.dispatch(
        "GET",
        "quotations",
        "total_amount",
        this.deliveryNoteData.quotation.id
      );
      this.totalAmount = total_amount;
    },

    /**
     * @vuese
     * 検収確認書作成メソッド
     */
    async certificateReportCreate() {
      const newCertificateReport = await this.$api.create("certificates", {
        quotation: this.quotationId,
        delivery_note: this.id,
      });
      await this.$router.push({
        name: `certificatesReport`,
        params: { id: newCertificateReport.id },
      });
    },
    /**
     * @vuese
     * 申請却下時の処理
     */
    async changeWorkflowReject(args) {
      await this.completeCancel();
      await this.changeWorkflow(args);
    },
    /**
     * @vuese
     * 申請却下のステータス変更処理
     */
    async completeCancel() {
      if (
        this.deliveryNoteData?.quotation?.case?.status ===
        statuses.CheckingAcceptance
      ) {
        await this.$api.update(
          "cases",
          this.deliveryNoteData?.quotation?.case?.id,
          { status: statuses.Ordered }
        );
      }
    },
    /**
     * @vuese
     * ワークフロー更新時の処理
     */
    async changeWorkflow(args) {
      this.workflowRefreshKey++;
      await Promise.all([
        this.getDeliveryNoteWorkflowStatus(),
        this.deliveryNoteRelord(),
      ]);
      if (args.action === "applicate") {
        // 申請の場合は備考が変更されている可能性があるので、納品書情報を更新する
        const deliveryNoteData = await this.$api.getOne(
          this.$options.resource,
          this.id
        );
        const remarks = this.formData.remarks;
        deliveryNoteData.remarks = remarks;
        this.$utils.update(this.formData, deliveryNoteData);
        this.save("messages.success.workflows.applicate");
      } else {
        // 申請以外の場合はメッセージのみ表示
        this.$message.success(
          this.$t("messages.success.workflows." + args.action)
        );
      }
    },
    /**
     * @vuese
     * 納品確認書情報更新メソッド
     */
    async deliveryNoteRelord() {
      const [deliveryNoteData] = await Promise.all([
        this.$api.getOne(this.$options.resource, this.id),
      ]);
      this.deliveryNoteData.delivery_note_no =
        deliveryNoteData.delivery_note_no;
      this.deliveryNoteData.delivery_note_no_seq =
        deliveryNoteData.delivery_note_no_seq;
      this.deliveryNoteData.issue_date = deliveryNoteData.issue_date;
    },
    /**
     * @vuese
     * 検索一覧移動メソッド
     */
    async moveTop() {
      if (this.hasPerm("adcom")) {
        await this.$router.push({
          name: "caseList",
        });
      } else {
        await this.$router.push({
          name: "quotationList",
        });
      }
    },
    /**
     * @vuese
     * 案件詳細移動メソッド
     */
    async moveCase(args) {
      if (args) {
        this.$message.success(
          this.$t("messages.success.workflows." + args.action)
        );
      }
      await this.$router.push({
        name: "casesEdit",
        params: { id: this.deliveryNoteData.quotation.case.id },
      });
    },
    /**
     * @vuese
     * 納品確認書のワークフロー状況取得メソッド
     */
    async getDeliveryNoteWorkflowStatus() {
      const { status } = await this.$api.dispatch(
        "GET",
        this.$options.resource,
        "get_workflow_status",
        this.id
      );
      this.deliveryNoteWorkflowStatus = status;
    },
    /**
     * @vuese
     * 検収確認書のワークフロー状況取得メソッド
     */
    async getCertificateWorkflowStatus() {
      if (this.certificate && this.certificate.length > 0) {
        const { status } = await this.$api.dispatch(
          "GET",
          "certificates",
          "get_workflow_status",
          this.certificate[0].id
        );
        this.certificateWorkflowStatus = status;
      }
    },
    /**
     * @vuese
     * PDF出力
     */
    async print() {
      const { data, filename } = await this.$api.getPDF(
        this.$options.resource,
        this.id
      );
      saveAs(data, filename);
    },
    toLocaleString(number) {
      return number?.toLocaleString() || "";
    },
  },
};
</script>
