<template>
  <v-navigation-drawer app :mini-variant="!showMenu">
    <v-list nav dense>
      <AppMenuItem
        v-for="item in authedMenus"
        :key="item.key"
        :title="item.title"
        :icon="item.icon"
        :link="item.link"
        :params="item.params"
        :tooltipDisabled="showMenu"
      ></AppMenuItem>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import AppMenuItem from "./AppMenuItem.vue";
import { fullMenus } from "../../../configs/menuConfig";
import { mapGetters } from "vuex";

export default {
  components: { AppMenuItem },
  props: {
    showMenu: { type: Boolean, required: false, default: true },
  },
  data() {
    return { items: fullMenus };
  },
  computed: {
    ...mapGetters(["identity"]),
    authedMenus() {
      const userIdentity = this.identity;
      const menuItems = [];
      fullMenus.forEach(
        ({ key, title, icon, params = {}, identities = [] }) => {
          if (identities.some((name) => userIdentity[name])) {
            menuItems.push({
              key,
              link: key.split("-")[0],
              title,
              icon,
              params,
            });
          }
        }
      );
      return menuItems;
    },
  },
};
</script>

<style></style>
