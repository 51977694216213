import { helpers } from "vuelidate/lib/validators";

export const any = (otherFields) =>
  helpers.withParams({ type: "any", otherFields }, function (value, parentVm) {
    const allValues = [
      ...otherFields.map((field) => helpers.ref(field, this, parentVm)),
      value,
    ];
    return allValues.some(Boolean);
  });

export const alreadyExist = (field) =>
  helpers.withParams(
    { type: "alreadyExist", field },
    function (value, parentVm) {
      const messages = helpers.ref(
        function () {
          return this.serverErrors[field];
        },
        this,
        parentVm
      );
      return !(messages && messages[0] === "already_exist");
    }
  );
