var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"lighten-5"},[_c('v-card',{staticClass:"mt-2 pa-4"},[_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.data,"server-items-length":_vm.total,"loading":_vm.loading,"hide-default-footer":""},on:{"click:row":_vm.edit},scopedSlots:_vm._u([{key:"item.request_title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"request-title-column"},[_vm._v(" "+_vm._s(item.request_title)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-card',{staticClass:"text-center py-2",attrs:{"color":item.status.display_color,"dark":"","width":"8rem"}},[_vm._v(" "+_vm._s(item.status.adcom_name)+" ")])]}},{key:"item.quotation",fn:function(ref){
var item = ref.item;
return [(item.quotation_id)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.showReport(item.quotation_id)}}},[_vm._v(" "+_vm._s(_vm.$t("resources.quotations.name"))+" ")]):_vm._e()]}},{key:"item.quotation_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toLocaleString(item.quotation_amount))+" ")]}},{key:"item.order_receipt",fn:function(ref){
var item = ref.item;
return [(item.order_receipt_id)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.showOrderReceiptReport(item.order_receipt_id)}}},[_vm._v(" "+_vm._s(_vm.$t("resources.orderReceipts.name"))+" ")]):_vm._e()]}},{key:"item.invoice",fn:function(ref){
var item = ref.item;
return [(item.delivery_note_id)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.showInvoiceReport(item.delivery_note_id)}}},[_vm._v(" "+_vm._s(_vm.$t("resources.deliveryNotes.name"))+" ")]):_vm._e()]}},{key:"item.delete_category",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.deleteCategoryDisplay(item))+" ")]}}],null,true)}),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary mt-3"},on:{"click":_vm.click}},[_vm._v(" "+_vm._s(_vm.$t("actions.export"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }