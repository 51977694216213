/** ステータス */
export const workfrowStatuses = {
  /** ワークフローが存在しない(0) */
  NoWorkfrow: 0,
  /** 未承認(1) */
  Unapproved: 1,
  /** 申請(2) */
  Request: 2,
  /** 承認(3) */
  Approval: 3,
  /** 却下(4) */
  Rejection: 4,
  /** 中止(5) */
  Stop: 5,
};
