var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"lighten-5"},[_c('v-card',{staticClass:"mt-2 pa-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.data,"server-items-length":_vm.total,"loading":_vm.loading,"show-select":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.workflow_case.status",fn:function(ref){
var item = ref.item;
return [_c('v-card',{staticClass:"text-center py-2",attrs:{"color":item.workflow_case.status.display_color,"dark":"","width":"8rem"}},[_vm._v(" "+_vm._s(item.workflow_case.status.client_name)+" ")])]}},{key:"item.workflow_case.request_title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"request-title-column"},[_vm._v(" "+_vm._s(item.workflow_case.request_title)+" ")])]}},{key:"item.report",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.showReport(item)}}},[_vm._v(" "+_vm._s(_vm.$t(("resources." + _vm.resource + ".name")))+" ")])]}},{key:"item.case",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.showCase(item)}}},[_vm._v(" "+_vm._s(_vm.$t("resources.cases.name"))+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-card-actions',[(_vm.isSelected)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.bulkApprove}},[_vm._v(" "+_vm._s(_vm.$t("resources.bulkApprove.actions.bulkApprove"))+" ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }