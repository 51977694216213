import axios from "axios";

import store from "../../store";
import i18n from "../i18n";
import qs from "query-string";

import handle401 from "./handle401";
// import { translateHttpCode } from "../../utils";
import utils from "../../utils";

axios.defaults.baseURL = process.env.VUE_APP_API_PREFIX;
axios.defaults.timeout = 30000;
axios.defaults.xsrfCookieName = "XSRF-TOKEN";
axios.defaults.xsrfHeaderName = "X-XSRF-TOKEN";
axios.defaults.paramsSerializer = (params) => qs.stringify(params);

axios.interceptors.request.use((config) => {
  // TOKENを取得してリクエストに設定
  if (store.getters.token) {
    config.headers.authorization = `Bearer ${store.getters.token}`;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (!err) return Promise.reject();
    // キャンセルエラー以外の場合、pendingPoolから削除
    if (axios.isCancel(err)) {
      throw new axios.Cancel(err.message || i18n.t("httpErrors.httpCancel"));
    }

    const { response } = err;
    if (response) {
      const { status, config } = response;
      if (status === 401) {
        return handle401(config);
      } else {
        err.code = status;
        err.message = utils.translateHttpCode(status);
        // 開発環境の場合のみエラーをコンソールに出力
        process.env.NODE_ENV !== "production" && console.log("エラー: ", err);
      }
    } else {
      if (err.stack?.includes("timeout")) {
        err.message = i18n.t("httpErrors.httpTimeout");
      } else {
        err.message = i18n.t("httpErrors.default", { error: "" });
      }
    }
    return Promise.reject(err);
  }
);

if (
  process.env.NODE_ENV === "development" &&
  process.env.VUE_APP_USE_MOCK === "true"
)
  require("../../mock/data").default(axios);

export default axios;
