<template>
  <!--承認フロー-->
  <v-skeleton-loader type="card" v-if="!id"></v-skeleton-loader>
  <v-card
    v-else
    class="mx-auto mt-5"
    width="60rem"
    :loading="loading"
    :disabled="disableAll"
  >
    <v-system-bar color="orange darken-2" dark class="pa-5">
      {{ $t("resources.workflows.name") }}
    </v-system-bar>
    <v-card-text class="px-7 pt-7 pb-0">
      <form-wrapper :validator="$v.formData">
        <v-form lazy-validation>
          <v-row class="d-flex justify-space-between">
            <v-col cols="12" sm="3">
              <v-text-field
                readonly
                outlined
                :label="$t('resources.workflows.forms.applicant')"
                :value="applicantName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <form-group
                :validator="$v.approver1Id"
                attribute="resources.workflows.forms.approver1"
              >
                <template v-slot="{ attrs }">
                  <v-autocomplete
                    v-bind="attrs"
                    v-if="control.details[1] !== false"
                    v-model="approver1Id"
                    :items="users"
                    item-text="all_name"
                    item-value="id"
                    outlined
                    :label="$t('resources.workflows.forms.approver1')"
                    clearable
                  ></v-autocomplete>
                  <v-text-field
                    v-else
                    readonly
                    outlined
                    :label="$t('resources.workflows.forms.approver1')"
                    :value="approver1Name"
                  ></v-text-field>
                </template>
              </form-group>
            </v-col>
            <v-col cols="12" sm="3">
              <v-autocomplete
                v-if="control.details[2] !== false"
                v-model="approver2Id"
                :items="users"
                item-text="all_name"
                item-value="id"
                outlined
                :label="$t('resources.workflows.forms.approver2')"
                clearable
              ></v-autocomplete>
              <v-text-field
                v-else
                readonly
                outlined
                :label="$t('resources.workflows.forms.approver2')"
                :value="approver2Name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-textarea
            v-model="formData.action_comment"
            outlined
            label="コメント"
          >
          </v-textarea>
        </v-form>
      </form-wrapper>
    </v-card-text>
    <v-card-actions class="px-7 pb-7" v-if="screen == 'certificate'">
      <v-btn
        @click="dispatch('applicate', 'applicate')"
        color="primary"
        width="8rem"
        v-if="control.applicate"
        >{{ $t("resources.workflows.actions.applicate") }}</v-btn
      >
      <v-btn
        @click="dispatch('approve', 'approve')"
        color="primary"
        width="8rem"
        v-if="control.approve"
        >{{ $t("resources.workflows.actions.approve") }}</v-btn
      >
      <v-btn
        @click="dispatch('reject', 'reject')"
        color="primary"
        width="8rem"
        v-if="control.reject"
        >{{ $t("resources.workflows.actions.reject") }}</v-btn
      >
      <v-btn
        @click="dispatch('cancel', 'cancel')"
        color="error"
        width="8rem"
        class="ml-auto"
        v-if="control.cancel"
        >{{ $t("resources.workflows.actions.cancel") }}</v-btn
      >
    </v-card-actions>
    <v-card-actions class="px-7 pb-7" v-else>
      <v-btn
        @click="dispatch('applicate', 'applicate')"
        color="primary"
        width="8rem"
        v-if="control.applicate && isAdcomStaff"
        >{{ $t("resources.workflows.actions.applicate") }}</v-btn
      >
      <v-btn
        @click="dispatch('approve', 'approve')"
        color="primary"
        width="8rem"
        v-if="control.approve && isAdcomManager"
        >{{ $t("resources.workflows.actions.approve") }}</v-btn
      >
      <v-btn
        @click="dispatch('applicate_client', 'applicate')"
        color="primary"
        width="8rem"
        v-if="control.applicate && isClientStaff"
        >{{ $t("resources.workflows.actions.applicate_client") }}</v-btn
      >
      <v-btn
        @click="dispatch('approve_client', 'approve')"
        color="primary"
        width="8rem"
        v-if="control.approve && isClientManager"
        >{{ $t("resources.workflows.actions.approve_client") }}</v-btn
      >
      <v-btn
        @click="dispatch('reject', 'reject')"
        color="primary"
        width="8rem"
        v-if="control.reject"
        >{{ $t("resources.workflows.actions.reject") }}</v-btn
      >
      <v-btn
        @click="dispatch('cancel', 'cancel')"
        color="error"
        width="8rem"
        class="ml-auto"
        v-if="control.cancel"
        >{{ $t("resources.workflows.actions.cancel") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import singlePageListMixin from "../../mixins/singlePageListMixin";
import groupPermissionMixin from "../../mixins/groupPermissionMixin";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  resource: "workflows",
  mixins: [singlePageListMixin, groupPermissionMixin],
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    screen: {
      type: String,
      default: null,
    },
  },
  validations() {
    return {
      formData: {},
      approver1Id: { required },
    };
  },
  data() {
    return {
      loading: false,
      formData: {
        workflow_details: [
          {
            id: null,
            user: null,
          },
          {
            id: null,
            user: null,
          },
          {
            id: null,
            user: null,
          },
        ],
        action_comment: "",
        version: null,
      },
      workflow_detail_data: [],
      users: [],
      control: {
        applicate: false,
        approve: false,
        reject: false,
        cancel: false,
        details: [],
      },
    };
  },
  watch: {
    id: async function (newVal) {
      if (newVal) {
        await this.fetchInitData(newVal);
      }
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    applicantName() {
      return (
        this.workflow_detail_data[0]?.user_fullname ||
        this.currentUser.full_name
      );
    },
    approver1Name() {
      return this.workflow_detail_data[1]?.user_fullname || "";
    },
    approver2Name() {
      return this.workflow_detail_data[2]?.user_fullname || "";
    },
    approver1Id: {
      get: function () {
        return this.formData.workflow_details[1].user || null;
      },
      set: function (newValue) {
        this.formData.workflow_details[1].user = newValue;
      },
    },
    approver2Id: {
      get: function () {
        return this.formData.workflow_details[2].user || null;
      },
      set: function (newValue) {
        this.formData.workflow_details[2].user = newValue;
      },
    },
    disableAll() {
      return !Object.values(this.control)
        .filter((v) => typeof v === "boolean")
        .some(Boolean);
    },
    isClientStaff() {
      return this.hasPerm("clientStaff");
    },
    isClientManager() {
      return this.hasPerm("clientManager");
    },
    isAdcomStaff() {
      return this.hasPerm("adcomStaff");
    },
    isAdcomManager() {
      return this.hasPerm("adcomManager");
    },
  },
  methods: {
    async fetchInitData(id) {
      if (id) {
        try {
          this.loading = true;
          const [{ workflow_details, ...workflowData }, control] =
            await Promise.all([
              this.$api.getOne(this.$options.resource, id),
              this.$api.dispatch("GET", this.$options.resource, "control", id),
            ]);
          this.$utils.update(this.formData, workflowData);
          if (control.show_last_approver === false) {
            workflow_details.pop();
          }
          workflow_details.forEach((detailData, idx) => {
            this.$utils.update(this.formData.workflow_details[idx], detailData);
          });
          this.workflow_detail_data = workflow_details;
          this.$utils.update(this.control, control);
          if (
            this.control.applicate ||
            this.control.approve ||
            this.control.reject
          ) {
            this.users = await this.$api.dispatch(
              "GET",
              "users",
              "sameIdentityManager"
            );
          }
          if (this.control.applicate) {
            this.formData.workflow_details[0].user = this.currentUser.id;
            const defaultWorkflowData = await this.$api.getMany(
              "defaultWorkflows",
              {
                user: this.currentUser.id,
              }
            );
            defaultWorkflowData.forEach((defaultData, idx) => {
              this.formData.workflow_details[idx + 1].user =
                defaultData.workflow_user;
            });
          }
        } catch (err) {
          console.log(err);
          this.$utils.update(this.control, {
            applicate: false,
            approve: false,
            reject: false,
            cancel: false,
          });
        } finally {
          this.loading = false;
        }
      }
    },
    async dispatch(display_action, action) {
      if (!action) return;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (action !== "cancel") {
          if (
            !(await this.$root.$confirm(
              "actions.confirm",
              "resources.workflows.messages.confirm." + display_action
            ))
          ) {
            return;
          }
        }

        try {
          this.loading = true;
          const { workflow_details: workflow_details_payload, ...payload } =
            this.formData;
          payload.workflow_details = workflow_details_payload
            .filter(({ user }) => user)
            .map(({ id, user }) => {
              if (id) {
                return { id, user };
              } else {
                return { user };
              }
            });
          const { workflow_details, ...workflowData } =
            await this.$api.dispatch(
              "PATCH",
              this.$options.resource,
              action,
              this.id,
              payload
            );
          const control = await this.$api.dispatch(
            "GET",
            this.$options.resource,
            "control",
            this.id
          );
          this.$utils.update(this.formData, workflowData);
          workflow_details.forEach((detailData, idx) => {
            this.$utils.update(this.formData.workflow_details[idx], detailData);
          });
          this.workflow_detail_data = workflow_details;
          this.$utils.update(this.control, control);
          this.$emit("updated", { action: action });
        } catch {
          this.$utils.update(this.control, {
            applicate: false,
            approve: false,
            reject: false,
            cancel: false,
          });
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style></style>
