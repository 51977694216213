<template>
  <v-container class="lighten-5">
    <v-card class="mt-2 pa-4">
      <v-container>
        <v-data-table
          item-key="id"
          :headers="headers"
          :items="data"
          :server-items-length="total"
          :loading="loading"
          @click:row="edit"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:[`item.request_title`]="{ item }">
            <div class="request-title-column">
              {{ item.request_title }}
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-card
              :color="item.status.display_color"
              dark
              width="8rem"
              class="text-center py-2"
            >
              {{ item.status.adcom_name }}
            </v-card>
          </template>
          <template v-slot:[`item.quotation`]="{ item }">
            <v-btn
              v-if="item.quotation_id"
              @click.stop="showReport(item.quotation_id)"
              color="primary"
            >
              {{ $t("resources.quotations.name") }}
            </v-btn>
          </template>
          <template v-slot:[`item.quotation_amount`]="{ item }">
            {{ toLocaleString(item.quotation_amount) }}
          </template>
          <template v-slot:[`item.order_receipt`]="{ item }">
            <v-btn
              v-if="item.order_receipt_id"
              @click.stop="showOrderReceiptReport(item.order_receipt_id)"
              color="primary"
            >
              {{ $t("resources.orderReceipts.name") }}
            </v-btn>
          </template>
          <template v-slot:[`item.invoice`]="{ item }">
            <v-btn
              v-if="item.delivery_note_id"
              @click.stop="showInvoiceReport(item.delivery_note_id)"
              color="primary"
            >
              {{ $t("resources.deliveryNotes.name") }}
            </v-btn>
          </template>
          <template v-slot:[`item.delete_category`]="{ item }">
            {{ deleteCategoryDisplay(item) }}
          </template>
        </v-data-table>
        <v-btn class="ml-4" @click="click" color="primary mt-3">
          {{ $t("actions.export") }}
        </v-btn>
      </v-container>
    </v-card>
  </v-container>
</template>
<script>
import groupPermissionMixin from "../../mixins/groupPermissionMixin";
import { saveAs } from "file-saver";

/**
 * @group 受注管理
 * 納品可能一覧表示画面<br>
 * 機能仕様書：5.11
 */
export default {
  resource: "completedCases",
  mixins: [groupPermissionMixin],
  data() {
    return {
      loading: true,
      total: 0,
      data: [],
    };
  },
  computed: {
    /**
     * @vuese
     * 明細のヘッダ項目情報
     */
    headers() {
      return [
        {
          text: this.$t("resources.cases.fields.preferred_due_date"),
          sortable: false,
          value: "preferred_due_date",
        },
        {
          text: this.$t("resources.cases.fields.order_date"),
          sortable: false,
          value: "order_date",
        },
        {
          text: this.$t("resources.cases.fields.request_title"),
          sortable: false,
          value: "request_title",
        },
        {
          text: this.$t("resources.cases.fields.client_user"),
          sortable: false,
          value: "client_user",
        },
        {
          text: this.$t("resources.cases.fields.client_department"),
          sortable: false,
          value: "client_department",
        },
        {
          text: this.$t("resources.cases.fields.contact_user"),
          sortable: false,
          value: "contact_user",
        },
        {
          text: this.$t("resources.cases.fields.quotation_amount"),
          sortable: false,
          value: "quotation_amount",
        },
        {
          text: this.$t("resources.cases.fields.status"),
          sortable: false,
          value: "status",
        },
        {
          text: this.$t("resources.cases.fields.scheduled_due_date"),
          sortable: false,
          value: "scheduled_due_date",
        },
        {
          text: this.$t("resources.cases.fields.case_no"),
          sortable: false,
          value: "case_no",
        },
        {
          text: this.$t("resources.quotations.name"),
          sortable: false,
          value: "quotation",
        },
        {
          text: this.$t("resources.orderReceipts.name"),
          sortable: false,
          value: "order_receipt",
        },
        {
          text: this.$t("resources.deliveryNotes.name"),
          sortable: false,
          value: "invoice",
        },
        {
          text: this.$t("resources.certificates.name"),
          sortable: false,
          value: "certificate",
        },
        {
          text: this.$t("resources.cases.fields.delete_category"),
          sortable: false,
          value: "delete_category",
        },
      ];
    },
  },
  async created() {
    await this.search();
  },
  methods: {
    click() {
      this.downloadCSV();
    },

    /**
     * @vuese
     * 完了案件取得メソッド
     */
    async search() {
      this.loading = true;
      try {
        const { total, data } = await this.$api.getList(
          this.$options.resource,
          {}
        );
        this.total = total;
        this.data = data;
      } finally {
        this.loading = false;
      }
    },
    async downloadCSV() {
      this.loading = true;
      const filters = this.$route.query;
      try {
        const { data, filename } = await this.$api.getCsv(
          this.$options.resource,
          filters
        );
        saveAs(data, filename);
      } finally {
        this.loading = false;
      }
    },
    /**
     * @vuese
     * 案件編集遷移メソッド
     * @arg id - 案件のID
     */
    edit({ id }) {
      this.$router.push({
        name: "casesEdit",
        params: { id },
      });
    },
    /**
     * @vuese
     * 見積書遷移メソッド
     * @arg id - 見積書のID
     */
    showReport(id) {
      this.$router.push({
        name: `quotationsReport`,
        params: { id },
      });
    },
    /**
     * @vuese
     * 請書遷移メソッド
     * @arg id - 請書のID
     */
    showOrderReceiptReport(id) {
      this.$router.push({
        name: `orderReceiptsReport`,
        params: { id },
      });
    },
    /**
     * @vuese
     * 納品確認書遷移メソッド
     * @arg id - 納品確認書のID
     */
    showInvoiceReport(id) {
      this.$router.push({
        name: `deliveryNotesReport`,
        params: { id },
      });
    },
    /**
     * @vuese
     * 削除区分翻訳メソッド
     * @arg item - 案件item
     */
    deleteCategoryDisplay({ delete_category }) {
      if (["1", "2", "3"].includes(delete_category)) {
        return this.$t(
          `resources.cases.constants.delete_category.adcom.${delete_category}`
        );
      }
      return "";
    },
    toLocaleString(number) {
      return number?.toLocaleString() || "";
    },
  },
};
</script>

<style>
.request-title-column {
  max-width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
